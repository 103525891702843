import React, { FC } from 'react';
import { Row } from 'antd';

import { DashboardPhotoListItem, Nullable } from '@typings';

import { PhotoElement } from './PhotoElement/PhotoElement';
import { PhotosInfo } from './PhotoInfo/PhotosInfo';

import styles from './photos.module.scss';

interface Props {
    photoList?: Nullable<DashboardPhotoListItem[]>;
}

export const Photos: FC<Props> = ({ photoList }) => {
    const photosListEl = photoList?.map((photoData) => {
        const { item, request } = photoData;

        const photosEl = renderPhotos(photoData);

        return (
            <div key={item.id} className={styles.row}>
                <Row gutter={[16, 16]} className={styles.container}>
                    {photosEl}
                </Row>

                <div style={{ padding: '10px', borderTop: '1px #d5d5d5 solid' }}>
                    <PhotosInfo item={item} request={request} />
                </div>
            </div>
        );
    });

    return <div>{photosListEl}</div>;
};

const renderPhotos = (photoData: DashboardPhotoListItem) => {
    return photoData.photos.map(({ photoId, ...photo }) => {
        return (
            <PhotoElement
                key={photoId}
                item={photoData.item}
                type={photo.type}
                url={photo.url}
                timestamp={photo.timestamp}
                rejected={photo.rejected}
                confirmed={photo.confirmed}
                request={photoData.request}
                isSuspicious={photo.isSuspicious}
                workerId={photo.workerId}
                workerName={photo.workerName}
                discrepancyCheck={photo.discrepancyCheck}
                id={photoId}
            />
        );
    });
};
