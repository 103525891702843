import _ from 'lodash';

import {
    DeliveryExpandedRequestValues,
    DeliveryRequestItem,
    DeliveryRequestOrExpanded,
    DeliveryRequestValues,
    TableCellColumn,
} from '@typings';

export const getCellValue = (
    column: TableCellColumn,
    request: DeliveryRequestOrExpanded
): DeliveryExpandedRequestValues | DeliveryRequestValues => {
    const { dataIndex, key } = column;
    const requestItem = request[dataIndex as keyof DeliveryRequestOrExpanded];

    if (dataIndex !== key && _.isArray(requestItem)) {
        return requestItem[0][key as keyof DeliveryRequestItem] as string;
    }

    return requestItem;
};
