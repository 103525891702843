import { FC, memo, useCallback, useEffect } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select, Tooltip, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { EyeOutlined } from '@ant-design/icons';
import { ColumnsViewControllerModal } from '@components/modals';
import RemoteSelect from '@components/RemoteSelect';
import { API_URI, DATE_FORMAT } from '@constants';
import { openModal } from '@store/modal';
import {
    FetchWorkersReportParams,
    FilterListColumns,
    OnColumnSortChange,
    OnColumnViewChange,
    RenderModal,
    WorkersReportFilterFormValues,
} from '@typings';
import { mapperWorkersReportFiltersForm } from '@utils/mapperWorkersReport';

import { availabilityOptions, readinessOptions, statusCodeOptions } from './config';
import { FormItem } from './FormItem';
import { useSearchParams } from './hooks';

import styles from './workersReportForm.module.scss';

const { Text } = Typography;

interface Props {
    onFinish: (params: FetchWorkersReportParams) => void;
    onColumnViewChange: OnColumnViewChange;
    filterListColumns: FilterListColumns;
    onColumnSortChange: OnColumnSortChange;
    workersCount: number;
}

const sortForCallTooltip = `
Устанавливает сортировку по нескольким столбцам:
- Доброжелателен (сначала сортируется по этому столбцу)
- Надежен
- Активность
- Доступность (по этому столбцу сортируется в последнюю очередь)`;

export const WorkersReportForm: FC<Props> = memo(
    ({ onFinish, workersCount, onColumnViewChange, filterListColumns, onColumnSortChange }) => {
        const [form] = useForm();
        const { formValues, onParamsChange } = useSearchParams();

        const onFormFilterFinish = useCallback(
            (formValues: Partial<WorkersReportFilterFormValues>) => {
                const formattedFormValues = mapperWorkersReportFiltersForm(formValues);

                onParamsChange(formattedFormValues);
                onFinish(formattedFormValues);
            },
            [onFinish, onParamsChange]
        );

        const onFormSearchFinish = useCallback(
            ({ search_text }: Pick<WorkersReportFilterFormValues, 'search_text'>) => {
                const formattedFormValues = mapperWorkersReportFiltersForm({
                    ...formValues,
                    search_text,
                });

                onFinish({ search_text });
                onParamsChange(formattedFormValues);
            },
            [onFinish, onParamsChange, formValues]
        );

        useEffect(() => {
            form.submit();
        }, []);

        const openColumnsViewModal = () => {
            openModal(renderViewColumnsModal(onColumnViewChange, filterListColumns));
        };

        const onSortForCall = () => {
            onColumnSortChange(
                {
                    isBenevolent: 'ascend',
                    reliability: 'ascend',
                    status: 'ascend',
                    availability: 'ascend',
                },
                true
            );
        };

        const onSortClear = () => {
            onColumnSortChange(null);
        };

        return (
            <div className={styles.main}>
                <Form
                    layout='vertical'
                    form={form}
                    onFinish={onFormFilterFinish}
                    className={styles.form_first}
                    initialValues={formValues}
                >
                    <Row gutter={20} wrap={false}>
                        <FormItem span={4} name='status_code' label='Активность'>
                            <Select
                                options={statusCodeOptions}
                                placeholder='Активность'
                                mode='multiple'
                                allowClear
                            />
                        </FormItem>

                        <FormItem span={4} name='availability' label='Доступность'>
                            <Select
                                options={availabilityOptions}
                                mode='multiple'
                                placeholder='Доступность'
                                allowClear
                            />
                        </FormItem>

                        <FormItem span={4} name='readiness' label='Готовность'>
                            <Select
                                options={readinessOptions}
                                mode='multiple'
                                placeholder='Готовность'
                                allowClear
                            />
                        </FormItem>

                        <Col span={3.5}>
                            <RemoteSelect.Item
                                form={form}
                                label='Регион'
                                key='zone'
                                name='zone'
                                placeholder='Регион'
                                url={API_URI.AUTOCOMPLETE_ZONE}
                                required={false}
                            />
                        </Col>

                        <FormItem span={2.7} name='last_call_date' label='Дата посл. звонка'>
                            <DatePicker format={DATE_FORMAT} placeholder='Дата посл. звонка' />
                        </FormItem>

                        <FormItem span={2.7} name='planned_contact_day' label='Дата след. контакта'>
                            <DatePicker format={DATE_FORMAT} placeholder='Дата след. контакта' />
                        </FormItem>

                        <FormItem style={{ marginTop: 29 }} span={2}>
                            <Button type='primary' htmlType='submit'>
                                Показать
                            </Button>
                        </FormItem>
                    </Row>
                </Form>

                <Form layout='vertical' onFinish={onFormSearchFinish}>
                    <Row gutter={20} wrap={false}>
                        <Col>
                            <Text strong>{workersCount} чел.</Text>
                        </Col>

                        <FormItem span={4} name='search_text'>
                            <Input placeholder='Поиск' />
                        </FormItem>

                        <FormItem span={1.5}>
                            <Button type='primary' htmlType='submit'>
                                Найти
                            </Button>
                        </FormItem>

                        <FormItem span={1.5}>
                            <Button onClick={openColumnsViewModal}>
                                <EyeOutlined />
                                Вид
                            </Button>
                        </FormItem>

                        <FormItem span={3.5}>
                            <Tooltip
                                placement='topLeft'
                                title={sortForCallTooltip}
                                overlayClassName={styles.sort_for_call_tooltip}
                            >
                                <Button onClick={onSortForCall}>Сортировать для обзвона</Button>
                            </Tooltip>
                        </FormItem>

                        <FormItem span={4}>
                            <Button onClick={onSortClear}>Cбросить сортировку</Button>
                        </FormItem>
                    </Row>
                </Form>
            </div>
        );
    }
);

const renderViewColumnsModal =
    (onColumnViewChange: OnColumnViewChange, filterListColumns: FilterListColumns): RenderModal =>
    (isOpen) => {
        return (
            <ColumnsViewControllerModal
                onColumnViewChange={onColumnViewChange}
                filterListColumns={filterListColumns}
                isOpen={isOpen}
            />
        );
    };
