import { FC } from 'react';
import { MapConsumer, ZoomControl } from 'react-leaflet';

import {
    DeliveryMapRequestBoundingBox,
    DeliveryMapRequestItem,
    DeliveryMapRequestSelectionState,
    DeliveryMapRequestWorkerItem,
    SetState,
} from '@typings';

import { useChangeView } from './hooks';

interface Props {
    boundingBox: DeliveryMapRequestBoundingBox | undefined;
    requestsList: DeliveryMapRequestItem[];
    workersList: DeliveryMapRequestWorkerItem[];
    setSelection: SetState<DeliveryMapRequestSelectionState>;
    currentRequest: DeliveryMapRequestSelectionState['request'];
    currentWorker: DeliveryMapRequestSelectionState['worker'];
}

export const MapController: FC<Props> = ({
    currentRequest,
    currentWorker,
    boundingBox,
    requestsList,
    workersList,
    setSelection,
}) => {
    useChangeView({
        currentRequest,
        currentWorker,
        boundingBox,
        requestsList,
        workersList,
    });

    const clearSelection = () => {
        setSelection({ request: null, worker: null });
    };

    return (
        <>
            <MapConsumer>
                {(map) => {
                    map.on('click', clearSelection);
                    return null;
                }}
            </MapConsumer>

            <ZoomControl position='topright' />
        </>
    );
};
