import { FC, useContext } from 'react';

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { banWorker, unbanWorker } from '@api';
import { WorkersReportTableContext } from '@providers';
import { WorkersReportWorkerId, WorkersReportWorkerStatusCode } from '@typings';
import { showError } from '@utils/show_error';

interface Props {
    workerId: WorkersReportWorkerId;
    action: number;
}

export const Action: FC<Props> = ({ workerId, action }) => {
    const { updateWorker } = useContext(WorkersReportTableContext);

    const onWorkerBan = () => {
        banWorker({
            params: {
                worker: workerId,
            },
            onSuccess() {
                updateWorker(workerId, { action: -1 });
            },
            onError(error) {
                showError(error.message);
            },
        });
    };

    const onWorkerUnban = () => {
        unbanWorker({
            params: {
                worker: workerId,
            },
            onSuccess() {
                updateWorker(workerId, { action: 2 });
            },
            onError(error) {
                showError(error.message);
            },
        });
    };

    const isBanned = action === WorkersReportWorkerStatusCode.Banned;

    const style = { color: isBanned ? 'green' : 'red' };

    if (isBanned) {
        return <CheckCircleOutlined style={style} onClick={onWorkerUnban} title='Разбанить' />;
    }

    return <CloseCircleOutlined style={style} onClick={onWorkerBan} title='Забанить' />;
};
