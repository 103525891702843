import { FC, useCallback, useMemo, useState } from 'react';
import { Anchor, Button, List, message, Typography } from 'antd';
import moment from 'moment';

import { CheckCircleTwoTone, CloseSquareTwoTone, MailOutlined } from '@ant-design/icons';
import { fetchReconciliations } from '@api';
import { BASE_URL_SERVER_API } from '@constants';
import { CalendarReconciliationsItem, Nullable } from '@typings';
import { authFetch } from '@utils/auth/auth';
const { Link } = Anchor;

export function statusToText(status?: string): string {
    switch (status) {
        case 'new':
            return 'новая';
        case 'ready_for_notification':
            return 'готова к отправке';
        case 'notification_sent':
            return 'отправлена';
        case 'confirmed':
            return 'подтверждена';
        default:
            return 'неизвестный статус';
    }
}

interface TooltipRenderProps {
    id?: string;
    status?: string;
    actions?: {
        confirm_unconfirm: boolean;
    };
}

export const TooltipRender: FC<TooltipRenderProps> = ({ id, actions }) => {
    const { confirm_unconfirm } = actions || {};
    const [state, setState] = useState<Nullable<CalendarReconciliationsItem>>(null);

    useMemo(() => {
        if (!id) {
            return;
        }

        setState(null);
        fetchReconciliations({
            params: {
                id,
            },
            onSuccess({ data }) {
                setState(data);
            },
        });
    }, [setState, id]);

    const statusText = useMemo(() => {
        return statusToText(state?.status);
    }, [state]);

    const sendEmail = useCallback(() => {
        authFetch(`${BASE_URL_SERVER_API}delivery/reconciliations/${id}/email/`, {
            params: {},
            method: 'POST',
        })
            .then((response) => {
                switch (response.status) {
                    case 204:
                        message.success({
                            duration: 1.5,
                            content: 'у последнего письма (last_notification) is_ok=True',
                        });
                        break;
                    default:
                        message.success({
                            duration: 1.5,
                            content: 'Письмо успешно отправлено!',
                        });
                }
            })
            .catch(() => {
                message.error({
                    duration: 1.5,
                    content: 'При отправке письма что-то пошло не так : (',
                });
            });
    }, [id]);

    const confirmUnconfirm = (confirm: boolean, okMsg: string, failMsg: string) => {
        const data = new FormData();
        data.append('confirm', confirm.toString());
        authFetch(`${BASE_URL_SERVER_API}delivery/reconciliations/${id}/confirm/`, {
            data: data,
            params: {
                confirm: true,
            },
            method: 'POST',
        })
            .then(() => {
                message.success({ duration: 1.5, content: okMsg });
            })
            .catch(() => {
                message.error({ duration: 1.5, content: failMsg });
            });
    };

    const sendConfirm = () => {
        confirmUnconfirm(true, 'Подтверждена', 'Ошибка подтверждения');
    };

    const sendUnconfirm = () => {
        confirmUnconfirm(false, 'Подтверждение сброшено', 'Ошибка сброса подтверждения');
    };

    const DATE_TIME_FORMAT = 'HH:mm DD.MM.YY';

    return (
        <List size={'small'}>
            <List.Item style={{ padding: '0px' }}>
                <Typography.Text strong={true}>{statusText}</Typography.Text>
            </List.Item>

            {state?.confirmation && (
                <List.Item style={{ padding: '0px' }}>
                    <Typography.Text>
                        email: {state?.confirmation?.author_email ?? ''}
                    </Typography.Text>
                </List.Item>
            )}

            {['ready_for_notification', 'notification_sent'].includes(state?.status || '') && (
                <List.Item style={{ padding: '0px' }}>
                    <Button
                        type='default'
                        size={'small'}
                        icon={<MailOutlined />}
                        onClick={sendEmail}
                    />
                </List.Item>
            )}

            {state?.last_notifications && (
                <Anchor affix={false}>
                    <Link
                        href={`/media/${state?.last_notifications.attachment}`}
                        title={[
                            'отправлена на ',
                            <br key='1' />,
                            state?.last_notifications.recipient_emails.join(', '),
                            <br key='2' />,
                            'в ',
                            moment(state?.last_notifications.timestamp ?? '').format(
                                DATE_TIME_FORMAT
                            ),
                        ]}
                    />
                </Anchor>
            )}
            {state?.confirmation && (
                <List.Item style={{ padding: '0px' }}>
                    <Typography.Text>
                        {`подтверждена в ${moment(state?.confirmation?.timestamp ?? '').format(
                            DATE_TIME_FORMAT
                        )}`}
                    </Typography.Text>
                </List.Item>
            )}

            {confirm_unconfirm && state?.status === 'confirmed' && (
                <Button
                    type='default'
                    size={'small'}
                    icon={<CloseSquareTwoTone />}
                    onClick={sendUnconfirm}
                >
                    Отменить
                </Button>
            )}

            {confirm_unconfirm && state?.status !== 'confirmed' && (
                <Button
                    type='default'
                    size={'small'}
                    icon={<CheckCircleTwoTone />}
                    onClick={sendConfirm}
                >
                    Подтвердить
                </Button>
            )}
        </List>
    );
};
