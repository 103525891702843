import { RequestConfirmModal } from '@components';
import { closeModal, openModal } from '@store/modal';
import { RequestConfig, Response, ServiceFetchType } from '@typings';

type Params = RequestConfig['params'];

interface SendRequestWithConfirmArg<T, P = Params> {
    requestCallback: ServiceFetchType<T, P>;
    initialParams: Omit<P, 'force_commit'>;
    onSuccess?: (response: Response<T>) => void;
    onError?: (error: Error) => void;
    onCancel?(): void;
}

type SendRequestWithConfirm = <T, P = Params>(arg: SendRequestWithConfirmArg<T, P>) => void;

interface CreateRequestArgs<T, P = Params> {
    requestCallback: ServiceFetchType<T, P>;
    onSuccess?(data: Response): void;
    onError?(error: Error): void;
    initialParams: Params;
    isForce: boolean;
}

export const useRequestConfirm = () => {
    const sendRequestWithConfirm: SendRequestWithConfirm = <T, P>({
        onError,
        onSuccess,
        requestCallback,
        initialParams,
        onCancel: onCancelCallback,
    }: SendRequestWithConfirmArg<T, P>) => {
        const onCallbackSuccess = (data: Response) => {
            const isRequestConfirm = data.data.confirmation_required;

            if (isRequestConfirm) {
                const [message] = data.data.messages;

                const onOk = () => {
                    createRequest({
                        isForce: true,
                        initialParams,
                        onSuccess,
                        onError,
                        requestCallback,
                    });

                    closeModal();
                };

                const onCancel = () => {
                    onCancelCallback?.();
                    closeModal();
                };

                openModal((isOpen) => (
                    <RequestConfirmModal
                        isOpen={isOpen}
                        content={message}
                        closeModal={onCancel}
                        onOk={onOk}
                    />
                ));
            }

            return onSuccess?.(data);
        };

        createRequest({
            isForce: false,
            initialParams,
            onSuccess: onCallbackSuccess,
            onError,
            requestCallback,
        });
    };

    return { sendRequestWithConfirm };
};

const createRequest = <T, P>({
    isForce,
    onError,
    onSuccess,
    initialParams,
    requestCallback,
}: CreateRequestArgs<T, P>) => {
    const currentParams = {
        ...initialParams,
        force_commit: isForce,
    };

    return requestCallback({
        onError,
        onSuccess,
        params: currentParams,
    });
};
