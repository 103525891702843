import { FC, memo, useState } from 'react';
import { Select } from 'antd';

import { MANUAL_STATUSES, TABLE_CELL_STATUSES } from '@constants';
import { DeliveryRequestCellProps, DeliveryStatusList } from '@typings';

export const Status: FC<DeliveryRequestCellProps> = memo(({ onCommit, rowData, isEditing }) => {
    const [status, setStatus] = useState(rowData.status);

    const onSelect = (value: DeliveryStatusList) => {
        setStatus(value);
        onCommit?.(value);
    };

    const onBlur = () => {
        onCommit?.(status);
    };

    if (isEditing) {
        const optionsListEl = MANUAL_STATUSES.map(({ label, text }) => {
            return (
                <Select.Option
                    key={label}
                    value={label}
                    style={{
                        backgroundColor: TABLE_CELL_STATUSES[label].color,
                    }}
                >
                    {text}
                </Select.Option>
            );
        });

        return (
            <Select
                defaultValue={status}
                defaultOpen={true}
                autoFocus={true}
                onSelect={onSelect}
                onBlur={onBlur}
                size='small'
                style={{ width: '120px' }}
            >
                {optionsListEl}
            </Select>
        );
    }

    return <>{rowData.status_description}</>;
});
