import { FC, memo } from 'react';
import _ from 'lodash';

import { DeliveryCellProps } from '@typings';

import { TextCell } from '.';

export const WorkerCount: FC<DeliveryCellProps> = memo(({ onCommit, isEditing, rowData }) => {
    const workerCounts = _.flatten(rowData.items.map((item) => item.assigned_workers)).filter(
        (item, key: number, arr) => arr.map((item) => item.id).indexOf(item.id) === key
    ).length;

    return <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={workerCounts} />;
});
