import { FC, useMemo } from 'react';

import { ColumnsViewControllerModal } from '@components/modals';
import { openModal } from '@store/modal';
import { DeliveryColumn, FilterListColumns, OnColumnViewChange, RenderModal } from '@typings';

import styles from './searchForm.module.scss';

interface Props {
    columns: DeliveryColumn[];
    onColumnViewChange: OnColumnViewChange;
}

// TODO use modals/ColumnsViewController

export const ColumnsController: FC<Props> = ({ columns, onColumnViewChange }) => {
    const filterListColumns: FilterListColumns = useMemo(() => {
        return columns?.reduce((acc: FilterListColumns, column) => {
            const { checked, title, key, required } = column || {};

            if (required) {
                return acc;
            }

            const filterData = {
                isVisible: checked,
                title: title?.toString() || '',
                key: key?.toString() || '',
            };

            return [...acc, filterData];
        }, []);
    }, [columns]);

    const onModalOpen = () => {
        openModal(renderModal(filterListColumns, onColumnViewChange));
    };

    return (
        <button type='button' className={styles.view_button} onClick={onModalOpen}>
            <img alt='icon' src='/icons/view_icon.svg' />
            Вид
        </button>
    );
};

const renderModal = (
    filterListColumns: FilterListColumns,
    onColumnViewChange: OnColumnViewChange
): RenderModal => {
    return (isOpen) => {
        return (
            <ColumnsViewControllerModal
                isOpen={isOpen}
                filterListColumns={filterListColumns}
                onColumnViewChange={onColumnViewChange}
            />
        );
    };
};
