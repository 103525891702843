import { FC } from 'react';
import { Tooltip } from 'antd';
import clsx from 'clsx';

import styles from '../../DeliveryWorkersOnAddressTable.module.scss';

interface Props {
    isFirst: boolean;
    isDuplicateFirst: boolean;
    isOnAddress: boolean;
}

export const FirstIndicator: FC<Props> = ({ isDuplicateFirst, isOnAddress, isFirst }) => {
    if (!isFirst || !isOnAddress) {
        return null;
    }

    const tagStyles = clsx(styles.worker_action_tag_first, {
        [styles.dublicate]: isDuplicateFirst,
    });

    if (isDuplicateFirst) {
        return (
            <Tooltip placement='topLeft' title='Первый адрес дублируется, уточните время подачи!'>
                <div className={tagStyles}>Первый</div>
            </Tooltip>
        );
    }

    return <div className={tagStyles}>Первый</div>;
};
