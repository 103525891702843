import clsx from 'clsx';
import moment from 'moment';

import {
    DeliveryMapRequestItem,
    DeliveryMapRequestMarkerItem,
    DeliveryMapRequestWorkerItem,
    Nullable,
} from '@typings';

import { isRequestMarker } from './isRequestMarker';

import styles from '../mapStyles.module.scss';
interface GetMarkerViewResult {
    className: string;
    text: string;
}

type GetMarkerView<T extends DeliveryMapRequestMarkerItem = DeliveryMapRequestMarkerItem> = (
    markerItem: T,
    isActive: Nullable<boolean>
) => GetMarkerViewResult;

const generateWorkerMarkerText: GetMarkerView<DeliveryMapRequestWorkerItem> = (
    { location, full_name, phone, last_name },
    isActive
) => {
    const timestamp = moment(location?.timestamp).format('HH:mm') || '';

    const className = clsx(styles.tooltip_blue, {
        [styles.tooltip_not_active]: isActive === false,
        [styles.tooltip_blue_active]: isActive,
    });

    if (isActive) {
        return {
            text: `${full_name} ${phone} ${timestamp}`,
            className,
        };
    }

    return {
        text: `${last_name} ${timestamp}`,
        className,
    };
};

const generateRequestMarkerText: GetMarkerView<DeliveryMapRequestItem> = (
    { confirmed_timepoint, driver_full_name, driver_phones, pk },
    isActive
) => {
    const timepoint = confirmed_timepoint || '';

    const className = clsx(styles.tooltip_red, {
        [styles.tooltip_not_active]: isActive === false,
        [styles.tooltip_red_active]: isActive,
    });

    if (isActive) {
        return {
            text: `${timepoint} ${pk} ${driver_full_name} ${driver_phones}`,
            className,
        };
    }

    return {
        className,
        text: timepoint,
    };
};

export const getMarkerView: GetMarkerView = (markerItem, isActive) => {
    if (isRequestMarker(markerItem)) {
        return generateRequestMarkerText(markerItem, isActive);
    }

    return generateWorkerMarkerText(markerItem, isActive);
};
