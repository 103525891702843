import { FC, memo } from 'react';
import { MapContainer, Polygon, TileLayer } from 'react-leaflet';

import { DeliveryMapRequest, DeliveryMapRequestSelectionState, Nullable, SetState } from '@typings';

import { MapController } from './MapController';
import { MarkerList } from './MarkerList';

import 'leaflet/dist/leaflet.css';
import './mapStyles.scss';
interface Props {
    mapData: Nullable<DeliveryMapRequest>;
    setSelection: SetState<DeliveryMapRequestSelectionState>;
    currentRequest: DeliveryMapRequestSelectionState['request'];
    currentWorker: DeliveryMapRequestSelectionState['worker'];
}

const layerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

export const Map: FC<Props> = memo(({ mapData, currentRequest, currentWorker, setSelection }) => {
    const workersMarkerList = [...(mapData?.workers.values() || [])];
    const requestsMarkerList = [...(mapData?.deliveryRequests.values() || [])];

    return (
        <MapContainer scrollWheelZoom={true} style={{ height: '87vh', width: '100%' }}>
            <TileLayer url={layerUrl} />

            <MapController
                currentRequest={currentRequest}
                currentWorker={currentWorker}
                workersList={workersMarkerList}
                requestsList={requestsMarkerList}
                boundingBox={mapData?.boundingBox}
                setSelection={setSelection}
            />

            <MarkerList
                markerType='worker'
                markerList={workersMarkerList}
                currentRequest={currentRequest}
                currentWorker={currentWorker}
                setSelection={setSelection}
            />

            <MarkerList
                markerType='request'
                markerList={requestsMarkerList}
                currentRequest={currentRequest}
                currentWorker={currentWorker}
                setSelection={setSelection}
            />

            {mapData?.cityBorders && (
                <Polygon pathOptions={{ opacity: 0.6 }} positions={mapData?.cityBorders} />
            )}
        </MapContainer>
    );
});
