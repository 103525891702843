import moment from 'moment';

import { Nullable } from '@typings';

export const getFormattedConfirmedTimepoint = (timepoint: Nullable<string>) => {
    if (timepoint !== '-' || !timepoint) {
        return moment(timepoint, 'HH:mm:ss').format('HH:mm');
    }

    return '-';
};
