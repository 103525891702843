import { FC, useEffect } from 'react';
import { Modal } from 'antd';

import { fetchRequestDetail } from '@api';
import { closeModal } from '@store/modal';
import { mapperRequestResponseDataToDetailFx } from '@utils/deliveryWorkersModal';
import { showError } from '@utils/show_error';

import { AddWorkerSelectLine } from './AddWorkerSelectLine';
import { setItemRequestFx, setRequestDetailFx, setRequestIdFx } from './model';
import { Table } from './Table';

interface Props {
    onCancel(): void;

    request: number;
    item: number;
    isOpen: boolean;
}

export const DeliveryWorkerStatusesModal: FC<Props> = ({ onCancel, request, item, isOpen }) => {
    useEffect(() => {
        if (request && item) {
            setItemRequestFx(item);
            setRequestIdFx(Number(request));
        }
    }, [request, item]);

    useEffect(() => {
        const params = {
            id: Number(request),
            with_photo: true,
        };

        fetchRequestDetail({
            params,
            onSuccess: ({ data }) => {
                if (data) {
                    setRequestDetailFx(mapperRequestResponseDataToDetailFx(data.data));
                }
            },

            onError: (error) => {
                showError(error.message);
            },
        });
    }, []);

    const cancelAction = () => {
        closeModal();
        onCancel();
    };

    return (
        <Modal
            visible={isOpen}
            title='Статусы рабочих'
            width='1400px'
            onCancel={cancelAction}
            destroyOnClose
            footer={[]}
        >
            <Table item={item} request={request} />

            <AddWorkerSelectLine request={request} item={item} />
        </Modal>
    );
};
