import { useEffect, useState } from 'react';
import { AxiosError } from 'axios';

import { addWorker, fetchRequestDetail } from '@api';
import { useRequestConfirm } from '@hooks';
import { DeliveryRequestDetail, Nullable } from '@typings';
import { mapperRequestResponseDataToDetailFx } from '@utils/deliveryWorkersModal';
import { showError } from '@utils/show_error';

export const useDetailRequest = (requestPk: number) => {
    const { sendRequestWithConfirm } = useRequestConfirm();
    const [request, setRequest] = useState<Nullable<DeliveryRequestDetail>>(null); // TODO переименовать тип

    useEffect(() => {
        const params = {
            id: requestPk,
            with_photo: true,
        };

        fetchRequestDetail({
            params,
            onSuccess: ({ data }) => {
                setRequest(mapperRequestResponseDataToDetailFx(data.data));
            },
            onError: (error: Error) => {
                showError(error.message);
            },
        });
    }, [requestPk]);

    const onWorkerAdd = (workerId: number) => {
        sendRequestWithConfirm({
            requestCallback: addWorker,
            initialParams: {
                request: requestPk,
                worker: workerId,
            },
            onError: (error) => {
                showError((error as AxiosError)?.response?.data?.detail || error.message);
            },
            onSuccess: ({ data }) => {
                if (data) {
                    setRequest(mapperRequestResponseDataToDetailFx(data.data));
                }
            },
        });
    };

    return { request, setRequest, onWorkerAdd };
};
