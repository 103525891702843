import { ChangeEvent, FC, useState } from 'react';
import { Button, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { sendWorkerComment } from '@api';
import { WorkersReportWorkerComment } from '@typings';
import { mapperWorkersReportWorkerItem } from '@utils/mapperWorkersReport';
import { showError } from '@utils/show_error';

interface Props {
    workerId: number;
    onCommentAdd(comment: WorkersReportWorkerComment): void;
}
export const CommentEditor: FC<Props> = ({ workerId, onCommentAdd }) => {
    const [commentText, setCommentText] = useState<string>('');
    const [isLock, setIsLock] = useState(false);

    const handleSendComment = () => {
        if (isLock) {
            return;
        }

        setIsLock(true);
        setCommentText('');

        sendWorkerComment({
            params: {
                text: commentText,
                worker: workerId,
            },
            onSuccess({ data }) {
                const { author, text, timestamp } = data;

                const currentComment = {
                    text,
                    timestamp,
                    author: mapperWorkersReportWorkerItem(author),
                };

                onCommentAdd(currentComment);

                setIsLock(false);
            },
            onError(error) {
                showError(error.message);
                setIsLock(false);
            },
        });
    };

    const onCommentChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
        setCommentText(target.value);
    };
    return (
        <>
            <Form.Item>
                <TextArea rows={4} onChange={onCommentChange} value={commentText} />
            </Form.Item>

            <Form.Item>
                <Button htmlType='submit' onClick={handleSendComment} type='primary'>
                    Добавить комментарий
                </Button>
            </Form.Item>
        </>
    );
};
