import { FC, memo, useCallback, useEffect, useRef } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import DadataAddressForAntForm from '@components/DadataAddressForAntForm';
import { useOnClickOutside } from '@hooks';
import { DeliveryCellProps, DeliveryRequestItem } from '@typings';
import { shortenAddress } from '@utils/shortenAddress';

const getInitialValue = (items: DeliveryRequestItem[]) => {
    const length = items?.length;

    if (length > 1) {
        return `${items?.length} адресов`;
    }

    return items?.[0]?.address || '-';
};

export const Address: FC<DeliveryCellProps> = memo(({ rowData, onCommit, isEditing }) => {
    const [form] = useForm();
    const ref = useRef<HTMLDivElement>(null);
    const initialValue = getInitialValue(rowData.items);

    useOnClickOutside(ref, () => {
        commit();
    });

    const commit = useCallback(() => {
        form.validateFields().then((values) => {
            if (values.address && values.address.value) {
                onCommit?.(values.address.value);
                return;
            }
            onCommit?.(values.address || '');
        });
    }, [form, onCommit]);

    useEffect(() => {
        const cb = (e: KeyboardEvent) => {
            if (e.key === 'Enter') {
                commit();
            }
        };

        const el = ref.current;
        if (el) {
            const input = el.querySelector('input');

            if (input) {
                input.focus();
                input.addEventListener('keyup', cb);
            }
        }
        return () => {
            if (el) {
                const input = el.querySelector('input');

                if (input) {
                    input.removeEventListener('keydown', cb);
                }
            }
        };
    }, [commit]);

    const shorterText = shortenAddress(initialValue as string);

    if (isEditing) {
        return (
            <div ref={ref}>
                <Form
                    layout='horizontal'
                    form={form}
                    style={{ width: '600px' }}
                    initialValues={{ address: { value: initialValue } }}
                >
                    <Form.Item
                        key='address'
                        name='address'
                        style={{ height: '30px', marginBottom: 0 }}
                    >
                        <DadataAddressForAntForm />
                    </Form.Item>
                </Form>
            </div>
        );
    }

    return shorterText ? <>{shorterText}</> : null;
});
