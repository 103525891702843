import { FC, memo, useCallback, useState } from 'react';

import RemoteSelect from '@components/RemoteSelect';
import { API_URI } from '@constants';
import { DeliveryRequestCellProps } from '@typings';
import { textContent } from '@utils/delivery';

export const Customer: FC<DeliveryRequestCellProps> = memo(({ isEditing, onCommit, rowData }) => {
    const [customer, setCustomer] = useState(rowData.customer);

    const commit = useCallback(
        (customer) => {
            onCommit?.(customer?.key || null);
        },
        [onCommit]
    );

    const onChange = (value: string | string[]) => {
        setCustomer({ key: Number(value.toString()), label: '' });
        commit({ key: value });
    };

    const onBlur = () => {
        commit(customer);
    };

    if (isEditing) {
        return (
            <RemoteSelect.Form
                url={API_URI.AUTOCOMPLETE_DELIVERY_CUSTOMER}
                name='request'
                placeholder='Клиент'
                onChange={onChange}
                style={{ width: 160 }}
                defaultOpen
                autoFocus
                onBlur={onBlur}
            />
        );
    }

    return <>{textContent(rowData, 'customer')}</>;
});
