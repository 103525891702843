import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Title from 'antd/es/typography/Title';
import { useStore } from 'effector-react';

import { fetchDeliveryAdditionalPhotos, fetchDeliveryPhotos } from '@api';
import { useHashTarget, usePhotoDashboardSearchParams } from '@hooks';
import { PhotoListState } from '@typings';
import { adapterPhotosByAddress } from '@utils/photoDasboard';
import { showError } from '@utils/show_error';

import { AdditionalPhotos, CheckBoxFilter, Photos } from './components';
import { $reloadedPhotoList } from './modal';

import styles from './photoDashboard.module.scss';

const PhotoDashboard: FC = () => {
    const reloadPhotoList = useStore($reloadedPhotoList);
    const { request, worker_pk } = usePhotoDashboardSearchParams();
    useHashTarget();

    const [data, setData] = useState<PhotoListState>({
        photoList: null,
        sortedPhotoList: null,
        additionalPhotoList: null,
    });

    useEffect(() => {
        if (request) {
            fetchDeliveryAdditionalPhotos({
                onError: (error) => {
                    showError(error.message);
                },

                onSuccess: ({ data }) => {
                    setData((prev) => ({
                        ...prev,
                        additionalPhotoList: data.data,
                    }));
                },

                params: { request },
            });

            fetchDeliveryPhotos({
                onError: (error) => {
                    showError(error.message);
                },

                onSuccess: ({ data }) => {
                    const adaptedData = adapterPhotosByAddress(data.data);

                    setData((prev) => ({
                        ...prev,
                        photoList: adaptedData,
                        sortedPhotoList: adaptedData,
                    }));
                },
                params: { request, worker: worker_pk },
            });
        }
    }, [reloadPhotoList]);

    return (
        <div>
            <CheckBoxFilter setPhotoList={setData} />

            <Title style={{ marginTop: 50 }} level={2}>
                Отметки о прибытии
            </Title>

            <Photos photoList={data?.sortedPhotoList} />

            <div className={styles.title} id='additional_photo'>
                <Title level={2}>Дополнительные фото</Title>

                <Link
                    className={styles.link}
                    to={(location) => {
                        return {
                            ...location,
                            hash: 'additional_photo',
                        };
                    }}
                >
                    §
                </Link>
            </div>

            <AdditionalPhotos
                request={request}
                photoList={data.additionalPhotoList}
                setPhotos={setData}
            />
        </div>
    );
};

export default PhotoDashboard;
