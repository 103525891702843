import { FC, memo } from 'react';
import { Badge, Upload, UploadProps } from 'antd';

import { grey, yellow } from '@ant-design/colors';
import { CameraTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { addDeliveryAdditinalPhotos, fetchRequestDetail } from '@api';
import { BASE_URL_SERVER } from '@constants';
import { DeliveryRequest, SetRowWithFormat } from '@typings';
import { normalizeDeliveryRequest } from '@utils/standartDataToNewBackend';
interface Props {
    rowData: DeliveryRequest;
    setRowWithFormat: SetRowWithFormat;
}

export const Photo: FC<Props> = memo(({ rowData, setRowWithFormat }) => {
    const onCustomRequestUpload: UploadProps['customRequest'] = async (options) => {
        const { onSuccess, file } = options;

        try {
            addDeliveryAdditinalPhotos({
                params: { images: file as File, request: rowData.pk },
                onSuccess({ data }) {
                    onSuccess?.(data, file as any);
                },
            });

            fetchRequestDetail({
                params: { id: rowData.pk },
                onSuccess({ data }) {
                    setRowWithFormat(normalizeDeliveryRequest(data.data.request));
                },
            });
        } catch (err) {
            //
        }
    };

    return (
        <>
            <a
                key='photos'
                href={`/rf/delivery/photos_dashboard/?request=${rowData.pk}`}
                target='_blank'
                rel='noreferrer'
                style={{ position: 'relative' }}
            >
                {renderIcon(rowData.new_photos)}
            </a>

            <a
                key='extra-photos'
                href={`${BASE_URL_SERVER}delivery/${rowData.pk}/extra_photos/`}
                target='_blank'
                rel='noreferrer'
                style={{ margin: '0 0 0 4px' }}
            >
                <CameraTwoTone
                    twoToneColor={rowData.extra_photos_exist ? yellow.primary : grey[1]}
                />
            </a>
            <Upload
                key='upload'
                name='image'
                customRequest={onCustomRequestUpload}
                showUploadList={false}
                multiple={false}
            >
                <PlusCircleTwoTone
                    style={{ cursor: 'pointer', margin: '0 0 0 4px' }}
                    twoToneColor={yellow.primary}
                />
            </Upload>
        </>
    );
});

const renderIcon = (new_photos: string) => {
    if (Number(new_photos) > 0) {
        return (
            <Badge className='photos-badge' dot>
                <CameraTwoTone />
            </Badge>
        );
    }

    return <CameraTwoTone />;
};
