import { FC } from 'react';

import { BASE_URL_SERVER } from '@constants';
import { getFormattedConfirmedTimepoint } from '@utils/tableCell';

import styles from '../DeliveryWorkersOnAddressTable.module.scss';

interface Props {
    confirmedTimepoint: string;
    name: string;
    isWorkerNotOnAdresses: boolean;
    workerId: number;
}

export const WorkerFullName: FC<Props> = ({
    name,
    confirmedTimepoint,
    isWorkerNotOnAdresses,
    workerId,
}) => {
    const renderWorkerTimepoint = () => {
        if (isWorkerNotOnAdresses) {
            return null;
        }
        if (confirmedTimepoint) {
            return <b>Подача в {getFormattedConfirmedTimepoint(confirmedTimepoint)}</b>;
        }

        return <b>Неизвестно время подачи!</b>;
    };

    return (
        <div className={styles.worker_fullName}>
            <a href={`${BASE_URL_SERVER}worker/${workerId}`} target='_blank' rel='noreferrer'>
                {name}
            </a>

            {renderWorkerTimepoint()}
        </div>
    );
};
