import { memo } from 'react';
import { Descriptions, List, Tooltip, Typography } from 'antd';

export interface FirstCellRenderProps {
    rowCustomer?: string;
    rowLocation?: string;
}

export const FirstCellRender = memo((props: FirstCellRenderProps) => {
    const { rowCustomer, rowLocation } = props ?? {};

    if (!props) {
        return <></>;
    }
    return (
        <Descriptions column={6} size='small'>
            <Descriptions.Item span={4}>
                <List size={'small'}>
                    <List.Item style={{ padding: '0px' }}>
                        <Tooltip title={rowCustomer ?? ''}>
                            <Typography.Text>{rowCustomer ?? ''}</Typography.Text>
                        </Tooltip>
                    </List.Item>
                    <List.Item style={{ padding: '0px' }}>
                        <Tooltip title={rowLocation ?? ''}>
                            <Typography.Text>{rowLocation ?? ''}</Typography.Text>
                        </Tooltip>
                    </List.Item>
                </List>
            </Descriptions.Item>
        </Descriptions>
    );
});
