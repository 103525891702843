import { sendRequest } from '@api/request';
import { API_URI, jsonTypeRequestHeaders } from '@constants';
import { AuthLoginUserParams, AuthLoginUserResponse, ServiceFetchType } from '@typings';

export const loginUser: ServiceFetchType<AuthLoginUserResponse, AuthLoginUserParams> = ({
    params,
    onError,
    onSuccess,
}) => {
    sendRequest<AuthLoginUserResponse>(API_URI.AUTH_LOGIN, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};
