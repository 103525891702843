import { sendRequest } from '@api';
import { API_URI, jsonTypeRequestHeaders } from '@constants';
import {
    ExpensesExpenseDetailParams,
    ExpensesExpenseDetailResponse,
    ExpensesFetchActualExpensesParams,
    ExpensesFetchActualExpensesReponse,
    ExpensesFetchProviderDetailParams,
    ExpensesFetchProviderDetailResponse,
    ServiceFetchType,
} from '@typings';

export const updateExpensesProvider: ServiceFetchType<any> = ({ params, onError, onSuccess }) => {
    sendRequest<any>(API_URI.EXPENSES_PROVIDER_UPDATE, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchExpensesProviderDetail: ServiceFetchType<
    ExpensesFetchProviderDetailResponse,
    ExpensesFetchProviderDetailParams
> = ({ params, onError, onSuccess }) => {
    sendRequest<ExpensesFetchProviderDetailResponse>(API_URI.EXPENSES_PROVIDER_DETAIL, {
        method: 'GET',
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const createExpensesExpense: ServiceFetchType<any> = ({ params, onError, onSuccess }) => {
    sendRequest<any>(API_URI.EXPENSES_EXPENSE_CREATE, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchExpensesExpenseDetail: ServiceFetchType<
    ExpensesExpenseDetailResponse,
    ExpensesExpenseDetailParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<ExpensesExpenseDetailResponse>(API_URI.EXPENSES_EXPENSE_DETAIL, {
        method: 'GET',
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const updateExpensesExpense: ServiceFetchType<any> = ({ params, onError, onSuccess }) => {
    sendRequest<any>(API_URI.EXPENSES_EXPENSE_UPDATE, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchActualExpenses: ServiceFetchType<
    ExpensesFetchActualExpensesReponse,
    ExpensesFetchActualExpensesParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<ExpensesFetchActualExpensesReponse>(API_URI.EXPENSES_ACTUAL_EXPENSES, {
        method: 'GET',
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};
