import { FC, memo, useEffect, useState } from 'react';
import { Button, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Title from 'antd/es/typography/Title';
import moment from 'moment';

import RemoteSelect from '@components/RemoteSelect';
import { API_URI, BASE_URL_SERVER } from '@constants';
import {
    DeliveryMapRequestList,
    DeliveryMapRequestSelectionState,
    DeliveryMapRequestWorkerList,
    MapRequestFormOnFinishValue,
    SetState,
} from '@typings';

import styles from './mapRequestForm.module.scss';

interface Props {
    deliveryRequests: DeliveryMapRequestList | undefined;
    workers: DeliveryMapRequestWorkerList | undefined;
    onSubmit(value: MapRequestFormOnFinishValue): void;
    setSelection: SetState<DeliveryMapRequestSelectionState>;
    selection: DeliveryMapRequestSelectionState;
    timestamp: Date | undefined;
}

const getTitle = (count: number = 0) => {
    const r100 = count % 100;
    const r10 = count % 10;

    let name = 'заявок';
    if (r100 === 1 || (r100 > 20 && r10 === 1)) {
        name = 'заявка';
    } else if ([2, 3, 4].includes(r100) || (r100 > 20 && [2, 3, 4].includes(r10))) {
        name = 'заявки';
    }

    return `${count} ${name} в`;
};

const initialRequestLink = `${BASE_URL_SERVER}rf/delivery/`;

const initialFormValue = {
    zone: '1',
};
const initialSelectionState = {
    request: null,
    worker: null,
};

export const MapRequestForm: FC<Props> = memo(
    ({ deliveryRequests, workers, timestamp, setSelection, selection, onSubmit: onSubmitCb }) => {
        const [requestLink, setRequestLink] = useState(initialRequestLink);
        const [form] = useForm();

        const onSubmit = ({ zone }: MapRequestFormOnFinishValue) => {
            onSubmitCb({ zone });
        };

        useEffect(() => {
            const { request, worker } = selection;

            form.setFieldsValue({ request: request?.pk.toString(), worker: worker?.pk.toString() });
        }, [selection]);

        const onZoneChange = (zone: string | string[]) => {
            setRequestLink(`${initialRequestLink}?zone=${zone}`);

            setSelection(initialSelectionState);
        };

        const onWorkerChange = (currentWorkerPk: string | string[]) => {
            const currentWorker = workers?.get(currentWorkerPk?.toString()) || null;

            setSelection({ ...initialSelectionState, worker: currentWorker });
        };

        const onRequestChange = (currentRequestPk: string | string[]) => {
            const currentRequest = deliveryRequests?.get(currentRequestPk?.toString()) || null;

            setSelection({ ...initialSelectionState, request: currentRequest });
        };

        const formattedTime = moment(timestamp).format('HH:mm');

        return (
            <Form
                layout='inline'
                form={form}
                onFinish={onSubmit}
                className={styles.form}
                initialValues={initialFormValue}
            >
                <div className={styles.item}>
                    <Title level={4} className={styles.title}>
                        <a href={requestLink} target='_blank' rel='noreferrer'>
                            {getTitle(deliveryRequests?.size)}
                        </a>
                    </Title>

                    <RemoteSelect.Item
                        form={form}
                        url={API_URI.AUTOCOMPLETE_ZONE}
                        name='zone'
                        placeholder='Город'
                        onChange={onZoneChange}
                        required={false}
                        className={styles.zone}
                    />
                    <Title level={4} className={styles.time}>
                        на {formattedTime}
                    </Title>

                    <Button type='primary' style={{ marginLeft: 10 }} htmlType='submit'>
                        Обновить
                    </Button>
                </div>

                <div className={styles.item}>
                    <RemoteSelect.WithForward
                        form={form}
                        url={API_URI.AUTOCOMPLETE_MAP_WORKER}
                        name='worker'
                        placeholder='Рабочий'
                        onChange={onWorkerChange}
                        required={false}
                        formForward={['zone']}
                    />
                    <RemoteSelect.WithForward
                        form={form}
                        url={API_URI.AUTOCOMPLETE_MAP_REQUEST}
                        name='request'
                        placeholder='Заявка'
                        onChange={onRequestChange}
                        required={false}
                        formForward={['zone']}
                    />
                </div>
            </Form>
        );
    }
);
