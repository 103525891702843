import { FC, memo } from 'react';

import { DeliveryRequestCellProps, DeliveryRequestOrExpandedValues } from '@typings';
import { isMergedRequest } from '@utils/delivery';

import { TextCell } from '.';

interface Props extends DeliveryRequestCellProps {
    columnDataIndex: string;
    cellValue: DeliveryRequestOrExpandedValues;
}

export const DefaultWrapped: FC<Props> = memo(
    ({ onCommit, isEditing, rowData, columnDataIndex, cellValue }) => {
        const isRequestItemValue = columnDataIndex === 'items';
        const defaultItemCellValue = cellValue || '-';

        if (!isMergedRequest(rowData) && isRequestItemValue) {
            return null;
        }

        return (
            <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={defaultItemCellValue} />
        );
    }
);
