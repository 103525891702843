import { FC, memo } from 'react';

import { DeliveryRequestOrExpanded, Nullable } from '@typings';
import { isMergedRequest, isUnwrappedRequest } from '@utils/delivery';

import styles from './lateness.module.scss';

interface Props {
    rowData: DeliveryRequestOrExpanded;
}

interface ILateness {
    seconds: number;
    value: string;
}

const getMaxLateness = (firstLateness: ILateness, secondLateness: ILateness) => {
    if (firstLateness?.seconds > secondLateness?.seconds) {
        return firstLateness;
    }

    return secondLateness;
};

export const Lateness: FC<Props> = memo(({ rowData }) => {
    const latenness = rowData.items[0].lateness;
    const style = latenness === '-' ? {} : { background: '#FAA' };

    if (!isMergedRequest(rowData) && !isUnwrappedRequest(rowData) && rowData.is_overdued) {
        const maxLateness = rowData.items.reduce(
            (acc: Nullable<ILateness>, { lateness_seconds, lateness }) => {
                const currentLateness: ILateness = {
                    value: lateness,
                    seconds: lateness_seconds || 0,
                };

                if (!acc) {
                    return currentLateness;
                }

                return getMaxLateness(acc, currentLateness);
            },
            null
        );

        return (
            <div style={style} className={styles.main}>
                {maxLateness?.value}
            </div>
        );
    }

    return (
        <div style={style} className={styles.main}>
            {latenness}
        </div>
    );
});
