import { ComponentType, NamedExoticComponent } from 'react';
import { Form } from 'antd';
import { NamePath } from 'antd/lib/form/interface';

interface Props {
    formForward?: NamePath[];
    externalForward?: object;
    transformForward?<T>(value: T): T;
    name: string;
}

export const withForward = <P extends Props = Props>(
    WrappedComponent: ComponentType<P> | NamedExoticComponent<P>
) => {
    return ({
        externalForward = {},
        formForward = [],
        name,
        transformForward,
        ...props
    }: Props & Omit<P, 'forward'>) => {
        return (
            <Form.Item dependencies={formForward}>
                {({ getFieldsValue }) => {
                    const formValues = getFieldsValue(formForward!!) || {};

                    const forward = {
                        ...(transformForward?.(formValues) || formValues),
                        ...externalForward,
                    };

                    return <WrappedComponent {...(props as P)} name={name} forward={forward} />;
                }}
            </Form.Item>
        );
    };
};
