import { FC, memo, useCallback, useContext } from 'react';

import { MinusCircleOutlined } from '@ant-design/icons';
import { moveRequestItem } from '@api';
import { useRequestConfirm } from '@hooks';
import { DeliveryContext } from '@providers/delivery';
import { DeliveryRequestOrExpanded } from '@typings';
import { getRequestPk, isUnwrappedRequest } from '@utils/delivery';
import { showError } from '@utils/show_error';

import styles from './deliveryRoute.module.scss';

interface Props {
    rowData: DeliveryRequestOrExpanded;
}

export const DeliveryRoute: FC<Props> = memo(({ rowData }) => {
    const { fetchData } = useContext(DeliveryContext);
    const { sendRequestWithConfirm } = useRequestConfirm();

    const onMoveItem = useCallback(() => {
        sendRequestWithConfirm({
            requestCallback: moveRequestItem,
            initialParams: {
                source: getRequestPk(rowData),
                destination: null,
                item: rowData.items[0].pk,
            },
            onSuccess() {
                fetchData(false);
            },
            onError(error) {
                showError(error.message);
            },
        });
    }, []);

    if (isUnwrappedRequest(rowData)) {
        return (
            <>
                {rowData.route}
                <MinusCircleOutlined className={styles.icon} onClick={onMoveItem} />
            </>
        );
    }

    return <>{rowData.route}</>;
});
