import React, { useState } from 'react';
import { Card, Col } from 'antd';

import {
    DashboardPhotoDiscrepancyCheck,
    DashboardPhotoIsSuspicious,
    DashboardPhotoItemType,
    DashboardPhotoListItem,
    DashboardPhotoListItemRejected,
} from '@typings';
import { getPhotoStatus } from '@utils';

import {
    CoverImage,
    FooterPhotoElement,
    MapPhotoElement,
    PhotoActionContainer,
    StatusContainer,
    StatusTag,
    TooltipPhotoElementInfo,
} from '../';

import styles from './photoElement.module.scss';

interface Props extends Pick<DashboardPhotoListItem, 'item' | 'request' | 'timestamp'> {
    isSuspicious: DashboardPhotoIsSuspicious;
    discrepancyCheck: DashboardPhotoDiscrepancyCheck;
    id: number;
    confirmed: boolean;
    url: string;
    rejected: DashboardPhotoListItemRejected;
    type: DashboardPhotoItemType;
    workerId: number;
    workerName: string;
}

const isShowDiscrepancyComment = (discrepancyCheck?: DashboardPhotoDiscrepancyCheck) => {
    if (discrepancyCheck?.comment) {
        return true;
    }

    return false;
};

const getTitleDisparencyComment = (
    isSuspicious: DashboardPhotoIsSuspicious,
    discrepancyCheck: DashboardPhotoDiscrepancyCheck
) => {
    if (isSuspicious === true && (discrepancyCheck === null || discrepancyCheck?.is_ok === true)) {
        return 'Расхождение одобрено';
    }

    if (isSuspicious === true && (discrepancyCheck === null || discrepancyCheck?.is_ok === false)) {
        return 'Расхождение отклонено';
    }

    return '';
};
export const PhotoElement: React.FC<Props> = ({
    id,
    rejected,
    item,
    request,
    timestamp,
    discrepancyCheck,
    isSuspicious,
    confirmed,
    type,
    workerId,
    workerName,
    url = 'https://www.eyurtlar.com/images/gorsel-yok.jpg',
}) => {
    const [showAction, setShowAction] = useState(false);

    const photoStatus = getPhotoStatus(rejected, confirmed);
    const rejectedText = typeof rejected === 'object' && rejected?.text;

    const tooltipData = [
        {
            title: 'Причина отмены:',
            reason: rejectedText || '',
            isVisible: photoStatus === 'rejected',
        },
        {
            title: getTitleDisparencyComment(isSuspicious, discrepancyCheck),
            reason: discrepancyCheck?.comment || '',
            isVisible: isShowDiscrepancyComment(discrepancyCheck),
        },
    ];

    return (
        <>
            <Col>
                <Card
                    className={styles.card}
                    bodyStyle={{ padding: 16 }}
                    hoverable
                    cover={
                        <CoverImage
                            initialUrl={url}
                            setShowAction={setShowAction}
                            showAction={showAction}
                            status={photoStatus}
                        />
                    }
                >
                    <StatusContainer placement='right'>
                        <StatusTag status={photoStatus} />
                    </StatusContainer>

                    <StatusContainer placement='left'>
                        <MapPhotoElement
                            isSuspicious={isSuspicious}
                            request={request}
                            type={type}
                            workerId={workerId}
                            viewType='icon'
                        />
                    </StatusContainer>

                    <TooltipPhotoElementInfo
                        tooltipData={tooltipData}
                        setShowAction={setShowAction}
                    />

                    <FooterPhotoElement timestamp={timestamp} workerName={workerName}>
                        <PhotoActionContainer
                            viewType='icon'
                            isSuspicious={isSuspicious}
                            discrepancyCheck={discrepancyCheck}
                            status={photoStatus}
                            request={request}
                            photoId={id}
                            type={type}
                            itemId={item.id}
                            workerId={workerId}
                        />
                    </FooterPhotoElement>
                </Card>
            </Col>

            <PhotoActionContainer
                viewType='button'
                setShow={setShowAction}
                show={showAction}
                isSuspicious={isSuspicious}
                discrepancyCheck={discrepancyCheck}
                status={photoStatus}
                request={request}
                photoId={id}
                type={type}
                itemId={item.id}
                workerId={workerId}
            >
                <MapPhotoElement
                    isSuspicious={isSuspicious}
                    request={request}
                    type={type}
                    workerId={workerId}
                    viewType='button'
                />
            </PhotoActionContainer>
        </>
    );
};
