import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import moment from 'moment';

import { DeliveryRequest, GetDeliveryColumnsProps, TableCellColumn } from '@typings';

import { allItemsFloatFieldSort, getItemValue } from '../utils/delivery';
import {
    dateSort,
    defaultSort,
    itemFloatFieldSort,
    itemStringFieldSort,
    labelSort,
    numberSort,
    stringSort,
} from '../utils/delivery/helpers';

const fixedColumnsKeys = ['pk', 'code'];

export const useGetDeliveryColumns = ({ columnsFilter }: GetDeliveryColumnsProps) => {
    const sorterTimeInterval = useCallback((a: DeliveryRequest, b: DeliveryRequest) => {
        let aTime = a.items[0]['interval_begin'];
        let bTime = b.items[0]['interval_begin'];
        const result = aTime.localeCompare(bTime);
        if (result !== 0) {
            return result;
        }
        aTime = a.items[0]['interval_end'];
        bTime = b.items[0]['interval_end'];
        return aTime.localeCompare(bTime);
    }, []);

    const sorterArrivialTime = useCallback((a: DeliveryRequest, b: DeliveryRequest) => {
        if (!a?.arrival_time) {
            return -1;
        }
        if (!b?.arrival_time) {
            return 1;
        }

        const aTime = moment(a?.arrival_time).format('HH:mm');
        const bTime = moment(b?.arrival_time).format('HH:mm');

        const result = aTime.toString().localeCompare(bTime.toString());

        return result;
    }, []);

    const sorterAssignedWorkers = useCallback((a: DeliveryRequest, b: DeliveryRequest) => {
        const valueA = getItemValue(a, 'assigned_workers') || [];
        const valueB = getItemValue(b, 'assigned_workers') || [];

        const getWorkersNames = (value: { name: string }[] | string[]) => {
            if (_.isObject(value[0])) {
                return (value as { name: string }[]).map((worker) => worker?.name || '').join(' ');
            }

            return '';
        };

        if (valueA !== null && valueA !== undefined && !Array.isArray(valueA)) {
            return -1;
        }
        if (valueB !== null && valueB !== undefined && !Array.isArray(valueB)) {
            return 1;
        }

        const workersAName = getWorkersNames(valueA);
        const workersBName = getWorkersNames(valueB);

        return workersAName.localeCompare(workersBName);
    }, []);

    const sorterWorkerCount = useCallback((a: DeliveryRequest, b: DeliveryRequest) => {
        const valueA = getItemValue(a, 'assigned_workers') || [];
        const valueB = getItemValue(b, 'assigned_workers') || [];

        if (valueA !== null && valueA !== undefined && !Array.isArray(valueA)) {
            return -1;
        }
        if (valueB !== null && valueB !== undefined && !Array.isArray(valueB)) {
            return 1;
        }

        return valueA.length - valueB.length;
    }, []);

    const sorterHours = useCallback((a: DeliveryRequest, b: DeliveryRequest) => {
        const aHours = parseFloat(a?.hours);
        const bHours = parseFloat(b?.hours);

        return aHours - bHours;
    }, []);

    const allColumns: TableCellColumn[] = useMemo(
        () => [
            {
                title: '№',
                dataIndex: 'pk',
                key: 'pk',
                align: 'right',
                sorter: numberSort('pk'),
            },
            {
                title: 'Индекс',
                dataIndex: 'items',
                key: 'code',
                sorter: itemStringFieldSort('code'),
            },
            {
                title: 'М-т',
                dataIndex: 'route',
                key: 'route',
                sorter: numberSort('route'),
            },
            {
                title: 'Автор',
                dataIndex: 'author',
                key: 'author',
                sorter: stringSort('author'),
            },
            {
                title: 'Отв-й',
                dataIndex: 'operator',
                key: 'operator',
                sorter: stringSort('operator'),
            },
            {
                title: 'Филиал',
                dataIndex: 'location',
                key: 'location',
                sorter: labelSort('location'),
            },
            {
                title: 'Дата',
                dataIndex: 'date',
                key: 'date',
                sorter: dateSort('date'),
            },
            {
                title: 'Cозд-е',
                dataIndex: 'timestamp',
                key: 'timestamp',
                sorter: stringSort('timestamp'),
            },
            {
                title: 'Выполнить',
                dataIndex: 'items',
                key: 'time_interval',
                sorter: sorterTimeInterval,
            },
            {
                title: 'Подача',
                dataIndex: 'items',
                key: 'confirmed_timepoint',
                sorter: itemStringFieldSort('confirmed_timepoint'),
            },
            {
                title: 'Скрыть',
                dataIndex: 'is_private',
                key: 'is_private',
                sorter: defaultSort('is_private'),
            },
            {
                title: 'Прибытие',
                dataIndex: 'arrival_time',
                key: 'arrival_time',
                sorter: sorterArrivialTime,
            },
            {
                title: 'Опоздание',
                dataIndex: 'items',
                key: 'lateness',
                sorter: allItemsFloatFieldSort('lateness_seconds'),
            },
            {
                title: 'Подтверждение',
                dataIndex: 'confirmation_time',
                key: 'confirmation_time',
                sorter: stringSort('confirmation_time'),
            },
            {
                title: 'Масса',
                dataIndex: 'items',
                key: 'mass',
                sorter: allItemsFloatFieldSort('mass'),
            },
            {
                title: 'Объем',
                dataIndex: 'items',
                key: 'volume',
                sorter: allItemsFloatFieldSort('volume'),
            },
            {
                title: 'Г-т',
                dataIndex: 'items',
                key: 'max_size',
                sorter: itemFloatFieldSort('max_size'),
            },
            {
                title: 'Мест',
                dataIndex: 'items',
                key: 'place_count',
                sorter: allItemsFloatFieldSort('place_count'),
            },
            {
                title: 'Лифт',
                dataIndex: 'items',
                key: 'has_elevator',
            },
            {
                title: 'Этаж',
                dataIndex: 'items',
                key: 'floor',
                sorter: itemFloatFieldSort('floor'),
            },
            {
                title: 'Пронос',
                dataIndex: 'items',
                key: 'carrying_distance',
                sorter: allItemsFloatFieldSort('carrying_distance'),
            },
            {
                title: 'Характер груза',
                dataIndex: 'items',
                key: 'shipment_type',
                sorter: itemStringFieldSort('shipment_type'),
            },
            {
                title: 'Клиент',
                dataIndex: 'customer',
                key: 'customer',
                editable: true,
                sorter: labelSort('customer'),
            },
            {
                title: 'Примеч-е',
                dataIndex: 'comment',
                key: 'comment',
                sorter: stringSort('comment'),
            },
            {
                title: 'Адрес',
                dataIndex: 'items',
                key: 'address',
                sorter: itemStringFieldSort('address'),
            },
            {
                title: 'Л-я',
                dataIndex: 'items',
                key: 'metro_line',
            },
            {
                title: 'Метро',
                dataIndex: 'items',
                key: 'metro_name',
                sorter: itemStringFieldSort('metro_name'),
            },
            {
                title: '+',
                dataIndex: 'call_to_driver',
                key: 'call_to_driver',
                editable: false,
            },
            {
                title: 'Водитель',
                dataIndex: 'driver_name',
                key: 'driver_name',
                editable: true,
                sorter: stringSort('driver_name'),
            },
            {
                title: 'Тел. водителя',
                dataIndex: 'driver_phones',
                key: 'driver_phones',
                sorter: stringSort('driver_phones'),
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                sorter: stringSort('status_description'),
            },
            {
                title: 'Фото',
                dataIndex: 'timesheet_photo',
                key: 'timesheet_photo',
            },
            {
                title: 'Сам',
                dataIndex: 'has_self_assigned_worker',
                key: 'has_self_assigned_worker',
                sorter: defaultSort('has_self_assigned_worker'),
            },
            {
                title: 'Направленные грузчики',
                dataIndex: 'items',
                key: 'assigned_workers',
                sorter: sorterAssignedWorkers,
            },
            {
                title: 'З-но',
                dataIndex: 'items',
                key: 'workers_required',
            },
            {
                title: 'Н-но',
                dataIndex: 'items',
                key: 'worker_count',
                sorter: sorterWorkerCount,
            },
            {
                title: 'Тариф',
                dataIndex: 'service',
                key: 'service',
                sorter: labelSort('service'),
            },
            {
                title: 'Кол-во часов (к оплате)',
                dataIndex: 'hours',
                key: 'hours',
                sorter: sorterHours,
            },
            {
                title: 'Стоимость',
                dataIndex: 'pay_estimate',
                key: 'pay_estimate',
                sorter: defaultSort('pay_estimate'),
            },
        ],
        [
            sorterWorkerCount,
            sorterHours,
            sorterAssignedWorkers,
            sorterArrivialTime,
            sorterTimeInterval,
        ]
    );

    const columns = allColumns.filter(
        (col: TableCellColumn) =>
            columnsFilter.findIndex((item) => item.key === col.key && item.checked) !== -1
    );

    const fixedColumns: TableCellColumn[] = [];
    const staticColumns: TableCellColumn[] = [];

    for (const column of columns) {
        if (fixedColumnsKeys.includes(column.key)) {
            fixedColumns.push(column);
        } else {
            staticColumns.push(column);
        }
    }

    return [fixedColumns, staticColumns];
};
