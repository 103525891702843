import React, { useState } from 'react';
import { Button, Input, Popover, Tooltip } from 'antd';

import { CheckCircleTwoTone, CheckOutlined } from '@ant-design/icons';
import { confirmItemWorkerFinish, confirmItemWorkerStart } from '@api';
import {
    ConfirmItemWorkerFinishResponseError,
    DashboardPhotoActionsView,
    DashboardPhotoItemType,
    DashborardPhotoActionButtonProps,
    Nullable,
} from '@typings';

interface Props extends DashborardPhotoActionButtonProps {
    type: DashboardPhotoItemType;
    workerId: number;
    itemId: number;
    viewType: DashboardPhotoActionsView;
}

interface PopoverData {
    isVisible: boolean;
    title: Nullable<string>;
    min: Nullable<number>;
    max: Nullable<number>;
}

export const ConfirmFinishButton: React.FC<Props> = ({
    onError,
    itemId,
    workerId,
    onSuccess,
    requestId,
    type,
    viewType,
}) => {
    const [popoverState, setPopoverState] = useState<PopoverData>({
        isVisible: false,
        title: null,
        min: null,
        max: null,
    });
    const [hours, setHours] = useState<number | string>('');

    const buttonStyle = { background: '#4caf50', borderColor: 'unset' };

    const onHoursChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;

        setHours(value);
    };

    const onFinishError = (error: Error) => {
        const requiredHoursErrorStatus = 409;
        const requiredHoursErrorCode = 'hours_required';

        const errorResponse = (error as ConfirmItemWorkerFinishResponseError).response;
        const isHoursError =
            errorResponse?.status === requiredHoursErrorStatus &&
            errorResponse.data.code === requiredHoursErrorCode;

        if (isHoursError) {
            const { max_hours, min_hours } = errorResponse?.data.messages || {};

            const detailMessage = `${errorResponse?.data.detail}
             Количество часов (от ${min_hours} до ${max_hours})
            `;
            setPopoverState({
                title: detailMessage,
                isVisible: true,
                max: Number(max_hours),
                min: Number(min_hours),
            });
        } else {
            onError(error);
        }
    };

    const onConfirm = async () => {
        const params = {
            request: requestId,
            item: itemId,
            worker: workerId,
        };

        setPopoverState((prev) => ({ ...prev, isVisible: false }));

        if (type === 'finish') {
            confirmItemWorkerFinish({
                params: {
                    ...params,
                    hours: hours ? Number(hours) : undefined,
                },
                onSuccess,
                onError: onFinishError,
            });
        } else {
            confirmItemWorkerStart({
                params,
                onSuccess,
                onError,
            });
        }
    };

    const content = (
        <div>
            <Input type='number' value={hours} onPressEnter={onConfirm} onChange={onHoursChange} />
        </div>
    );

    const renderContent = () => {
        if (viewType === 'icon') {
            return (
                <Tooltip placement='bottom' title='Принять табель'>
                    <CheckCircleTwoTone style={{ margin: '0 5px 0 5px' }} onClick={onConfirm} />
                </Tooltip>
            );
        }

        return (
            <Button style={buttonStyle} type='primary' onClick={onConfirm}>
                <CheckOutlined />
                Принять табель
            </Button>
        );
    };

    return (
        <Popover
            content={content}
            title={popoverState.title}
            visible={popoverState.isVisible}
            trigger='click'
            overlayStyle={{ zIndex: 9999 }}
            destroyTooltipOnHide
        >
            {renderContent()}
        </Popover>
    );
};
