import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';

const isDev = process.env.NODE_ENV === 'test';

ReactDOM.render(
    !isDev ? (
        <App />
    ) : (
        <StrictMode>
            <App />
        </StrictMode>
    ),
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
