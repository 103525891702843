import { FC } from 'react';

import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import {
    DashboardButtonIconProps,
    DashboardPhotoActionsView,
    DashboardPhotoDiscrepancyCheck,
    DashboardPhotoIsSuspicious,
    DashborardPhotoActionButtonProps,
} from '@typings';

import { SuspicionsButton } from '.';

interface ButtonsSuspicionsProps extends DashborardPhotoActionButtonProps {
    isSuspicious: DashboardPhotoIsSuspicious;
    discrepancyCheck: DashboardPhotoDiscrepancyCheck;
    itemId: number;
    workerId: number;
    isVisible: boolean;
    viewType: DashboardPhotoActionsView;
}

const confirmText = 'Объяснить расхождение';
const cancelText = 'Отправить на переделку';

const CancelIcon: FC<DashboardButtonIconProps> = ({ ...props }) => {
    return <CloseCircleTwoTone {...props} twoToneColor='ff0000' />;
};

export const ButtonsSuspicions: FC<ButtonsSuspicionsProps> = ({
    onSuccess,
    workerId,
    requestId,
    itemId,
    isSuspicious,
    discrepancyCheck,
    onError,
    isVisible,
    viewType,
}) => {
    const isConfirmSuspicious = !discrepancyCheck?.is_ok && isSuspicious;

    if (!isVisible) {
        return null;
    }

    const confirmButtonEl = (
        <SuspicionsButton
            viewType={viewType}
            itemId={itemId}
            onSuccess={onSuccess}
            onError={onError}
            requestId={requestId}
            workerId={workerId}
            icon={CheckCircleTwoTone}
            buttonText={confirmText}
            type='confirm'
            background='#4caf50'
        />
    );

    const cancelButtonEl = (
        <SuspicionsButton
            viewType={viewType}
            itemId={itemId}
            onSuccess={onSuccess}
            onError={onError}
            requestId={requestId}
            workerId={workerId}
            icon={CancelIcon}
            buttonText={cancelText}
            type='cancel'
            background='#f44336'
        />
    );

    if (discrepancyCheck === null) {
        return (
            <>
                {confirmButtonEl}
                {cancelButtonEl}
            </>
        );
    }

    if (isConfirmSuspicious) {
        return confirmButtonEl;
    }

    if (!isConfirmSuspicious) {
        return cancelButtonEl;
    }

    return null;
};
