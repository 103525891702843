import _ from 'lodash';
import moment from 'moment';

import { DATE_FORMAT } from '@constants';
import {
    ExpensesExpenseDetail,
    ExpensesFormCostTypes,
    ExpensesFormFieldNames,
    ExpensesFormNormalizeDataArg,
    ExpensesOnFinishData,
} from '@typings';

type MapperExpensesFormData = (data: ExpensesFormNormalizeDataArg) => ExpensesOnFinishData;

export const mapperExpensesFormData: MapperExpensesFormData = ({
    days_interval,
    administration_cost_type,
    industrial_cost_type,
    material_type,
    customer,
    ...data
}) => {
    const result: Partial<ExpensesOnFinishData> = { ...data };

    if (days_interval) {
        const [firstDay, lastDay] = days_interval;

        result.first_day = firstDay.format(DATE_FORMAT);
        result.last_day = lastDay.format(DATE_FORMAT);
    }

    if (customer) {
        result.customer = customer;
    }

    const costTypes: Partial<Record<ExpensesFormCostTypes, string>> = {
        administration: administration_cost_type,
        industrial: industrial_cost_type,
        material: material_type,
    };

    result.cost_type_pk = costTypes[data.cost_type_group];

    return result as ExpensesOnFinishData;
};

const mergeFieldAndInitialExpensesData = (
    names: Array<keyof ExpensesExpenseDetail>,
    initial: ExpensesExpenseDetail
) => {
    return names.reduce(
        (acc: Partial<Pick<ExpensesExpenseDetail, keyof ExpensesExpenseDetail>>, name) => {
            const current = initial?.[name];

            if (!current) return acc;

            if (_.isObject(current) && _.has(current, 'key')) {
                return { ...acc, [name]: current.key.toString() };
            }

            return { ...acc, [name]: current };
        },
        {}
    );
};

export const getInitialExpensesFormData = (
    expensesFieldNamesConfig: ExpensesFormFieldNames[],
    initial?: ExpensesExpenseDetail
) => {
    if (!initial) return [];

    const formattedFieldNames: Array<keyof ExpensesExpenseDetail> = [
        ...expensesFieldNamesConfig,
        'amount',
        'comment',
    ];

    return {
        ...mergeFieldAndInitialExpensesData(formattedFieldNames, initial),
        days_interval: [
            moment(initial.first_day, DATE_FORMAT),
            moment(initial.last_day, DATE_FORMAT),
        ],
    };
};
