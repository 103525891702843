import { FC } from 'react';
import { Modal } from 'antd';

import { GetProviderForm } from '@components/forms';
import { closeModal } from '@store/modal';
import { ExpensesProviderDetail, Nullable } from '@typings';

interface FinishValues {
    pk?: number;
    tax_code?: string;
    name: string;
}

interface Props {
    onSubmit: (values: FinishValues) => void;
    onCancel: () => void;
    isLoading?: boolean;
    isOpen: boolean;
    initial: Nullable<ExpensesProviderDetail>;
}

export const GetProvider: FC<Props> = ({
    initial,
    isOpen,
    onSubmit,
    isLoading,
    onCancel: onCancelCb,
}) => {
    const onCancel = () => {
        closeModal();
        onCancelCb();
    };

    return (
        <Modal
            visible={isOpen}
            title='Завести поставщика'
            width={'50%'}
            okButtonProps={{ form: 'provider_modal_form', htmlType: 'submit' }}
            onCancel={onCancel}
            okText='Сохранить'
            destroyOnClose
            cancelText='Отмена'
            confirmLoading={isLoading}
        >
            <GetProviderForm id='provider_modal_form' initial={initial} onSubmit={onSubmit} />
        </Modal>
    );
};
