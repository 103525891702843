import { FC } from 'react';
import { Comment as UIComment, Tooltip } from 'antd';
import moment from 'moment';

import styles from './comment.module.scss';

interface Props {
    author: string;
    content: string;
    avatar?: string;
    datetime: moment.Moment;
}

export const Comment: FC<Props> = ({ author, content, datetime, avatar }) => {
    const dateTime = (
        <Tooltip title={moment(datetime).format('YYYY-MM-DD HH:mm:ss')}>
            <span>{moment(datetime).fromNow()}</span>
        </Tooltip>
    );

    return (
        <UIComment
            className={styles.comment}
            author={author}
            avatar={avatar}
            content={<p>{content}</p>}
            datetime={dateTime}
        />
    );
};
