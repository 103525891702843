import { FC, MouseEvent, ReactNode, useState } from 'react';
import { Tag, Tooltip } from 'antd';
import { ColumnType, TableProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import Button from 'antd-button-color';

import { DeleteFilled, EditFilled, UndoOutlined } from '@ant-design/icons';
import { ExpensesCommentModal } from '@components/modals';
import { openModal } from '@store/modal';
import { ExpensesExpenseItem, RenderModal } from '@typings';
import { getDate } from '@utils/date';
import { formatToMoney } from '@utils/formatToMoney';

import { ScrollableTable } from '../../Tables';

import styles from './ExpensesTable.module.scss';

const statusInfo = {
    new: { text: 'новый', color: 'green' },
    confirmed: { text: 'одобрен', color: 'blue' },
    confirmed_waiting_supply: {
        text: (
            <>
                одобрен,
                <br />
                ждем поступления
            </>
        ),
        color: 'geekblue',
    },
    confirmed_supplied: { text: 'одобрен, поступил', color: 'blue' },
    rejected: { text: 'отклонен', color: 'red' },
    paid: { text: 'оплачен', color: 'gold' },
};

const getAmount = (amount: number) => (
    <div className={styles.amount}>{amount ? formatToMoney(amount) : '0'}</div>
);

interface Props {
    data: ExpensesExpenseItem[];
    initialPageSize?: number;
    loading: boolean;
    showTotal?: (total: number) => string;
    onExpenseAction: (
        idExpense: number,
        action: string,
        comment?: string,
        row?: ExpensesExpenseItem
    ) => void;
    onProviderClick: (providerId: number) => void;
}

type ButtonType =
    | 'link'
    | 'success'
    | 'warning'
    | 'info'
    | 'dark'
    | 'lightdark'
    | 'danger'
    | 'dashed'
    | 'ghost'
    | 'default'
    | 'primary'
    | 'text';

export const ExpensesTable: FC<Props> = ({
    data,
    loading,
    onExpenseAction,
    onProviderClick,
    initialPageSize,
}) => {
    const [sortedInfo, setSortedInfo] = useState<SorterResult<ExpensesExpenseItem[]>>({});

    const getSortOrder = (currentColumnKey: string) => {
        if (sortedInfo.columnKey === currentColumnKey) {
            return sortedInfo?.order;
        }
        return undefined;
    };

    const handleChangeTable: TableProps<ExpensesExpenseItem>['onChange'] = (
        _pagination,
        _filters,
        sorter
    ) => {
        setSortedInfo(sorter as SorterResult<ExpensesExpenseItem[]>);
    };

    const getAction = (row: ExpensesExpenseItem, code: string) => {
        let type: ButtonType = 'danger';
        let text: string | ReactNode = code;
        let action = (_e: MouseEvent<HTMLElement>) => {
            return onExpenseAction(row.id, code, '', row);
        };

        if (code === 'fill_from') {
            type = 'primary';
            text = <UndoOutlined />;
        } else if (code === 'edit') {
            type = 'primary';
            text = <EditFilled />;
        } else if (code === 'delete') {
            type = 'danger';
            text = <DeleteFilled />;
        } else if (code === 'confirm') {
            type = 'primary';
            text = 'одобрить';
        } else if (code === 'confirm_supply') {
            type = 'primary';
            text = 'подтвердить поступление';
        } else if (code === 'unconfirm') {
            type = 'danger';
            text = 'отменить одобрение';
        } else if (code === 'reject') {
            type = 'danger';
            text = 'отклонить';
            action = () => {
                const onReject = (comment: string) => {
                    onExpenseAction(row.id, 'reject', comment);
                };

                openModal(renderModal(onReject));
            };
        }

        return (
            <Button ghost key={code} type={type} size='small' onClick={action}>
                {text}
            </Button>
        );
    };

    const getActions = (row: ExpensesExpenseItem, actions: string[]) => {
        return actions.map((action) => getAction(row, action));
    };

    const columns: ColumnType<ExpensesExpenseItem>[] = [
        {
            title: '№',
            dataIndex: 'id',
            key: 'id',
            sorter: {
                compare: (a, b) => a.id - b.id,
                multiple: 13,
            },
            sortOrder: getSortOrder('id'),
            ellipsis: true,
        },
        {
            title: 'Автор',
            dataIndex: 'author',
            key: 'author',
            render: (_text: string, row: ExpensesExpenseItem) => (
                <Tooltip placement='top' title={row?.author?.first_name}>
                    {row?.author?.username}
                </Tooltip>
            ),
            sorter: {
                compare: (a, b) => a.author.username.localeCompare(b.author.username),
                multiple: 12,
            },
            sortOrder: getSortOrder('author'),
            ellipsis: true,
        },
        {
            title: () => <div className={styles.limitedWidth}>Дата заведения</div>,
            dataIndex: 'timestamp',
            key: 'timestamp',
            sorter: {
                compare: (a, b) => {
                    const date = getDate(a.timestamp, b.timestamp);
                    return date.a.getTime() - date.b.getTime();
                },
                multiple: 11,
            },
            sortOrder: getSortOrder('timestamp'),
            ellipsis: true,
        },
        {
            title: 'Поставщик',
            dataIndex: 'name',
            key: 'name',
            render: (_, { name, id }) => (
                <Button className={styles.vendor} type='link' onClick={() => onProviderClick(id)}>
                    {name}
                </Button>
            ),
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 10,
            },
            sortOrder: getSortOrder('name'),
            ellipsis: true,
        },
        {
            title: 'Клиент',
            dataIndex: 'customer',
            key: 'customer',
            render: (text: string) => <div className={styles.client}>{text}</div>,
            sorter: {
                compare: (a: { customer: string }, b: { customer: string }) =>
                    a.customer.localeCompare(b.customer),
                multiple: 9,
            },
            sortOrder: getSortOrder('customer'),
            ellipsis: true,
        },
        {
            title: 'Тип расхода',
            dataIndex: 'type',
            key: 'type',
            render: (_, { debit, type }) => (
                <a href={`/operating_account/${debit}/detail`} target='_blank' rel='noreferrer'>
                    {type}
                </a>
            ),
            sorter: {
                compare: (a, b) => a.type.localeCompare(b.type),
                multiple: 8,
            },
            sortOrder: getSortOrder('type'),
            ellipsis: true,
        },
        {
            title: 'С',
            dataIndex: 'first_day',
            key: 'first_day',
            sorter: {
                compare: (a, b) => {
                    const date = getDate(a.first_day, b.first_day);
                    return date.a.getTime() - date.b.getTime();
                },
                multiple: 7,
            },
            sortOrder: getSortOrder('first_day'),
            ellipsis: true,
        },
        {
            title: 'По',
            dataIndex: 'last_day',
            key: 'last_day',
            sorter: {
                compare: (a, b) => {
                    const date = getDate(a.last_day, b.last_day);
                    return date.a.getTime() - date.b.getTime();
                },
                multiple: 6,
            },
            sortOrder: getSortOrder('last_day'),
            ellipsis: true,
        },
        {
            title: () => <div className={styles.limitedWidth}>Сумма заявки</div>,
            dataIndex: 'amount',
            key: 'amount',
            align: 'center',
            render: getAmount,
            sorter: {
                compare: (a, b) => {
                    return Number(a.amount) < Number(b.amount) ? -1 : 1;
                },
                multiple: 5,
            },
            sortOrder: getSortOrder('amount'),
            ellipsis: true,
        },
        {
            title: () => <div className={styles.limitedWidth}>Сумма пост-я</div>,
            dataIndex: 'supplied_amount',
            key: 'supplied_amount',
            align: 'center',
            render: getAmount,
            sorter: {
                compare: (a, b) => {
                    return Number(a.supplied_amount) < Number(b.supplied_amount) ? -1 : 1;
                },

                multiple: 4,
            },
            sortOrder: getSortOrder('supplied_amount'),
            ellipsis: true,
        },
        {
            title: () => <div className={styles.limitedWidth}>Сумма оплаты</div>,
            dataIndex: 'paid_amount',
            key: 'paid_amount',
            align: 'center',
            render: getAmount,
            sorter: {
                compare: (a, b) => {
                    return Number(a.paid_amount) < Number(b.paid_amount) ? -1 : 1;
                },

                multiple: 3,
            },
            sortOrder: getSortOrder('paid_amount'),
            ellipsis: true,
        },
        {
            title: () => <div className={styles.limitedWidth}>Сумма продажи</div>,
            dataIndex: 'sold_amount',
            key: 'sold_amount',
            align: 'center',
            render: getAmount,
            sorter: {
                compare: (a, b) => {
                    return Number(a.sold_amount) < Number(b.sold_amount) ? -1 : 1;
                },

                multiple: 2,
            },
            sortOrder: getSortOrder('sold_amount'),
            ellipsis: true,
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (text: keyof typeof statusInfo) => (
                <Tag className={styles.tag} color={statusInfo?.[text]?.color}>
                    {statusInfo?.[text]?.text}
                </Tag>
            ),
            sorter: {
                compare: (a, b) => a.status.localeCompare(b.status),
                multiple: 1,
            },
            sortOrder: getSortOrder('status'),
            ellipsis: true,
        },
        {
            title: 'Комментарий',
            dataIndex: 'comment',
            key: 'comment',
            render: (_text: string) => <p className={styles.comment}>{_text}</p>,
        },
        {
            title: 'Действия',
            key: 'action',
            render: (_, row: ExpensesExpenseItem) => (
                <div className={styles.actions}>{getActions(row, row.actions)}</div>
            ),
        },
    ];

    return (
        <ScrollableTable
            data={data}
            initialPageSize={initialPageSize}
            loading={loading}
            className={styles.ExpensesTable}
            showSorterTooltip={false}
            onChange={handleChangeTable}
            columns={columns}
            size={'small'}
        />
    );
};

const renderModal =
    (onCommentSubmit: (comment: string) => void): RenderModal =>
    (isOpen) => {
        return (
            <ExpensesCommentModal
                isOpen={isOpen}
                title='Отклонить расход'
                onOk={onCommentSubmit}
                okText='Отклонить расход'
                cancelText='Отмена'
            />
        );
    };
