import { CSSProperties, FC, useContext, useState } from 'react';
import { DatePicker } from 'antd';
import Text from 'antd/lib/typography/Text';
import moment, { Moment } from 'moment';

import { saveWorkerPlannedContactDay } from '@api';
import { DATE_FORMAT } from '@constants';
import { WorkersReportTableContext } from '@providers/workersReportTable';
import { Nullable } from '@typings';
import { showError } from '@utils/show_error';

interface Props {
    workerId: number;
    value: Nullable<Moment>;
    style?: CSSProperties;
}

const onError = (error: Error) => {
    showError(error.message);
};
export const DatePickerPlanContactDay: FC<Props> = ({ value, workerId, style }) => {
    const { updateWorker } = useContext(WorkersReportTableContext);
    const [isEdit, setEdit] = useState<boolean>(false);

    const onDateChange = (date: Nullable<Moment>) => {
        setEdit(false);

        if (date) {
            saveWorkerPlannedContactDay({
                onSuccess() {
                    updateWorker(workerId, { plannedContactDay: date });
                },
                onError,
                params: {
                    date: date.format(DATE_FORMAT),
                    worker: workerId,
                },
            });
        }
    };

    const disabledDate = (current: Moment) => {
        return current && current < moment().subtract(1, 'day').endOf('day');
    };

    const onBlur = () => {
        setEdit(false);
    };

    const onEdit = () => {
        setEdit(true);
    };

    const renderContent = () => {
        if (isEdit) {
            return (
                <DatePicker
                    onChange={onDateChange}
                    onBlur={onBlur}
                    open={isEdit}
                    disabledDate={disabledDate}
                    showToday={false}
                    placeholder='Дата следующего контакта'
                    format={DATE_FORMAT}
                    autoFocus
                />
            );
        }

        return <Text onClick={onEdit}>{value?.format(DATE_FORMAT) || '-'}</Text>;
    };

    return <div style={style}>{renderContent()}</div>;
};
