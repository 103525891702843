import { FC } from 'react';
import { Button } from 'antd';

import { addDeliveryAdditinalPhotos } from '@api';
import { UploadPhoto } from '@components';
import { PhotoListState, SetState } from '@typings';
import { showError } from '@utils/show_error';

import styles from './additionalPhoto.module.scss';
interface Props {
    request: number | undefined;
    setPhotos: SetState<PhotoListState>;
}

export const ButtonAddPhoto: FC<Props> = ({ request, setPhotos }) => {
    const onPhotoAdd = (image: File) => {
        addDeliveryAdditinalPhotos({
            params: { images: image, request: request!! },
            onSuccess({ data }) {
                setPhotos((prev) => ({ ...prev, additionalPhotoList: data.data }));
            },
            onError(error) {
                showError(error.message);
            },
        });
    };

    return (
        <div className={styles.button}>
            <Button>
                <UploadPhoto label='Добавить фото' request={request} onLoad={onPhotoAdd} />
            </Button>
        </div>
    );
};
