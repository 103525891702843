import { FC } from 'react';
import { Button, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';

import { createRequest, createRequestItem } from '@api';
import { AddDeliveryRequestForm } from '@components';
import { formatCreateRequestForm, getRequestDataByCreateType } from '@pages/Delivery/mapper';
import { closeModal } from '@store/modal';
import {
    AddDeliveryRequestFormValues,
    DeliveryCreateRequestItemParams,
    DeliveryCreateRequestParams,
    DeliveryRequestMutationDefaultResponse,
    Response,
} from '@typings';
import { showError } from '@utils/show_error';

interface Props {
    isOpen: boolean;
    targetRequest?: number;
    toggleLoading(isLoading: boolean): void;
    onSuccess(): void;
}

export const DeliveryAddRequest: FC<Props> = ({
    isOpen,
    targetRequest,
    onSuccess: onSuccessCallback,
    toggleLoading,
}) => {
    const [form] = useForm<AddDeliveryRequestFormValues>();

    const onClearNewRequest = () => {
        form.resetFields();
    };

    const onModalClose = () => {
        closeModal();
    };

    const onRequestCreate = async () => {
        const values = await form.validateFields();
        const formattedValues = formatCreateRequestForm(values);

        const isCreateRequest = !parseInt(formattedValues.requestPk);
        const params = getRequestDataByCreateType(formattedValues, isCreateRequest);

        toggleLoading(true);

        const onSuccess = (data: Response<DeliveryRequestMutationDefaultResponse>) => {
            if (data) {
                closeModal();
                onSuccessCallback();
            }
        };

        const onError = (error: Error) => {
            showError((error as AxiosError).response?.data?.detail || error.message);
        };

        if (isCreateRequest) {
            createRequest({
                onSuccess,
                onError,
                params: params as DeliveryCreateRequestParams,
            });
        } else {
            createRequestItem({
                onSuccess,
                onError,
                params: params as DeliveryCreateRequestItemParams,
            });
        }
    };

    return (
        <Modal
            key='modal_request'
            visible={isOpen}
            onCancel={onModalClose}
            onOk={onRequestCreate}
            title='Новая заявка'
            footer={
                <>
                    <Button onClick={onClearNewRequest}>Очистить</Button>
                    <Button onClick={onModalClose}>Отмена</Button>
                    <Button type='primary' onClick={onRequestCreate}>
                        Ок
                    </Button>
                </>
            }
            destroyOnClose
            width={600}
        >
            <AddDeliveryRequestForm pk={targetRequest} form={form} />
        </Modal>
    );
};
