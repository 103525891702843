import { ChangeEvent, FC, useState } from 'react';
import { FormInstance, Input, Space, TimePicker as UITimePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';

import styles from './timepicker.module.scss';

interface Props {
    form: FormInstance;
    name: string;
}

const getInterval = (value: string) => {
    const intervalRegex = /(\d{1,2}:\d{1,2})-(\d{1,2}:\d{1,2})/;

    const [_, intervalBegin, intervalEnd] = value.match(intervalRegex) || [];

    if (intervalBegin && intervalEnd) {
        return [intervalBegin, intervalEnd].map((time) => moment(time, 'HH:mm'));
    }

    return null;
};

export const TimePicker: FC<Props & RangePickerProps> = ({ form, name, ...props }) => {
    const [inputValue, setInputValue] = useState('');

    const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        const timeInterval = getInterval(target.value);
        if (timeInterval) {
            form.setFieldsValue({ [name]: timeInterval });
        }

        setInputValue(target.value);
    };

    return (
        <Space className={styles.main}>
            <UITimePicker.RangePicker name={name} {...props} />

            <Input
                className={styles.input}
                onChange={onInputChange}
                value={inputValue}
                placeholder='Интервал'
            />
        </Space>
    );
};
