import { FC } from 'react';

import { startItemWorker } from '@api';
import { UploadPhoto } from '@components';
import { useRequestConfirm } from '@hooks';
import { DeliveryRequestMutationDefaultResponse, Response } from '@typings';

import { CustomButton } from './CustomButton';

interface Props {
    item: number;
    request: number;
    worker_id: number;
    onError(error: Error): void;
    onSuccess(data: Response<DeliveryRequestMutationDefaultResponse>): void;
}
export const UploadStartPhoto: FC<Props> = ({ item, onError, onSuccess, request, worker_id }) => {
    const { sendRequestWithConfirm } = useRequestConfirm();

    const startItemWorkerAction = async (image: File | undefined = undefined) => {
        sendRequestWithConfirm({
            requestCallback: startItemWorker,
            onError,
            onSuccess,
            initialParams: {
                worker: worker_id,
                request,
                item,
                image,
            },
        });
    };

    return (
        <CustomButton>
            <UploadPhoto
                request={item}
                label='Приложить селфи'
                onLoad={startItemWorkerAction}
                viewFilename={false}
            />
        </CustomButton>
    );
};
