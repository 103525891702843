import { API_URI, BASE_URL_SERVER } from '@constants';

export const openNewWindowWithPostRequest = <T>(uri: API_URI, data: T) => {
    const form = document.createElement('form');

    form.target = '_blank';
    form.method = 'POST';
    form.action = `${BASE_URL_SERVER}${uri}`;
    form.style.display = 'none';

    for (const key in data) {
        const input = document.createElement('input');

        input.type = 'hidden';
        input.name = key;
        input.value = String(data[key]);
        form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
};
