import React, { memo, RefObject, useCallback, useEffect } from 'react';
import { WindowScroller } from 'react-virtualized';
import { VariableSizeGrid } from 'react-window';
import { Table, TableProps } from 'antd';
import { ColumnType } from 'antd/lib/table';
import clsx from 'clsx';
import { CustomizeScrollBody, TableComponents } from 'rc-table/lib/interface';

import { AttachOnColumnsChange, CustomColumn } from '@typings';

import { useScroll } from './hooks';

import styles from './VirtualTable.module.scss';

const typedMemo: <T>(_props: T) => T = memo;

interface Props<T> extends Omit<TableProps<T>, 'columns'> {
    columns: CustomColumn<T>[];
    attachOnColumnsChange: AttachOnColumnsChange;
    rowHeight?: number;
    getCellClassName?(record: T): string;
}

const initialRowHeight = 40;

// * source: https://ant.design/components/table/#components-table-demo-virtual-list
export const VirtualTable = typedMemo(
    <T extends object>({
        columns,
        dataSource,
        attachOnColumnsChange,
        getCellClassName,
        rowHeight,
        ...props
    }: Props<T>) => {
        const { gridRef } = useScroll();

        const onScroll = useCallback(({ scrollTop }) => {
            gridRef.current?.scrollTo({ scrollTop });
        }, []);

        useEffect(() => {
            const onColumnsFilterChange = (columnIndex: number) => {
                if (gridRef) {
                    gridRef.current?.resetAfterColumnIndex(columnIndex);
                }
            };

            attachOnColumnsChange({ onColumnsFilterChange });
        }, [gridRef]);

        const renderVirtualList: CustomizeScrollBody<T> = (rawData, { onScroll }) => {
            const getColumnWidth = (index: number) => {
                const { width } = columns!![index] || {};

                return width as number;
            };

            const getRowHeight = () => {
                return rowHeight || initialRowHeight;
            };

            const columnsWidthSum = columns.reduce((acc, column) => {
                return (acc += Number(column?.width));
            }, 0);

            const height = window.innerHeight + 40;

            return (
                <VariableSizeGrid
                    ref={gridRef as RefObject<VariableSizeGrid>}
                    className={`${styles.grid} virtual-grid`}
                    columnCount={columns!!.length}
                    columnWidth={getColumnWidth}
                    rowCount={rawData.length}
                    rowHeight={getRowHeight}
                    onScroll={onScroll}
                    width={columnsWidthSum}
                    height={height}
                    style={{ height: '100% !important' }}
                >
                    {({ columnIndex, rowIndex, style }) => {
                        const column = columns[columnIndex] as ColumnType<T>;
                        const dataIndex = column.dataIndex as keyof T;

                        const rowItemData = rawData[rowIndex][dataIndex];

                        const renderContent = () => {
                            if (column.render) {
                                return column.render(rowItemData, rawData[rowIndex], rowIndex);
                            }

                            return rowItemData;
                        };

                        const mainStyles = clsx(
                            getCellClassName?.(rawData[rowIndex]),
                            'virtual-table-cell',
                            {
                                'virtual-table-cell-last': columnIndex === columns.length - 1,
                            }
                        );

                        return (
                            <div className={mainStyles} style={style}>
                                {renderContent()}
                            </div>
                        );
                    }}
                </VariableSizeGrid>
            );
        };

        const tableStyles = clsx(styles.table, 'virtual-table');
        const scroll = { y: 'max-content' };
        const components: TableComponents<T> = {
            body: renderVirtualList,
        };

        return (
            <WindowScroller onScroll={onScroll}>
                {({ registerChild }) => {
                    return (
                        <div ref={registerChild}>
                            <Table<T>
                                {...props}
                                dataSource={dataSource}
                                columns={columns}
                                className={tableStyles}
                                scroll={scroll}
                                components={components}
                                pagination={false}
                            />
                        </div>
                    );
                }}
            </WindowScroller>
        );
    }
);
