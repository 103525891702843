import { FC, memo, useState } from 'react';
import { Select } from 'antd';

import { useUpdateRequest } from '@hooks';
import { DeliveryCellProps, DeliveryRequestOrExpanded } from '@typings';
import { getRequestPk, isMergedRequest } from '@utils/delivery';

type Props = Pick<DeliveryCellProps, 'rowData'>;

const getInitialValue = (request: DeliveryRequestOrExpanded) => {
    const hasElevator = request.items?.[0]?.has_elevator;

    if (hasElevator === null) {
        return 'Неизвестно';
    }

    return hasElevator ? 'Да' : 'Нет';
};

const selectOptions = ['Да', 'Нет', 'Неизвестно'];

export const ElevatorIndicator: FC<Props> = memo(({ rowData }) => {
    const doUpdateRequest = useUpdateRequest();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [value, setValue] = useState<string | number>(getInitialValue(rowData));

    const onChangeAction = async (data: string | number) => {
        setIsEdit(false);

        const onSuccess = () => {
            setValue(data);
        };

        doUpdateRequest({
            pk: getRequestPk(rowData),
            items: rowData.items,
            field: 'has_elevator',
            value: data === 'Неизвестно' ? null : data === 'Да',
            onSuccess,
        });
    };

    if (!isMergedRequest(rowData)) {
        return null;
    }

    if (isEdit) {
        return (
            <Select
                style={{ fontSize: '12px' }}
                size='small'
                defaultValue={value}
                onChange={(value) => onChangeAction(value)}
                onBlur={() => setIsEdit(false)}
            >
                {selectOptions.map((name, key) => (
                    <Select.Option key={key} value={name}>
                        {name}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    return <div onClick={() => setIsEdit(true)}>{value}</div>;
});
