import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { DatePicker, Form, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import _ from 'lodash';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import { DATE_FORMAT } from '@constants';
import { CustomDatePickerRangeProps } from '@typings';

const START_YEAR = 2015;
const LAST_YEAR = new Date().getFullYear() + 2;
const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

type DateState = Record<string, number | RangeValue<moment.Moment>>;

const CustomDatePickerRange: FC<CustomDatePickerRangeProps> = ({
    name = 'datepicker',
    placeholder,
    first_day,
    last_day,
    showYearSelector,
    showMonthSelector,
    formInstance = undefined,
    noStyle,
    onChange = () => {},
}) => {
    showYearSelector = _.defaults(showYearSelector, true).valueOf();
    showMonthSelector = _.defaults(showMonthSelector, true).valueOf();

    const [debouncedOnChange] = useState(() => _.debounce(onChange, 100));
    const [initialState] = useState({ firstDay: first_day, lastDay: last_day });
    const initialDate: RangeValue<moment.Moment> = useMemo(
        () => [
            moment(initialState.firstDay, DATE_FORMAT),
            moment(initialState.lastDay, DATE_FORMAT),
        ],
        [initialState]
    );
    const initialValue = useMemo(
        () => ({
            [name]: initialDate,
            [`${name}-year`]: moment(initialState.firstDay, DATE_FORMAT).year(),
        }),
        [initialState, initialDate, name]
    );
    const [value, setValue] = useState<DateState>(initialValue);

    const isInitialMount = useRef(true);
    const isInitialMountValue = useRef(true);
    const [form] = useForm(formInstance);

    const options = new Array(LAST_YEAR - START_YEAR)
        .fill(0)
        .map((_item, i) => START_YEAR + i)
        .reverse();

    const updateForm = (newValue: DateState) => {
        setValue((value) => ({ ...value, ...newValue }));
    };

    const onChangeDate = (newValue: RangeValue<moment.Moment>) => {
        updateForm({ [name]: newValue });
    };

    const onChangeMonth = (month: number) => {
        const currentValue = value[`${name}-year`] as number;

        const firstDay = moment().year(currentValue).month(month).startOf('month');
        const lastDay = moment().year(currentValue).month(month).endOf('month');

        updateForm({ [name]: [firstDay, lastDay] });
    };

    const onChangeYear = (year: number) => {
        const currentValue = value?.[name] as RangeValue<moment.Moment>;

        const newValue = currentValue?.map((item) => {
            const date = item?.toDate();
            date?.setFullYear(year);

            return moment(date, DATE_FORMAT);
        }) as RangeValue<moment.Moment>;

        updateForm({ [name]: newValue, [`${name}-year`]: +year });
    };

    const stateDate = value[name] as RangeValue<moment.Moment>;

    useEffect(() => {
        if (stateDate == null) {
            setValue(initialValue);
            return;
        }
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            debouncedOnChange({
                first_day: stateDate[0]?.format(DATE_FORMAT),
                last_day: stateDate[1]?.format(DATE_FORMAT),
            });
        }
    }, [name, stateDate, debouncedOnChange, initialValue]);

    useEffect(() => {
        if (isInitialMountValue.current) {
            isInitialMountValue.current = false;
        } else {
            isInitialMount.current = true;
            setValue((prevValue) => ({
                ...prevValue,
                [name]: [moment(first_day, DATE_FORMAT), moment(last_day, DATE_FORMAT)],
            }));
        }
    }, [name, first_day, last_day]);

    useEffect(() => {
        form.setFieldsValue(value);
    }, [form, value]);

    return (
        <Form.Item noStyle={noStyle}>
            {showYearSelector && (
                <Form.Item name={`${name}-year`}>
                    <Select
                        style={{ width: 75 }}
                        onChange={onChangeYear}
                        options={options.map((item) => ({ value: item, label: item }))}
                    />
                </Form.Item>
            )}
            {showMonthSelector && (
                <Form.Item name={`${name}-month`}>
                    <Select
                        style={{ width: 120 }}
                        placeholder='Месяц'
                        onChange={onChangeMonth}
                        options={months.map((item, i) => ({ value: i, label: item }))}
                    />
                </Form.Item>
            )}
            <Form.Item name={name}>
                <DatePicker.RangePicker
                    placeholder={placeholder}
                    format={DATE_FORMAT}
                    onChange={onChangeDate}
                />
            </Form.Item>
        </Form.Item>
    );
};

const isEqual = (prevProps: CustomDatePickerRangeProps, nextProps: CustomDatePickerRangeProps) => {
    return (
        Object.keys(prevProps).length === Object.keys(nextProps).length &&
        (Object.keys(prevProps) as Array<keyof CustomDatePickerRangeProps>)
            .filter((param) => param !== 'placeholder')
            .every((param) => prevProps[param] === nextProps[param])
    );
};

export default memo(CustomDatePickerRange, isEqual);
