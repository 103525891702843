import {
    DeliveryRequestFormattedStatusList,
    DeliveryRequestsDashboardItemsNames,
    DeliveryStatusList,
} from '@typings';

export enum TABLE_METRO_COLORS {
    'RU-MOW_1' = '#EF0E18',
    'RU-MOW_2' = '#29BF28',
    'RU-MOW_3' = '#0079BF',
    'RU-MOW_4' = '#00C0FF',
    'RU-MOW_5' = '#8E5B29',
    'RU-MOW_6' = '#EE921B',
    'RU-MOW_7' = '#810081',
    'RU-MOW_8' = '#FFD800',
    'RU-MOW_8A' = '#FFD800',
    'RU-MOW_9' = '#9A9A9A',
    'RU-MOW_10' = '#9ACD00',
    'RU-MOW_11' = '#83C1C1',
    'RU-MOW_11A' = '#83C1C1',
    'RU-MOW_12' = '#A4B6D7',
    'RU-MOW_13' = '#0079BF',
    'RU-MOW_14' = '#FFFFFF',
    'RU-MOW_15' = '#DF64A2',
    'RU-MOW_D1' = '#F6A800',
    'RU-MOW_D2' = '#E83F83',

    'RU-SPE_1' = '#DA2128',
    'RU-SPE_2' = '#0078BF',
    'RU-SPE_3' = '#48B85E',
    'RU-SPE_4' = '#F58220',
    'RU-SPE_5' = '#8E479B',

    'RU-SAM_1' = '#DA2128',

    'RU-NIZ_1' = '#EF0E18',
    'RU-NIZ_2' = '#0078BF',
}
export const TABLE_READONLY_CELLS = [
    'pk',
    'author',
    'timestamp',
    'operator',
    'arrival_time',
    'confirmation_time',
    'time_interval',
    'has_elevator',
    'metro_line',
    'metro_name',
    'call_to_driver',
    'worker_count',
    'hours',
    'timesheet_photo',
    'arrived_workers',
    'assigned_workers',
    'route',
    'hours',
    'has_self_assigned_worker',
    'lateness',
    'pay_estimate',
];

export const TABLE_READONLY_REQUEST_CELLS = [
    'address',
    'mass',
    'place_count',
    'floor',
    'carrying_distance',
    'workers_required',
    'worker_count',
    'volume',
    'max_size',
];

export const TABLE_NO_TOOLTIP_CELLS = ['timesheet_photo', 'has_elevator', 'metro_line'];

export const DELIVERY_TABLE_FILTER_BY_STATUS: Map<
    DeliveryStatusList,
    DeliveryRequestsDashboardItemsNames
> = new Map([
    ['cancelled', 'cancelled'],
    ['cancelled_with_payment', 'cancelled'],
    ['declined', 'cancelled'],
    ['failed', 'cancelled'],
    ['removed', 'cancelled'],
    ['finished', 'in_work'],
    ['partly_arrival_submitted', 'in_work'],
    ['partly_arrived', 'in_work'],
    ['partly_photo_attached', 'in_work'],
    ['driver_callback', 'no_contact'],
    ['no_response', 'no_contact'],
    ['autotarification_attempt', 'preprocessing'],
    ['new', 'preprocessing'],
    ['partly_confirmed', 'preprocessing'],
    ['timepoint_confirmed', 'preprocessing'],
    ['photo_attached', 'in_work'],
]);

export const DELIVERY_DASHBOARD_GROUPS_NAMES: Map<
    DeliveryRequestsDashboardItemsNames,
    DeliveryRequestFormattedStatusList[]
> = new Map([
    ['cancelled', ['removed', 'cancelled', 'failed', 'declined', 'cancelled_with_payment']],
    ['in_work', ['finished', 'partly_arrived', 'partly_photo_attached', 'photo_attached']],
    [
        'preprocessing',
        ['timepoint_confirmed', 'partly_confirmed', 'new', 'autotarification_attempt'],
    ],
    ['no_contact', ['no_response', 'driver_callback']],
]);
