import { WorkersReportWorkerDetail } from '@typings';

export const workerCardTableTitleList: Partial<Record<keyof WorkersReportWorkerDetail, string>> = {
    balance: 'Баланс',
    citizenship: 'Гр-во',
    contractStatus: 'Договор',
    workerId: 'Айди',
    inputDate: 'Дата регестрации',
    isOnlineTomorrow: 'На завтра',
    label: 'Метка',
    fullName: 'ФИО',
    plannedContactDay: 'Дата след. контакта',
    status: 'Активность',
    tag: 'Тэг',
    telNumber: 'Телефон',
    unconfirmedCount: 'З-к в работе',
    zone: 'Регион',
    lastTurnoutDate: 'Последний выход',
    lastCallDate: 'Дата последнего звонка',
    reliability: 'Надежность',
    isBenevolent: 'Доброжелательный',
    availability: 'Доступность',
    readiness: 'Готовность',
};
