import { FC } from 'react';
import { Button as UIButton, ButtonProps } from 'antd';

export const CustomButton: FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <UIButton style={{ fontSize: '12px' }} size='small' {...props}>
            {children}
        </UIButton>
    );
};
