import { useCallback } from 'react';
import { Icon } from 'leaflet';

import {
    DeliveryMapRequestItemItem,
    DeliveryMapRequestMarkerItem,
    DeliveryMapRequestMarkerPosition,
    DeliveryMapRequestMarkerType,
    DeliveryMapRequestSelectionState,
    DeliveryMapRequestWorkerItem,
    Nullable,
} from '@typings';

import { isRequestMarker } from '../utils';

interface Params {
    currentRequest: DeliveryMapRequestSelectionState['request'];
    currentWorker: DeliveryMapRequestSelectionState['worker'];
}

type GetMarkerInfo<T> = (
    markerItem: DeliveryMapRequestMarkerItem,
    markerType: DeliveryMapRequestMarkerType
) => T;

type IconSize = [number, number];

const largeIconSize: IconSize = [24, 24];
const mediumIconSize: IconSize = [16, 16];
const smallIconSize: IconSize = [10, 10];

const iconRed = '/icons/mapRedIcon.svg';
const iconGreen = '/icons/mapGreenIcon.svg';
const iconBlue = '/icons/mapBlueIcon.svg';

const getMarkerIconMap = (iconUrl: string, iconSize: IconSize): Icon => {
    return new Icon({
        iconUrl: iconUrl,
        iconRetinaUrl: iconUrl,
        popupAnchor: [0, -16],
        iconSize: iconSize,
    });
};

export const useMarker = ({ currentRequest, currentWorker }: Params) => {
    const getSelectionItem = useCallback(
        (markerType: DeliveryMapRequestMarkerType) => {
            if (markerType === 'request') {
                return currentRequest;
            }

            return currentWorker;
        },
        [currentRequest, currentWorker]
    );

    const isExistMarker = useCallback(
        (pk: number, markerType: DeliveryMapRequestMarkerType) => {
            if (markerType === 'request') {
                return currentWorker?.requests?.includes(pk) || false;
            }

            return currentRequest?.workers?.includes(pk) || false;
        },
        [currentRequest, currentWorker]
    );

    const isMarkerActive: GetMarkerInfo<Nullable<boolean>> = useCallback(
        (markerItem, markerType) => {
            const isActiveMarker = getSelectionItem(markerType)?.pk === markerItem.pk;

            if (isExistMarker(markerItem.pk, markerType) || isActiveMarker) {
                return true;
            }

            const isDefaultStatus = currentWorker === null && currentRequest === null;

            if (isDefaultStatus) {
                return null;
            }

            return false;
        },
        [isExistMarker, getSelectionItem, currentWorker, currentRequest]
    );

    const getMarkerIconSize: GetMarkerInfo<IconSize> = (markerItem, markerType) => {
        const isActive = isMarkerActive(markerItem, markerType);

        if (isActive) {
            return largeIconSize;
        }

        if (isActive === null) {
            return mediumIconSize;
        }

        return smallIconSize;
    };

    const getMarkerIcon: GetMarkerInfo<Icon> = (markerItem, markerType) => {
        const iconSize = getMarkerIconSize(markerItem, markerType);

        if (isRequestMarker(markerItem)) {
            const currentIcon = markerItem.expiring ? iconRed : iconGreen;

            return getMarkerIconMap(currentIcon, iconSize);
        }

        return getMarkerIconMap(iconBlue, iconSize);
    };

    const getWorkerMarkerPosition = (
        worker: DeliveryMapRequestWorkerItem
    ): DeliveryMapRequestMarkerPosition => {
        const latitude = worker.location?.latitude || 0;
        const longitude = worker.location?.longitude || 0;

        return [latitude, longitude];
    };

    const getRequestMarkerPosition = (
        item: DeliveryMapRequestItemItem
    ): DeliveryMapRequestMarkerPosition => {
        const latitude = item.location?.latitude || 0;
        const longitude = item.location?.longitude || 0;

        return [latitude, longitude];
    };

    return {
        isMarkerActive,
        getMarkerIcon,
        getWorkerMarkerPosition,
        getRequestMarkerPosition,
    };
};
