import { memo, useMemo } from 'react';
import { List, Typography } from 'antd';

interface HeaderRenderProps {
    columnDate?: string;
}

function getHoliday({ columnDate }: { columnDate?: string }) {
    const time = new Date(columnDate ?? '');
    const date = time.getDate();
    const month = time.getMonth() + 1;
    const day = time.getDay();

    return {
        headerTitle: `${date < 10 ? '0' : ''}${date}.${month < 10 ? '0' : ''}${month}`,
        weekHeader: `${time.toLocaleString('ru-ru', { weekday: 'short' })}`,
        isHolyday: !(day !== 0 && day !== 6),
    };
}

export const HeaderRender = memo((props: HeaderRenderProps) => {
    const { columnDate } = props ?? {};

    const { headerTitle, weekHeader, isHolyday } = useMemo(() => {
        return getHoliday({ columnDate });
    }, [columnDate]);

    return (
        <List size={'small'} style={{ backgroundColor: isHolyday ? '#ffeeee' : undefined }}>
            <List.Item style={{ padding: '0px' }}>
                <Typography.Text>{headerTitle}</Typography.Text>
            </List.Item>
            <List.Item style={{ padding: '0px' }}>
                <Typography.Text>{weekHeader}</Typography.Text>
            </List.Item>
        </List>
    );
});
