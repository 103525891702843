import { FC, useContext } from 'react';
import clsx from 'clsx';

import { DeliveryContext } from '@providers/delivery';
import { DeliveryRequestsDashboardItemGroup, DeliveryRequestsDashboardItemsNames } from '@typings';
import { getPercent } from '@utils/getPrecent';

import { DashboardGroup } from './DashboardGroup';

import styles from './RequestsDashboard.module.scss';

const groupNames = {
    preprocessing: 'В обработке',
    in_work: 'Выполняется',
    no_contact: 'Нет контакта',
    cancelled: 'Отменены',
};

interface Props {
    groups: DeliveryRequestsDashboardItemGroup[];
    title: DeliveryRequestsDashboardItemsNames;
    itemTotal: number;
    dashBoardTotal: number;
}

export const DashboardItem: FC<Props> = ({ dashBoardTotal, itemTotal, title, groups }) => {
    const { setFilterStatus, filterStatus } = useContext(DeliveryContext);

    const dashboardGroupsEl = groups.map(({ title, total }) => {
        return <DashboardGroup key={title} total={total} title={title} itemTotal={itemTotal} />;
    });

    const onGroupSelect = () => {
        const currentGroup = title === filterStatus ? null : title;

        setFilterStatus(currentGroup);
    };

    const itemClass = clsx(filterStatus ? [styles.noSelected] : '', {
        [styles.block]: true,
        [styles[title]]: true,
        [styles.selected]: filterStatus === title,
    });

    return (
        <div className={itemClass} onClick={onGroupSelect}>
            <div className={styles.title}>
                {groupNames[title]}
                <span className={styles.blockTotal}>{itemTotal}</span>
            </div>

            <div className={`${styles.row} ${styles.mb3}`}>
                <div className={styles.loader}>
                    <div
                        className={styles.persent}
                        style={{ width: `${getPercent(itemTotal, dashBoardTotal)}%` }}
                    ></div>
                </div>
            </div>

            {dashboardGroupsEl}
        </div>
    );
};
