import { FC } from 'react';
import { Col, Form, FormItemProps as UIFormItemProps } from 'antd';

import styles from './workersReportForm.module.scss';
interface FormItemProps extends UIFormItemProps {
    span: number;
}

export const FormItem: FC<FormItemProps> = ({ children, span, style, ...props }) => {
    return (
        <Col span={span}>
            <Form.Item
                {...props}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                className={styles.form_item}
                style={style}
            >
                {children}
            </Form.Item>
        </Col>
    );
};
