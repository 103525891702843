import { FC, memo, useEffect, useState } from 'react';
import { TableProps } from 'antd';
import { SorterResult, SortOrder } from 'antd/lib/table/interface';

import { VirtualTable } from '@components';
import { WorkersReportTableProvider } from '@providers';
import {
    AttachOnColumnsChange,
    CustomColumn,
    InitializeColumns,
    OnColumnSortChange,
    WorkersReportUpdateWorker,
    WorkersReportWorker,
} from '@typings';

import { WorkersReportTableConfig } from './configs';

import styles from './workersReportTable.module.scss';

interface Props {
    dataSource: WorkersReportWorker[];
    filtredColumns: CustomColumn<WorkersReportWorker>[];
    initializeColumns: InitializeColumns;
    attachOnColumnsFilterChange: AttachOnColumnsChange;
    updateWorker: WorkersReportUpdateWorker;
    onColumnSortChange: OnColumnSortChange;
}

type SortData = Record<string, SortOrder | undefined>;

const getSortData = (
    sortData: SorterResult<WorkersReportWorker>[] | SorterResult<WorkersReportWorker>
): SortData => {
    const formattedSortData = Array.isArray(sortData) ? sortData : [sortData];

    return formattedSortData.reduce((acc: SortData, sortEl) => {
        if ('columnKey' in sortEl) {
            const { columnKey, order } = sortEl;
            const formattedKey = columnKey?.toString()!!;

            acc[formattedKey] = order;
        }

        return acc;
    }, {});
};

export const WorkersReportTable: FC<Props> = memo(
    ({
        dataSource,
        initializeColumns,
        filtredColumns,
        attachOnColumnsFilterChange,
        updateWorker,
        onColumnSortChange,
    }) => {
        const [sortedWorkers, setSortedWorkers] = useState(dataSource);

        useEffect(() => {
            setSortedWorkers(dataSource);
        }, [dataSource]);

        useEffect(() => {
            initializeColumns(WorkersReportTableConfig);
        }, []);

        const onTableChange: TableProps<WorkersReportWorker>['onChange'] = (
            _columns,
            _dataSource,
            sortData,
            { currentDataSource }
        ) => {
            onColumnSortChange(getSortData(sortData));

            setSortedWorkers(currentDataSource);
        };

        const getCellClassName = ({ registrationOk }: WorkersReportWorker) => {
            if (!registrationOk) {
                return styles.reqistration_not_ok_color;
            }

            return '';
        };

        const onChangeCurrentData = (currentDataSource: readonly WorkersReportWorker[]) => {
            setTimeout(() => {
                setSortedWorkers(currentDataSource as WorkersReportWorker[]);
            }, 0);

            return null;
        };

        return (
            <WorkersReportTableProvider updateWorker={updateWorker} sortedWorkers={sortedWorkers}>
                <VirtualTable
                    rowHeight={30}
                    getCellClassName={getCellClassName}
                    summary={onChangeCurrentData}
                    onChange={onTableChange}
                    rowKey='workerId'
                    size='small'
                    attachOnColumnsChange={attachOnColumnsFilterChange}
                    className={styles.table_container}
                    columns={filtredColumns}
                    dataSource={dataSource}
                    showSorterTooltip={false}
                    bordered
                />
            </WorkersReportTableProvider>
        );
    }
);
