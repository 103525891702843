import { CSSProperties, FC, ReactNode, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Upload } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NamePath } from 'antd/lib/form/interface';
import { RcFile } from 'antd/lib/upload';

import { DeleteFilled, UploadOutlined } from '@ant-design/icons';
import RemoteSelect from '@components/RemoteSelect';
import { API_URI, DATE_FORMAT } from '@constants';
import {
    ClaimsFormFinishValues,
    ClaimsFormValues,
    RemoteSelectOption,
    RemoteSelectProps,
} from '@typings';

interface ClaimsFormProps {
    onSubmit: (values: ClaimsFormFinishValues) => void;
}

interface GetRemoteSelectProps {
    key: string;
    label: string;
    url: API_URI;
    onChange?: RemoteSelectProps['onChange'];
    forward?: NamePath[];
}

interface GetSelectFieldProps {
    key: string;
    label: string;
    style?: CSSProperties;
    onChange?: (value: string) => void;
    options: RemoteSelectOption[];
}

const getField = (key: string, children: ReactNode) => {
    return (
        <Form.Item
            key={key}
            name={key}
            style={{ marginBottom: 0 }}
            rules={[{ required: true, message: 'Это поле необходимо!' }]}
        >
            {children}
        </Form.Item>
    );
};

const getSelectField = ({ key, label, onChange, style, options }: GetSelectFieldProps) => {
    return getField(
        key,
        <Select
            placeholder={label}
            style={style || { width: 200 }}
            onChange={onChange}
            options={options}
        />
    );
};

export const ClaimsForm: FC<ClaimsFormProps> = ({ onSubmit }) => {
    const [form] = useForm();
    const [customer, setCustomer] = useState<string>('');
    const [claimType, setClaimType] = useState<string>('');
    const [fineType, setFineType] = useState<string>('');
    const [deductionType, setDeductionType] = useState<string>('');
    const [deductionDateType, setDeductionDateType] = useState<string>('');
    const [deductionAmountType, setDeductionAmountType] = useState<string>('');
    const [worker, setWorker] = useState<string>('');
    const [fileList, setFileList] = useState<RcFile[]>([]);

    const normalizedData = ({
        fine_date,
        deduction_date,
        ...values
    }: ClaimsFormValues): ClaimsFormFinishValues => {
        const formatDate = (date?: moment.Moment) => {
            return date && date.format(DATE_FORMAT);
        };

        return {
            data: {
                ...values,
                deduction_date: formatDate(deduction_date),
                fine_date: formatDate(fine_date),
            },
            files: [...fileList],
        };
    };

    const getRemoteSelect = ({ key, label, url, forward, onChange }: GetRemoteSelectProps) => {
        return (
            <RemoteSelect.WithForward
                form={form}
                key={key}
                name={key}
                placeholder={label}
                onChange={onChange}
                formForward={forward}
                url={url}
            />
        );
    };

    const onFinish = (values: ClaimsFormValues) => {
        onSubmit(normalizedData(values));
    };

    const claimTypeItem = () => {
        return getSelectField({
            key: 'claim_type',
            label: 'Вычет/штраф',
            onChange: (value: string) => setClaimType(value),
            options: [
                { value: 'deduction', label: 'Вычет' },
                { value: 'fine', label: 'Штраф' },
            ],
        });
    };

    const customerItem = () => {
        return getRemoteSelect({
            key: 'customer',
            label: 'Клиент',
            url: API_URI.AUTOCOMPLETE_COMMON_CUSTOMER,
            onChange: (value) => setCustomer(value?.toString()),
        });
    };

    const workerItem = () => {
        return getRemoteSelect({
            key: 'worker',
            label: 'Работник',
            url: API_URI.AUTOCOMPLETE_WORKER_BY_CUSTOMER,
            onChange: (value) => {
                setWorker(value?.toString());
            },
            forward: ['customer'],
        });
    };

    const fineTypeItem = () => {
        return getSelectField({
            key: 'fine_type',
            label: 'Тип штрафа',
            onChange: (value: string) => setFineType(value),
            options: [
                { value: 'customer', label: 'от клиента' },
                { value: 'provider', label: 'от поставщика' },
            ],
        });
    };

    const providerItem = () => {
        return getRemoteSelect({
            key: 'provider',
            label: 'Поставщик',
            url: API_URI.AUTOCOMPLETE_PROVIDER,
        });
    };

    const fineDateItem = () => {
        return getField(
            'fine_date',
            <DatePicker format={DATE_FORMAT} placeholder='Дата штрафа' style={{ width: 200 }} />
        );
    };

    const deductionTypeItem = () => {
        return getSelectField({
            key: 'deduction_type',
            label: 'Тип вычета',
            onChange: (value: string) => setDeductionType(value),
            options: [
                { value: 'disciplinary', label: 'дисциплинарный' },
                { value: 'industrial_cost', label: 'за наши услуги' },
                { value: 'material', label: 'за материалы' },
            ],
        });
    };

    const industrialCostTypeItem = () => {
        return getRemoteSelect({
            key: 'industrial_cost_type',
            label: 'Услуга',
            url: API_URI.AUTOCOMPLETE_INDUSTRIAL_CUST_TYPE,
        });
    };

    const expenseItem = () => {
        return getRemoteSelect({
            key: 'expense',
            label: 'Закупка',
            url: API_URI.AUTOCOMPLETE_EXPENSE,
            forward: ['customer'],
        });
    };

    const deductionDateTypeItem = (claimType: string) => {
        const options = [{ value: 'turnout', label: 'к выходу' }];
        if (claimType === 'fine') {
            options.push({ value: 'fine_date', label: 'к дате штрафа' });
        } else if (claimType === 'deduction') {
            options.push({ value: 'date', label: 'к дате' });
        }

        return getSelectField({
            key: 'deduction_date_type',
            label: 'Вычет привязать',
            options,
            onChange: (value) => setDeductionDateType(value),
        });
    };

    const turnoutItem = () => {
        return getRemoteSelect({
            key: 'turnout',
            label: 'Выход',
            url: API_URI.AUTOCOMPLETE_TURNOUT,
            forward: ['customer', 'worker'],
        });
    };

    const deductionDateItem = () => {
        return getField(
            'deduction_date',
            <DatePicker format={DATE_FORMAT} placeholder='Дата вычета' />
        );
    };

    const fineAmountItem = () => {
        return getField(
            'fine_amount',
            <Input
                placeholder='Сумма штрафа'
                style={{ width: 200 }}
                type='number'
                min='0'
                step='0.01'
            />
        );
    };

    const deductionAmountTypeItem = () => {
        return getSelectField({
            key: 'deduction_amount_type',
            label: 'Сумма вычета',
            options: [
                { value: 'by_fine', label: 'по сумме штрафа' },
                { value: 'arbitrary', label: 'внесена вручную' },
            ],
            onChange: (value) => setDeductionAmountType(value),
        });
    };

    const deductionAmountItem = () => {
        return getField(
            'deduction_amount',
            <Input
                placeholder='Сумма вычета'
                style={{ width: 200 }}
                type='number'
                min='0'
                step='0.01'
            />
        );
    };

    const commentItem = () => {
        return getField(
            'comment',
            <Input.TextArea placeholder='Комментарий' style={{ width: 200 }} />
        );
    };

    const submitItem = () => {
        return (
            <Button key='submit_button' type='primary' htmlType='submit'>
                Внести претензию
            </Button>
        );
    };

    const documentItem = () => {
        const images = [...fileList];
        const images_num = images ? images.length : 0;
        const label = 'приложить скан документа (' + images_num + ')';

        let clear_button = null;
        if (images_num > 0) {
            clear_button = (
                <Button
                    key='clear_button'
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setFileList([]);
                    }}
                >
                    <DeleteFilled />
                </Button>
            );
        }

        const key = 'images';
        return (
            <Form.Item key={key} style={{ marginBottom: 0 }}>
                <Upload
                    name={key}
                    listType='picture'
                    multiple
                    showUploadList={false}
                    fileList={images}
                    beforeUpload={(file) => {
                        setFileList((state) => [...state, file]);
                    }}
                >
                    <Button.Group>
                        <Button>
                            <UploadOutlined />
                            {label}
                        </Button>
                        {clear_button}
                    </Button.Group>
                </Upload>
            </Form.Item>
        );
    };

    const renderItems = () => {
        const col1 = [claimTypeItem(), customerItem()];

        const is_customer_selected = !!customer;
        const is_worker_visible = is_customer_selected;
        if (is_worker_visible) {
            col1.push(workerItem());
        }

        const col2 = [];

        const is_claim_selected = !!claimType;

        const is_fine_type_visible = claimType === 'fine';
        if (is_fine_type_visible) {
            col2.push(fineTypeItem());
        }

        const is_provider_visible = is_fine_type_visible && fineType === 'provider';
        if (is_provider_visible) {
            col2.push(providerItem());
            col2.push(fineDateItem());
        }

        const is_deduction_type_visible = claimType === 'deduction';
        if (is_deduction_type_visible) {
            col2.push(deductionTypeItem());
        }

        const is_industrial_cost_type_visible =
            is_deduction_type_visible && deductionType === 'industrial_cost';
        if (is_industrial_cost_type_visible) {
            col2.push(industrialCostTypeItem());
        }

        const is_expense_item_visible =
            is_customer_selected && is_deduction_type_visible && deductionType === 'material';
        if (is_expense_item_visible) {
            col2.push(expenseItem());
        }

        const is_deduction_date_type_visible =
            claimType === 'deduction' && is_worker_visible && worker;
        if (is_deduction_date_type_visible) {
            col2.push(deductionDateTypeItem(claimType));
        }

        const is_turnout_visible =
            worker &&
            ((claimType === 'deduction' &&
                is_deduction_date_type_visible &&
                deductionDateType === 'turnout') ||
                (claimType === 'fine' &&
                    is_worker_visible &&
                    is_fine_type_visible &&
                    fineType === 'customer'));
        if (is_turnout_visible) {
            col2.push(turnoutItem());
        }

        const is_deduction_date_visible =
            is_deduction_date_type_visible && deductionDateType === 'date';
        if (is_deduction_date_visible) {
            col2.push(deductionDateItem());
        }

        const col3 = [];

        const is_fine_amount_visible = claimType === 'fine';
        if (is_fine_amount_visible) {
            col3.push(fineAmountItem());
        }

        const is_deduction_amount_type_visible = claimType === 'fine';
        if (is_deduction_amount_type_visible) {
            col3.push(deductionAmountTypeItem());
        }

        if (is_deduction_amount_type_visible && deductionAmountType === 'arbitrary') {
            col3.push(deductionAmountItem());
        }

        if (claimType === 'deduction') {
            col3.push(deductionAmountItem());
        }

        if (is_claim_selected) {
            col3.push(commentItem());
        }

        if (is_fine_type_visible) {
            col3.push(documentItem());
        }
        col3.push(submitItem());

        return (
            <Row gutter={[12, 2]}>
                <Col key='col1'>
                    <Space direction='vertical'>{col1}</Space>
                </Col>
                <Col key='col2'>
                    <Space direction='vertical'>{col2}</Space>
                </Col>
                <Col key='col3'>
                    <Space direction='vertical'>{col3}</Space>
                </Col>
            </Row>
        );
    };
    return (
        <Form form={form} onFinish={onFinish}>
            {renderItems()}
        </Form>
    );
};
