export const generateFormData = <T>(data: T): FormData => {
    const formData = new FormData();

    for (const [item, itemValue] of Object.entries(data)) {
        if (itemValue !== undefined) {
            formData.append(item, itemValue);
        }
    }

    return formData;
};
