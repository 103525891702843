import { FC, useState } from 'react';
import { Card, Col } from 'antd';

import { CoverImage } from '../CoverImage/CoverImage';

import styles from '../photos.module.scss';
interface Props {
    url: string;
}
export const AdditionalPhotoElement: FC<Props> = ({ url }) => {
    const [showAction, setShowAction] = useState(false);

    return (
        <Col>
            <Card
                className={styles.card}
                bodyStyle={{ padding: 16 }}
                hoverable
                cover={
                    <CoverImage
                        initialUrl={url}
                        setShowAction={setShowAction}
                        showAction={showAction}
                    />
                }
            ></Card>
        </Col>
    );
};
