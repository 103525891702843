import { FC, memo } from 'react';
import { Tooltip } from 'react-leaflet';
import clsx from 'clsx';

import { DeliveryMapRequestMarkerItem, Nullable } from '@typings';

import { getMarkerView } from './utils';

import styles from './mapStyles.module.scss';

interface Props {
    markerItem: DeliveryMapRequestMarkerItem;

    isActive: Nullable<boolean>;
}

export const MarkerTooltip: FC<Props> = memo(({ markerItem, isActive }) => {
    const { className, text } = getMarkerView(markerItem, isActive);

    const mainStyles = clsx(className, styles.tooltip_container);

    return (
        <Tooltip permanent interactive>
            <div className={mainStyles}>{text}</div>
        </Tooltip>
    );
});
