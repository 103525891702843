import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { DATE_FORMAT } from '@constants';
import { useQuery } from '@hooks';
import { FetchWorkersReportParams, WorkersReportFilterFormValues } from '@typings';

type WorkersFormKeys = keyof WorkersReportFilterFormValues;
type WorkersFormValues = WorkersReportFilterFormValues[WorkersFormKeys];
type WorkerFormParamsValue<T extends WorkersFormKeys> = FetchWorkersReportParams[T];

const formatDay = (day: WorkersFormValues | undefined) => {
    if (day) {
        return moment(day, DATE_FORMAT);
    }

    return undefined;
};

const todayDate = moment(new Date()).format(DATE_FORMAT);

const initialFormValues: Partial<FetchWorkersReportParams> = {
    planned_contact_day: todayDate,
    last_call_date: todayDate,
    status_code: '1,2,0',
};

export const useSearchParams = () => {
    const history = useHistory();
    const searchParams = useQuery();

    const onParamsChange = useCallback(
        (params: Partial<FetchWorkersReportParams>) => {
            const validParams: Partial<FetchWorkersReportParams> = {};

            for (const param in params) {
                const currentParam = params[param as keyof FetchWorkersReportParams];

                if (currentParam) {
                    validParams[param as keyof FetchWorkersReportParams] = currentParam;
                }
            }

            history.push({
                search: new URLSearchParams(validParams).toString(),
            });
        },
        [history]
    );

    const formValues = useMemo((): Partial<WorkersReportFilterFormValues> => {
        const getParams = <T extends WorkersFormKeys>(
            params: T
        ): WorkerFormParamsValue<T> | undefined => {
            const paramsValue = searchParams.get(params);
            const initialValue = initialFormValues?.[params];

            return paramsValue || initialValue || undefined;
        };

        const formValues = {
            zone: getParams('zone'),
            availability: getParams('availability')?.split(','),
            last_call_date: formatDay(getParams('last_call_date')),
            planned_contact_day: formatDay(getParams('planned_contact_day')),
            status_code: getParams('status_code')?.split(','),
            search_text: getParams('search_text'),
            readiness: getParams('readiness')?.split(','),
        };

        return formValues;
    }, [searchParams]);

    return { formValues, onParamsChange };
};
