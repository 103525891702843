import { FC, memo } from 'react';

import { DeliveryCellProps } from '@typings';

import { TextCell } from '.';

export const Code: FC<DeliveryCellProps> = memo(({ onCommit, isEditing, rowData }) => {
    const codeValue = rowData.items?.length > 1 ? '' : rowData.items?.[0]?.code || '-';

    return <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={codeValue} />;
});
