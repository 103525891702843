import { FC } from 'react';

import { ScrollableTable } from '@components';
import { ClaimsClaimItem } from '@typings';

import { columns } from './config';

interface Props {
    data: ClaimsClaimItem[];
    loading: boolean;
}

export const ClaimsTable: FC<Props> = ({ loading, data }) => (
    <ScrollableTable data={data} loading={loading} columns={columns} size='small' />
);
