import React, { FC } from 'react';
import { Button, Tooltip } from 'antd';

import { CheckCircleTwoTone, CheckOutlined } from '@ant-design/icons';
import { confirmItemWorkerFinish, confirmItemWorkerStart } from '@api';
import {
    DashboardPhotoActionsView,
    DashboardPhotoItemType,
    DashborardPhotoActionButtonProps,
} from '@typings';

interface Props extends DashborardPhotoActionButtonProps {
    type: DashboardPhotoItemType;
    workerId: number;
    itemId: number;
    viewType: DashboardPhotoActionsView;
}

export const ConfirmStartButton: FC<Props> = ({
    type,
    requestId,
    onSuccess,
    workerId,
    itemId,
    onError,
    viewType,
}) => {
    const buttonStyle = { background: '#4caf50', borderColor: 'unset' };

    const onClick = async () => {
        const requestArg = {
            onSuccess,
            onError,
            params: {
                request: requestId,
                item: itemId,
                worker: workerId,
            },
        };

        if (type === 'finish') {
            confirmItemWorkerFinish(requestArg);
        } else {
            confirmItemWorkerStart(requestArg);
        }
    };

    if (viewType === 'icon') {
        return (
            <Tooltip placement='top' title='Принять отметку'>
                <CheckCircleTwoTone style={{ margin: '0 5px 0 5px' }} onClick={onClick} />
            </Tooltip>
        );
    }

    return (
        <Button style={buttonStyle} type='primary' onClick={onClick}>
            <CheckOutlined />
            Принять отметку
        </Button>
    );
};
