import { FC, memo, useCallback } from 'react';
import { Icon } from 'leaflet';

import {
    DeliveryMapRequestMarkerItem,
    DeliveryMapRequestMarkerPosition,
    DeliveryMapRequestMarkerType,
    DeliveryMapRequestOnMarkerClick,
    DeliveryMapRequestSelectionState,
    DeliveryMapRequestWorkerItem,
    Nullable,
    SetState,
} from '@typings';

import { useMarker } from './hooks';
import { Marker } from './Marker';
import { MarkerTooltip } from './MarkerTooltip';
import { isRequestMarker } from './utils';

interface Props {
    markerList: DeliveryMapRequestWorkerItem[] | DeliveryMapRequestMarkerItem[];
    setSelection: SetState<DeliveryMapRequestSelectionState>;
    currentRequest: DeliveryMapRequestSelectionState['request'];
    currentWorker: DeliveryMapRequestSelectionState['worker'];
    markerType: DeliveryMapRequestMarkerType;
}

const initialSelectionState = {
    request: null,
    worker: null,
};

interface RenderMarkerArg {
    position: DeliveryMapRequestMarkerPosition;
    icon: Icon;
    markerItem: DeliveryMapRequestMarkerItem;
    isActive: Nullable<boolean>;
}

export const MarkerList: FC<Props> = memo(
    ({ markerList, currentRequest, currentWorker, setSelection, markerType }) => {
        const { getMarkerIcon, isMarkerActive, getRequestMarkerPosition, getWorkerMarkerPosition } =
            useMarker({
                currentRequest,
                currentWorker,
            });

        const renderMarker = ({ icon, isActive, markerItem, position }: RenderMarkerArg) => {
            return (
                <Marker
                    markerPosition={position}
                    markerIcon={icon}
                    markerItem={markerItem}
                    markerType={markerType}
                    onMarkerClick={onMarkerClick}
                    key={markerItem.pk}
                >
                    <MarkerTooltip isActive={isActive} markerItem={markerItem} />
                </Marker>
            );
        };

        const onMarkerClick: DeliveryMapRequestOnMarkerClick = useCallback(
            (markerType, markerItem) => {
                setSelection({ ...initialSelectionState, [markerType]: markerItem });
            },
            []
        );

        const markerListEl = markerList.map((markerItem: DeliveryMapRequestMarkerItem) => {
            const isActive = isMarkerActive(markerItem, markerType);
            const icon = getMarkerIcon(markerItem, markerType);

            if (isRequestMarker(markerItem)) {
                return markerItem.items.map((item) => {
                    const position = getRequestMarkerPosition(item);

                    return renderMarker({ icon, isActive, markerItem, position });
                });
            }

            const position = getWorkerMarkerPosition(markerItem);

            return renderMarker({ icon, isActive, markerItem, position });
        });

        return <>{markerListEl}</>;
    }
);
