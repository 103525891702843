import { FC, useState } from 'react';
import { Col, Form, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { FieldItemWithObservableValue } from '@components/FieldItemWithObservableValue';
import RemoteSelect from '@components/RemoteSelect';
import { API_URI, API_URLS, BASE_URL_SERVER_API } from '@constants';
import { closeModal } from '@store/modal';
import { DeliveryFilterState } from '@typings';

interface Props {
    isOpen: boolean;
    state: Partial<DeliveryFilterState>;
}

export const ReportsExport: FC<Props> = ({ isOpen, state }) => {
    const [form] = useForm();
    const [client, setClient] = useState<{ customer: string | undefined }>({ customer: undefined });
    const [location, setLocation] = useState<{ location: string | undefined }>({
        location: undefined,
    });

    return (
        <Modal
            key='requests_import'
            visible={isOpen}
            title='Отчеты'
            onCancel={() => {
                closeModal();
                setClient({ customer: undefined });
                setLocation({ location: undefined });

                form.setFieldsValue({ customer: undefined, location: undefined });
            }}
            footer={null}
        >
            <Form form={form}>
                <Row style={{ margin: '0 0 20px' }}>
                    <RemoteSelect.Item
                        form={form}
                        required={false}
                        url={API_URI.AUTOCOMPLETE_DELIVERY_CUSTOMER}
                        name='customer'
                        placeholder='Клиент'
                        onChange={(value: string | string[]) => {
                            setClient({ customer: value.toString() });
                            setLocation({ location: undefined });
                        }}
                        onClear={() => {
                            setLocation({ location: undefined });
                        }}
                    />
                </Row>
                <FieldItemWithObservableValue dependency={['customer']}>
                    <Row style={{ margin: '0 0 20px' }}>
                        <RemoteSelect.WithForward
                            required={false}
                            formForward={['customer']}
                            form={form}
                            url={API_URI.AUTOCOMPLETE_LOCATION}
                            name='location'
                            placeholder='Филиал'
                            onChange={(value: string | string[]) => {
                                setLocation({ location: value.toString() });
                            }}
                        />
                    </Row>
                </FieldItemWithObservableValue>
                {location.location && (
                    <Row style={{ margin: '0 0 20px' }}>
                        <Col>
                            <a
                                href={`${BASE_URL_SERVER_API}${API_URLS.day_report_url}?day=${state.last_day}&customer=${client.customer}&location=${location.location}`}
                            >
                                отчет за {state.last_day}
                            </a>

                            <a
                                href={`${BASE_URL_SERVER_API}${API_URLS.interval_report_url}?first_day=${state.first_day}&last_day=${state.last_day}&customer=${client.customer}&location=${location.location}`}
                                style={{ margin: '0 0 0 20px' }}
                            >
                                отчет {state.first_day} до {state.last_day}
                            </a>
                        </Col>
                    </Row>
                )}
            </Form>
        </Modal>
    );
};
