import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { fetchWorker } from '@api';
import { DATE_FORMAT } from '@constants';
import {
    Nullable,
    WorkersReportMiniTableData,
    WorkersReportUpdateWorker,
    WorkersReportWorkerComment,
    WorkersReportWorkerDetail,
    WorkersReportWorkerId,
} from '@typings';
import { mapperWorkerDetail, mapperWorkersReportWorkerComment } from '@utils/mapperWorkersReport';
import { showError } from '@utils/show_error';

import { workerCardTableTitleList } from '../config';

interface Params {
    onError: () => void;
    workerId: WorkersReportWorkerId;
    updateWorker: WorkersReportUpdateWorker;
}
type WorkerDetailKeys = keyof WorkersReportWorkerDetail;

interface WorkerDataState {
    workerData: Nullable<WorkersReportWorkerDetail>;
    isLoading: boolean;
}

const initialWorkerDataState: WorkerDataState = {
    workerData: null,
    isLoading: true,
};

const getLastCallDate = () => {
    return moment(new Date()).format(DATE_FORMAT);
};

export const useWorker = ({
    onError: onErrorCb,
    workerId,
    updateWorker: updateWorkerCb,
}: Params) => {
    const [{ isLoading, workerData }, setWorkerData] =
        useState<WorkerDataState>(initialWorkerDataState);

    const onError = (error: Error) => {
        setWorkerData({ workerData: null, isLoading: true });
        showError(error.message);
        onErrorCb();
    };

    useEffect(() => {
        setWorkerData((prev) => ({ ...prev, isLoading: true }));

        fetchWorker({
            onError,
            params: {
                worker: workerId,
            },
            onSuccess({ data }) {
                setWorkerData({ isLoading: false, workerData: mapperWorkerDetail(data) });
            },
        });
    }, [workerId]);

    const updateWorkerData: WorkersReportUpdateWorker = useCallback(
        (currentWorkerId, workerValues) => {
            updateWorkerCb(currentWorkerId, workerValues);

            setWorkerData((prev) => {
                if (!prev.workerData) {
                    return { isLoading: false, workerData: null };
                }

                return {
                    isLoading: false,
                    workerData: { ...prev.workerData, ...workerValues },
                };
            });
        },
        [updateWorkerCb]
    );

    const workerDataList = useMemo((): WorkersReportMiniTableData[] => {
        if (workerData) {
            const workerList: Partial<
                Record<WorkerDetailKeys, WorkersReportMiniTableData['value']>
            > = {
                inputDate: workerData.inputDate,
                fullName: workerData.fullName,
                isOnlineTomorrow: workerData.isOnlineTomorrow,
                citizenship: workerData.citizenship.value,
                contractStatus: workerData.contractStatus.toString(),
                zone: workerData.zone.value,
                telNumber: workerData.telNumber,
                status: workerData.status,
                unconfirmedCount: workerData.unconfirmedCount.toString(),
                plannedContactDay: workerData.plannedContactDay,
                tag: workerData.tag,
                lastTurnoutDate: workerData.lastTurnoutDate,
                balance: workerData.balance,
                label: workerData.label.value,
                lastCallDate: getLastCallDate(),
                reliability: workerData.reliability?.toString() || null,
                isBenevolent: workerData.isBenevolent,
                availability: workerData.availability,
                readiness: workerData.readiness,
            };

            return Object.entries(workerList).map(([name, value]) => ({
                title: workerCardTableTitleList[name as WorkerDetailKeys] || '-',
                key: name as WorkerDetailKeys,
                value: value || null,
                workerId: workerData.workerId,
            }));
        }

        return [];
    }, [workerData]);

    const addWorkerComments = useCallback(
        (comment: WorkersReportWorkerComment) => {
            updateWorkerCb(workerId, { lastComment: comment });

            setWorkerData((prev) => {
                if (!prev.workerData) {
                    return { isLoading: false, workerData: null };
                }

                return {
                    isLoading: false,
                    workerData: {
                        ...prev.workerData,
                        comments: [...(prev?.workerData.comments || []), comment],
                    },
                };
            });
        },
        [updateWorkerCb]
    );

    const workerComments = useMemo(() => {
        return workerData?.comments?.map(mapperWorkersReportWorkerComment) || [];
    }, [workerData]);

    return {
        workerDataList,
        isLoading,
        workerRegistrationOk: workerData?.registrationOk,
        workerSelfie: workerData?.selfie,
        workerComments: workerComments,
        updateWorkerData,
        addWorkerComments,
    };
};
