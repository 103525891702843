import { FC, useContext } from 'react';
import Text from 'antd/lib/typography/Text';
import clsx from 'clsx';

import { WorkersReportCommentsModal } from '@components';
import { WorkersReportTableContext } from '@providers/workersReportTable';
import { openModal } from '@store/modal';
import {
    Nullable,
    RenderModal,
    WorkersReportUpdateWorker,
    WorkersReportWorkerComment,
    WorkersReportWorkerId,
} from '@typings';

import styles from './cells.module.scss';

interface Props {
    workerId: WorkersReportWorkerId;
    comment: Nullable<WorkersReportWorkerComment>;
}

export const Comment: FC<Props> = ({ comment, workerId }) => {
    const { updateWorker } = useContext(WorkersReportTableContext);

    const showAction = () => {
        openModal(renderModal(workerId, updateWorker));
    };

    if (!comment) {
        const emptyCommentStyle = clsx(styles.comment, styles.comment_empty);

        return <div className={emptyCommentStyle} onClick={showAction}></div>;
    }

    const { text, author } = comment;

    const textStyle = clsx(styles.text_ellipsis, styles.comment_text);
    const authorStyle = clsx(styles.text_ellipsis, styles.comment_author);

    return (
        <div className={styles.comment} onClick={showAction}>
            <Text type='secondary' className={authorStyle}>
                {author.value}:
            </Text>

            <Text className={textStyle}>{text}</Text>
        </div>
    );
};

const renderModal = (
    workerId: WorkersReportWorkerId,
    updateWorker: WorkersReportUpdateWorker
): RenderModal => {
    return (isOpen) => (
        <WorkersReportCommentsModal
            updateWorker={updateWorker}
            workerId={workerId}
            isOpen={isOpen}
        />
    );
};
