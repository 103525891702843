import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata';

import { DADATA_API_KEY } from '@constants';

import './styles.css';
import 'react-dadata/dist/react-dadata.css';

interface DadataAddressForAntFormProps {
    value?: DaDataSuggestion<DaDataAddress>;
    onChange?: (value: string) => void;
}

const DadataAddressForAntForm: FC<DadataAddressForAntFormProps> = ({
    value,
    onChange,
}: DadataAddressForAntFormProps) => {
    const [state, setState] = useState<DaDataSuggestion<DaDataAddress> | undefined>(value);
    const ref = useRef<AddressSuggestions>(null);

    useEffect(() => {
        if (onChange && (state?.unrestricted_value || state?.unrestricted_value === '')) {
            onChange(state?.unrestricted_value);
        }
    }, [state?.unrestricted_value]);

    const handleBlur = useCallback(() => {
        if (!ref?.current) {
            return;
        }
        const stateValue = state?.value;
        const inputValue = ref.current.state.inputQuery;
        if (inputValue !== stateValue || !stateValue) {
            ref.current.setInputValue(stateValue);
        }
    }, [state, ref]);

    return (
        <div className='DadataAddressForAntForm'>
            <AddressSuggestions
                ref={ref}
                inputProps={{
                    onBlur: handleBlur,
                }}
                token={DADATA_API_KEY}
                value={state}
                onChange={setState}
            />
        </div>
    );
};

export default DadataAddressForAntForm;
