import { FC, memo, useState } from 'react';
import { Button, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { AxiosError } from 'axios';

import { addItemWorker } from '@api';
import RemoteSelect from '@components/RemoteSelect';
import { API_URI } from '@constants';
import { useRequestConfirm } from '@hooks';
import { mapperRequestResponseDataToDetailFx } from '@utils/deliveryWorkersModal';
import { showError } from '@utils/show_error';

import { reloadTableFx, setRequestDetailFx } from './model';

import styles from './DeliveryWorkerStatus.module.scss';

export type TSelectWorkers = Array<number>;

interface FinishFormValues {
    workers: number[];
}

interface Props {
    request: number;
    item: number;
}

export const AddWorkerSelectLine: FC<Props> = memo(({ request, item }) => {
    const { sendRequestWithConfirm } = useRequestConfirm();
    const [isDisabled, setIsDisabled] = useState(true);
    const [form] = useForm();

    const selectStyles = { width: '100%', margin: '15px 0' };

    const addWorkers = ({ workers }: FinishFormValues) => {
        sendRequestWithConfirm({
            requestCallback: addItemWorker,
            initialParams: {
                request,
                item,
                workers,
            },
            onSuccess: ({ data }) => {
                if (data) {
                    setRequestDetailFx(mapperRequestResponseDataToDetailFx(data.data));
                    reloadTableFx();
                }
            },
            onError: (error: Error | AxiosError) => {
                showError((error as AxiosError)?.response?.data?.detail || error?.message);
            },
        });

        form.resetFields();
    };

    const handleFormChange = () => {
        const hasErrors = !form.getFieldValue('workers')?.length;
        setIsDisabled(hasErrors);
    };

    if (!request) {
        return null;
    }

    return (
        <Form
            form={form}
            className={styles.form}
            layout='inline'
            onFinish={addWorkers}
            onFieldsChange={handleFormChange}
        >
            <RemoteSelect.WithForward
                required={false}
                form={form}
                name='workers'
                style={selectStyles}
                url={API_URI.AUTOCOMPLETE_WORKER}
                mode='tags'
                defaultOpen={false}
                externalForward={{
                    delivery_request_pk: request,
                }}
            />

            <Form.Item className={styles.submit_button}>
                <Button htmlType='submit' type='primary' disabled={isDisabled}>
                    Добавить грузчиков
                </Button>
            </Form.Item>
        </Form>
    );
});
