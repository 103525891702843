import { FC, memo, useState } from 'react';

import { red } from '@ant-design/colors';
import { PhoneTwoTone } from '@ant-design/icons';
import { BASE_URL_SERVER, jsonTypeRequestHeaders } from '@constants';
import { DeliveryRequestCellProps } from '@typings';
import { authFetch } from '@utils/auth/auth';

export const PhoneCall: FC<Pick<DeliveryRequestCellProps, 'rowData'>> = memo(({ rowData }) => {
    const [fetching, setFetching] = useState(false);

    if (fetching) {
        return (
            <td>
                <PhoneTwoTone twoToneColor={red.primary} />
            </td>
        );
    }

    const startCall = async () => {
        setFetching(true);
        try {
            await authFetch(`${BASE_URL_SERVER}bo/delivery/call_to_driver/`, {
                method: 'POST',
                data: { request: rowData.pk },
                headers: jsonTypeRequestHeaders,
            });
        } catch (err) {
            // nothing to do
        }
        setFetching(false);
    };

    return (
        <>
            <PhoneTwoTone onClick={startCall} />
        </>
    );
});
