import { createContext, FC, useCallback, useMemo, useState } from 'react';
import { message } from 'antd';

import { moveRequestItem } from '@api';
import { useRequestConfirm } from '@hooks';
import {
    DeliveryColumn,
    DeliveryMoveRequestItemResponse,
    DeliveryOnAddRequest,
    DeliveryOnChangeRequestData,
    DeliveryRequestsDashboardItemsNames,
    DeliveryState,
    Nullable,
    Response,
    SetState,
} from '@typings';
import { normalizeDeliveryRequest } from '@utils/standartDataToNewBackend';

type FilterStatus = Nullable<DeliveryRequestsDashboardItemsNames>;

interface IDeliveryContext {
    onAddRequest: DeliveryOnAddRequest;
    onChangeRequestData: DeliveryOnChangeRequestData;
    fetchData: (withLoading?: boolean) => void;
    setFilterStatus: (status: FilterStatus) => void;
    toggleLoading: (isLoading: boolean) => void;
    filterStatus: FilterStatus;
    columns: DeliveryColumn[];
}

export const DeliveryContext = createContext<IDeliveryContext>({} as IDeliveryContext);

interface Props {
    setState: SetState<DeliveryState>;
    fetchData: (withLoading?: boolean) => void;
    toggleLoading: (isLoading: boolean) => void;
    columns: DeliveryColumn[];
}

export const DeliveryProvider: FC<Props> = ({
    columns,
    fetchData,
    setState,
    toggleLoading,
    children,
}) => {
    const [filterStatus, setFilterStatus] = useState<FilterStatus>(null);
    const { sendRequestWithConfirm } = useRequestConfirm();

    const onFilterStatusChange = useCallback(
        (status: FilterStatus) => {
            fetchData();
            setFilterStatus(status);
        },
        [fetchData]
    );

    const onChangeRequestData: DeliveryOnChangeRequestData = useCallback((row) => {
        if (Array.isArray(row)) {
            return setState((prevState) => ({
                ...prevState,
                requestsData: row,
            }));
        }

        setState((prevState) => {
            const newData = prevState.requestsData.map((item) => (item.pk === row.pk ? row : item));

            return {
                ...prevState,
                requestsData: newData,
            };
        });
    }, []);

    const onAddRequest: DeliveryOnAddRequest = useCallback(
        (params: { item_pk: number; target_pk: number }) => {
            setState((prevState) => ({
                ...prevState,
                isLoading: true,
            }));

            const initialParams = {
                destination: params.target_pk,
                item: params.item_pk,
            };
            const onError = (error: Error) => {
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                }));

                message.error({ content: error.message });
            };

            const onSuccess = ({ data }: Response<DeliveryMoveRequestItemResponse>) => {
                setState((prevState) => ({
                    ...prevState,
                    isLoading: false,
                }));

                if (!data) {
                    return;
                }

                const { destination_data } = data;

                setState((prev) => {
                    const formattedData = prev.requestsData.map((request) => {
                        if (request?.pk === destination_data[0]?.request?.pk) {
                            return normalizeDeliveryRequest(destination_data[0].request);
                        }

                        return request;
                    });

                    return {
                        ...prev,
                        requestsData: formattedData,
                    };
                });
            };

            sendRequestWithConfirm({
                requestCallback: moveRequestItem,
                initialParams,
                onError,
                onSuccess,
            });
        },
        [fetchData, setState]
    );

    const value = useMemo(
        () => ({
            columns,
            fetchData,
            onAddRequest,
            onChangeRequestData,
            filterStatus,
            setFilterStatus: onFilterStatusChange,
            toggleLoading,
        }),
        [columns, fetchData, onChangeRequestData, filterStatus, toggleLoading]
    );

    return <DeliveryContext.Provider value={value}>{children}</DeliveryContext.Provider>;
};
