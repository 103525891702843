import { FC, memo } from 'react';
import moment from 'moment';

import { DeliveryCellProps, DeliveryRequestOrExpandedValues } from '@typings';

import { TextCell } from '.';

interface Props extends Omit<DeliveryCellProps, 'rowData'> {
    cellValue: DeliveryRequestOrExpandedValues;
}
export const Time: FC<Props> = memo(({ onCommit, isEditing, cellValue }) => {
    const formattedData = cellValue && moment(cellValue.toString()).format('HH:mm');

    return <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={formattedData} />;
});
