import { FC, memo, useContext } from 'react';

import { green, grey, red } from '@ant-design/colors';
import { CheckSquareTwoTone, ClockCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { useUpdateRequest } from '@hooks';
import { DeliveryContext } from '@providers/delivery';
import {
    DeliveryRequest,
    DeliveryRequestMutationDefaultResponse,
    Nullable,
    Response,
    SetState,
} from '@typings';
import { getRequestPk } from '@utils/delivery';
import { normalizeDeliveryRequest } from '@utils/standartDataToNewBackend';

interface Props {
    rowData: DeliveryRequest;
    setIsFetching: SetState<boolean>;
}

const getIconByCustomerResolution = (customerResolution: Nullable<string>) => {
    switch (customerResolution) {
        case 'confirmed': {
            return { Icon: CheckSquareTwoTone, color: green.primary };
        }
        case 'suspicious': {
            return { Icon: WarningTwoTone, color: red.primary };
        }
        default:
            return { Icon: ClockCircleTwoTone, color: grey[1] };
    }
};

export const ConfirmationRequestController: FC<Props> = memo(({ rowData, setIsFetching }) => {
    const doUpdateRequest = useUpdateRequest();
    const { onChangeRequestData } = useContext(DeliveryContext);
    const { Icon, color } = getIconByCustomerResolution(rowData.customer_resolution);

    const handleClick = async () => {
        const value = rowData.customer_resolution !== 'confirmed';

        setIsFetching(true);
        const onSuccess = ({ data }: Response<DeliveryRequestMutationDefaultResponse>) => {
            onChangeRequestData(normalizeDeliveryRequest(data.data.request));
            setIsFetching(false);
        };

        const onError = () => {
            setIsFetching(false);
        };

        doUpdateRequest({
            value,
            field: 'customer_confirmation',
            pk: getRequestPk(rowData),
            items: rowData.items,
            onError,
            onSuccess,
        });
    };

    return (
        <Icon
            twoToneColor={color}
            style={{ paddingLeft: 4, width: '18px' }}
            onClick={handleClick}
        />
    );
});
