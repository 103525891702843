import { FC, useContext } from 'react';
import { Typography } from 'antd';

import { CloseCircleOutlined } from '@ant-design/icons';
import { updateWorkerOnlineStatus } from '@api';
import { WorkersReportTableContext } from '@providers/workersReportTable';
import { Nullable, WorkersReportWorkerId } from '@typings';

import styles from './cells.module.scss';
interface Props {
    isOnlineTomorrow: Nullable<boolean>;
    workerId: WorkersReportWorkerId;
}

export const OnlineTomorrowStatus: FC<Props> = ({ isOnlineTomorrow, workerId }) => {
    const { updateWorker } = useContext(WorkersReportTableContext);

    const onWorkerCancelOnlineStatus = () => {
        updateWorkerOnlineStatus({
            params: {
                worker: workerId,
                is_online_tomorrow: false,
            },
            onSuccess({ data }) {
                updateWorker(workerId, { isOnlineTomorrow: data.is_online_tomorrow });
            },
        });
    };

    switch (isOnlineTomorrow) {
        case null:
            return <div>Не записался</div>;
        case true:
            return (
                <div>
                    <CloseCircleOutlined
                        className={styles.close_circle}
                        onClick={onWorkerCancelOnlineStatus}
                        title='Отменить'
                    />

                    <Typography.Text>Да</Typography.Text>
                </div>
            );
        case false:
            return <div>Нет</div>;
    }
};
