import { ArrayElement, DeliveryMapRequest, DeliveryMapRequestResponse } from '@typings';

type List = {
    pk: number;
}[];

type FormatList = <T extends List>(list: T) => Map<string, ArrayElement<T>>;

const formatList: FormatList = (list) => {
    return new Map(list.map((data) => [data.pk.toString(), data]));
};

export const mapperRequestOnMap = ({
    workers,
    delivery_requests,
    bounding_box,
    city_borders,
    timestamp,
}: DeliveryMapRequestResponse): DeliveryMapRequest => {
    return {
        timestamp,
        workers: formatList(workers),
        deliveryRequests: formatList(delivery_requests),
        boundingBox: bounding_box,
        cityBorders: city_borders,
    };
};
