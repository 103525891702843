import { FC } from 'react';
import { Button, Space } from 'antd';

import { CameraTwoTone, StopOutlined } from '@ant-design/icons';
import { removeItemWorker } from '@api';
import { useRequestConfirm } from '@hooks';
import { showError } from '@utils/show_error';

import styles from '../../DeliveryWorkersOnAddressTable.module.scss';
interface Props {
    requestPk: number;
    addressId: number;
    workerId: number;
}

export const AdressActions: FC<Props> = ({ addressId, requestPk, workerId }) => {
    const { sendRequestWithConfirm } = useRequestConfirm();

    const setReason = (reason: 'cancelled' | 'failure') => {
        if (addressId) {
            sendRequestWithConfirm({
                requestCallback: removeItemWorker,
                initialParams: {
                    request: requestPk,
                    reason,
                    item: addressId,
                    worker: workerId,
                },
                onError: (error) => {
                    showError(error.message);
                },
            });
        }
    };

    return (
        <Space size='small' className={styles.worker_action_container}>
            <Button
                className={styles.worker_action_button}
                size='small'
                onClick={() => setReason('failure')}
            >
                <StopOutlined />
                Срыв
            </Button>

            <a
                href={`/rf/delivery/photos_dashboard/?request=${requestPk}&worker=${workerId}`}
                target='_blank'
                rel='noreferrer'
            >
                <Button className={styles.worker_action_button} size='small'>
                    <CameraTwoTone />
                </Button>
            </a>
        </Space>
    );
};
