import { FC, memo } from 'react';

import { DeliveryCellProps } from '@typings';
import { itemTextContent } from '@utils/delivery';

import { TextCell } from '.';

export const TimeInterval: FC<DeliveryCellProps> = memo(({ onCommit, isEditing, rowData }) => {
    const formattedTime =
        rowData.items?.length > 1
            ? ''
            : itemTextContent(rowData.items?.[0], 'time_interval', '-')?.toString();

    return <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={formattedTime} />;
});
