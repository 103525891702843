import { FC } from 'react';
import { Space } from 'antd';

import { AddressCheckbox } from './AddressCheckbox';
import { AdressActions } from './AdressActions';
import { FirstIndicator } from './FirstIndicator';

import styles from '../../DeliveryWorkersOnAddressTable.module.scss';

interface Props {
    requestPk: number;
    isFirst: boolean;
    isOnAddress: boolean;
    workerId: number;
    addressId: number;
    isNotVirtual: boolean;
    isDuplicateFirst: boolean;
}

export const WorkerAction: FC<Props> = ({
    workerId,
    addressId,
    isNotVirtual,
    requestPk,
    isOnAddress,
    isFirst,
    isDuplicateFirst,
}) => {
    if (!isNotVirtual && !isOnAddress) {
        return null;
    }

    return (
        <Space direction='vertical' className={styles.worker_action}>
            <Space align='start'>
                <AddressCheckbox
                    addressId={addressId}
                    onAddress={isOnAddress}
                    requestPk={requestPk}
                    workerId={workerId}
                    isNotVirtual={isNotVirtual}
                />

                <FirstIndicator
                    isOnAddress={isOnAddress}
                    isDuplicateFirst={isDuplicateFirst}
                    isFirst={isFirst}
                />
            </Space>

            {isNotVirtual && (
                <AdressActions requestPk={requestPk} addressId={addressId} workerId={workerId} />
            )}
        </Space>
    );
};
