import { FC } from 'react';

import styles from './cells.module.scss';

interface Props {
    balance: string;
}
export const Balance: FC<Props> = ({ balance }) => {
    return (
        <div title={balance} className={styles.balance}>
            {balance}
        </div>
    );
};
