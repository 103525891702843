import { API_URI } from '@constants';
import {
    ExpensesFormCostTypes,
    ExpensesFormFieldNames,
    ExpensesFormRemoteSelectItemConfig,
} from '@typings';

export const expensesFieldNamesConfig: ExpensesFormFieldNames[] = [
    'provider',
    'cost_type_group',
    'administration_cost_type',
    'customer',
    'industrial_cost_type',
    'material_type',
];

export const expensesCostItemsConfig = [
    {
        value: 'administration',
        label: 'Офисные расходы',
        isAdministrationItem: true,
    },
    {
        value: 'industrial',
        label: 'Производственные расходы',
    },
    {
        value: 'material',
        label: 'Материалы',
    },
];

const customerRemoteSelectConfig = {
    key: 'customer',
    label: 'Клиент',
    url: API_URI.AUTOCOMPLETE_COMMON_CUSTOMER,
};

const providerRemoteSelectConfig = {
    key: 'provider',
    label: 'Поставщик',
    url: API_URI.AUTOCOMPLETE_PROVIDER,
};

export const expensesRemoteSelectItemsConfig: Record<
    ExpensesFormCostTypes,
    ExpensesFormRemoteSelectItemConfig[]
> = {
    administration: [
        providerRemoteSelectConfig,
        {
            key: 'administration_cost_type',
            label: 'Тип расхода',
            url: API_URI.AUTOCOMPLETE_ADMINISTRATION_COST_TYPE,
        },
    ],

    industrial: [
        providerRemoteSelectConfig,
        customerRemoteSelectConfig,
        {
            key: 'industrial_cost_type',
            label: 'Тип расхода',
            url: API_URI.AUTOCOMPLETE_INDUSTRIAL_CUST_TYPE,
        },
    ],

    material: [
        providerRemoteSelectConfig,
        customerRemoteSelectConfig,
        {
            key: 'material_type',
            label: 'Материал',
            url: API_URI.AUTOCOMPLETE_MATERIAL,
        },
    ],
};
