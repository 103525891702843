import { FC } from 'react';
import { Row } from 'antd';
import clsx from 'clsx';

import { DashboardAdditionalPhoto, Nullable, PhotoListState, SetState } from '@typings';

import { AdditionalPhotoElement } from './AdditionalPhotoElement';
import { ButtonAddPhoto } from './ButtonAddPhoto';

import styles from '../photos.module.scss';

interface Props {
    request: number | undefined;
    photoList: Nullable<DashboardAdditionalPhoto[]>;
    setPhotos: SetState<PhotoListState>;
}

export const AdditionalPhotos: FC<Props> = ({ request, photoList, setPhotos }) => {
    const photoListEl = photoList?.map(renderPhotoList);

    const rowStyles = clsx({
        [styles.row]: !!photoList?.length,
    });

    return (
        <div className={rowStyles}>
            <Row gutter={[16, 16]} className={styles.container}>
                {photoListEl}
            </Row>

            <ButtonAddPhoto request={request} setPhotos={setPhotos} />
        </div>
    );
};

const renderPhotoList = ({ url, id }: DashboardAdditionalPhoto) => {
    return <AdditionalPhotoElement url={url} key={id} />;
};
