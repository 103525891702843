import { createContext, FC, useCallback, useMemo } from 'react';

import {
    WorkersReportGetNextWorkerId,
    WorkersReportUpdateWorker,
    WorkersReportWorker,
    WorkersReportWorkerId,
} from '@typings';

interface IWorkersReportTableContext {
    updateWorker: WorkersReportUpdateWorker;
    getNextWorkerId?: WorkersReportGetNextWorkerId;
}

export const WorkersReportTableContext = createContext<IWorkersReportTableContext>(
    {} as IWorkersReportTableContext
);

interface Props {
    updateWorker: WorkersReportUpdateWorker;
    sortedWorkers?: WorkersReportWorker[];
}

export const WorkersReportTableProvider: FC<Props> = ({
    updateWorker,
    sortedWorkers,
    children,
}) => {
    const getNextWorkerId = useCallback(
        (currentWorkerId: WorkersReportWorkerId) => {
            const currentIndex = sortedWorkers?.findIndex(
                ({ workerId }) => workerId === currentWorkerId
            )!!;

            const nextWorkerId = sortedWorkers!![currentIndex + 1]?.workerId;

            return nextWorkerId || null;
        },
        [sortedWorkers]
    );

    const value = useMemo(() => {
        if (sortedWorkers) {
            return { updateWorker, getNextWorkerId };
        }

        return { updateWorker };
    }, [updateWorker, sortedWorkers]);

    return (
        <WorkersReportTableContext.Provider value={value}>
            {children}
        </WorkersReportTableContext.Provider>
    );
};
