import { FC } from 'react';
import { Modal } from 'antd';

import { ExpensesForm } from '@components/forms';
import { closeModal } from '@store/modal';
import { ExpensesExpenseDetail, ExpensesOnFinishData } from '@typings';

interface Props {
    isOpen: boolean;
    initial: ExpensesExpenseDetail;
    onSubmit(values: ExpensesOnFinishData): void;
    isLoading: boolean;
}

export const ExpensesChangeExpense: FC<Props> = ({ isOpen, initial, isLoading, onSubmit }) => {
    const onModalClose = () => {
        closeModal();
    };

    return (
        <Modal
            visible={isOpen}
            title='Изменить расход'
            width='70%'
            destroyOnClose
            onCancel={onModalClose}
            okButtonProps={{ form: 'expense_modal_form', htmlType: 'submit' }}
            confirmLoading={isLoading}
        >
            {initial && (
                <ExpensesForm
                    showAdministrationExpenses={false}
                    id='expense_modal_form'
                    layout='horizontal'
                    initial={initial}
                    showLabels
                    onSubmit={onSubmit}
                />
            )}
        </Modal>
    );
};
