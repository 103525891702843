import { FC, memo } from 'react';

import { TABLE_NO_TOOLTIP_CELLS } from '@constants';
import { TableCellColumn } from '@typings';

interface Props {
    cellKey: TableCellColumn['key'];
    isFetching: boolean;
}

const isNotVisibleTooltip = (key: TableCellColumn['key']) => {
    if (TABLE_NO_TOOLTIP_CELLS.includes(key)) {
        return true;
    }

    return false;
};

export const TooltipCell: FC<Props> = memo(({ children, isFetching, cellKey }) => {
    if (isFetching || isNotVisibleTooltip(cellKey)) {
        return null;
    }

    return (
        <div className='tooltip_container'>
            <span className='tooltip_text'>{children}</span>
        </div>
    );
});
