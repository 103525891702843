import { useMemo } from 'react';
import _ from 'lodash';

import { RemoteSelectMapOptions, RemoteSelectValue } from '@typings';

const getNotExistOptions = (options: RemoteSelectMapOptions, selected: string[]): string[] => {
    const notExistOptions = selected.reduce((notExistOptions: string[], el): string[] => {
        const isNotExistOption = !options.get(el);

        if (isNotExistOption) {
            notExistOptions.push(el);
        }

        return notExistOptions;
    }, []);

    return notExistOptions;
};

interface Params {
    options: RemoteSelectMapOptions;
    getOptionById(id: string): void;
}

export const useRemoteAddOptions = ({ options, getOptionById }: Params) => {
    const getNotExistOptionsMemoized = useMemo(() => {
        let prevSelectValue: string | string[] | undefined;

        return (
            options: RemoteSelectMapOptions,
            selectValue: string | string[] | undefined
        ): string[] => {
            if (_.isEqual(selectValue, prevSelectValue)) {
                return [];
            }
            prevSelectValue = selectValue;

            if (!selectValue) {
                return [];
            }

            if (Array.isArray(selectValue)) {
                return getNotExistOptions(options, selectValue);
            }

            return getNotExistOptions(options, [selectValue]);
        };
    }, []);

    const onRemoteSelectValuesAdd = (value?: RemoteSelectValue) => {
        const formattedSelectValue = value?.toString();
        const notExistValues = getNotExistOptionsMemoized(options, formattedSelectValue);

        for (const values of notExistValues) {
            getOptionById(values);
        }

        return { value: formattedSelectValue };
    };

    return { onRemoteSelectValuesAdd };
};
