import React, { FC, Key, useLayoutEffect, useRef } from 'react';
import moment from 'moment';

import { Comment } from './Comment';
import { NoComments } from './NoComments';

import styles from './comment.module.scss';

interface Props {
    dataSource: {
        key: Key;
        author: string;
        content: string;
        datetime: moment.Moment;
    }[];
}

export const CommentList: FC<Props> = ({ dataSource }) => {
    const commentList = useRef<HTMLDivElement | null>(null);

    useLayoutEffect(() => {
        commentList.current?.scrollTo({ top: commentList.current.scrollHeight });
    });

    if (!dataSource?.length) {
        return <NoComments />;
    }

    const commentsEl = dataSource.map(({ author, content, datetime, key }, index) => {
        return (
            <Comment
                key={`${key}-${index}`}
                author={author}
                content={content}
                datetime={datetime}
            />
        );
    });

    return (
        <div ref={commentList} className={styles.comments}>
            <div className={styles.comments_data}>{commentsEl}</div>
        </div>
    );
};
