import { useQuery } from './useQuery';

export const usePhotoDashboardSearchParams = () => {
    const searchParams = useQuery();

    const getParam = (param: string) => {
        const paramData = searchParams.get(param);

        return paramData ? parseInt(paramData) : undefined;
    };

    return {
        request: getParam('request'),
        worker_pk: getParam('worker'),
    };
};
