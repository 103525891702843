import { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Row, Space, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import clsx from 'clsx';
import moment from 'moment';

import { fetchReconciliationsCalendar } from '@api';
import CustomDatePickerRange from '@components/CustomDatePickerRange';
import { DATE_FORMAT } from '@constants';
import { showError } from '@utils/show_error';

import { CellRender, statusToColor } from './cell';
import { FirstCellRender } from './firstcell';
import { HeaderRender } from './header';
import { statusToText } from './tooltip';

import './table.scss';

interface TableValues {
    rowTitle: {
        rowCustomer: string;
        rowLocation: string;
    };

    cells: Record<
        string,
        {
            date?: string;
            id?: number;
            location?: number;
            requests_paid?: number;
            requests_total?: number;
            status?: string;
            worked_hours?: string;
            worker_count?: number;
        }
    >;
}

function getColumns({
    columns,
    actions,
}: {
    columns: string[];
    actions?: {
        confirm_unconfirm: boolean;
    };
}) {
    return [
        {
            title: '   ',
            dataIndex: 'rowTitle',
            key: 'rowTitle',
            width: '150px',
            fixed: 'left' as const,
            render: ({ rowCustomer, rowLocation }: TableValues['rowTitle']) => {
                return <FirstCellRender rowCustomer={rowCustomer} rowLocation={rowLocation} />;
            },
            className: 'calendar-table-column',
        },
        ...columns.map((column: string, index) => ({
            title: <HeaderRender columnDate={column} />,
            dataIndex: 'cells',
            key: `${column}-${index}`,
            width: '70px',
            render: (cells: TableValues['cells']) => {
                const {
                    date,
                    id,
                    location,
                    requests_paid,
                    requests_total,
                    status,
                    worker_count,
                    worked_hours,
                } = cells?.[column] || {};

                return (
                    <CellRender
                        date={date}
                        id={id}
                        location={location}
                        requests_paid={requests_paid}
                        requests_total={requests_total}
                        status={status}
                        worked_hours={worked_hours}
                        worker_count={worker_count}
                        actions={actions}
                        columnDate={column}
                    />
                );
            },
            className: 'calendar-table-column',
        })),
    ];
}

const finishtDate = moment(new Date(Date.now())).format(DATE_FORMAT);

const getStartDate = () => {
    const date = new Date(Date.now());
    date.setDate(date.getDate() - 15);

    return moment(date).format(DATE_FORMAT);
};

const Calendar = () => {
    const [loading, setLoading] = useState(false);
    const [firstDay, setFirstDay] = useState(getStartDate());
    const [lastDay, setLastDay] = useState(finishtDate);
    const [tableColumns, setTableColumns] = useState<ColumnsType<TableValues>>([]);
    const [tableData, setTableData] = useState<TableValues[]>([]);

    const onChange = useCallback(
        ({ first_day, last_day }) => {
            setFirstDay(first_day);
            setLastDay(last_day);
        },
        [setFirstDay, setLastDay]
    );

    const fetchData = () => {
        setLoading(true);
        fetchReconciliationsCalendar({
            params: {
                first_day: firstDay,
                last_day: lastDay,
            },
            onSuccess({ data }) {
                const { actions, cells, columns, rows } = data;
                setTableColumns(getColumns({ columns, actions }));

                const cellData = rows.map((row): TableValues => {
                    const cellData = cells[row?.id] || {};

                    return {
                        cells: {
                            ...cellData,
                        },
                        rowTitle: {
                            rowCustomer: row?.customer,
                            rowLocation: row?.location,
                        },
                    };
                });

                setTableData(cellData);
                setLoading(false);
            },
            onError(error) {
                showError(error.message);
                setLoading(false);
            },
        });
    };

    useEffect(() => {
        fetchData();
    }, []);

    const statuses = ['new', 'ready_for_notification', 'notification_sent', 'confirmed'].map(
        (status: string) => (
            <span
                key={status}
                style={{
                    background: statusToColor(status),
                    border: '1px solid black',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                }}
            >
                {statusToText(status)}
            </span>
        )
    );

    return (
        <Spin spinning={loading}>
            <div style={{ marginTop: 27 }}>
                <Row justify='space-between'>
                    <Space>
                        <CustomDatePickerRange.Form
                            name='datepicker'
                            first_day={firstDay}
                            last_day={lastDay}
                            placeholder={['С', 'По']}
                            onChange={onChange}
                        />
                        <Button type='primary' onClick={fetchData}>
                            Показать
                        </Button>
                    </Space>
                    <Space>{statuses}</Space>
                </Row>
                <Divider style={{ height: '100%' }} />
                <Table
                    columns={tableColumns}
                    dataSource={tableData}
                    tableLayout='fixed'
                    size='small'
                    pagination={{ pageSize: 50 }}
                    scroll={{ x: '90%' }}
                    rowClassName={(_, index) => {
                        const isActiveLight = index % 2 === 0;

                        return clsx({
                            'calendar-table-row-light': isActiveLight,
                            'calendar-table-row-dark': !isActiveLight,
                        });
                    }}
                />
            </div>
        </Spin>
    );
};

export default Calendar;
