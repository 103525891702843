import { createEvent, createStore } from 'effector';

import { DeliveryRequestDetail } from '@typings';

// Store of item request
export const $itemRequest = createStore<number | null>(null);
export const setItemRequestFx = createEvent<number>();
$itemRequest.on(setItemRequestFx, (_, item) => item);

// Store of item request
export const $requestDetail = createStore<DeliveryRequestDetail>({
    request: null,
    workers: [],
    item_workers: {},
});
export const setRequestDetailFx = createEvent<DeliveryRequestDetail>();
$requestDetail.on(setRequestDetailFx, (_, item) => item);

// Store of request
export const $requestId = createStore<number | null>(null);
export const setRequestIdFx = createEvent<number>();
$requestId.on(setRequestIdFx, (_, request) => request);

// Store for re-render table in modal
export const $reloadTable = createStore<number>(0);
export const reloadTableFx = createEvent();
$reloadTable.on(reloadTableFx, (reloadCount, _) => reloadCount + 1);
