import { createEffect, createEvent, createStore } from 'effector';

import { ModalState, RenderModal } from '@typings';
import { wait } from '@utils/wait';

const initialState: ModalState = {
    isOpen: false,
    renderModal: null,
};

const animationDuration = 300;

export const openModal = createEvent<RenderModal>('openModal');

export const closeModal = createEffect<void, Promise<unknown>>('closeModal').use(() =>
    wait(animationDuration)
);

export const $modalState = createStore(initialState, {
    name: 'modal',
})
    .on(openModal, (_, renderModal) => {
        return { isOpen: true, renderModal };
    })
    .on(closeModal, (state) => {
        return { ...state, isOpen: false };
    })
    .on(closeModal.done, () => {
        return { isOpen: false, renderModal: null };
    });
