import { Form, Select } from 'antd';

import { ExpensesExpenseDetail, ExpensesFormCostTypes, Nullable, SetState } from '@typings';

import { expensesCostItemsConfig } from '../config';

interface Props {
    setCostType: SetState<Nullable<ExpensesFormCostTypes>>;
    showAdminExpenses: boolean;
    initial?: ExpensesExpenseDetail;
    showLabels: boolean;
}

export const SelectCost: React.FC<Props> = ({
    setCostType,
    showLabels,
    initial,
    showAdminExpenses,
}) => {
    const optionsEl = expensesCostItemsConfig.map(({ label, value, isAdministrationItem }) => {
        const isAvailableAdministrationExpenses =
            (isAdministrationItem && showAdminExpenses) || initial;

        const selectOption = (
            <Select.Option key={value} value={value}>
                {label}
            </Select.Option>
        );

        if (isAvailableAdministrationExpenses) {
            return selectOption;
        } else if (!isAdministrationItem) {
            return selectOption;
        }

        return null;
    });

    const onChange = (value: ExpensesFormCostTypes) => {
        setCostType(value);
    };

    const label = showLabels ? 'Группа расходов' : undefined;

    return (
        <Form.Item
            key='cost_type_group'
            name='cost_type_group'
            style={{ marginBottom: 0 }}
            label={label}
            rules={[{ required: true, message: 'Это поле необходимо!' }]}
        >
            <Select placeholder='Группа расходов' style={{ width: '200px' }} onChange={onChange}>
                {optionsEl}
            </Select>
        </Form.Item>
    );
};
