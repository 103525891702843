import { FC } from 'react';

import { WorkersReportWorkerStatus } from '@typings';

import styles from './cells.module.scss';

interface Props {
    status: WorkersReportWorkerStatus;
}

export const Status: FC<Props> = ({ status }) => {
    const { bgColor, name } = status;
    const style = {
        background: bgColor,
    };

    return (
        <div title={name} style={style} className={styles.custom_color_cell}>
            {name}
        </div>
    );
};
