import { message } from 'antd';

import {
    DeliveryColumn,
    DeliveryRequestItemKeys,
    DeliveryRequestOrExpanded,
    DeliveryRequestOrExpandedRequestOrItemsValues,
} from '@typings';

import {
    copyToClipboard,
    isMergedRequest,
    isUnwrappedRequest,
    textContent,
    textContentInner,
} from '..';

const ALLOWED_EMPTY_CELLS = ['has_elevator'];

const DATA_LIST_REQUEST = [
    'date',
    'customer',
    'time_interval',
    'address',
    'driver_name',
    'driver_phones',
    'mass',
    'shipment_type',
    'volume',
    'place_count',
    'pay_estimate',
];

const DATA_LIST_ITEM_REQUEST = [
    'date',
    'customer',
    'time_interval',
    'address',
    'driver_name',
    'mass',
    'shipment_type',
    'volume',
    'place_count',
    'carrying_distance',
    'floor',
    'has_elevator',
    'pay_estimate',
    'driver_phones',
];

const formatCellContent = (
    value: DeliveryRequestOrExpandedRequestOrItemsValues,
    key: DeliveryRequestItemKeys
) => {
    switch (key) {
        case 'has_elevator':
            if (value) {
                return 'Да';
            }

            return value === '' ? '-' : 'Нет';

        default:
            return value;
    }
};

const isValidValue = (
    value: DeliveryRequestOrExpandedRequestOrItemsValues,
    key: DeliveryRequestItemKeys
) => {
    if (value) {
        return true;
    }

    if (!value && ALLOWED_EMPTY_CELLS.includes(key)) {
        return true;
    }

    return false;
};

const getCopyColumns = (columns: DeliveryColumn[], rowData: DeliveryRequestOrExpanded) => {
    return columns.filter((item) => {
        if (isUnwrappedRequest(rowData)) {
            return DATA_LIST_ITEM_REQUEST.includes(item.key);
        }

        return DATA_LIST_REQUEST.includes(item.key);
    });
};

export const copyTableRowData = (columns: DeliveryColumn[], rowData: DeliveryRequestOrExpanded) => {
    const isNotExistAddress =
        (isUnwrappedRequest(rowData) || isMergedRequest(rowData)) &&
        !textContent(rowData, 'workers_required');

    if (isNotExistAddress) {
        return message.error({ content: 'Адрес отменен' });
    }

    const textCopy = getCopyColumns(columns, rowData).reduce((acc: string, item) => {
        const columnContent = textContentInner(rowData, item.key as DeliveryRequestItemKeys);

        if (isValidValue(columnContent, item.key as DeliveryRequestItemKeys)) {
            const formattedContent = formatCellContent(
                columnContent,
                item.key as DeliveryRequestItemKeys
            );

            return (acc += `${item.title}: ${formattedContent}\n`);
        }

        return acc;
    }, '');

    copyToClipboard(textCopy);
};
