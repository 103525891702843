import React, { useEffect, useMemo, useState } from 'react';

import { DeliveryTable, SearchForm } from '@components';
import { useLocalStorage } from '@hooks';
import { DeliveryProvider } from '@providers';
import { DeliveryColumn } from '@typings';

import RequestsDashboard from '../../components/RequestsDashboard';

import { baseColumns } from './data';
import { useRequests } from './hooks';

import './Delivery.scss';
import styles from './Delivery.module.scss';

const Delivery = () => {
    const {
        getRequests,
        onFiltersChange,
        toggleLoading,
        requestsData,
        requestsFilters,
        setRequestsData,
        isLoading,
    } = useRequests();
    const [initialColumns_, setInitialColumns] = useLocalStorage(
        'request_columns_filter',
        baseColumns
    );

    const initialColumns = useMemo(() => {
        return baseColumns.map((column) => {
            const savedColumn = initialColumns_.find((column_) => column_.key === column.key);

            return {
                ...column,
                checked: savedColumn ? savedColumn.checked : column.checked,
            };
        });
    }, [initialColumns_]);

    const [columns, setColumns] = useState<DeliveryColumn[]>(initialColumns);

    useEffect(() => {
        setInitialColumns(columns);
    }, [columns]);

    return (
        <div className={styles.delivery_page}>
            <DeliveryProvider
                fetchData={getRequests}
                setState={setRequestsData}
                toggleLoading={toggleLoading}
                columns={columns}
            >
                <RequestsDashboard data={requestsData} />

                <SearchForm
                    filterState={requestsFilters}
                    columns={columns}
                    setColumns={setColumns}
                    fetchData={getRequests}
                    changeFormFilterHandler={onFiltersChange}
                    toggleLoading={toggleLoading}
                />
                <DeliveryTable data={requestsData} loading={isLoading} />
            </DeliveryProvider>
        </div>
    );
};

export default Delivery;
