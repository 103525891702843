import { FC } from 'react';
import { Form, Input } from 'antd';

interface Props {
    showLabels: boolean;
}

export const Comment: FC<Props> = ({ showLabels }) => (
    <Form.Item
        key='comment'
        name='comment'
        style={{ marginBottom: 0 }}
        label={showLabels ? 'Комментарий' : undefined}
        rules={[{ required: true, message: 'Это поле необходимо!' }]}
    >
        <Input.TextArea placeholder='Комментарий' style={{ width: '320px' }} />
    </Form.Item>
);
