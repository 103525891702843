import { ChangeEvent, FC, useContext, useState } from 'react';
import { Input, Tag as UITag, Tooltip } from 'antd';
import clsx from 'clsx';

import { PlusOutlined } from '@ant-design/icons';
import { setWorkerTag } from '@api';
import { WorkersReportTableContext } from '@providers/workersReportTable';
import { WorkersReportWorkerId } from '@typings';
import { showError } from '@utils/show_error';

import styles from './cells.module.scss';

interface Props {
    workerId: WorkersReportWorkerId;
    tag: string;
}

export const Tag: FC<Props> = ({ tag, workerId }) => {
    const [isEdit, setIsEdit] = useState(false);
    const [currentTagValue, setCurrentTagValue] = useState('');
    const { updateWorker } = useContext(WorkersReportTableContext);

    const onEscPress = ({ key }: KeyboardEvent) => {
        if (key === 'Escape') {
            setIsEdit(false);
        }
    };

    const onBlur = () => {
        document.removeEventListener('keydown', onEscPress);
        setCurrentTagValue('');
        setIsEdit(false);
    };

    const onTagSubmit = () => {
        document.removeEventListener('keydown', onEscPress);

        setWorkerTag({
            params: {
                tag: currentTagValue,
                worker: workerId,
            },

            onSuccess({ data }) {
                setIsEdit(false);
                updateWorker(workerId, { tag: data.tag });
            },
            onError(error) {
                setIsEdit(false);
                showError(error.message);
            },
        });
    };

    const onTagChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setCurrentTagValue(target.value);
    };

    if (isEdit) {
        return (
            <Input
                onChange={onTagChange}
                autoFocus
                type='text'
                size='small'
                style={{ width: 78 }}
                value={currentTagValue}
                onBlur={onBlur}
                onPressEnter={onTagSubmit}
            />
        );
    }

    const onTagStartEdit = () => {
        document.addEventListener('keydown', onEscPress);

        setIsEdit(true);
    };

    const main = clsx(styles.tag, {
        [styles.tag_new]: !tag,
    });

    if (tag) {
        return (
            <Tooltip title={tag}>
                <UITag onClick={onTagStartEdit} className={main}>
                    {tag}
                </UITag>
            </Tooltip>
        );
    }

    return (
        <UITag onClick={onTagStartEdit} className={main}>
            <PlusOutlined />
            Добавить
        </UITag>
    );
};
