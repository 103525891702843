import { FC, useContext } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { updateWorkerRating } from '@api';
import { WorkersReportTableContext } from '@providers/workersReportTable';
import { WorkersReportWorkerId } from '@typings';
import { mapperWorkersReportWorkerUpdateRatingToDetail } from '@utils/mapperWorkersReport';

import { Select } from './Select';

import styles from './cells.module.scss';
type BenevolentStatuses = 0 | 1;

const benevolentStatuses: Record<BenevolentStatuses, boolean> = {
    1: true,
    0: false,
};
interface Props {
    isBenevolent: boolean;
    workerId: WorkersReportWorkerId;
    size?: SizeType;
}

const options = [
    {
        value: 1,
        label: 'Да',
    },
    {
        value: 0,
        label: 'Нет',
    },
];

export const BenevolentStatus: FC<Props> = ({ isBenevolent, workerId, size = 'small' }) => {
    const { updateWorker } = useContext(WorkersReportTableContext);

    const onBenevolentChange = (benevolentValue: string) => {
        updateWorkerRating({
            params: {
                worker: workerId,
                is_benevolent: benevolentStatuses[Number(benevolentValue) as BenevolentStatuses],
            },
            onSuccess({ data }) {
                updateWorker(workerId, mapperWorkersReportWorkerUpdateRatingToDetail(data));
            },
        });
    };

    return (
        <Select
            name='benevolent'
            size={size}
            text={isBenevolent ? 'Да' : 'Нет'}
            className={styles.select}
            options={options}
            onChange={onBenevolentChange}
        />
    );
};
