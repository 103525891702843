import { FC, memo, useState } from 'react';
import { Input } from 'antd';

import { DeliveryCellProps, DeliveryRequestOrExpandedValues } from '@typings';

interface Props extends Omit<DeliveryCellProps, 'rowData'> {
    cellValue: DeliveryRequestOrExpandedValues;
}
const getFormattedText = (text: string) => {
    // Todo: take real value into account, not this magic constant ('-')
    return text === '-' ? '' : text;
};

export const Text: FC<Props> = memo(({ isEditing, onCommit, cellValue }) => {
    const [inputText, setInputText] = useState(getFormattedText(cellValue as string));

    const onFieldUpdate = () => {
        onCommit?.(getFormattedText(inputText));
    };

    const onChangeText = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(target.value);
    };

    const isVisibleInput = isEditing && cellValue !== undefined && cellValue !== null;

    if (isVisibleInput) {
        return (
            <Input
                size='small'
                autoFocus={true}
                defaultValue={inputText}
                onChange={onChangeText}
                onPressEnter={onFieldUpdate}
                onBlur={onFieldUpdate}
            />
        );
    }

    return <>{cellValue || null}</>;
});
