import { FC } from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import moment from 'moment';

import { fetchWorkerMapData } from '@api';
import { API_URI, DATE_FORMAT } from '@constants';
import {
    DashboardPhotoIsSuspicious,
    DashboardPhotoItemType,
    DashboardPhotoListItem,
    DeliveryWorkerMapDataResponse,
    Response,
} from '@typings';
import { openNewWindowWithPostRequest } from '@utils/openNewWindowWithPostRequest';

import { MapIcon } from './MapIcon';

import styles from './mapPhotoElement.module.scss';

interface Props extends Pick<DashboardPhotoListItem, 'request'> {
    workerId: number;
    isSuspicious: DashboardPhotoIsSuspicious;
    type: DashboardPhotoItemType;
    viewType: 'icon' | 'button';
}

export const MapPhotoElement: FC<Props> = ({ isSuspicious, request, workerId, type, viewType }) => {
    const isNotVisible = type !== 'start';

    const onMapClick = () => {
        const onSuccess = ({ data }: Response<DeliveryWorkerMapDataResponse>) => {
            openNewWindowWithPostRequest<DeliveryWorkerMapDataResponse>(API_URI.MAP_PAGE, data);
        };

        fetchWorkerMapData({
            params: {
                worker: workerId,
                date: moment(request.date).format(DATE_FORMAT),
            },
            onSuccess,
        });
    };

    if (isNotVisible) {
        return null;
    }

    const mapStyles = clsx(styles.map, {
        [styles.map_warning]: isSuspicious,
        [styles.map_ok]: !isSuspicious,
    });

    if (viewType === 'icon') {
        return <MapIcon onClick={onMapClick} className={mapStyles} />;
    }

    return (
        <Button onClick={onMapClick} type='primary'>
            Показать карту
        </Button>
    );
};
