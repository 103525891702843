import { FC } from 'react';

import { Nullable } from '@typings';

interface Props {
    url: string;
    text: Nullable<string>;
}

export const Link: FC<Props> = ({ url, text }) => {
    return (
        <a href={url} target='_blank' rel='noreferrer' title={text || '-'}>
            {text || '-'}
        </a>
    );
};
