import { FC, memo } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { DeliveryCellProps } from '@typings';

interface Props extends Pick<DeliveryCellProps, 'onCommit'> {
    is_private: boolean;
    isFetching: boolean;
}

export const RequestPrivateCheckbox: FC<Props> = memo(({ onCommit, isFetching, is_private }) => {
    const onChange = (e: CheckboxChangeEvent) => {
        onCommit?.(e.target.checked);
    };

    if (isFetching) {
        return null;
    }

    return <Checkbox onChange={onChange} defaultChecked={is_private} checked={is_private} />;
});
