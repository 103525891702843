import { FC, memo } from 'react';

import { DeliveryRequestCellProps } from '@typings';

import { TextCell } from '.';

export const HasSelfAssignedWorkerIndicator: FC<DeliveryRequestCellProps> = memo(
    ({ onCommit, isEditing, rowData }) => {
        const isHasSelfAssigned = rowData.has_self_assigned_worker ? '+' : '';

        return <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={isHasSelfAssigned} />;
    }
);
