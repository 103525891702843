import { FC, memo } from 'react';
import _ from 'lodash';

import { DeliveryRequest, DeliveryTableSortState, SetState, TableCellColumn } from '@typings';

import { ArrowsSort } from './ArrowsSort';
import { TitleRow } from './TitleRow';

interface Props {
    column: TableCellColumn;
    sortState: DeliveryTableSortState;
    setSortState: SetState<DeliveryTableSortState>;
    setExpandedRequests: SetState<number[]>;
    expandedRequests: number[];
    data: DeliveryRequest[];
}

const getDirectionSort = (column: TableCellColumn, sortState: DeliveryTableSortState) => {
    if (sortState && sortState.column.key === column.key) {
        const direction = sortState.direction === 'asc' ? 'desc' : null;

        if (direction) {
            return { column, direction };
        }

        return null;
    }

    return { column, direction: 'asc' };
};

const TableHeadRow: FC<Props> = memo(
    ({ column, setSortState, sortState, data, setExpandedRequests, expandedRequests }) => {
        const onSortClick = () => {
            setSortState(getDirectionSort(column, sortState));
        };

        const titleRowEl = (
            <TitleRow
                column={column}
                data={data}
                setExpandedRequests={setExpandedRequests}
                expandedRequests={expandedRequests}
            />
        );

        if (!column.sorter) {
            return <th>{titleRowEl}</th>;
        }

        return (
            <th className='sorter' title={column.title} onClick={onSortClick}>
                {titleRowEl}
                <ArrowsSort column={column} sortState={sortState} />
            </th>
        );
    },
    _.isEqual
);

export default TableHeadRow;
