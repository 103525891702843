import { ChangeEvent, FC, useEffect, useState } from 'react';
import { Checkbox, DatePicker, Form, Input } from 'antd';
import { FormInstance } from 'antd/es/form';
import Title from 'antd/es/typography/Title';
import { Rule } from 'antd/lib/form';
import moment from 'moment';

import { FieldItemWithObservableValue, TimePicker } from '@components';
import DadataAddressForAntForm from '@components/DadataAddressForAntForm';
import RemoteSelect from '@components/RemoteSelect';
import { API_URI, DATE_FORMAT } from '@constants';
import { parseRequestText } from '@utils';

interface NewRequestFormProps {
    pk?: number;
    onSave?: () => void;
    form: FormInstance;
}

interface RouteForward {
    date: moment.Moment;
    customer: string;
}
// eslint-disable-next-line no-useless-escape
const numberRule = [{ pattern: /^-?[0-9,\.]+$/, message: 'Требуется число' }];
const integerRule = [{ pattern: /^([+-]?[1-9]\d*|0)$/, message: 'Нужно целочисленное число' }];

export const AddDeliveryRequestForm: FC<NewRequestFormProps> = (props) => {
    const { pk, form } = props;
    const [fields] = useState([{ name: 'has_elevator', value: true }]);
    const [requestPk, setRequestPk] = useState<number | undefined>(pk);

    useEffect(() => {
        form.setFieldsValue({ requestPk });
    }, [requestPk, form]);
    const getInputItem = ({
        key,
        label,
        placeholder,
        required,
        onChange,
        customRules = [],
    }: {
        key: string;
        label: string;
        placeholder: string;
        required: boolean;
        onChange?: (value: React.ChangeEvent<HTMLTextAreaElement>) => void;
        customRules?: Rule[];
    }) => {
        return (
            <Form.Item
                key={key}
                name={key}
                style={{ marginBottom: 20 }}
                label={label}
                rules={
                    required
                        ? [...customRules, { required: true, message: 'Это поле необходимо!' }]
                        : customRules
                }
            >
                <Input.TextArea
                    placeholder={placeholder}
                    style={{ width: 300 }}
                    autoSize={true}
                    onChange={onChange}
                />
            </Form.Item>
        );
    };

    const onTextChange = (value: React.ChangeEvent<HTMLTextAreaElement>) => {
        const result = parseRequestText(value.target.value);
        if (result) {
            form.setFieldsValue(result);
        }
    };

    const today = moment(new Date().getDate(), DATE_FORMAT);

    const transformRouteForward = ({ date, customer }: RouteForward) => {
        return {
            date: date.format(DATE_FORMAT),
            customer,
        };
    };

    return (
        <Form
            layout='horizontal'
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 22 }}
            name='form_new_request'
            form={form}
            fields={fields}
            initialValues={{ workers_required: '1', date: today }}
        >
            {requestPk && (
                <Title style={{ marginBottom: 20 }} level={5}>
                    Прикрепить к заявке №{requestPk}
                </Title>
            )}
            <Form.Item hidden={true} key='requestPk' name='requestPk'>
                <Input />
            </Form.Item>

            <FieldItemWithObservableValue externalDependency={[!requestPk]}>
                {getInputItem({
                    key: 'text',
                    label: 'Весь текст заявки',
                    placeholder: '',
                    required: false,
                    onChange: onTextChange,
                })}
            </FieldItemWithObservableValue>

            <FieldItemWithObservableValue externalDependency={[!requestPk]}>
                <RemoteSelect.Item
                    url={API_URI.AUTOCOMPLETE_COMMON_CUSTOMER}
                    name='customer'
                    label='Клиент'
                    form={form}
                    placeholder='Клиент'
                    style={{ marginBottom: 20, width: 300 }}
                />
            </FieldItemWithObservableValue>
            <FieldItemWithObservableValue
                externalDependency={[!requestPk]}
                dependency={['customer']}
            >
                <RemoteSelect.WithForward
                    url={API_URI.AUTOCOMPLETE_LOCATION}
                    name='location'
                    style={{ marginBottom: 20, width: 300 }}
                    formItemProps={{
                        labelCol: { span: 8 },
                        wrapperCol: { span: 22 },
                    }}
                    label='Филиал'
                    form={form}
                    placeholder='Филиал'
                    required={false}
                    formForward={[['customer']]}
                />
            </FieldItemWithObservableValue>

            <FieldItemWithObservableValue externalDependency={[!requestPk]} dependency={['date']}>
                <Form.Item label='Дата' key='date' name='date' required={true}>
                    <DatePicker format={DATE_FORMAT} allowClear={false} />
                </Form.Item>
            </FieldItemWithObservableValue>

            <Form.Item
                name='time_interval'
                label='Время выполнения'
                rules={[{ required: true, message: 'Это поле необходимо!' }]}
            >
                <TimePicker form={form} format={'HH:mm'} name='time_interval' />
            </Form.Item>

            <FieldItemWithObservableValue externalDependency={[!requestPk]}>
                <RemoteSelect.WithForward
                    url={API_URI.AUTOCOMPLETE_ROUTE}
                    name='route'
                    form={form}
                    label='Прикрепить к маршруту'
                    placeholder='Прикрепить к маршруту'
                    transformForward={transformRouteForward}
                    formForward={['date', 'customer']}
                    style={{ marginBottom: 20, width: 300 }}
                    required={false}
                    onChange={(value) => setRequestPk(Number(value.toString()))}
                    onClear={() => setRequestPk(undefined)}
                />
            </FieldItemWithObservableValue>

            {getInputItem({ key: 'code', label: 'Индекс', placeholder: '', required: true })}
            {getInputItem({
                key: 'volume',
                label: 'Объем',
                placeholder: 'в формате 2.5',
                customRules: numberRule,
                required: true,
            })}
            {getInputItem({
                key: 'mass',
                label: 'Масса',
                placeholder: 'в формате 67.3',
                customRules: numberRule,
                required: true,
            })}
            {getInputItem({
                key: 'workers_required',
                label: 'Кол-во грузчиков',
                customRules: integerRule,
                placeholder: '1',
                required: false,
            })}
            {getInputItem({
                key: 'max_size',
                label: 'Макс. габарит',
                customRules: numberRule,
                placeholder: 'в формате 2.3',
                required: false,
            })}
            <Form.Item
                key='has_elevator'
                name='has_elevator'
                style={{ marginBottom: 20 }}
                label='Есть лифт?'
                rules={[]}
                valuePropName='checked'
            >
                <Checkbox />
            </Form.Item>
            {getInputItem({
                key: 'floor',
                label: 'Этаж',
                customRules: integerRule,
                placeholder: '',
                required: false,
            })}
            {getInputItem({
                key: 'carrying_distance',
                label: 'Пронос',
                customRules: integerRule,
                placeholder: 'дистанция проноса на руках в метрах',
                required: false,
            })}
            {getInputItem({
                key: 'place_count',
                label: 'Мест',
                placeholder: '',
                required: true,
            })}
            {getInputItem({
                key: 'shipment_type',
                label: 'Характер груза',
                placeholder: '',
                required: true,
            })}

            <Form.Item
                key='address'
                name='address'
                style={{ marginBottom: 20 }}
                label='Адрес'
                rules={[{ required: true, message: 'Это поле необходимо!' }]}
            >
                <DadataAddressForAntForm />
            </Form.Item>

            <FieldItemWithObservableValue externalDependency={[!requestPk]}>
                {getInputItem({
                    key: 'driver_name',
                    label: 'ФИО водителя',
                    placeholder: '',
                    required: false,
                })}
            </FieldItemWithObservableValue>

            <FieldItemWithObservableValue externalDependency={[!requestPk]}>
                {getInputItem({
                    key: 'driver_phones',
                    label: 'Телефоны водителя',
                    placeholder: '',
                    required: false,
                })}
            </FieldItemWithObservableValue>
        </Form>
    );
};
