import { FC } from 'react';

import styles from './StatusContainer.module.scss';
interface Props {
    placement: 'left' | 'right';
}

export const StatusContainer: FC<Props> = ({ children, placement }) => {
    return (
        <div
            className={styles.main}
            style={{
                [placement]: 0,
            }}
        >
            {children}
        </div>
    );
};
