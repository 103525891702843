import { FC } from 'react';
import { Row } from 'antd';
import moment from 'moment';

import { DATE_FORMAT } from '@constants';
import { DashboardPhotoListItem } from '@typings';

import styles from './photoInfo.module.scss';

interface Props {
    item: DashboardPhotoListItem['item'];
    request: DashboardPhotoListItem['request'];
}

export const PhotosInfo: FC<Props> = ({ request, item }) => {
    const photosData = [
        {
            title: 'Заявка',
            content: request.id,
        },
        {
            title: 'Дата',
            content: moment(new Date(request.date)).format(DATE_FORMAT),
        },
        {
            title: 'Индекс',
            content: item.code,
        },
        {
            title: 'Адрес',
            content: item.address,
        },
    ];

    const photosInfoEl = photosData.map(({ content, title }) => (
        <Row key={title} className={styles.main}>
            <p style={{ margin: 0 }}>
                <b>{title}: </b>
                <span>{content}</span>
            </p>
        </Row>
    ));

    return <>{photosInfoEl}</>;
};
