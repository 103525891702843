import { FC } from 'react';
import { BrowserRouter, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import locale from 'antd/lib/locale/ru_RU';

import { ModalsContainer } from '@components';
import { Login } from '@components/Login/Login';
import PhotoDashboard from '@pages/PhotoDashboard';
import RequestsCount from '@pages/RequestsCount';
import { useAuth } from '@utils/auth/auth';

import Calendar from './pages/Calendar';
import Delivery from './pages/Delivery/Delivery';
import Expenses from './pages/Expenses';
import ImportsReport from './pages/ImportsReport';
import RequestsOnMap from './pages/RequestsOnMap';
import WorkersReport from './pages/WorkersReport';
import TemplatePage from './templates/TemplatePage';

import 'antd/dist/antd.css';
import './styles/style.scss';
import './App.css';

import 'moment/locale/ru';

const PageNotFound = () => {
    return <Redirect to='/rf/delivery' />;
};

const routeSchema: RouteExtendsProps[] = [
    {
        path: '/rf/delivery/photos_dashboard',
        exact: true,
        component: PhotoDashboard,
    },
    {
        path: '/rf/delivery/requests_count',
        exact: true,
        component: RequestsCount,
    },
    {
        path: '/rf/delivery/imports_report',
        exact: true,
        component: ImportsReport,
    },
    {
        path: '/rf/delivery/requests_on_map',
        exact: true,
        component: RequestsOnMap,
    },
    {
        path: '/rf/delivery/workers_report',
        exact: true,
        component: WorkersReport,
    },
    {
        path: '/rf/expenses',
        exact: true,
        component: Expenses,
    },
    {
        path: '/rf/delivery',
        exact: true,
        component: Delivery,
    },
    {
        path: '/rf/delivery/calendar',
        exact: true,
        component: Calendar,
    },
    {
        path: '*',
        component: PageNotFound,
    },
];

interface RouteExtendsProps extends RouteProps {
    allow?: string; // todo: token.scope
}

const App: FC = () => {
    const [logged] = useAuth();

    return (
        <div className='App'>
            <BrowserRouter>
                <>
                    <ModalsContainer />

                    {!logged && (
                        <Switch>
                            <Route exact path='/rf/login' component={Login} />
                            <Redirect to='/rf/login' />
                        </Switch>
                    )}

                    {logged && (
                        <ConfigProvider locale={locale}>
                            <TemplatePage>
                                <Switch>
                                    {routeSchema.map((item: RouteExtendsProps) => (
                                        <Route key={String(item.path)} {...item} />
                                    ))}
                                </Switch>
                            </TemplatePage>
                        </ConfigProvider>
                    )}
                </>
            </BrowserRouter>
        </div>
    );
};

export default App;
