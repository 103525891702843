import { ColumnType } from 'antd/es/table';
import moment from 'moment';

import { DATE_FORMAT } from '@constants';
import { ClaimsClaimId, ClaimsClaimItem, Nullable } from '@typings';

interface GetClaimTypeParams {
    claimType: ClaimsClaimItem['claim_type'];
    firstPhoto: ClaimsClaimItem['first_photo'];
    id: ClaimsClaimId;
}

const getClaimType = ({ claimType, id, firstPhoto }: GetClaimTypeParams) => {
    if (claimType === 'fine') {
        if (firstPhoto) {
            const url = `/claims/photos/${id}`;

            return (
                <a href={url} target='_blank' rel='noreferrer'>
                    штраф
                </a>
            );
        }

        return <div>штраф</div>;
    }

    if (claimType === 'deduction') {
        return <div>вычет</div>;
    }

    return <div>неизвестный тип О_о</div>;
};

const getAmountLink = (id: Nullable<number>, amount: Nullable<string>) => {
    if (amount) {
        const url = `/admin/finance/operation/${id}/change/`;

        return (
            <a href={url} target='_blank' rel='noreferrer'>
                {amount}
            </a>
        );
    }

    return '-';
};

export const columns: ColumnType<ClaimsClaimItem>[] = [
    {
        title: 'Дата заведения',
        dataIndex: 'timestamp',
        key: 'timestamp',
        render: (text: Date) => moment(text).format(DATE_FORMAT),
    },
    {
        title: 'Тип',
        dataIndex: 'claim_type',
        key: 'claim_type',
        render: (_, { claim_type, first_photo, id }) => {
            return getClaimType({ claimType: claim_type, firstPhoto: first_photo, id });
        },
    },
    {
        title: 'Тип вычета',
        dataIndex: 'deduction_type',
        key: 'claim_type',
    },
    {
        title: 'Клиент',
        dataIndex: 'customer_name',
        key: 'customer_name',
    },
    {
        title: 'Претензия от',
        dataIndex: 'claim_by',
        key: 'claim_by',
    },
    {
        title: 'Сумма штрафа',
        dataIndex: 'fine_amount',
        key: 'fine_amount',
        render: (_, record) => getAmountLink(record.fine_id, record.fine_amount),
    },
    {
        title: 'Сумма вычета',
        dataIndex: 'deduction_amount',
        key: 'deduction_amount',
        render: (_, record) => getAmountLink(record.id, record.amount),
    },
    {
        title: (
            <>
                Комментарий
                <br />
                (к вычету)
            </>
        ),
        dataIndex: 'comment',
        key: 'comment',
    },
    {
        title: 'Работник',
        dataIndex: 'worker',
        key: 'worker',
        render: (_, record) => (
            <a href={`/worker/${record.worker_id}/`} target='_blank' rel='noreferrer'>
                {record.worker_full_name}
            </a>
        ),
    },
];
