import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useHashTarget = () => {
    const { hash } = useLocation();

    useEffect(() => {
        if (hash) {
            const scrollToTarget = () => {
                const targetElement = document.querySelector(hash);

                if (!targetElement) {
                    return;
                }

                targetElement?.scrollIntoView({
                    block: 'start',
                    inline: 'center',
                });
            };

            setTimeout(scrollToTarget, 200);

            window.addEventListener('load', scrollToTarget);

            return () => {
                window.removeEventListener('load', scrollToTarget);
            };
        }
    }, [hash]);
};
