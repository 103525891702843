import moment from 'moment';

import { DATE_FORMAT } from '@constants';
import {
    AddDeliveryRequestFormValues,
    DeliveryCreateRequestItemParams,
    DeliveryCreateRequestParams,
    FormatedAddDeliveryRequestFormValues,
} from '@typings';

export const getRequestDataByCreateType = (
    requestData: FormatedAddDeliveryRequestFormValues,
    isCreateRequest: boolean
): DeliveryCreateRequestItemParams | DeliveryCreateRequestParams => {
    const defaultItem = {
        interval_begin: requestData.interval_begin,
        interval_end: requestData.interval_end,
        code: requestData.code,
        mass: Number(requestData.mass),
        volume: Number(requestData.volume),
        max_size: Number(requestData.max_size),
        place_count: Number(requestData.place_count),
        shipment_type: requestData.shipment_type,
        address: requestData.address,
        has_elevator: requestData.has_elevator,
        floor: Number(requestData.floor),
        carrying_distance: Number(requestData.carrying_distance),
    };

    if (isCreateRequest) {
        return {
            customer: Number(requestData.customer),
            location: null,
            date: requestData.date,
            driver_name: requestData.driver_name,
            driver_phones: requestData.driver_phones,
            items: [
                {
                    ...defaultItem,
                    workers_required: Number(requestData.workers_required),
                },
            ],
        };
    }

    return {
        ...defaultItem,
        request: Number(requestData.requestPk),
    };
};

export const formatCreateRequestForm = (
    data: AddDeliveryRequestFormValues
): FormatedAddDeliveryRequestFormValues => {
    const result: Partial<FormatedAddDeliveryRequestFormValues> = {};

    for (const [key, value] of Object.entries(data)) {
        switch (key as keyof AddDeliveryRequestFormValues) {
            case 'time_interval':
                const [interval_begin, interval_end] = value.map((time: moment.Moment) => {
                    return time.format('HH:mm');
                });

                result.interval_begin = interval_begin;
                result.interval_end = interval_end;
                break;
            case 'driver_name':
                result.driver_name = value || '';
                break;

            case 'driver_phones':
                result.driver_phones = value || '';
                break;
            case 'date':
                result.date = value.format(DATE_FORMAT);
                break;
            default:
                result[key as keyof FormatedAddDeliveryRequestFormValues] = value;
        }
    }

    return result as FormatedAddDeliveryRequestFormValues;
};
