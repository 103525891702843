import { useCallback, useEffect, useRef, useState } from 'react';
import { FormInstance } from 'antd';
import parse from 'html-react-parser';
import _ from 'lodash';

import { API_URI } from '@constants';
import { useAutocompleteData } from '@hooks';
import {
    AutocompleteData,
    AutocompleteDataResult,
    Nullable,
    RemoteSelectMapOptions,
} from '@typings';

import { useRemoteAddOptions } from './useRemoteAddOptions';

type OnOptionsChange = (data: Nullable<AutocompleteDataResult[]>) => void;

const formatResponseOptions = (options: AutocompleteDataResult[] = []): RemoteSelectMapOptions => {
    return new Map(
        options.map(({ id, text }) => [
            id,
            {
                value: id.toString(),
                label: parse(text) as string | JSX.Element,
            },
        ])
    );
};

interface Params {
    url: API_URI;
    form: FormInstance;
    name: string;
    forward?: object;
    initialOptions?: AutocompleteDataResult[];
    isClearOnForward: boolean;
}

export const useOptions = ({
    form,
    name,
    forward,
    url,
    initialOptions,
    isClearOnForward,
}: Params) => {
    const prevForward = useRef(forward);
    const formattedInitialOptions = formatResponseOptions(initialOptions);
    const [options, setOptions] = useState<RemoteSelectMapOptions>(formattedInitialOptions);
    const { onPageUp, onAutocompleteUpdate, data, getOptionById, onAutocompleteSearchChange } =
        useAutocompleteData({
            url,
            forward,
        });
    const { onRemoteSelectValuesAdd } = useRemoteAddOptions({ getOptionById, options });

    const onOptionsChange: OnOptionsChange = useCallback((data) => {
        if (data) {
            setOptions((prev) => new Map([...prev, ...formatResponseOptions(data)]));
        }
    }, []);

    useEffect(() => {
        onOptionsChange(data);
    }, [data, onOptionsChange]);

    useEffect(() => {
        if (isClearOnForward && !_.isEqual(prevForward.current, forward)) {
            prevForward.current = forward;

            form.resetFields([name]);
            setOptions(formattedInitialOptions);
        }
    }, [forward, form, formattedInitialOptions]);

    const onSelectClear = () => {
        form.resetFields([name]);
        onAutocompleteUpdate(true);
        setOptions(formattedInitialOptions);
    };

    const updateOptions = () => {
        onAutocompleteUpdate(true);
    };

    const onSearchChange = (searchData: string) => {
        const onSuccess = (data: AutocompleteData) => {
            setOptions(formatResponseOptions(data.results));
        };

        onAutocompleteSearchChange(searchData, onSuccess);
    };

    return {
        options: [...options.values()],
        setOptions: onOptionsChange,
        onSelectClear,
        onSearchChange,
        updateOptions,
        onPageUp,
        onRemoteSelectValuesAdd,
    };
};
