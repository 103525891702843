import { FC, memo } from 'react';

import { TABLE_METRO_COLORS } from '@constants';
import { DeliveryRequestOrExpanded } from '@typings';
import { isMergedRequest } from '@utils/delivery';

interface Props {
    rowData: DeliveryRequestOrExpanded;
}

export const MetroLine: FC<Props> = memo(({ rowData }) => {
    const { metro_line, region } = rowData.items?.[0] || {};

    const metroName = `${region}_${metro_line}` as keyof typeof TABLE_METRO_COLORS;

    if (!isMergedRequest(rowData) || !metro_line) {
        return null;
    }

    return (
        <div
            style={{
                background: TABLE_METRO_COLORS[metroName],
                height: 20,
                width: `100%`,
                color: '#000',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {metro_line}
        </div>
    );
});
