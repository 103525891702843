import { FC, memo, useCallback, useState } from 'react';

import RemoteSelect from '@components/RemoteSelect';
import { API_URI } from '@constants';
import { DeliveryRequestCellProps } from '@typings';
import { textContent } from '@utils/delivery';

export const Location: FC<DeliveryRequestCellProps> = memo(({ onCommit, rowData, isEditing }) => {
    const [location, setLocation] = useState(rowData.location);

    const commit = useCallback(
        (location) => {
            onCommit?.(location?.key || null);
        },
        [onCommit]
    );

    const onChange = (value: string | string[]) => {
        setLocation({ key: Number(value.toString()), label: '' });
        commit({ key: value.toString() });
    };

    const onBlur = () => {
        commit(location);
    };

    if (isEditing) {
        return (
            <RemoteSelect.Form
                url={API_URI.AUTOCOMPLETE_LOCATION}
                name='request'
                placeholder='Филиал'
                onChange={onChange}
                style={{ width: 160 }}
                defaultOpen
                autoFocus
                onBlur={onBlur}
                externalForward={{
                    customer: rowData.customer.key,
                }}
            />
        );
    } else {
        return <>{textContent(rowData, 'location')}</>;
    }
});
