import { FC, memo } from 'react';

import { DeliveryRouteCell, RequestItemCell, TextCell, TimeIntervalCell } from '@components';
import { CurrentCellProps } from '@typings';

export const UnwrappedCell: FC<
    Omit<CurrentCellProps, 'setRequest' | 'isFetching' | 'setRowWithFormat' | 'columnDataIndex'>
> = memo(({ cellValue, isEditing, onCommit, rowData, cellKey }) => {
    switch (cellKey) {
        case 'mass':
        case 'volume':
        case 'max_size':
        case 'place_count':
        case 'floor':
        case 'carrying_distance':
        case 'shipment_type':
        case 'workers_required':
            return (
                <RequestItemCell
                    cellKey={cellKey}
                    isEditing={isEditing}
                    onCommit={onCommit}
                    rowData={rowData}
                />
            );
        case 'customer':
        case 'date':
        case 'driver_name':
            return null;
        case 'route':
            return <DeliveryRouteCell rowData={rowData} />;

        case 'time_interval':
            return <TimeIntervalCell isEditing={isEditing} onCommit={onCommit} rowData={rowData} />;

        default:
            return <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={cellValue} />;
    }
});
