import { FC, useContext } from 'react';

import { PhoneOutlined } from '@ant-design/icons';
import { callToWorker } from '@api';
import { WorkersReportTableContext } from '@providers/workersReportTable';
import { WorkersReportWorkerId } from '@typings';
import { showError } from '@utils/show_error';

import styles from './cells.module.scss';

interface Props {
    value: string;
    workerId: WorkersReportWorkerId;
}

export const TelNumber: FC<Props> = ({ value, workerId }) => {
    const { updateWorker } = useContext(WorkersReportTableContext);

    const onWorkerCall = () => {
        callToWorker({
            params: {
                worker: workerId,
            },
            onError(error) {
                showError(error.message);
                updateWorker(workerId, { callIcon: 1 });
            },
            onSuccess() {
                updateWorker(workerId, { callIcon: 1 });
            },
        });
    };

    return (
        <div onClick={onWorkerCall} className={styles.tel_number}>
            <PhoneOutlined /> {value}
        </div>
    );
};
