import { FC } from 'react';
import { RouteProps } from 'react-router';
import { Col, Row, Spin } from 'antd';

import { WorkersReportForm, WorkersReportTable } from '@components';
import { useColumns } from '@hooks';
import { WorkersReportWorker } from '@typings';

import { useWorkers } from './hooks';

const WorkersReport: FC<RouteProps> = () => {
    const { fetchWorkers, isLoading, workers, updateWorker, workersCount } = useWorkers();
    const {
        onColumnViewChange,
        filterListColumns,
        initializeColumns,
        filtredColumns,
        attachOnColumnsFilterChange,
        onColumnSortChange,
    } = useColumns<WorkersReportWorker>('workers');

    return (
        <Row>
            <Col>
                <WorkersReportForm
                    filterListColumns={filterListColumns}
                    onColumnViewChange={onColumnViewChange}
                    workersCount={workersCount}
                    onFinish={fetchWorkers}
                    onColumnSortChange={onColumnSortChange}
                />

                <Spin spinning={isLoading}>
                    <WorkersReportTable
                        updateWorker={updateWorker}
                        attachOnColumnsFilterChange={attachOnColumnsFilterChange}
                        dataSource={workers}
                        filtredColumns={filtredColumns}
                        initializeColumns={initializeColumns}
                        onColumnSortChange={onColumnSortChange}
                    />
                </Spin>
            </Col>
        </Row>
    );
};

export default WorkersReport;
