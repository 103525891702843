import { FC, memo, useContext } from 'react';

import { DownCircleOutlined, DownCircleTwoTone, UpCircleTwoTone } from '@ant-design/icons';
import { DeliveryTableContext } from '@providers/deliveryTable';
import { DeliveryRequest } from '@typings';
import { getRequestPk, isMergedRequest } from '@utils/delivery';

interface Props {
    rowData: DeliveryRequest;
}

export const ButtonUnwrap: FC<Props> = memo(({ rowData }) => {
    const { setExpandedRequests } = useContext(DeliveryTableContext);
    const requestPk = getRequestPk(rowData);

    if (isMergedRequest(rowData)) {
        return <DownCircleOutlined style={{ color: 'white', paddingRight: 4 }} />;
    }

    const onClickWrapRequest = () => {
        setExpandedRequests((prev) => {
            return prev.filter((itemPk) => itemPk !== requestPk);
        });
    };

    const onClickUnWrapRequest = () => {
        setExpandedRequests((prev) => [...prev, requestPk]);
    };

    if (rowData.expanded) {
        return <UpCircleTwoTone style={{ paddingRight: 4 }} onClick={onClickWrapRequest} />;
    }

    return <DownCircleTwoTone style={{ paddingRight: 4 }} onClick={onClickUnWrapRequest} />;
});
