import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { Content } from 'antd/es/layout/layout';
import SubMenu from 'antd/es/menu/SubMenu';

import { BASE_URL_SERVER_API, PATH_ROUTES } from '@constants';
import { authFetch, logout } from '@utils/auth/auth';

import './TemplatePage.scss';

export interface IMenu {
    key?: string;
    label: string;
    url?: string;
    items?: Array<{
        key?: string;
        label: string;
        url?: string;
    }>;
}

const TemplatePage: FC = ({ children }) => {
    const [menuItems, setMenuItems] = useState<IMenu[]>([]);
    useEffect(() => {
        authFetch(`${BASE_URL_SERVER_API}common/menu/`)
            .then((_menu) => {
                if (_menu.data?.menu?.length) {
                    setMenuItems(_menu.data.menu);
                }
            })
            .catch((e) => {
                throw e;
            });
    }, []);

    const getPathRoute = (key: string) => {
        return PATH_ROUTES.find((pathRoute) => pathRoute.key === key)?.path;
    };

    return (
        <>
            <div>
                <Menu mode='horizontal'>
                    {menuItems.map((MI: IMenu, i) => {
                        if (MI?.items?.length) {
                            return (
                                <SubMenu
                                    key={i}
                                    title={
                                        <span>
                                            <span>{MI.label}</span>
                                        </span>
                                    }
                                >
                                    {MI.items.map((subItem) => {
                                        if (subItem?.key) {
                                            if (subItem.key === 'logout') {
                                                return (
                                                    <Menu.Item
                                                        className='custom-submenu-item'
                                                        key='logout'
                                                    >
                                                        <div onClick={() => logout()}>
                                                            {subItem.label}
                                                        </div>
                                                    </Menu.Item>
                                                );
                                            } else {
                                                return (
                                                    <Menu.Item
                                                        className='custom-submenu-item'
                                                        key={getPathRoute(subItem.key)}
                                                    >
                                                        <Link to={getPathRoute(subItem.key) || ''}>
                                                            {subItem.label}
                                                        </Link>
                                                    </Menu.Item>
                                                );
                                            }
                                        } else {
                                            return (
                                                <Menu.Item
                                                    className='custom-submenu-item'
                                                    key={subItem.url}
                                                >
                                                    <a href={subItem.url || ''}>{subItem.label}</a>
                                                </Menu.Item>
                                            );
                                        }
                                    })}
                                </SubMenu>
                            );
                        } else {
                            return (
                                <Menu.Item key={MI.url}>
                                    <a href={MI.url || ''}>{MI.label}</a>
                                </Menu.Item>
                            );
                        }
                    })}
                </Menu>
            </div>
            <Content className='site-layout' style={{ padding: '0 20px', marginTop: 10 }}>
                {children}
            </Content>
        </>
    );
};

export default TemplatePage;
