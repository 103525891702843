import { FC, FocusEventHandler } from 'react';
import { Form, FormInstance } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { NamePath } from 'antd/lib/form/interface';

import { RemoteSelectProps } from '@typings';

import RemoteSelect from '.';

interface Props extends Omit<RemoteSelectProps, 'form' | 'onBlur' | 'forward'> {
    onBlur?: FocusEventHandler<HTMLFormElement> | undefined;
    formForward?: NamePath[];
    externalForward?: object;
    transformForward?<T>(value: T): T;
    initialForm?: FormInstance;
}

const RemoteSelectForm: FC<Props> = ({ onBlur, name, initialForm, ...props }) => {
    const [form] = useForm(initialForm);

    return (
        <Form form={form} onBlur={onBlur} layout='inline'>
            <RemoteSelect.WithForward {...props} form={form} name={name} />
        </Form>
    );
};

export default RemoteSelectForm;
