import { FC } from 'react';
import clsx from 'clsx';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { DeliveryTableSortState, TableCellColumn } from '@typings';

interface Props {
    column: TableCellColumn;
    sortState: DeliveryTableSortState;
}

const isActiveByDirection = (
    column: TableCellColumn,
    sortState: DeliveryTableSortState,
    direction: 'asc' | 'desc'
) => {
    if (sortState && column.key === sortState.column.key) {
        return sortState.direction === direction;
    }

    return false;
};

export const ArrowsSort: FC<Props> = ({ column, sortState }) => {
    const iconUpStyles = clsx({
        active: isActiveByDirection(column, sortState, 'desc'),
    });
    const iconDownStyles = clsx({
        active: isActiveByDirection(column, sortState, 'asc'),
    });

    return (
        <div className='sort'>
            <CaretUpOutlined className={iconUpStyles} />
            <CaretDownOutlined className={iconDownStyles} />
        </div>
    );
};
