import { useCallback, useState } from 'react';

import { fetchWorkersData } from '@api';
import { FetchWorkersReportParams, WorkersReportUpdateWorker, WorkersReportWorker } from '@typings';
import { mapperWorkersListReport } from '@utils';
import { showError } from '@utils/show_error';

interface WorkersData {
    workers: WorkersReportWorker[];

    isLoading: boolean;
}

export const useWorkers = () => {
    const [{ isLoading, workers }, setWorkersData] = useState<WorkersData>({
        isLoading: true,
        workers: [],
    });

    const workersCount = workers.length;

    const toggleLoading = useCallback((isLoading: boolean) => {
        setWorkersData((prev) => ({ ...prev, isLoading }));
    }, []);

    const fetchWorkers = useCallback((params: FetchWorkersReportParams) => {
        toggleLoading(true);

        fetchWorkersData({
            params,
            onError(error) {
                showError(error.message);
                toggleLoading(false);
            },

            onSuccess({ data }) {
                setWorkersData({
                    workers: mapperWorkersListReport(data),
                    isLoading: false,
                });
            },
        });
    }, []);

    const updateWorker: WorkersReportUpdateWorker = useCallback((currentWorkerId, workerValues) => {
        setWorkersData((prev) => {
            const workers = prev.workers.map((worker) => {
                if (worker.workerId === currentWorkerId) {
                    return { ...worker, ...workerValues };
                }

                return worker;
            });

            return { ...prev, workers };
        });
    }, []);

    return { isLoading, workers, fetchWorkers, updateWorker, workersCount };
};
