import { CSSProperties, useState } from 'react';
import { Select as UISelect } from 'antd';
import { DefaultOptionType, SelectProps } from 'antd/lib/select';
import Text from 'antd/lib/typography/Text';

import RemoteSelect from '@components/RemoteSelect';
import { API_URI } from '@constants';

import styles from './cells.module.scss';
interface Props<Value extends string> extends SelectProps {
    name: string;
    text?: string;
    className?: string;
    autocompleteUrl?: API_URI;
    options?: DefaultOptionType[];
    onChange: (value: Value) => void;
    style?: CSSProperties;
}

export const Select = <Value extends string = string>({
    onChange,
    text,
    name,
    autocompleteUrl,
    className,
    options,
    ...props
}: Props<Value>) => {
    const [isEdit, setIsEdit] = useState(false);

    const onSelectChange = (value: Value) => {
        setIsEdit(false);

        onChange(value);
    };

    const onCellEdit = () => {
        setIsEdit(true);
    };

    const onBlur = () => {
        setIsEdit(false);
    };

    const renderSelect = () => {
        if (autocompleteUrl) {
            return (
                <RemoteSelect.Form
                    url={autocompleteUrl}
                    name={name}
                    placeholder={text}
                    onBlur={onBlur}
                    onChange={onSelectChange}
                    autoFocus
                    {...props}
                />
            );
        }

        return (
            <UISelect
                size='small'
                className={styles.select}
                options={options}
                placeholder={text}
                onBlur={onBlur}
                onChange={onSelectChange}
                autoFocus
                {...props}
            />
        );
    };

    const renderContent = () => {
        if (isEdit) {
            return renderSelect();
        }

        return <Text onClick={onCellEdit}>{text || ''}</Text>;
    };

    return (
        <div title={text} className={className}>
            {renderContent()}
        </div>
    );
};
