import React, { FC } from 'react';
import { Button, Tooltip } from 'antd';

import { RollbackOutlined, UndoOutlined } from '@ant-design/icons';
import { confirmItemWorkerFinishPhoto, confirmItemWorkerStartPhoto } from '@api';
import { useRequestConfirm } from '@hooks';
import {
    DashboardPhotoActionsView,
    DashboardPhotoItemType,
    DashborardPhotoActionButtonProps,
} from '@typings';

interface Props extends DashborardPhotoActionButtonProps {
    photoId: number;
    type: DashboardPhotoItemType;
    viewType: DashboardPhotoActionsView;
}

export const AcceptButton: FC<Props> = ({
    onSuccess,
    requestId,
    photoId,
    type,
    onError,
    viewType,
}) => {
    const { sendRequestWithConfirm } = useRequestConfirm();
    const onClick = () => {
        const initialRequestParams = {
            onSuccess,
            onError,
            initialParams: {
                request: requestId,
                photo: photoId,
            },
        };

        if (type === 'finish') {
            sendRequestWithConfirm({
                ...initialRequestParams,
                requestCallback: confirmItemWorkerFinishPhoto,
            });
        } else {
            sendRequestWithConfirm({
                ...initialRequestParams,
                requestCallback: confirmItemWorkerStartPhoto,
            });
        }
    };

    if (viewType === 'icon') {
        return (
            <Tooltip placement='top' title='Сбросить отмену'>
                <UndoOutlined style={{ margin: '0 5px 0 5px' }} onClick={onClick} />
            </Tooltip>
        );
    }

    return (
        <Button type='primary' onClick={onClick}>
            <RollbackOutlined />
            Сбросить отмену
        </Button>
    );
};
