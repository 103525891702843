import { DeliveryColumn } from '@typings';

export const baseColumns: DeliveryColumn[] = [
    {
        title: '№',
        checked: true,
        key: 'pk',
    },
    {
        title: 'Индекс',
        checked: true,
        key: 'code',
    },
    {
        title: 'Маршрут',
        checked: true,
        key: 'route',
    },
    {
        title: 'Автор',
        checked: false,
        key: 'author',
    },
    {
        title: 'Ответственный',
        checked: true,
        key: 'operator',
    },
    {
        title: 'Дата',
        checked: true,
        key: 'date',
    },
    {
        title: 'Создание (время создания заявки)',
        checked: false,
        key: 'timestamp',
    },
    {
        title: 'Выполнить (интервал выполнения)',
        checked: true,
        key: 'time_interval',
    },
    {
        title: 'Подача (согласованное время подачи)',
        checked: true,
        key: 'confirmed_timepoint',
    },
    {
        title: 'Скрыть заявку от свободных грузчиков',
        checked: true,
        key: 'is_private',
    },
    {
        title: 'Прибытие (реальное время прибытия)',
        checked: true,
        key: 'arrival_time',
    },
    {
        title: 'Опоздание',
        checked: true,
        key: 'lateness',
    },
    {
        title: 'Подтверждение (время подтверждения)',
        checked: false,
        key: 'confirmation_time',
    },
    {
        title: 'Масса',
        checked: true,
        key: 'mass',
    },
    {
        title: 'Объем',
        checked: false,
        key: 'volume',
    },
    {
        title: 'Габарит',
        checked: false,
        key: 'max_size',
    },
    {
        title: 'Мест',
        checked: true,
        key: 'place_count',
    },
    {
        title: 'Лифт',
        checked: true,
        key: 'has_elevator',
    },
    {
        title: 'Этаж',
        checked: true,
        key: 'floor',
    },
    {
        title: 'Пронос',
        checked: true,
        key: 'carrying_distance',
    },
    {
        title: 'Характер груза',
        checked: false,
        key: 'shipment_type',
    },
    {
        title: 'Клиент',
        checked: true,
        key: 'customer',
    },
    {
        title: 'Примечаниее',
        checked: true,
        key: 'comment',
    },
    {
        title: 'Адрес',
        checked: true,
        key: 'address',
    },
    {
        title: 'Линия метро',
        checked: true,
        key: 'metro_line',
    },
    {
        title: 'Станция метро',
        checked: true,
        key: 'metro_name',
    },
    {
        title: 'Позвонить водителю',
        checked: true,
        key: 'call_to_driver',
    },
    {
        title: 'Водитель',
        checked: true,
        key: 'driver_name',
    },
    {
        title: 'Тел. водителя',
        checked: true,
        key: 'driver_phones',
    },
    {
        title: 'Статус',
        checked: true,
        key: 'status',
    },
    {
        title: 'Фото',
        checked: true,
        key: 'timesheet_photo',
    },
    {
        title: 'Есть самоназначенные грузчики',
        checked: true,
        key: 'has_self_assigned_worker',
    },
    {
        title: 'Направленные грузчики',
        checked: true,
        key: 'assigned_workers',
    },
    {
        title: 'Грузчиков заказано',
        checked: true,
        key: 'workers_required',
    },
    {
        title: 'Грузчиков назначено',
        checked: true,
        key: 'worker_count',
    },
    {
        title: 'Филиал',
        checked: true,
        key: 'location',
    },
    {
        title: 'Тариф',
        checked: true,
        key: 'service',
    },
    {
        title: 'Кол-во часов (к оплате)',
        checked: true,
        key: 'hours',
    },
    {
        title: 'Стоимость',
        checked: true,
        key: 'pay_estimate',
    },
];
