import { FC } from 'react';

import { Nullable } from '@typings';

interface Props {
    date: Nullable<string>;
}

export const LastCallDate: FC<Props> = ({ date }) => {
    return <div>{date || '-'}</div>;
};
