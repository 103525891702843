import { FC, memo } from 'react';

import { DeliveryRequest } from '@typings';
import { adapterDeliveryToDashboard } from '@utils/adapterDeliveryToDashboard';

import { DashboardItem } from './DashboardItem';
import { DashboardPieChart } from './DashboardPieChart';

import styles from './RequestsDashboard.module.scss';

interface RequestsDashboardProps {
    data: DeliveryRequest[];
}

const RequestsDashboard: FC<RequestsDashboardProps> = ({ data }) => {
    const dashboardData = adapterDeliveryToDashboard(data);

    const dashboardItemsEl = [...dashboardData.items.values()].map(({ groups, title, total }) => {
        return (
            <DashboardItem
                key={title}
                groups={[...groups.values()]}
                dashBoardTotal={dashboardData.total}
                itemTotal={total}
                title={title}
            />
        );
    });

    return (
        <div className={styles.dashboard}>
            <DashboardPieChart data={dashboardData} />

            {dashboardItemsEl}
        </div>
    );
};

export default memo(RequestsDashboard);
