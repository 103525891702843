import _ from 'lodash';

import {
    DeliveryRequestDetail,
    DeliveryRequestItem,
    DeliveryRequestWorker,
    DeliveryWorkersOnAddressTableData,
} from '@typings';

const getDuplicateConfirmedTimepointList = (items: DeliveryRequestItem[]) => {
    const confirmedTimepointList = items.reduce((acc: string[], { confirmed_timepoint }) => {
        if (confirmed_timepoint !== '-' || !confirmed_timepoint) {
            return [...acc, confirmed_timepoint];
        }

        return acc;
    }, []);

    const duplicateList = _.filter(confirmedTimepointList, (confirmedTimepoint, i, iteratee) =>
        _.includes(iteratee, confirmedTimepoint, i + 1)
    );

    return _.uniq(duplicateList);
};

const isDuplicateAddress = (confirmedTimepointList: string[], workers: DeliveryRequestWorker[]) => {
    if (!confirmedTimepointList.length) {
        return false;
    }

    for (const { confirmed_timepoint } of workers) {
        if (confirmedTimepointList.includes(confirmed_timepoint)) {
            return true;
        }
    }

    return false;
};
const getCountAdressesByWorkers = (workersOnAddress: Record<string, string[]>) => {
    return Object.values(workersOnAddress).reduce((acc: Record<string, number>, workers) => {
        for (const workerId of workers) {
            const currentAddresses = acc?.[workerId] || 0;

            acc[workerId] = currentAddresses + 1;
        }

        return acc;
    }, {});
};

export const mapperRequestToTableData = ({
    request,
    workers,
}: DeliveryRequestDetail): DeliveryWorkersOnAddressTableData[] => {
    if (!request) {
        return [];
    }
    const duplicateConfirmedTimepointList = getDuplicateConfirmedTimepointList(request.items);

    return request?.items?.map(
        ({
            address,
            metro_line,
            metro_name,
            pk,
            confirmed_timepoint,
        }): DeliveryWorkersOnAddressTableData => {
            return {
                key: pk,
                confirmedTimepoint: {
                    isDuplicateFirst: isDuplicateAddress(duplicateConfirmedTimepointList, workers),
                    confirmedTimepoint: confirmed_timepoint,
                    addressId: pk,
                    requestPk: request.pk,
                },
                address: {
                    name: address,
                    metroName: metro_name,
                    metroLine: metro_line,
                    addressId: pk,
                },
                action: {
                    requestPk: request.pk,
                    confirmedTimepoint: confirmed_timepoint,
                    duplicateConfirmedTimepointList,
                    addressId: pk,
                },
                workers,
                workerData: {
                    addressId: pk,
                },
            };
        }
    );
};

export const isWorkerOnAddresses = (
    workerId: number,
    workersOnAddress: Record<string, string[]>
) => {
    for (const workersOnAddressList of Object.values(workersOnAddress)) {
        if (workersOnAddressList.includes(workerId.toString())) {
            return true;
        }
    }

    return false;
};

const getSortByVirtualStatus = (first: DeliveryRequestWorker, second: DeliveryRequestWorker) => {
    const isVirtualFirstWorker = first.id < 0;
    const isVirtualSecondWorker = first.id < 0;

    if (!isVirtualFirstWorker && isVirtualSecondWorker) {
        return -1;
    }

    if (!isVirtualSecondWorker && isVirtualFirstWorker) {
        return 1;
    }

    if (isVirtualSecondWorker && isVirtualFirstWorker) {
        return first.name?.toString().localeCompare(second.name!?.toString());
    }

    return null;
};

const getSortByAdressesCount = (
    first: DeliveryRequestWorker,
    second: DeliveryRequestWorker,
    countAdressesByWorkers: Record<string, number>
) => {
    const firstAdressesCount = countAdressesByWorkers?.[first.id] || 0;
    const secondAdressesCount = countAdressesByWorkers?.[second.id] || 0;

    if (firstAdressesCount > secondAdressesCount) {
        return -1;
    }
    if (firstAdressesCount < secondAdressesCount) {
        return 1;
    }

    return first.name?.toString().localeCompare(second.name!?.toString());
};

export const sortWorkers = (
    workers: DeliveryRequestWorker[],
    workersOnAddress: Record<string, string[]>
) => {
    const countAdressesByWorkers = getCountAdressesByWorkers(workersOnAddress);

    return workers.sort((first, second) => {
        const sortByVirtualStatus = getSortByVirtualStatus(first, second);
        const sortByAdressesCount = getSortByAdressesCount(first, second, countAdressesByWorkers);

        return sortByVirtualStatus || sortByAdressesCount;
    });
};
