import { FC, memo, useEffect, useMemo, useState } from 'react';
import { Modal } from 'antd';

import { fetchWorker } from '@api';
import { CommentEditor, CommentList } from '@components/Comment';
import { closeModal } from '@store/modal';
import {
    Nullable,
    WorkerFetchWorkerResponse,
    WorkersReportUpdateWorker,
    WorkersReportWorkerComment,
    WorkersReportWorkerId,
} from '@typings';
import {
    mapperWorkersReportWorkerComment,
    mapperWorkersReportWorkerItem,
} from '@utils/mapperWorkersReport';
import { showError } from '@utils/show_error';

interface Props {
    isOpen: boolean;
    workerId: WorkersReportWorkerId;
    updateWorker: WorkersReportUpdateWorker;
}

const formatComments = (comments: WorkerFetchWorkerResponse['comments']) => {
    return (
        comments?.map((comment) => {
            return {
                ...comment,
                author: mapperWorkersReportWorkerItem(comment.author),
            };
        }) || []
    );
};

export const WorkersReportComments: FC<Props> = memo(({ workerId, isOpen, updateWorker }) => {
    const [comments, setComments] = useState<Nullable<WorkersReportWorkerComment[]>>(null);

    useEffect(() => {
        if (typeof workerId !== 'number') return;

        setComments(null);

        fetchWorker({
            params: {
                worker: workerId,
            },
            onError(error) {
                showError(error.message);
            },
            onSuccess({ data }) {
                setComments(formatComments(data.comments));
            },
        });
    }, [workerId]);

    const commentsList = useMemo(() => {
        return comments?.map(mapperWorkersReportWorkerComment).reverse() || [];
    }, [comments]);

    const onCommentAdd = (comment: WorkersReportWorkerComment) => {
        updateWorker(workerId, { lastComment: comment });

        setComments((prev) => [comment, ...(prev || [])]);
    };

    const onModalClose = () => {
        closeModal();
    };

    return (
        <>
            <Modal
                title='Список комментариев'
                visible={isOpen}
                onCancel={onModalClose}
                destroyOnClose={true}
                footer={[]}
            >
                <CommentList dataSource={commentsList} />

                <CommentEditor workerId={workerId} onCommentAdd={onCommentAdd} />
            </Modal>
        </>
    );
});
