import { DELIVERY_DASHBOARD_GROUPS_NAMES, DELIVERY_TABLE_FILTER_BY_STATUS } from '@constants';
import {
    DeliveryRequest,
    DeliveryRequestFormattedStatusList,
    DeliveryRequestsDashboard,
    DeliveryRequestsDashboardItem,
    DeliveryRequestsDashboardItemGroup,
    DeliveryRequestsDashboardItemsNames,
    DeliveryStatusList,
} from '@typings';

type AdapterDeliveryToDashboard = (
    data: DeliveryRequest[],
    prevData?: DeliveryRequest[]
) => DeliveryRequestsDashboard;

type DashboardGroups = Map<DeliveryRequestFormattedStatusList, DeliveryRequestsDashboardItemGroup>;

const getInitialGroup = (itemName: DeliveryRequestsDashboardItemsNames): DashboardGroups => {
    const groupMap = new Map<
        DeliveryRequestFormattedStatusList,
        DeliveryRequestsDashboardItemGroup
    >();
    const currentGroupNames = DELIVERY_DASHBOARD_GROUPS_NAMES.get(itemName);

    if (!currentGroupNames) {
        return groupMap;
    }

    for (const groupTitle of currentGroupNames) {
        groupMap.set(groupTitle, { title: groupTitle, total: 0 });
    }

    return groupMap;
};

const getInitialItemState = (itemName: DeliveryRequestsDashboardItemsNames) => ({
    groups: getInitialGroup(itemName),
    title: itemName,
    total: 0,
});

const getInitialRequestsDashboardState = (requests: DeliveryRequest[]) => ({
    totalMoney: 0,
    total: requests.length,
    items: new Map<DeliveryRequestsDashboardItemsNames, DeliveryRequestsDashboardItem>([
        ['preprocessing', getInitialItemState('preprocessing')],
        ['in_work', getInitialItemState('in_work')],
        ['no_contact', getInitialItemState('no_contact')],
        ['cancelled', getInitialItemState('cancelled')],
    ]),
});

export const getMergedRequest = (request: DeliveryRequest[], prevRequests: DeliveryRequest[]) => {
    const getRequestMap = (request: DeliveryRequest[]) => {
        return new Map(request.map((request) => [request.pk, request]));
    };

    const requestMap = getRequestMap(request);
    const prevRequestsMap = getRequestMap(prevRequests);

    return [...new Map([...prevRequestsMap, ...requestMap]).values()];
};

const getCurrentStatus = (stauts: DeliveryStatusList) => {
    switch (stauts) {
        case 'partly_arrival_submitted':
            return 'partly_arrived';
        default:
            return stauts;
    }
};

export const adapterDeliveryToDashboard: AdapterDeliveryToDashboard = (requests, prevRequests) => {
    const currentRequest = prevRequests ? getMergedRequest(requests, prevRequests) : requests;

    return currentRequest.reduce((acc: DeliveryRequestsDashboard, { status, customer_amount }) => {
        const currentStatus = getCurrentStatus(status);
        const itemName = DELIVERY_TABLE_FILTER_BY_STATUS.get(currentStatus)!!;
        const curentItem = acc.items.get(itemName) || getInitialItemState(itemName);

        const currentGroup = {
            total: (curentItem.groups.get(currentStatus)?.total || 0) + 1,
            title: currentStatus,
        };

        curentItem.groups.set(currentStatus, currentGroup);

        curentItem.total += 1;

        acc.items.set(itemName, curentItem);
        acc.totalMoney += Number(customer_amount || 0);

        return acc;
    }, getInitialRequestsDashboardState(requests));
};
