import { FC, memo } from 'react';

import { DeliveryRequestCellProps } from '@typings';

import { TextCell } from '.';

export const Comment: FC<DeliveryRequestCellProps> = memo(({ onCommit, isEditing, rowData }) => {
    const formattedComment = rowData.comment || null;

    return <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={formattedComment} />;
});
