import { FC, memo, ReactNode, useMemo } from 'react';
import { Tag } from 'antd';

import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

interface StatusTagDict {
    [arg0: string]: { statusString: string; statusColor: string; icon?: ReactNode };
}
interface StatusTagProps {
    status: string;
}
export const StatusTag: FC<StatusTagProps> = memo(({ status }) => {
    const { statusString, statusColor, icon } = useMemo(() => {
        const dict: StatusTagDict = {
            default: {
                statusString: status,
                statusColor: '',
            },
            new: {
                statusString: 'Новая',
                statusColor: '',
                icon: <ClockCircleOutlined style={{ marginRight: '4px' }} />,
            },
            confirmed: {
                statusString: 'Одобрена',
                statusColor: 'green',
                icon: <CheckCircleOutlined style={{ marginRight: '4px' }} />,
            },
            rejected: {
                statusString: 'Отклонена',
                statusColor: 'red',
                icon: <CloseCircleOutlined style={{ marginRight: '4px' }} />,
            },
        };

        return dict[status] || dict.default;
    }, [status]);

    return (
        <Tag color={statusColor} style={{ marginRight: 0 }}>
            {icon || null}
            {statusString}
        </Tag>
    );
});
