import { FC, memo } from 'react';

import { DeliveryCellProps, DeliveryRequestItemKeys } from '@typings';

import { TextCell } from '.';

interface Props extends DeliveryCellProps {
    cellKey: DeliveryRequestItemKeys;
}

export const RequestItem: FC<Props> = memo(({ rowData, cellKey, onCommit, isEditing }) => {
    const additionalItemValue =
        rowData.items?.length > 1 ? '' : rowData.items?.[0]?.[cellKey]?.toString() || '-';
    return <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={additionalItemValue} />;
});
