import { FC } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { PhotoListState, SetState } from '@typings';
import { filterPhotoDashboardList } from '@utils';

interface Props {
    setPhotoList: SetState<PhotoListState>;
}

export const CheckBoxFilter: FC<Props> = ({ setPhotoList }) => {
    const onFilterPhotos = ({ target }: CheckboxChangeEvent) => {
        if (target.checked) {
            return setPhotoList((prev) => ({
                ...prev,
                photoList: prev.photoList,
                sortedPhotoList: filterPhotoDashboardList(prev.photoList),
            }));
        }

        setPhotoList((prev) => ({
            ...prev,
            photoList: prev.photoList,
            sortedPhotoList: prev.photoList,
        }));
    };

    return <Checkbox onChange={onFilterPhotos}>Только новые</Checkbox>;
};
