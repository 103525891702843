import { FC } from 'react';
import { Button, Card, Col, Form, Input, Row } from 'antd';

import { loginUser } from '@api';
import { login } from '@utils/auth/auth';
import { showError } from '@utils/show_error';

interface FormValues {
    username: string;
    password: string;
}

export const Login: FC = () => {
    const onFinish = (values: FormValues) => {
        loginUser({
            params: values,
            onSuccess({ data }) {
                login({ accessToken: data.access_token, refreshToken: data.refresh_token });
            },
            onError(error) {
                showError(error.message);
            },
        });
    };

    return (
        <Row justify='center' align='middle' style={{ minHeight: '100vh' }}>
            <Col>
                <Card>
                    <Form onFinish={onFinish}>
                        <Form.Item
                            label='Username'
                            name='username'
                            rules={[{ required: true, message: 'Please input your username!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label='Password'
                            name='password'
                            rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input.Password />
                        </Form.Item>

                        <Form.Item>
                            <Button type='primary' htmlType='submit'>
                                Login
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};
