import { DeliveryTableStatuses, ManualStatuses, WorkerStatus } from '@typings';

export const WORKER_STATUS: WorkerStatus = {
    '1': {
        name: 'Актив',
        bgColor: '#D9F2B4',
    },
    '2': {
        name: 'Выходил давно',
        bgColor: '#D4F4DD',
    },
    '0': {
        name: 'Не выходил',
        bgColor: '#70587CAA',
    },
    '-1': {
        name: 'Забанен',
        bgColor: '#AB4E68',
    },
};

export const MANUAL_STATUSES: ManualStatuses = [
    {
        label: 'declined',
        text: 'Не принята в работу',
    },
    {
        label: 'cancelled',
        text: 'Отмена',
    },
    {
        label: 'failed',
        text: 'Срыв заявки',
    },
    {
        label: 'driver_callback',
        text: 'Перезвонит сам',
    },
    {
        label: 'no_response',
        text: 'Нет ответа',
    },
    {
        label: 'cancelled_with_payment',
        text: 'Отмена с оплатой',
    },
];

export const TABLE_CELL_STATUSES: DeliveryTableStatuses = {
    new: {
        color: '#FFFFFF',
        className: 'delivery_table_row_new',
    },
    declined: {
        color: '#FFCCCC',
        className: 'delivery_table_row_cancelled',
    },
    cancelled: {
        color: '#FFCCCC',
        className: 'delivery_table_row_cancelled',
    },
    failed: {
        color: '#FFAAAA',
        className: 'delivery_table_row_failed',
    },
    driver_callback: {
        color: '#FFFFAA',
        className: 'delivery_table_row_driver_callback',
    },
    no_response: {
        color: '#FF8000AA',
        className: 'delivery_table_row_no_response',
    },
    cancelled_with_payment: {
        color: '#CCFFCC',
        className: 'delivery_table_row_cancelled_with_payment',
    },
    timepoint_confirmed: {
        color: '#49C6FB',
        className: 'delivery_table_row_timepoint_confirmed',
    },
    partly_confirmed: {
        color: '#7A57C770',
        className: 'delivery_table_row_partly_confirmed',
    },
    partly_arrival_submitted: {
        color: '#CDCAF6AA',
        className: 'delivery_table_row_partly_arrival_submitted',
    },
    partly_arrived: {
        color: '#D9DDF2AA',
        className: 'delivery_table_row_partly_arrived',
    },
    partly_photo_attached: {
        color: '#D9EEF2AA',
        className: 'delivery_table_row_partly_photo_attached',
    },
    photo_attached: {
        color: '#C6ECDFAA',
        className: 'delivery_table_row_photo_attached',
    },
    finished: {
        color: '#EEFFEE',
        className: 'delivery_table_row_finished',
    },
};
