import { FC } from 'react';
import { Button, Col, Form, Modal, Row, Space } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { DeliveryAddRequestModal } from '@components';
import RemoteSelect from '@components/RemoteSelect';
import { API_URI } from '@constants';
import { closeModal, openModal } from '@store/modal';
import { DeliveryOnAddRequest, RenderModal } from '@typings';

interface Props {
    onAddRequest: DeliveryOnAddRequest;
    fetchData: () => void;
    toggleLoading: (isLoading: boolean) => void;
    isOpen: boolean;
    request?: number;
}
interface FormValues {
    request: string;
}

export const DeliveryAttachRequest: FC<Props> = ({
    isOpen,
    request,
    onAddRequest,
    fetchData,
    toggleLoading,
}) => {
    const [form] = useForm();

    const handleAddRequest = (value: FormValues) => {
        if (value.request) {
            onAddRequest({
                item_pk: Number(value.request),
                target_pk: Number(request),
            });
            closeModal();
        }
    };

    const onModalClose = () => {
        closeModal();
    };

    const onAttachNewRequest = () => {
        openModal(renderModal({ fetchData, toggleLoading, targetRequest: request }));
    };

    return (
        <Modal
            key='modal_add_request'
            visible={isOpen}
            title='Новая заявка'
            destroyOnClose
            onOk={onModalClose}
            onCancel={onModalClose}
            width={650}
            footer={null}
        >
            <Row gutter={[10, 20]}>
                <Col span={24}>
                    <Space>
                        <Form layout='inline' form={form} onFinish={handleAddRequest}>
                            {request && (
                                <RemoteSelect.WithForward
                                    form={form}
                                    url={API_URI.AUTOCOMPLETE_ITEM}
                                    name='request'
                                    placeholder='Заявка'
                                    required={false}
                                    style={{ width: 450 }}
                                    externalForward={{ request }}
                                />
                            )}

                            <Button type='primary' htmlType='submit'>
                                Прикрепить
                            </Button>
                        </Form>
                    </Space>
                </Col>

                <Col span={24}>
                    <Space>
                        <Button type='primary' onClick={onAttachNewRequest}>
                            Прикрепить новую заявку
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Modal>
    );
};

interface RenderModalParams {
    fetchData: () => void;
    toggleLoading: (isLoading: boolean) => void;
    targetRequest: number | undefined;
}

const renderModal =
    ({ fetchData, toggleLoading, targetRequest }: RenderModalParams): RenderModal =>
    (isOpen) => {
        return (
            <DeliveryAddRequestModal
                onSuccess={fetchData}
                toggleLoading={toggleLoading}
                isOpen={isOpen}
                targetRequest={targetRequest}
            />
        );
    };
