import {
    DeliveryRequest,
    DeliveryRequestItem,
    DeliveryRequestOrExpanded,
    DeliveryRequestResponse,
} from '@typings';

export const normalizeDeliveryRequest = (request: DeliveryRequestResponse): DeliveryRequest => {
    const {
        pk,
        author,
        comment,
        customer: _customer,
        operator: _operator,
        service: _service,
        items: _items,
        location: _location,
        hours,
        date,
        driver_name,
        driver_phones,
        route,
        status,
        status_description,
        timestamp,
        customer_resolution,
        is_overdue: is_overdued,
        is_private,
        has_self_assigned_worker,
        new_photo_count: new_photos,
        confirmation_time,
        extra_photos_exist,
        arrival_time,
        pay_estimate,
        timepoint_ok,
        customer_amount,
    } = request;

    const service = _service || { id: -1, name: '-' };
    const location = _location || { id: -1, name: '-' };
    const customer = _customer || { id: -1, name: '-' };
    const operator = _operator ? _operator.name || '-' : '-';

    const items: DeliveryRequestItem[] = _items.map(
        ({
            id,
            interval_begin,
            interval_end,
            code,
            mass,
            volume,
            max_size,
            place_count,
            shipment_type,
            address,
            has_elevator,
            floor,
            carrying_distance,
            metro,
            workers_required,
            lateness,
            assigned_workers,
            first,
            confirmed_timepoint,
        }) => {
            const currentLateness = lateness || { label: '-', value: null };

            const { line, region, station } = metro || {
                line: '-',
                station: '-',
                region: '-',
            };

            return {
                confirmed_timepoint: confirmed_timepoint || '-',
                lateness: currentLateness.label,
                lateness_seconds: currentLateness.value,
                pk: id,
                interval_begin,
                interval_end,
                code,
                mass,
                volume,
                max_size,
                place_count,
                shipment_type,
                address,
                has_elevator,
                floor,
                carrying_distance,
                metro_line: line,
                metro_name: station,
                region,
                workers_required,
                assigned_workers,
                first,
            };
        }
    );

    return {
        timepoint_ok,
        pay_estimate,
        comment: comment || '-',
        customer_amount,
        date,
        driver_name,
        driver_phones,
        pk,
        route,
        status,
        status_description,
        author: author?.name || '',
        customer: { key: customer.id, label: customer.name },
        service: { key: service.id, label: service.name },
        operator,
        timestamp,
        tableId: pk,
        hours,
        items,
        customer_resolution,
        is_overdued,
        has_self_assigned_worker,
        is_private,
        location: { key: location.id, label: location.name },
        new_photos,
        extra_photos_exist,
        confirmation_time,
        arrival_time,
    };
};

export const normalizeDeliveryRequestList = (data: DeliveryRequestResponse[]) => {
    if (!data) return [];
    return data.map(normalizeDeliveryRequest);
};

export const getDeliveryRequestsRows = (
    requests: DeliveryRequest[],
    expandedRequests: number[]
): DeliveryRequestOrExpanded[] => {
    const expandedRequestsMap = new Map(expandedRequests.map((el) => [el, el]));
    const rows: DeliveryRequestOrExpanded[] = [];

    for (const { pk, items, ...request } of requests) {
        const expanded = items.length > 1 && expandedRequestsMap.has(pk);

        rows.push({
            ...request,
            items,
            pk,
            expanded,
        });

        if (expanded) {
            for (const item of items) {
                rows.push({
                    date: request.date,
                    customer: request.customer,
                    driver_name: request.driver_name,
                    workers_required: item.workers_required,
                    driver_phones: request.driver_phones,
                    pay_estimate: request.pay_estimate,
                    tableId: item.pk,
                    parent_pk: pk,
                    items: [{ ...item }],
                    expanded: true,
                    route: request.route || null,
                });
            }
        }
    }

    return rows;
};
