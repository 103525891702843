import { TABLE_READONLY_CELLS, TABLE_READONLY_REQUEST_CELLS } from '@constants';
import { DeliveryRequestOrExpanded } from '@typings';

export const isNotReadonlyCell = (name: string, request: DeliveryRequestOrExpanded) => {
    if (TABLE_READONLY_CELLS.includes(name)) {
        return false;
    }

    const isNotMergedRequest = request.items.length > 1;

    if (isNotMergedRequest && TABLE_READONLY_REQUEST_CELLS.includes(name)) {
        return false;
    }

    return true;
};
