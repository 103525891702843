import { FC, memo, useState } from 'react';
import { Spin } from 'antd';

import { PhoneTwoTone } from '@ant-design/icons';
import { callToWorker } from '@api';
import { showError } from '@utils/show_error';

interface Props {
    phone: string;
    worker_id: number;
}

export const RenderPhone: FC<Props> = memo(({ phone, worker_id }) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleClickPhone = () => {
        setLoading(true);
        const onSuccess = () => {
            setLoading(false);
        };

        const onError = (error: Error) => {
            showError(error.message);
            setLoading(false);
        };

        const params = {
            worker: worker_id,
        };

        callToWorker({ params, onSuccess, onError });
    };

    if (loading) {
        return <Spin key='spin' size='small' spinning={true} />;
    }

    return (
        <span
            onClick={handleClickPhone}
            style={{
                display: 'flex',
                gap: '5px',
                alignItems: 'center',
            }}
        >
            <PhoneTwoTone />
            <span>{phone}</span>
        </span>
    );
});
