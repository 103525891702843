import { FC } from 'react';

import { finishItemWorker } from '@api';
import { UploadPhoto } from '@components';
import { useRequestConfirm } from '@hooks';
import { DeliveryRequestMutationDefaultResponse, Response } from '@typings';

import { CustomButton } from './CustomButton';

interface Props {
    item: number;
    request: number;
    worker_id: number;
    onError(error: Error): void;
    onSuccess(data: Response<DeliveryRequestMutationDefaultResponse>): void;
}
export const UploadFinishPhoto: FC<Props> = ({ item, onError, onSuccess, request, worker_id }) => {
    const { sendRequestWithConfirm } = useRequestConfirm();

    const finishItemWorkerAction = (image: File) => {
        sendRequestWithConfirm({
            requestCallback: finishItemWorker,
            onError,
            onSuccess,
            initialParams: {
                worker: worker_id,
                request,
                item,
                image,
            },
        });
    };

    return (
        <CustomButton style={{ fontSize: '12px', marginTop: 10 }}>
            <UploadPhoto
                request={item}
                label='Приложить накладную'
                onLoad={finishItemWorkerAction}
                viewFilename={false}
            />
        </CustomButton>
    );
};
