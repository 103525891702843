import { FC } from 'react';

import { ReportsExportModal } from '@components/modals';
import { openModal } from '@store/modal';
import { DeliveryFilterState, RenderModal } from '@typings';

import styles from './searchForm.module.scss';

interface Props {
    state: Partial<DeliveryFilterState>;
}

export const ReportsExport: FC<Props> = ({ state }) => {
    const onModalOpen = () => {
        openModal(renderModal(state));
    };

    return (
        <button
            type='button'
            className={styles.reports_button}
            onClick={onModalOpen}
            title='Выгрузка отчетов'
        >
            <img alt='icon' src='/icons/save_xls_icon.svg' />
        </button>
    );
};

const renderModal = (state: Partial<DeliveryFilterState>): RenderModal => {
    return (isOpen) => {
        return <ReportsExportModal isOpen={isOpen} state={state} />;
    };
};
