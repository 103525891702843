import { AutocompleteDataResult, Nullable } from '@typings';

export enum UseAutocompleteDataActionsType {
    SEARCH_UPDATE = 'SEARCH_UPDATE',
    PAGE_UP = 'PAGE_UP',
    MORE_UPDATE = 'MORE_UPDATE',
    RESET = 'RESET',
    DATA_UPDATE = 'DATA_UPDATE',
    STATE_UPDATE = 'STATE_UPDATE',
}

export interface UseAutocompleteState {
    page: number;
    more: boolean;
    searchData: string;
    data: Nullable<AutocompleteDataResult[]>;
}

type ActionSearchUpdate = {
    type: UseAutocompleteDataActionsType.SEARCH_UPDATE;
    data: string;
};

type ActionPageUp = {
    type: UseAutocompleteDataActionsType.PAGE_UP;
};

type ActionStateReset = {
    type: UseAutocompleteDataActionsType.RESET;
};

type ActionDataUpdate = {
    type: UseAutocompleteDataActionsType.DATA_UPDATE;
    data: Nullable<AutocompleteDataResult[]>;
};

type ActionStateUpdate = {
    type: UseAutocompleteDataActionsType.STATE_UPDATE;
    data: Partial<UseAutocompleteState>;
};

export type UseAutocompleteActions =
    | ActionSearchUpdate
    | ActionPageUp
    | ActionStateReset
    | ActionDataUpdate
    | ActionStateUpdate;
