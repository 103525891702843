import { FC, useContext } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import moment from 'moment';

import { saveWorkerPlannedContactDay, updateWorkerRating } from '@api';
import { DATE_FORMAT } from '@constants';
import { WorkersReportTableContext } from '@providers/workersReportTable';
import {
    WorkersReportRatingWorker,
    WorkersReportWorker,
    WorkersReportWorkerAvailability,
    WorkersReportWorkerId,
} from '@typings';
import { mapperWorkersReportWorkerUpdateRatingToDetail } from '@utils/mapperWorkersReport';
import { showError } from '@utils/show_error';

import { Select } from './Select';

import styles from './cells.module.scss';
interface Props {
    availability: WorkersReportWorkerAvailability;
    workerId: WorkersReportWorkerId;
    size?: SizeType;
    bgColor?: string;
}

const options = [
    {
        label: 'На связи',
        value: 'available',
    },
    {
        label: 'Нет связи',
        value: 'not_available',
    },
    {
        label: 'Не отвечает',
        value: 'no_answer',
    },
];

const textList: Record<WorkersReportWorkerAvailability, string> = {
    available: 'На связи',
    no_answer: 'Не отвечает',
    not_available: 'Нет связи',
    unknown: 'Не звонили',
};

const tomorrow = moment().add(1, 'days');

const getCurrentData = (data: WorkersReportRatingWorker): Partial<WorkersReportWorker> => {
    const { availability } = data;

    if (availability === 'not_available' || availability === 'no_answer') {
        return { ...data, readiness: 'not_ready' };
    }

    return data;
};

const onError = (error: Error) => {
    showError(error.message);
};

export const AvailabilityStatus: FC<Props> = ({
    availability,
    workerId,
    bgColor,
    size = 'small',
}) => {
    const { updateWorker } = useContext(WorkersReportTableContext);

    const updatePlannedDay = () => {
        saveWorkerPlannedContactDay({
            onSuccess() {
                updateWorker(workerId, { plannedContactDay: tomorrow });
            },
            onError,
            params: {
                date: tomorrow.format(DATE_FORMAT),
                worker: workerId,
            },
        });
    };

    const onAvailabilityChange = (availabilityValue: WorkersReportWorkerAvailability) => {
        updateWorkerRating({
            params: {
                worker: workerId,
                availability: availabilityValue,
            },
            onError,
            onSuccess({ data }) {
                const currentData = getCurrentData(
                    mapperWorkersReportWorkerUpdateRatingToDetail(data)
                );

                if (data.availability === 'no_answer') {
                    updatePlannedDay();
                }

                updateWorker(workerId, currentData);
            },
        });
    };

    return (
        <div className={styles.custom_color_cell} style={{ background: bgColor }}>
            <Select
                name='availability'
                size={size}
                text={textList[availability]}
                className={styles.select}
                options={options}
                onChange={onAvailabilityChange}
            />
        </div>
    );
};
