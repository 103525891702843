import { FC } from 'react';

import { DeliveryRouteColumn } from '@components';
import { DeliveryRequest, SetState, TableCellColumn } from '@typings';
interface Props {
    column: TableCellColumn;
    data: DeliveryRequest[];
    expandedRequests: number[];
    setExpandedRequests: SetState<number[]>;
}

export const TitleRow: FC<Props> = ({ column, expandedRequests, data, setExpandedRequests }) => {
    const unwrapAllRequest = () => {
        setExpandedRequests((prev) => {
            const isActiveExpanded = prev.length > 1;

            if (isActiveExpanded) {
                return [];
            }

            return data.filter((request) => request.items.length > 1).map((request) => request.pk);
        });
    };

    switch (column.key) {
        case 'route':
            return (
                <DeliveryRouteColumn
                    isActive={expandedRequests.length > 1}
                    onClick={unwrapAllRequest}
                />
            );

        default:
            return <>{column.title}</>;
    }
};
