import { FC } from 'react';
import { Modal } from 'antd';

interface Props {
    isOpen: boolean;
    content: string;
    onOk(): void;
    closeModal(): void;
}

export const RequestConfirm: FC<Props> = ({ isOpen, content, onOk, closeModal }) => {
    return (
        <Modal
            zIndex={10000}
            visible={isOpen}
            onOk={onOk}
            onCancel={closeModal}
            okText='Подтверждаю начисления'
            cancelText='Отмена'
        >
            {content}
        </Modal>
    );
};
