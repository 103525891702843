import { FC, useState } from 'react';
import { Checkbox, Row } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

interface Props {
    isChecked: boolean;
    title: string;
    columnKey: string;
    onChange(title: string, isChecked: boolean): void;
}

export const FilterCheckbox: FC<Props> = ({
    isChecked: isInitialChecked,
    columnKey,
    title,
    onChange: onChangeCb,
}) => {
    const [isChecked, setIsChecked] = useState(isInitialChecked);

    const onChange = ({ target }: CheckboxChangeEvent) => {
        setIsChecked(target.checked);
        onChangeCb(columnKey, target.checked);
    };
    const style = {
        marginBottom: '8px',
    };

    return (
        <Row gutter={[0, 16]}>
            <Checkbox checked={isChecked} onChange={onChange} style={style}>
                {title}
            </Checkbox>
        </Row>
    );
};
