import { FC, memo } from 'react';

import { CurrentCellProps } from '@typings';
import { isUnwrappedRequest } from '@utils';

import { DefaultCell } from './DefaultCell';
import { UnwrappedCell } from './UnwrappedCell';
import { WrappedCell } from './WrappedCell';

export const CurrentCell: FC<CurrentCellProps> = memo(
    ({
        cellValue,
        cellKey,
        setRequest,
        rowData,
        setRowWithFormat,
        isEditing,
        onCommit,
        isFetching,
        columnDataIndex,
    }) => {
        const renderCell = () => {
            if (isUnwrappedRequest(rowData)) {
                return (
                    <UnwrappedCell
                        cellValue={cellValue}
                        isEditing={isEditing}
                        onCommit={onCommit}
                        rowData={rowData}
                        cellKey={cellKey}
                    />
                );
            }
            return (
                <WrappedCell
                    columnDataIndex={columnDataIndex}
                    isFetching={isFetching}
                    setRowWithFormat={setRowWithFormat}
                    cellValue={cellValue}
                    isEditing={isEditing}
                    onCommit={onCommit}
                    rowData={rowData}
                    cellKey={cellKey}
                />
            );
        };

        return (
            <DefaultCell
                columnDataIndex={columnDataIndex}
                setRowWithFormat={setRowWithFormat}
                setRequest={setRequest}
                isEditing={isEditing}
                onCommit={onCommit}
                rowData={rowData}
                cellKey={cellKey}
            >
                {renderCell()}
            </DefaultCell>
        );
    }
);
