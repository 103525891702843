import { RequestsImportModal } from '@components/modals';
import { openModal } from '@store/modal';
import { RenderModal } from '@typings';

import styles from './searchForm.module.scss';

export const RequestsImport = () => {
    const onModalOpen = () => {
        openModal(renderModal);
    };

    return (
        <button
            type='button'
            className={styles.requests_import_button}
            onClick={onModalOpen}
            title='Импорт заявок'
        >
            <img alt='icon' src='/icons/import_xls_icon.svg' />
        </button>
    );
};

const renderModal: RenderModal = (isOpen) => {
    return <RequestsImportModal isOpen={isOpen} />;
};
