import {
    DashboardGetPhotoListResponse,
    DashboardPhotoItemType,
    DashboardPhotoListItem,
    DashboardPhotoListItemRejected,
    GetDashboardPhotoListItemResponse,
    Nullable,
    PhotoDashboardListItem,
} from '@typings';

export const adapterPhotosByAddress = (
    photoListDasboard: DashboardGetPhotoListResponse['data']
) => {
    const mergePhotoListByStatus = (photoList: GetDashboardPhotoListItemResponse[]) => {
        const hashedPhotoList = new Map<string, DashboardPhotoListItem>();

        for (const {
            worker,
            photos,
            is_suspicious,
            discrepancy_check,
            confirmed,
            ...photo
        } of photoList) {
            const addressId = `${photo.item.id}${photo.request.id}`;

            const hashedPhoto = hashedPhotoList.get(addressId)?.photos || [];
            const formattedPhotos = [
                ...hashedPhoto,
                ...formatPhotos({ photos, worker, discrepancy_check, is_suspicious, confirmed }),
            ].sort((a, b) => a.workerId - b.workerId);

            hashedPhotoList.set(addressId, { ...photo, photos: formattedPhotos });
        }

        return [...hashedPhotoList.values()];
    };

    return mergePhotoListByStatus([...photoListDasboard.start, ...photoListDasboard.finish]);
};

type FormatPhotos = Pick<
    GetDashboardPhotoListItemResponse,
    'worker' | 'photos' | 'is_suspicious' | 'discrepancy_check' | 'confirmed'
>;

const formatPhotos = ({
    photos,
    worker,
    is_suspicious,
    discrepancy_check,
    confirmed,
}: FormatPhotos) => {
    return photos.map(({ id, ...el }) => {
        const type: DashboardPhotoItemType = is_suspicious === undefined ? 'finish' : 'start';

        return {
            ...el,
            confirmed,
            type,
            photoId: id,
            isSuspicious: is_suspicious,
            discrepancyCheck: discrepancy_check,
            workerName: worker.name,
            workerId: worker.id,
        };
    });
};

export const getPhotoStatus = (rejected: DashboardPhotoListItemRejected, confirmed: boolean) => {
    if (rejected) {
        return 'rejected';
    }

    if (confirmed) {
        return 'confirmed';
    }

    return 'new';
};

export const filterPhotoDashboardList = (
    photos: Nullable<DashboardPhotoListItem[]>
): Nullable<DashboardPhotoListItem[]> => {
    if (!photos) {
        return null;
    }

    const filterPhotoListItem = (photoList: DashboardPhotoListItem[]): DashboardPhotoListItem[] => {
        return photoList.reduce((acc: DashboardPhotoListItem[], { photos, ...photo }) => {
            const formattedPhotosByStatus: PhotoDashboardListItem[] = photos.filter(
                ({ rejected, confirmed }) => {
                    return getPhotoStatus(rejected, confirmed) === 'new';
                }
            );

            if (formattedPhotosByStatus.length) {
                return [...acc, { ...photo, photos: formattedPhotosByStatus }];
            }

            return acc;
        }, []);
    };

    return filterPhotoListItem(photos);
};
