import { FC, memo, useContext, useMemo, useState } from 'react';
import { Col, Row, Spin } from 'antd';

import { TableHeadRow, TableRow } from '@components';
import { useGetDeliveryColumns } from '@hooks';
import { DeliveryTableProvider } from '@providers';
import { DeliveryContext } from '@providers/delivery';
import { DeliveryRequest, DeliveryTableSortState, TableCellColumn } from '@typings';
import {
    filterDeliveryRequestListByStatus,
    getDeliveryRequestsRows,
    sortDeliveryRequestList,
} from '@utils';

import './DeliveryTable.css';

interface Props {
    data: DeliveryRequest[];
    loading: boolean;
}

export const DeliveryTable: FC<Props> = memo(({ data, loading }) => {
    const [expandedRequests, setExpandedRequests] = useState<number[]>([]);
    const [sortState, setSortState] = useState<DeliveryTableSortState>(null);
    const { columns, onChangeRequestData, filterStatus } = useContext(DeliveryContext);

    const deliveryTableRows = useMemo(() => {
        const filtredData = filterDeliveryRequestListByStatus(data, filterStatus);
        const sortedData = sortDeliveryRequestList(filtredData, sortState);

        return getDeliveryRequestsRows(sortedData, expandedRequests);
    }, [data, sortState, expandedRequests, filterStatus]);

    const [fixedColumns, staticColumns] = useGetDeliveryColumns({
        columnsFilter: columns,
    });

    if (loading) {
        return <Spin size='large' spinning={true} style={{ width: '100%', paddingTop: '50px' }} />;
    }

    const renderTableHeadRow = (tableCellColumns: TableCellColumn[]) => {
        return tableCellColumns.map((column) => {
            return (
                <TableHeadRow
                    key={column.key}
                    setExpandedRequests={setExpandedRequests}
                    expandedRequests={expandedRequests}
                    column={column}
                    setSortState={setSortState}
                    sortState={sortState}
                    data={data}
                />
            );
        });
    };

    const renderTableBodyRow = (tableCellColumns: TableCellColumn[]) => {
        return deliveryTableRows.map((row) => {
            return (
                <TableRow
                    onChangeData={onChangeRequestData}
                    key={row.tableId}
                    columns={tableCellColumns}
                    rowData={row}
                />
            );
        });
    };

    return (
        <Row>
            <Col style={{ width: '100%' }}>
                <DeliveryTableProvider setExpandedRequests={setExpandedRequests}>
                    <div className='table table-hover rh-table mt-3 delivery_table'>
                        <table>
                            <thead>
                                <tr>{renderTableHeadRow(fixedColumns)}</tr>
                            </thead>
                            <tbody>{renderTableBodyRow(fixedColumns)}</tbody>
                        </table>
                        <table>
                            <thead>
                                <tr>{renderTableHeadRow(staticColumns)}</tr>
                            </thead>
                            <tbody>{renderTableBodyRow(staticColumns)}</tbody>
                        </table>
                    </div>
                </DeliveryTableProvider>
            </Col>
        </Row>
    );
});

export default DeliveryTable;
