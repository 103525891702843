import { ColumnType } from 'antd/lib/table';
import moment, { isMoment } from 'moment';

import { BASE_URL_SERVER, DATE_FORMAT } from '@constants';
import {
    CustomColumn,
    Nullable,
    TalkBankBindStatus,
    WorkersReportMiniTableData,
    WorkersReportWorker,
    WorkersReportWorkerItem,
    WorkersReportWorkerStatus,
} from '@typings';

import {
    ActionCell,
    AvailabilityStatusCell,
    BalanceCell,
    BenevolentStatusCell,
    CallCell,
    CommentCell,
    DatePickerPlanContactDayCell,
    LastCallDateCell,
    LinkCell,
    OnlineTomorrowStatusCell,
    ReadinessStatusCell,
    RegistrationStatusCell,
    StatusCell,
    TableMiniCell,
    TagCell,
    TalkBankBindStatusCell,
    ZoneSelectCell,
} from './cells';

const getTime = (value: string | Date) => {
    return new Date(value).getTime();
};

const formatToMoment = (value: Nullable<string>) => {
    if (value === '-' || !value) {
        return null;
    }

    return moment(value, DATE_FORMAT);
};

const getValuesLocaleCompare = (a: number | string, b: number | string) =>
    a?.toString().localeCompare(b!?.toString()) || 0;

type CustomDate = Nullable<moment.Moment | Date>;

const sortDate = (aDate: CustomDate, bDate: CustomDate) => {
    if (aDate === null) {
        return 1;
    }

    if (bDate === null) {
        return -1;
    }

    if (isMoment(aDate) && isMoment(bDate)) {
        return getTime(aDate.toDate()) - getTime(bDate.toDate());
    }

    return getTime(aDate as Date) - getTime(bDate as Date);
};

type NumberSortItem = Nullable<boolean | string | number>;

const sortToNumber = (aStatus: NumberSortItem, bStatus: NumberSortItem) => {
    if (aStatus === null) {
        return 1;
    }

    if (bStatus === null) {
        return -1;
    }

    return Number(aStatus) < Number(bStatus) ? -1 : 1;
};

export const WorkersReportTableConfig: CustomColumn<WorkersReportWorker>[] = [
    {
        title: '#',
        dataIndex: 'workerId',
        key: 'key',
        width: 40,
        isNotEdit: true,
        isVisible: true,
        render: (_item, _worker, rowIndex) => rowIndex + 1,
    },

    {
        title: 'Дата регистрации',
        dataIndex: 'inputDate',
        key: 'inputDate',
        width: 105,
        sorter: {
            compare: (a, b) => {
                return sortDate(formatToMoment(a.inputDate), formatToMoment(b.inputDate));
            },
            multiple: 0,
        },
    },
    {
        title: 'ФИО',
        key: 'fullName',
        width: 250,
        isVisible: true,
        render: (_, { fullName, workerId }) => {
            const url = `${BASE_URL_SERVER}worker/${workerId}`;

            return <LinkCell text={fullName} url={url} />;
        },
        sorter: {
            compare: (a, b) => {
                return getValuesLocaleCompare(a.fullName, b.fullName);
            },
            multiple: 0,
        },
    },
    {
        title: 'На завтра',
        key: 'isOnlineTomorrow',
        width: 100,
        isVisible: true,
        render: (_, { isOnlineTomorrow, workerId }) => {
            return (
                <OnlineTomorrowStatusCell workerId={workerId} isOnlineTomorrow={isOnlineTomorrow} />
            );
        },
        sorter: {
            compare: (a, b) => {
                return sortToNumber(a.isOnlineTomorrow, b.isOnlineTomorrow);
            },
            multiple: 0,
        },
    },
    {
        title: 'Гр-во',
        dataIndex: 'citizenship',
        showSorterTooltip: { title: 'Гражданство' },
        fullTitle: 'Гражданство (Гр-во)',
        key: 'citizenship',
        width: 85,
        render: (citizenship: WorkersReportWorkerItem) => (
            <span title={citizenship.value}>{citizenship.value}</span>
        ),
        sorter: {
            compare: (a, b) => {
                return getValuesLocaleCompare(a.citizenship.value, b.citizenship.value);
            },
            multiple: 0,
        },
    },
    {
        title: 'Договор',
        key: 'contractStatus',
        width: 120,
        render: (_, { contractStatus, workerId }) => {
            const url = `${BASE_URL_SERVER}staff/worker/${workerId}/documents`;

            return <LinkCell url={url} text={contractStatus.toString()} />;
        },

        sorter: {
            compare: (a, b) => {
                return getValuesLocaleCompare(a.contractStatus, b.contractStatus);
            },
            multiple: 0,
        },
    },
    {
        title: 'Регион',
        key: 'zone',
        width: 130,
        isVisible: true,
        render: (_, { zone, workerId }) => {
            return <ZoneSelectCell workerId={workerId} value={zone.value} />;
        },
        sorter: {
            compare: (a, b) => {
                return getValuesLocaleCompare(a.zone.value, b.zone.value);
            },
            multiple: 0,
        },
    },
    {
        title: 'Телефон',
        dataIndex: 'telNumber',
        key: 'telNumber',
        width: 90,
        isVisible: true,
        render: (telNumber: string) => {
            return <span title={telNumber}>{telNumber}</span>;
        },
        sorter: {
            compare: (a, b) => {
                return getValuesLocaleCompare(a.telNumber, b.telNumber);
            },
            multiple: 0,
        },
    },
    {
        title: '',
        key: 'callIcon',
        width: 30,
        isVisible: true,
        isNotEdit: true,
        render: (_, { callIcon, workerId }) => {
            return <CallCell callIcon={callIcon} workerId={workerId} />;
        },
    },
    {
        title: 'Активность',
        dataIndex: 'status',
        key: 'status',
        width: 120,
        isVisible: true,
        render: (status: WorkersReportWorkerStatus) => {
            return <StatusCell status={status} />;
        },
        sorter: {
            compare: (a, b) => {
                return getValuesLocaleCompare(a.status.name, b.status.name);
            },
            multiple: 0,
        },
    },
    {
        title: 'З-к в работе',
        dataIndex: 'unconfirmedCount',
        key: 'unconfirmedCount',
        showSorterTooltip: { title: 'Заявок в работе' },
        fullTitle: 'Заявок в работе (З-к в работе)',
        width: 70,
        sorter: {
            compare: (a, b) => {
                return a.unconfirmedCount - b.unconfirmedCount;
            },
            multiple: 0,
        },
    },
    {
        title: 'Дата след. контакта',
        key: 'plannedContactDay',
        width: 100,
        isVisible: true,
        render: (_, { workerId, plannedContactDay }) => {
            return <DatePickerPlanContactDayCell workerId={workerId} value={plannedContactDay} />;
        },

        sorter: {
            compare: (a, b) => {
                return sortDate(a.plannedContactDay, b.plannedContactDay);
            },
            multiple: 0,
        },
    },
    {
        title: 'Комментарий',
        key: 'lastComment',
        width: 400,
        isVisible: true,
        render: (_, { lastComment, workerId }) => {
            return <CommentCell comment={lastComment} workerId={workerId} />;
        },
    },
    {
        title: 'Тэг',
        key: 'tag',
        width: 105,
        isVisible: true,
        render: (_, { tag, workerId }) => {
            return <TagCell tag={tag} workerId={workerId} />;
        },

        sorter: {
            compare: (a, b) => {
                const valueA = a.tag;
                const valueB = b.tag;

                if (!valueA) {
                    return 1;
                }

                if (!valueB) {
                    return -1;
                }

                return getValuesLocaleCompare(valueA, valueB);
            },
            multiple: 0,
        },
    },
    {
        title: 'Последний выход',
        dataIndex: 'lastTurnoutDate',
        key: 'lastTurnoutDate',
        width: 95,
        sorter: {
            compare: (a, b) => {
                return sortDate(
                    formatToMoment(a.lastTurnoutDate),
                    formatToMoment(b.lastTurnoutDate)
                );
            },
            multiple: 0,
        },
    },
    {
        title: 'Баланс',
        dataIndex: 'balance',
        key: 'balance',
        width: 90,
        render: (balance: string) => {
            return <BalanceCell balance={balance} />;
        },
        sorter: {
            compare: (a, b) => {
                return parseFloat(a.balance) - parseFloat(b.balance);
            },
            multiple: 0,
        },
    },
    {
        title: 'Дата последнего звонка',
        key: 'lastCallDate',
        dataIndex: 'lastCallDate',
        width: 100,
        isVisible: true,
        render: (lastCallDate: Nullable<string>) => {
            return <LastCallDateCell date={lastCallDate} />;
        },
        sorter: {
            compare: (a, b) => {
                const aLastCallDate = a.lastCallDate ? moment(a.lastCallDate, 'YYYY-MM-DD') : null;
                const bLastCallDate = b.lastCallDate ? moment(b.lastCallDate, 'YYYY-MM-DD') : null;

                return sortDate(aLastCallDate, bLastCallDate);
            },
            multiple: 0,
        },
    },
    {
        title: 'Д-й',
        key: 'isBenevolent',
        showSorterTooltip: { title: 'Доброжелательный' },
        fullTitle: 'Доброжелательный (Д-й)',
        width: 60,
        isVisible: true,
        render: (_, { isBenevolent, workerId }) => {
            return (
                <BenevolentStatusCell
                    size='small'
                    isBenevolent={isBenevolent}
                    workerId={workerId}
                />
            );
        },
        sorter: {
            compare: (a, b) => {
                return sortToNumber(a.isBenevolent, b.isBenevolent);
            },
            multiple: 0,
        },
    },
    {
        title: 'Доступность',
        key: 'availability',
        width: 120,
        isVisible: true,
        render: (_, { workerId, availability }) => {
            return (
                <AvailabilityStatusCell
                    size='small'
                    availability={availability}
                    workerId={workerId}
                />
            );
        },
        sorter: {
            compare: (a, b) => {
                return getValuesLocaleCompare(a.availability, b.availability);
            },
            multiple: 0,
        },
    },
    {
        title: 'Готовность',
        key: 'readiness',
        width: 110,
        isVisible: true,
        render: (_, { workerId, readiness }) => {
            return <ReadinessStatusCell size='small' readiness={readiness} workerId={workerId} />;
        },
        sorter: {
            compare: (a, b) => {
                return getValuesLocaleCompare(a.readiness, b.readiness);
            },
            multiple: 0,
        },
    },
    {
        title: 'Н-ть',
        key: 'reliability',
        showSorterTooltip: { title: 'Надежность' },
        fullTitle: 'Надежность (Н-ть)',
        dataIndex: 'reliability',
        width: 60,
        isVisible: true,
        render: (reliability: Nullable<string>) => {
            return <div>{reliability || '-'}</div>;
        },
        sorter: {
            compare: (a, b) => {
                return sortToNumber(a.reliability, b.reliability);
            },
            multiple: 0,
        },
    },
    {
        title: 'П-на Р-я',
        key: 'registrationOk',
        showSorterTooltip: { title: 'Просрочена регистрация' },
        fullTitle: 'Просрочена регистрация (П-на Р-я)',
        dataIndex: 'registrationOk',
        width: 65,
        render: (registrationOk: boolean) => {
            return <RegistrationStatusCell registrationOk={registrationOk} />;
        },
        sorter: {
            compare: (a, b) => {
                return sortToNumber(a.registrationOk, b.registrationOk);
            },
            multiple: 0,
        },
    },
    {
        title: '',
        key: 'action',
        width: 30,
        isNotEdit: true,
        isVisible: true,
        render: (_, { action, workerId }) => {
            return <ActionCell action={action} workerId={workerId} />;
        },
    },
    {
        title: 'Привязка к ТокБанку',
        key: 'talkBankBindStatus',
        showSorterTooltip: { title: 'Привязка к ТокБанку' },
        fullTitle: 'Привязка к ТокБанку',
        dataIndex: 'talkBankBindStatus',
        width: 120,
        render: (talkBankBindStatus: Nullable<TalkBankBindStatus>, { workerId }) => {
            return (
                <TalkBankBindStatusCell
                    talkBankBindStatus={talkBankBindStatus}
                    workerId={workerId}
                />
            );
        },
    },
];

export const workersReportMiniTableConfig: ColumnType<WorkersReportMiniTableData>[] = [
    {
        dataIndex: 'title',
        key: 'title',
    },
    {
        key: 'value',
        width: 200,
        render(_, { title, value, key, workerId }) {
            return <TableMiniCell title={title} value={value} cellKey={key} workerId={workerId} />;
        },
    },
];
