import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';

import { useQuery } from '@hooks';
import { DeliveryParamsType } from '@typings';

export const useDeliverySearchParams = (): DeliveryParamsType => {
    const searchParams = useQuery();
    const [query, setQuery] = useState<URLSearchParams | null>(null);

    useEffect(() => {
        if (!query || query?.toString() !== searchParams.toString()) {
            setQuery(searchParams);
        }
    }, [searchParams]);

    const first_day = new Date();
    const last_day = new Date();

    const params = useMemo(() => {
        const data = query || searchParams;

        return {
            first_day: data.get('first_day') || moment(first_day).format('DD.MM.YYYY'),
            last_day: data.get('last_day') || moment(last_day).format('DD.MM.YYYY'),
            search_text: data.get('search_text') || undefined,
            operator: data.get('operator') || undefined,
            customer: data.get('customer') || undefined,
            customer_resolution: data.get('customer_resolution') || undefined,
            can_merge: data.get('can_merge') || undefined,
            in_progress: data.get('in_progress') || undefined,
            location: data.get('location') || undefined,
            unprofitable: data.get('unprofitable') || undefined,
            zone: data.get('zone') || undefined,
        };
    }, [query]);

    return params;
};
