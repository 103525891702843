import { useMemo, useState } from 'react';

import { AttachOnColumnsChange, CustomColumn } from '@typings';

import { useFilters } from './useFilters';
import { useSort } from './useSort';

interface Observer {
    onColumnsFilterChange(columnIndex: number): void;
}

const observers: Observer[] = [];

const notifyFilterChange = (columnIndex: number) => {
    for (const observer of observers) {
        observer.onColumnsFilterChange(columnIndex);
    }
};

const attachOnColumnsFilterChange: AttachOnColumnsChange = (observer) => {
    observers.push(observer);
};

export const useColumns = <T>(name: string) => {
    const [columns, setColumns] = useState<CustomColumn<T>[]>([]);
    const onColumnSortChange = useSort({ setColumns });
    const { filterListColumns, onColumnViewChange, initializeFilters } = useFilters({
        columns,
        notifyFilterChange,
        setColumns,
        name,
    });

    const filtredColumns = useMemo(() => {
        return columns?.filter(({ isVisible }) => {
            return isVisible;
        });
    }, [columns]);

    return {
        filterListColumns,
        filtredColumns,
        onColumnViewChange,
        attachOnColumnsFilterChange,
        onColumnSortChange,
        initializeColumns: initializeFilters,
    };
};
