import { FC } from 'react';
import { Form, Input } from 'antd';

interface Props {
    showLabels: boolean;
}

export const Amount: FC<Props> = ({ showLabels }) => (
    <Form.Item
        key='amount'
        name='amount'
        style={{ marginBottom: 0 }}
        label={showLabels ? 'Сумма' : undefined}
        rules={[{ required: true, message: 'Это поле необходимо!' }]}
    >
        <Input placeholder='Сумма' style={{ width: '100px' }} type='number' min='0' step='0.01' />
    </Form.Item>
);
