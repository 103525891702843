import { DeliveryFilterState, DeliveryParamsType } from '@typings';

export const mapperDeliveryParamsToDeliveryState = (
    params: DeliveryParamsType
): DeliveryFilterState => {
    const getFlag = (flag: string | undefined) => Boolean(flag) || undefined;

    return {
        ...params,
        can_merge: getFlag(params.can_merge),
        unprofitable: getFlag(params.unprofitable),
        in_progress: getFlag(params.in_progress),
    };
};

export const concatParamsToQuery = (params: Partial<DeliveryFilterState>): string => {
    const query = new URLSearchParams();

    for (const [key, param] of Object.entries(params)) {
        if (param) {
            query.set(key, param.toString());
        }
    }
    return `?${query}`;
};
