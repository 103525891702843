import { FC } from 'react';
import { Table } from 'antd';

import { DeliveryRequestDetail, Nullable } from '@typings';

import { generateColumns } from './config';
import { mapperRequestToTableData } from './utils';

import styles from './DeliveryWorkersOnAddressTable.module.scss';
interface Props {
    requestDetail: Nullable<DeliveryRequestDetail>;
}

export const DeliveryWorkersOnAddressTable: FC<Props> = ({ requestDetail }) => {
    if (!requestDetail) {
        return null;
    }

    const dataSource = mapperRequestToTableData(requestDetail);
    const columns = generateColumns({
        workers: requestDetail.workers,
        item_workers: requestDetail.item_workers,
    });

    return (
        <Table
            className={styles.table}
            pagination={false}
            columns={columns}
            dataSource={dataSource}
        />
    );
};
