import { FC, useContext } from 'react';

import { setWorkerZone } from '@api';
import { API_URI } from '@constants';
import { WorkersReportTableContext } from '@providers/workersReportTable';
import { WorkersReportWorkerId } from '@typings';
import { mapperWorkersReportWorkerItem } from '@utils/mapperWorkersReport';
import { showError } from '@utils/show_error';

import { SelectCell } from '.';

interface Props {
    value: string;
    workerId: WorkersReportWorkerId;
}

export const ZoneSelect: FC<Props> = ({ value, workerId }) => {
    const { updateWorker } = useContext(WorkersReportTableContext);

    const onZoneEdit = (zone: string) => {
        setWorkerZone({
            onSuccess({ data }) {
                if (data.zone) {
                    updateWorker(workerId, { zone: mapperWorkersReportWorkerItem(data.zone) });
                }
            },
            onError(error) {
                showError(error.message);
            },
            params: {
                worker: workerId,
                zone: zone.trim(),
            },
        });
    };
    return (
        <SelectCell
            text={value}
            name='zone'
            autocompleteUrl={API_URI.AUTOCOMPLETE_ZONE}
            style={{ width: 100 }}
            onChange={onZoneEdit}
        />
    );
};
