interface IGetDate {
    a: Date;
    b: Date;
}

export const getDate = (a: string | void, b: string | void): IGetDate => {
    if (a === undefined) {
        a = '01.01.1990';
    }
    if (b === undefined) {
        b = '01.01.1990';
    }

    const [dayA, monthA, yearA] = a.split('.');
    const [dayB, monthB, yearB] = b.split('.');

    const resDateA = new Date(+yearA, +monthA - 1, +dayA);
    const resDateB = new Date(+yearB, +monthB - 1, +dayB);

    return { a: resDateA, b: resDateB };
};
