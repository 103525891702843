import { FC, memo } from 'react';
import { Spin } from 'antd';

interface Props {
    isFetching: boolean;
}

export const SpinnerController: FC<Props> = memo(({ isFetching }) => {
    if (!isFetching) {
        return null;
    }

    return <Spin key='spin' size='small' spinning={true} style={{ paddingRight: '8px' }} />;
});
