import { FC } from 'react';
import { Table, TableProps } from 'antd';

import { WorkersReportTableProvider } from '@providers/workersReportTable';
import { WorkersReportMiniTableData, WorkersReportUpdateWorker } from '@typings';

import { workersReportMiniTableConfig } from './configs';

import styles from './workersReportTable.module.scss';

interface Props extends Pick<TableProps<WorkersReportMiniTableData>, 'dataSource'> {
    updateWorkerData: WorkersReportUpdateWorker;
    registrationOk: boolean;
}

export const WorkersReportMiniTable: FC<Props> = ({
    dataSource,
    updateWorkerData,
    registrationOk,
}) => {
    const getRowClassName = () => {
        if (!registrationOk) {
            return styles.reqistration_not_ok_color;
        }

        return '';
    };

    return (
        <WorkersReportTableProvider updateWorker={updateWorkerData}>
            <Table<WorkersReportMiniTableData>
                rowClassName={getRowClassName}
                size='small'
                pagination={false}
                showHeader={false}
                dataSource={dataSource}
                columns={workersReportMiniTableConfig}
            />
        </WorkersReportTableProvider>
    );
};
