import { FC, memo } from 'react';
import moment from 'moment';

import { DATE_FORMAT } from '@constants';
import { DeliveryRequestCellProps } from '@typings';

import { TextCell } from '.';

export const Date: FC<DeliveryRequestCellProps> = memo(({ onCommit, isEditing, rowData }) => {
    const date = rowData.date;

    if (!date) {
        return null;
    }

    const formattedDate = moment(date).format(DATE_FORMAT);

    return <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={formattedDate} />;
});
