import React from 'react';
import { AxiosError } from 'axios';
import clsx from 'clsx';

import {
    DashboardPhotoActionsView,
    DashboardPhotoDiscrepancyCheck,
    DashboardPhotoIsSuspicious,
    DashboardPhotoItemType,
    DashboardPhotoListItem,
    SetState,
} from '@typings';
import { showError } from '@utils/show_error';

import { reloadPhotoList } from '../../modal';
import { AcceptButton, ButtonsNew, ButtonsSuspicions, CancelButton } from '../ActionsButton';

import styles from './actionContainer.module.scss';

interface Props extends Pick<DashboardPhotoListItem, 'request'> {
    isSuspicious: DashboardPhotoIsSuspicious;
    discrepancyCheck: DashboardPhotoDiscrepancyCheck;
    type: DashboardPhotoItemType;
    setShow?: SetState<boolean>;
    show?: boolean;
    status: string;
    photoId: number;
    itemId: number;
    workerId: number;
    viewType: DashboardPhotoActionsView;
}

const onError = (error: Error) => {
    showError(
        (error as AxiosError).response?.data.detail ||
            (error as AxiosError).response?.data.message ||
            error.message
    );
};

export const PhotoActionContainer: React.FC<Props> = ({
    show = true,
    setShow,
    status,
    request,
    photoId,
    type,
    itemId,
    isSuspicious,
    workerId,
    discrepancyCheck,
    viewType,
    children,
}) => {
    const isVisibleButtonsSuspicions = type !== 'finish' && (!!isSuspicious || !!discrepancyCheck);

    const onSuccess = () => {
        reloadPhotoList();
        setShow?.(false);
    };

    const renderButton = () => {
        switch (status) {
            case 'new':
                return (
                    <ButtonsNew
                        isVisible={!isVisibleButtonsSuspicions}
                        itemId={itemId}
                        onSuccess={onSuccess}
                        onError={onError}
                        requestId={request.id}
                        workerId={workerId}
                        photoId={photoId}
                        type={type}
                        viewType={viewType}
                    />
                );
            case 'rejected':
                return (
                    <AcceptButton
                        viewType={viewType}
                        onSuccess={onSuccess}
                        onError={onError}
                        requestId={request.id}
                        photoId={photoId}
                        type={type}
                    />
                );
            case 'confirmed':
                return (
                    <CancelButton
                        viewType={viewType}
                        onError={onError}
                        onSuccess={onSuccess}
                        photoId={photoId}
                        requestId={request.id}
                        type={type}
                    />
                );
            default:
                return null;
        }
    };

    if (!show) {
        return null;
    }

    const mainStyles = clsx({
        [styles.main]: viewType === 'button',
    });
    const containerStyles = clsx({
        [styles.container]: viewType === 'button',
    });

    return (
        <div className={mainStyles}>
            <div className={containerStyles}>
                {renderButton()}

                <ButtonsSuspicions
                    viewType={viewType}
                    isVisible={isVisibleButtonsSuspicions}
                    itemId={itemId}
                    onSuccess={onSuccess}
                    onError={onError}
                    requestId={request.id}
                    workerId={workerId}
                    isSuspicious={isSuspicious}
                    discrepancyCheck={discrepancyCheck}
                />

                {children}
            </div>
        </div>
    );
};
