import { useCallback, useEffect, useReducer, useRef } from 'react';
import { AxiosError } from 'axios';
import _ from 'lodash';

import { fetchAutocompleteData } from '@api';
import { API_URI } from '@constants';
import {
    AutocompleteResponse,
    UseAutocompleteActions,
    UseAutocompleteDataActionsType,
    UseAutocompleteState,
} from '@typings';
import { showError } from '@utils/show_error';

interface FetchNewDataParams {
    url: API_URI;
    q: string;
    page: number;
    forward?: object;
    id?: string;
    onSuccess(data: AutocompleteResponse): void;
}

const searchErrorMessage = 'Не найдено.';

const fetchNewData = async ({ onSuccess, ...params }: FetchNewDataParams) => {
    fetchAutocompleteData({
        params,
        onError(error) {
            let message = 'Unknown error';

            if ((error as AxiosError).response?.data.detail === searchErrorMessage) {
                return;
            }

            if (typeof error === 'string') {
                message = error;
            }

            if (error instanceof Error) {
                message = error.message;
            }

            showError(message);
        },
        onSuccess(data) {
            if (data.status === 200) {
                return onSuccess(data.data);
            }

            throw new Error(`A request came with a code ${data.status}, not 200`);
        },
    });
};

interface Params {
    url: API_URI;
    forward?: object;
}

const autocompleteDataInitialState = {
    page: 1,
    more: true,
    searchData: '',
    data: null,
};
type OnSuccess = (data: AutocompleteResponse) => void;

const audocompleteDataReducer = (
    state: UseAutocompleteState,
    action: UseAutocompleteActions
): UseAutocompleteState => {
    switch (action.type) {
        case UseAutocompleteDataActionsType.SEARCH_UPDATE:
            return { ...state, searchData: action.data };
        case UseAutocompleteDataActionsType.PAGE_UP:
            return { ...state, page: state.page + 1 };
        case UseAutocompleteDataActionsType.DATA_UPDATE:
            return { ...state, data: action.data };
        case UseAutocompleteDataActionsType.STATE_UPDATE:
            return { ...state, ...action.data };
        case UseAutocompleteDataActionsType.RESET:
            return autocompleteDataInitialState;
    }
};

export const useAutocompleteData = ({ url, forward }: Params) => {
    const prevForward = useRef<object | undefined>(undefined);
    const [{ page, more, data, searchData }, dispatch] = useReducer(
        audocompleteDataReducer,
        autocompleteDataInitialState
    );

    const onSuccess: OnSuccess = useCallback((data) => {
        const state = {
            more: data.pagination.more,
            data: data.results,
        };

        dispatch({
            type: UseAutocompleteDataActionsType.STATE_UPDATE,
            data: state,
        });
    }, []);

    const onAutocompleteUpdate = useCallback(
        (force: boolean = false) => {
            if (_.isEqual(prevForward.current, forward) && !force && forward) {
                return;
            }
            prevForward.current = forward;

            dispatch({
                type: UseAutocompleteDataActionsType.RESET,
            });

            fetchNewData({ forward, onSuccess, page: 1, q: '', url });
        },
        [forward, onSuccess]
    );

    useEffect(() => {
        onAutocompleteUpdate();
    }, [onAutocompleteUpdate]);

    const getOptionById = (id: string) => {
        fetchNewData({
            onSuccess(data) {
                dispatch({
                    type: UseAutocompleteDataActionsType.DATA_UPDATE,
                    data: data.results,
                });
            },
            forward,
            page: 1,
            q: '',
            url: `${url}?id=${id}` as API_URI,
        });
    };

    const onAutocompleteSearchChange = (searchData: string, onSuccess: OnSuccess) => {
        dispatch({ type: UseAutocompleteDataActionsType.SEARCH_UPDATE, data: searchData });

        fetchNewData({ forward, onSuccess, page: 1, q: searchData, url });
    };

    const onPageUp = () => {
        if (more) {
            dispatch({ type: UseAutocompleteDataActionsType.PAGE_UP });

            fetchNewData({ forward, onSuccess, page: page + 1, q: searchData, url });
        }
    };

    return { onAutocompleteSearchChange, onPageUp, onAutocompleteUpdate, data, getOptionById };
};
