import { FC } from 'react';
import moment from 'moment';

import styles from './footerPhotoElement.module.scss';

interface Props {
    workerName: string;
    timestamp: Date;
}
export const FooterPhotoElement: FC<Props> = ({ timestamp, workerName, children }) => {
    const timeValue = moment(timestamp).format('HH:mm');

    return (
        <div className={styles.footer}>
            <div>{workerName}</div>
            {children}
            <div className={styles.time}>{timeValue}</div>
        </div>
    );
};
