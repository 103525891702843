import { FC, memo, useContext } from 'react';
import { Tooltip } from 'antd';

import { DeliveryContext } from '@providers/delivery';
import { DeliveryColumn, DeliveryRequestItemKeys, DeliveryRequestOrExpanded } from '@typings';
import { getRequestPk, textContentInner } from '@utils/delivery';
import { copyTableRowData } from '@utils/tableCell';

interface Props {
    rowData: DeliveryRequestOrExpanded;
}

const getTooltipColumns = (columns: DeliveryColumn[]) => {
    const BANNED_KEYS = ['timesheet_photo'];

    return columns.filter((item) => !BANNED_KEYS.includes(item.key));
};

export const TooltipControler: FC<Props> = memo(({ rowData }) => {
    const { columns } = useContext(DeliveryContext);

    const onTooltipClick = () => {
        copyTableRowData(columns, rowData);
    };

    return (
        <Tooltip
            key='tooltip'
            placement='right'
            title={<TooltipTable columns={columns} rowData={rowData} />}
        >
            <span onClick={onTooltipClick} style={{ padding: '4px' }}>
                {getRequestPk(rowData)}
            </span>
        </Tooltip>
    );
});

interface TooltipTableProps {
    columns: DeliveryColumn[];
    rowData: DeliveryRequestOrExpanded;
}

const TooltipTable: FC<TooltipTableProps> = ({ columns, rowData }) => {
    const rowsEl = getTooltipColumns(columns).map(({ title, key }) => {
        return (
            <tr key={key}>
                <th>{title}</th>
                <td>{textContentInner(rowData, key as DeliveryRequestItemKeys)}</td>
            </tr>
        );
    });

    return (
        <table className='table table-hover rh-table' key='table'>
            <tbody>{rowsEl}</tbody>
        </table>
    );
};
