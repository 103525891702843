import { FC, memo, useContext, useState } from 'react';

import { PlusCircleTwoTone } from '@ant-design/icons';
import { DeliveryAttachRequestModal } from '@components/modals';
import { DeliveryContext } from '@providers/delivery';
import { openModal } from '@store/modal';
import { DeliveryCellProps, DeliveryOnAddRequest, RenderModal } from '@typings';
import { copyTableRowData, getRequestPk, isUnwrappedRequest } from '@utils';

import { ButtonUnwrap } from './ButtonUnwrap';
import { ConfirmationRequestController } from './ConfirmationRequestController';
import { SpinnerController } from './SpinnerController';
import { TooltipControler } from './TooltipControler';

type Props = Pick<DeliveryCellProps, 'rowData'>;

export const RowController: FC<Props> = memo(({ rowData }) => {
    const { columns, onAddRequest, fetchData, toggleLoading } = useContext(DeliveryContext);

    const [isFetching, setIsFetching] = useState(false);

    if (isUnwrappedRequest(rowData)) {
        if (rowData.expanded) {
            const onControllerClick = () => {
                copyTableRowData(columns, rowData);
            };

            return (
                <div style={{ paddingLeft: '20px' }} onClick={onControllerClick}>
                    {rowData.parent_pk}
                </div>
            );
        }

        return null;
    }

    const onModalOpen = () => {
        openModal(
            renderModal({ onAddRequest, toggleLoading, fetchData, request: getRequestPk(rowData) })
        );
    };

    return (
        <>
            <SpinnerController isFetching={isFetching} />
            <ButtonUnwrap rowData={rowData} />
            <TooltipControler rowData={rowData} />
            <ConfirmationRequestController rowData={rowData} setIsFetching={setIsFetching} />
            <PlusCircleTwoTone key='plus' style={{ paddingLeft: 4 }} onClick={onModalOpen} />
        </>
    );
});

interface RenderModalParams {
    request?: number;
    onAddRequest: DeliveryOnAddRequest;
    fetchData: () => void;
    toggleLoading: (isLoading: boolean) => void;
}
const renderModal =
    ({ onAddRequest, request, fetchData, toggleLoading }: RenderModalParams): RenderModal =>
    (isOpen) => {
        return (
            <DeliveryAttachRequestModal
                fetchData={fetchData}
                toggleLoading={toggleLoading}
                isOpen={isOpen}
                onAddRequest={onAddRequest}
                request={request}
            />
        );
    };
