import { FC, memo } from 'react';
import { Marker as UIMarker } from 'react-leaflet';
import { Icon } from 'leaflet';

import {
    DeliveryMapRequestMarkerItem,
    DeliveryMapRequestMarkerPosition,
    DeliveryMapRequestMarkerType,
    DeliveryMapRequestOnMarkerClick,
} from '@typings';

interface Props {
    markerItem: DeliveryMapRequestMarkerItem;
    markerType: DeliveryMapRequestMarkerType;
    markerIcon: Icon;
    markerPosition: DeliveryMapRequestMarkerPosition;
    onMarkerClick: DeliveryMapRequestOnMarkerClick;
}

export const Marker: FC<Props> = memo(
    ({ markerIcon, markerItem, onMarkerClick, markerType, children, markerPosition }) => {
        const onClick = () => {
            onMarkerClick(markerType, markerItem);
        };

        return (
            <UIMarker
                position={markerPosition}
                icon={markerIcon}
                eventHandlers={{ click: onClick }}
            >
                {children}
            </UIMarker>
        );
    }
);
