import { FC, useContext, useState } from 'react';

import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { bindWorkerToTalkBank } from '@api';
import { WorkersReportTableContext } from '@providers';
import { Nullable, TalkBankBindStatus, WorkersReportWorkerId } from '@typings';
import { formatToTalkBankBindStatus } from '@utils/mapperWorkersReport';
import { showError } from '@utils/show_error';

interface Props {
    talkBankBindStatus: Nullable<TalkBankBindStatus>;
    workerId: WorkersReportWorkerId;
}

export const TalkBankBindStatusCell: FC<Props> = ({ talkBankBindStatus, workerId }) => {
    const { updateWorker } = useContext(WorkersReportTableContext);

    const [isLoading, setIsloading] = useState(false);

    let components: Array<JSX.Element> = [];

    if (talkBankBindStatus === null) {
        components.push(
            <span
                key='text'
                style={{
                    margin: '5px',
                }}
            >
                ---
            </span>
        );
    } else {
        if (talkBankBindStatus.isBound) {
            components.push(<CheckCircleOutlined key='icon' style={{ color: 'green' }} />);
        } else {
            components.push(<CloseCircleOutlined key='icon' style={{ color: 'red' }} />);
        }
        components.push(
            <span
                key='text'
                style={{
                    width: '100px',
                    margin: '5px',
                    overflow: 'hidden',
                    display: 'inline-block',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
                title={talkBankBindStatus.operationResultDescription}
            >
                {talkBankBindStatus.operationResultDescription}
            </span>
        );
    }

    const onSyncClick = () => {
        if (isLoading) {
            return;
        }

        setIsloading(true);
        bindWorkerToTalkBank({
            params: {
                worker: workerId,
            },
            onSuccess(response) {
                setIsloading(false);

                const status = formatToTalkBankBindStatus(response.data);
                updateWorker(workerId, { talkBankBindStatus: status });
            },
            onError(error) {
                setIsloading(false);

                showError(error.message);
            },
        });
    };

    components.push(
        <SyncOutlined
            key='action'
            onClick={onSyncClick}
            style={{ color: '#2db7f5' }}
            spin={isLoading}
        />
    );

    return <>{components}</>;
};
