import { FC, memo } from 'react';
import _ from 'lodash';

import { fetchRequestDetail } from '@api';
import { DeliveryWorkersOnAddressModal, DeliveryWorkerStatusesModal } from '@components/modals';
import { openModal } from '@store/modal';
import {
    DeliveryRequestDetailResponse,
    DeliveryRequestOrExpanded,
    Nullable,
    RenderModal,
    Response,
    SetRowWithFormat,
    SetState,
} from '@typings';
import { getRequestPk, isMergedRequest, isUnwrappedRequest, textContent } from '@utils/delivery';
import { normalizeDeliveryRequest } from '@utils/standartDataToNewBackend';

interface Props {
    rowData: DeliveryRequestOrExpanded;
    setRequest: SetState<DeliveryRequestOrExpanded>;
    setRowWithFormat: SetRowWithFormat;
}

interface GetModalPropsArgs {
    rowData: DeliveryRequestOrExpanded;
}

const getItemPk = ({ rowData }: GetModalPropsArgs) => {
    if (isUnwrappedRequest(rowData)) {
        return rowData.tableId;
    }

    if (isMergedRequest(rowData)) {
        return rowData.items[0].pk;
    }

    return null;
};

export const Worker: FC<Props> = memo(({ rowData, setRequest, setRowWithFormat }) => {
    const onSuccessGetData = ({ data }: Response<DeliveryRequestDetailResponse>) => {
        const { request } = data.data;
        const normalizedRequest = normalizeDeliveryRequest(request);

        if (!isUnwrappedRequest(rowData)) {
            return setRequest(normalizedRequest);
        }

        const requestItem = normalizedRequest.items.find(({ pk }) => pk === rowData.tableId);

        setRequest((prev) => {
            const changedItems = prev?.items?.map((el) => {
                if (el.pk === requestItem?.pk) {
                    return requestItem;
                }
                return el;
            });

            return {
                ...prev,
                items: changedItems,
            };
        });
    };

    const assignedWorkersList = _.flatten(rowData.items.map((item) => item.assigned_workers))
        .filter((item, key, arr) => arr.map((item) => item.id).indexOf(item.id) === key)
        .map((item) => item.name)
        .join(',');

    const textData = isMergedRequest(rowData)
        ? textContent(rowData, 'assigned_workers')
        : assignedWorkersList;

    const onCancel = async () => {
        fetchRequestDetail({
            onSuccess: onSuccessGetData,
            params: {
                id: Number(getRequestPk(rowData)),
            },
        });
    };

    const onModalOpen = () => {
        openModal(
            renderModal({
                isMergedRequest: isMergedRequest(rowData),
                requestPk: getRequestPk(rowData),
                itemPk: getItemPk({ rowData }),
                setRowWithFormat,
                onCancel,
            })
        );
    };

    return (
        <div style={{ height: '10px' }} onClick={onModalOpen}>
            {textData}
        </div>
    );
});

interface RenderModalParams {
    setRowWithFormat: SetRowWithFormat;
    onCancel: () => void;
    requestPk: number;
    itemPk: Nullable<number>;
    isMergedRequest: boolean;
}

const renderModal =
    ({
        itemPk,
        onCancel,
        requestPk,
        isMergedRequest,
        setRowWithFormat,
    }: RenderModalParams): RenderModal =>
    (isOpen) => {
        if (isMergedRequest) {
            return (
                <DeliveryWorkerStatusesModal
                    isOpen={isOpen}
                    item={itemPk!!}
                    onCancel={onCancel}
                    request={requestPk}
                />
            );
        }

        return (
            <DeliveryWorkersOnAddressModal
                setRowWithFormat={setRowWithFormat}
                isOpen={isOpen}
                onCancel={onCancel}
                requestPk={requestPk}
            />
        );
    };
