import { FC, memo } from 'react';
import Modal from 'antd/lib/modal/Modal';

import { closeModal } from '@store/modal';
import { FilterListColumns, OnColumnViewChange } from '@typings';

import { FilterCheckbox } from './FilterCheckbox';

interface Props {
    isOpen: boolean;
    filterListColumns: FilterListColumns;
    onColumnViewChange: OnColumnViewChange;
}

export const ColumnsViewController: FC<Props> = memo(
    ({ isOpen, onColumnViewChange, filterListColumns }) => {
        const checkboxListEl = filterListColumns?.map(({ isVisible, title, key }) => {
            return (
                <FilterCheckbox
                    key={key}
                    isChecked={!!isVisible}
                    title={title}
                    columnKey={key}
                    onChange={onColumnViewChange}
                />
            );
        });

        const onModalClose = () => {
            closeModal();
        };

        return (
            <Modal
                title='Список колонок'
                visible={isOpen}
                onCancel={onModalClose}
                onOk={onModalClose}
                destroyOnClose={true}
            >
                {checkboxListEl}
            </Modal>
        );
    }
);
