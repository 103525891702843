import { FC, useCallback, useEffect, useState } from 'react';
import { RouteProps } from 'react-router';
import { Button, Col, Divider, Row, Space, Spin, Table } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import { ColumnsType } from 'antd/es/table';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';

import { fetchRequestCount } from '@api';
import CustomDatePickerRange from '@components/CustomDatePickerRange';
import RemoteSelect from '@components/RemoteSelect';
import { API_URI, BASE_URL_SERVER_API } from '@constants';
import {
    CustomDatePickerRangeValue,
    Nullable,
    RequestCountState,
    RequestCountStateItem,
} from '@typings';

import styles from './RequestsCount.module.scss';

type ReportsCountState = {
    operator: string | null;
    first_day: string;
    last_day: string;
    score: boolean;
    score_v2: boolean;
    score_v3: boolean;
    no_locations: boolean;
};

type ReportsCountProps = RouteProps;

interface Columns {
    key: string;
    id: string;
}

const RequestsCount: FC<ReportsCountProps> = ({ location }) => {
    const params = new URLSearchParams(location?.search);
    const initialState: ReportsCountState = {
        operator: params.get('operator'),
        first_day: params.get('first_day') || moment().format('DD.MM.YYYY'),
        last_day: params.get('last_day') || moment().add('day').format('DD.MM.YYYY'),
        score: false,
        score_v2: false,
        score_v3: false,
        no_locations: false,
    };

    const [data, setData] = useState<Nullable<RequestCountState>>(null);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(initialState);

    const onChangeData = useCallback((data: CustomDatePickerRangeValue) => {
        setState((state: ReportsCountState) => ({ ...state, ...data }));
    }, []);

    const onChangeOperator = useCallback((value: string | string[]) => {
        setState((state: ReportsCountState) => ({ ...state, operator: value.toString() }));
    }, []);

    const getTable = () => {
        if (!data) {
            return;
        }

        const columns: ColumnsType<Columns> = Object.keys(data).map((customer, i) => ({
            title: customer,
            key: i,
            dataIndex: `item_${customer}`,
        }));
        columns.unshift({
            title: '#',
            key: -1,
            dataIndex: 'id',
            fixed: 'left',
        });
        const statuses = [
            ['Готовых', 'finished_count'],
            ['Отмен с оплатой', 'cancelled_with_payment_count'],
            ['Отмен', 'cancelled_count'],
            ['Просрочено', 'overdue_count'],
        ];
        let tableData: Columns[] = statuses.map((item) => ({
            id: item[0],
            key: item[1],
        }));

        Object.entries(data).forEach(([customer, values]: [string, RequestCountStateItem]) => {
            tableData = tableData.map((item) => ({
                ...item,
                [`item_${customer}`]: values[item.key as keyof RequestCountStateItem],
            }));
        });

        return (
            <Table
                columns={columns}
                dataSource={tableData}
                scroll={{ x: true }}
                pagination={{ hideOnSinglePage: true }}
            />
        );
    };

    const handleOnChangeScores = useCallback((event: CheckboxChangeEvent) => {
        setState((state: ReportsCountState) => ({
            ...state,
            ...{
                score: event.target.checked,
                score_v2: false,
                score_v3: false,
            },
        }));
    }, []);

    const handleOnChangeScores_v2 = useCallback((event: CheckboxChangeEvent) => {
        setState((state: ReportsCountState) => ({
            ...state,
            ...{
                score: false,
                score_v2: event.target.checked,
                score_v3: false,
            },
        }));
    }, []);

    const handleOnChangeScores_v3 = useCallback((event: CheckboxChangeEvent) => {
        setState((state: ReportsCountState) => ({
            ...state,
            ...{
                score: false,
                score_v2: false,
                score_v3: event.target.checked,
            },
        }));
    }, []);

    const handleOnChangeNoLocations = useCallback((event: CheckboxChangeEvent) => {
        setState((state: ReportsCountState) => ({
            ...state,
            ...{ no_locations: event.target.checked },
        }));
    }, []);

    const updateData = useCallback(() => {
        setLoading(true);
        fetchRequestCount({
            params: state,
            onSuccess({ data }) {
                setData(data.data);
                setLoading(false);
            },
            onError() {
                setLoading(false);
            },
        });
    }, [state]);

    useEffect(() => {
        updateData();
    }, []);

    const calls_report_url = `${BASE_URL_SERVER_API}delivery/calls_report?first_day=${state.first_day}&last_day=${state.last_day}`;

    return (
        <>
            <Row>
                <Col>
                    <Space>
                        <span>
                            <a href={calls_report_url}>Отчет о звонках</a>
                        </span>
                        <div className={styles.data_form_wrapper}>
                            <CustomDatePickerRange.Form
                                noStyle
                                name='datepicker'
                                first_day={state.first_day}
                                last_day={state.last_day}
                                placeholder={['С', 'По']}
                                onChange={onChangeData}
                            />
                        </div>
                        <RemoteSelect.Form
                            url={API_URI.AUTOCOMPLETE_OPERATOR}
                            name='operator'
                            placeholder='Оператор'
                            onChange={onChangeOperator}
                            externalForward={{
                                first_day: state.first_day,
                                last_day: state.last_day,
                            }}
                        />
                        <Checkbox
                            defaultChecked={state.score}
                            checked={state.score}
                            onChange={handleOnChangeScores}
                        >
                            Очки, а не кол-во
                        </Checkbox>
                        <Checkbox
                            defaultChecked={state.score_v2}
                            checked={state.score_v2}
                            onChange={handleOnChangeScores_v2}
                        >
                            Очки v2 (с объединением)
                        </Checkbox>
                        <Checkbox
                            defaultChecked={state.score_v3}
                            checked={state.score_v3}
                            onChange={handleOnChangeScores_v3}
                        >
                            Очки v3 (без объединения)
                        </Checkbox>
                        <Checkbox onChange={handleOnChangeNoLocations}>Без филиалов</Checkbox>
                        <Button type='primary' onClick={updateData}>
                            Обновить
                        </Button>
                    </Space>
                </Col>
            </Row>

            <Divider />

            <Spin spinning={loading}>
                <Row>
                    <Col>{getTable()}</Col>
                </Row>
            </Spin>
        </>
    );
};

export default RequestsCount;
