import { FC, useContext } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { updateWorkerRating } from '@api';
import { WorkersReportTableContext } from '@providers/workersReportTable';
import { WorkersReportWorkerId, WorkersReportWorkerReadiness } from '@typings';
import { mapperWorkersReportWorkerUpdateRatingToDetail } from '@utils/mapperWorkersReport';

import { Select } from './Select';

import styles from './cells.module.scss';

interface Props {
    readiness: WorkersReportWorkerReadiness;
    workerId: WorkersReportWorkerId;
    size?: SizeType;
    bgColor?: string;
}

const options = [
    {
        label: 'Готов завтра',
        value: 'tomorrow',
    },
    {
        label: 'Готов в др. день',
        value: 'another_day',
    },
    {
        label: 'Не готов',
        value: 'not_ready',
    },
    {
        label: 'Неизвестно',
        value: 'unknown',
    },
];

const textList: Record<WorkersReportWorkerReadiness, string> = {
    another_day: 'Готов в др. день',
    not_ready: 'Не готов',
    tomorrow: 'Готов завтра',
    unknown: 'Неизвестно',
};

export const ReadinessStatus: FC<Props> = ({ readiness, workerId, bgColor, size = 'small' }) => {
    const { updateWorker } = useContext(WorkersReportTableContext);

    const onReadinessChange = (readinessValue: WorkersReportWorkerReadiness) => {
        updateWorkerRating({
            params: {
                worker: workerId,
                readiness: readinessValue,
            },
            onSuccess({ data }) {
                updateWorker(workerId, mapperWorkersReportWorkerUpdateRatingToDetail(data));
            },
        });
    };

    return (
        <div className={styles.custom_color_cell} style={{ background: bgColor }}>
            <Select
                name='readiness'
                text={textList[readiness]}
                size={size}
                className={styles.select}
                onChange={onReadinessChange}
                value={readiness}
                options={options}
            />
        </div>
    );
};
