import { FC, memo } from 'react';

import { TABLE_METRO_COLORS } from '@constants';
import { DeliveryCellProps } from '@typings';
import { isMergedRequest } from '@utils/delivery';

import { RequestItemCell } from '..';

import styles from './styles.module.scss';

export const MetroName: FC<DeliveryCellProps> = memo(({ isEditing, onCommit, rowData }) => {
    const { metro_line, region } = rowData.items?.[0] || {};

    const metroName = `${region}_${metro_line}` as keyof typeof TABLE_METRO_COLORS;

    if (!isMergedRequest(rowData) || !metro_line) {
        return null;
    }
    return (
        <div
            className={styles.main}
            style={{
                background: TABLE_METRO_COLORS[metroName],
            }}
        >
            <RequestItemCell
                cellKey='metro_name'
                isEditing={isEditing}
                onCommit={onCommit}
                rowData={rowData}
            />
        </div>
    );
});
