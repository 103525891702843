import { FC } from 'react';
import { Form, Input } from 'antd';
import { FormProps } from 'antd/es/form';

import { ExpensesProviderDetail, Nullable } from '@typings';
import {
    GetProviderFormFinishValues,
    GetProviderFormValues,
} from '@typings/components/getProviderForm';

export interface ProviderMiniFormProps extends FormProps {
    initial: Nullable<ExpensesProviderDetail>;
    onSubmit: (values: GetProviderFormFinishValues) => void;
}

export const GetProviderForm: FC<ProviderMiniFormProps> = ({ initial, onSubmit, ...props }) => {
    const onFinish = (values: GetProviderFormValues) => {
        if (initial) {
            onSubmit({ ...values, pk: initial.pk });
        }

        onSubmit(values);
    };

    return (
        <Form
            id={props.id}
            labelCol={{ span: 6, offset: 0 }}
            wrapperCol={{ span: 10, offset: 0 }}
            onFinish={onFinish}
            initialValues={{ name: initial?.name, tax_code: initial?.tax_code }}
        >
            <Form.Item
                key='name'
                name='name'
                label='Название'
                style={{ marginBottom: 10 }}
                rules={[{ required: true, message: 'Это поле необходимо!' }]}
            >
                <Input placeholder='Название поставщика'></Input>
            </Form.Item>

            <Form.Item key='tax_code' name='tax_code' label='ИНН' style={{ marginBottom: 10 }}>
                <Input placeholder='ИНН'></Input>
            </Form.Item>
        </Form>
    );
};
