import { FC, memo } from 'react';
import { Spin } from 'antd';

import { TableCellColumn } from '@typings';

interface Props {
    column: TableCellColumn;
    isFetching: boolean;
}

export const ActionsCell: FC<Props> = memo(({ column, isFetching }) => {
    const isError = (column?.[column.key as keyof typeof column] as any)?.error || false; // TODO does it use?

    return (
        <>
            {isError && (
                <span key='error' style={{ color: 'red' }}>
                    !
                </span>
            )}

            {isFetching && (
                <Spin key='spin' size='small' spinning={true} style={{ paddingRight: '8px' }} />
            )}
        </>
    );
});
