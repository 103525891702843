import { sendRequest } from '@api';
import { API_URI, jsonTypeRequestHeaders } from '@constants';
import {
    ServiceFetchType,
    WorkerBanParams,
    WorkerBanResponse,
    WorkerCallParams,
    WorkerFetchWorkerParams,
    WorkerFetchWorkerResponse,
    WorkerSavePlannedContactDayParams,
    WorkerSavePlannedContactDayResponse,
    WorkersDataParams,
    WorkersDataResponse,
    WorkerSetCommentsParams,
    WorkerSetCommentsResponse,
    WorkerSetLabelParams,
    WorkerSetLabelResponse,
    WorkerSetRatingParams,
    WorkerSetRatingResponse,
    WorkerSetTagParams,
    WorkerSetTagResponse,
    WorkerSetZoneParams,
    WorkerSetZoneResponse,
    WorkerTalkBankBindParams,
    WorkerTalkBankBindStatusResponse,
    WorkerUnBanParams,
    WorkerUnBanResponse,
    WorkerUpdateOnlineStatusParams,
    WorkerUpdateOnlineStatusResponse,
} from '@typings';
import { generateFormData } from '@utils/generateFormData';

/**
 * @see https://wiki.yandex.ru/workers/active/#pozvonitrabotniku
 */
export const callToWorker: ServiceFetchType<void, WorkerCallParams> = ({
    params,
    onSuccess,
    onError,
}) => {
    sendRequest<void>(API_URI.DELIVERY_WORKERS_CALL, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        data: params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchWorkersData: ServiceFetchType<WorkersDataResponse, WorkersDataParams> = ({
    params,
    onSuccess,
    onError,
}) => {
    sendRequest<WorkersDataResponse>(API_URI.DELIVERY_WORKERS, {
        method: 'GET',
        params: params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const banWorker: ServiceFetchType<WorkerBanResponse, WorkerBanParams> = ({
    params,
    onSuccess,
    onError,
}) => {
    // * be sure to use the formData
    sendRequest<WorkerBanResponse>(API_URI.DELIVERY_BAN_WORKER, {
        method: 'POST',
        data: generateFormData(params),
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const unbanWorker: ServiceFetchType<WorkerUnBanResponse, WorkerUnBanParams> = ({
    params,
    onSuccess,
    onError,
}) => {
    // * be sure to use the formData
    sendRequest<WorkerUnBanResponse>(API_URI.DELIVERY_UNBAN_WORKER, {
        method: 'POST',
        data: generateFormData(params),
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const saveWorkerPlannedContactDay: ServiceFetchType<
    WorkerSavePlannedContactDayResponse,
    WorkerSavePlannedContactDayParams
> = ({ params, onSuccess, onError }) => {
    // * be sure to use the formData
    sendRequest<WorkerSavePlannedContactDayResponse>(
        API_URI.DELIVERY_WORKERS_SET_PLANNED_CONTACT_DAY,
        {
            method: 'POST',
            data: generateFormData(params),
        }
    )
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const setWorkerLabel: ServiceFetchType<WorkerSetLabelResponse, WorkerSetLabelParams> = ({
    params,
    onSuccess,
    onError,
}) => {
    // * be sure to use the formData
    sendRequest<WorkerSetLabelResponse>(API_URI.DELIVERY_WORKERS_LABEL_SET, {
        method: 'POST',
        data: generateFormData(params),
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const setWorkerZone: ServiceFetchType<WorkerSetZoneResponse, WorkerSetZoneParams> = ({
    params,
    onSuccess,
    onError,
}) => {
    const { worker, ...currentParams } = params || {};
    const url = `${API_URI.DELIVERY_WORKER_ZONE_SET}${worker}/` as API_URI;

    // * be sure to use the formData
    sendRequest<WorkerSetZoneResponse>(url, {
        method: 'POST',
        data: generateFormData(currentParams),
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const sendWorkerComment: ServiceFetchType<
    WorkerSetCommentsResponse,
    WorkerSetCommentsParams
> = ({ params, onSuccess, onError }) => {
    const { worker, ...currentParams } = params || {};
    const url = `delivery/workers/${worker}/comments/add/` as API_URI;

    // * be sure to use the formData
    sendRequest<WorkerSetCommentsResponse>(url, {
        method: 'POST',
        data: generateFormData(currentParams),
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const setWorkerTag: ServiceFetchType<WorkerSetTagResponse, WorkerSetTagParams> = ({
    params,
    onSuccess,
    onError,
}) => {
    const { worker, ...currentParams } = params || {};
    const url = `delivery/workers/${worker}/tag/set/` as API_URI;

    // * be sure to use the formData
    sendRequest<WorkerSetTagResponse>(url, {
        method: 'POST',
        data: generateFormData(currentParams),
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchWorker: ServiceFetchType<WorkerFetchWorkerResponse, WorkerFetchWorkerParams> = ({
    params,
    onSuccess,
    onError,
}) => {
    const { worker } = params || {};
    const url = `delivery/workers/${worker}/` as API_URI;

    // * be sure to use the formData
    sendRequest<WorkerFetchWorkerResponse>(url, {
        method: 'GET',
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const updateWorkerRating: ServiceFetchType<
    WorkerSetRatingResponse,
    WorkerSetRatingParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<WorkerSetRatingResponse>(API_URI.DELIVERY_WORKER_RATING_UPDATE, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        data: params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const updateWorkerOnlineStatus: ServiceFetchType<
    WorkerUpdateOnlineStatusResponse,
    WorkerUpdateOnlineStatusParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<WorkerUpdateOnlineStatusResponse>(API_URI.DELIVERY_WORKER_UPDATE_ONLINE_STATUS, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        data: params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const bindWorkerToTalkBank: ServiceFetchType<
    WorkerTalkBankBindStatusResponse,
    WorkerTalkBankBindParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<WorkerTalkBankBindStatusResponse>(API_URI.DELIVERY_WORKER_BIND_TO_TALK_BANK, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        data: params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};
