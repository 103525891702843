import { FC, memo, UIEvent } from 'react';
import { Form, Select } from 'antd';

import { RemoteSelectProps, RemoteSelectValue } from '@typings';

import { useOptions } from './hooks';

import styles from './remoteSelect.module.scss';

const requiredRules = [{ required: true, message: 'Это поле необходимо!' }];
const initialStyle = { width: 200 };

const RemoteSelect: FC<RemoteSelectProps> = ({
    name,
    forward,
    url,
    form,
    label,
    initialOptions,
    onChange: onChangeCb,
    onBlur: onBlurCb,
    onClear: onClearCb,
    className,
    formItemProps,
    style,
    required = true,
    isClearOnForward = true,
    ...props
}) => {
    const {
        options,
        onSelectClear,
        updateOptions,
        onSearchChange,
        onPageUp,
        onRemoteSelectValuesAdd,
    } = useOptions({
        url,
        form,
        forward,
        name,
        initialOptions,
        isClearOnForward,
    });

    const onPopupScroll = (e: UIEvent<HTMLDivElement>) => {
        e.persist();
        e.preventDefault();

        const target = e.target as HTMLDivElement;
        const isBottomScroll = target.offsetHeight + target.scrollTop >= target.scrollHeight;

        if (isBottomScroll) {
            onPageUp();
        }
    };

    const onSearch = (searchData: string) => {
        onSearchChange(searchData);
    };

    const onValueChange = (value: RemoteSelectValue) => {
        setTimeout(() => {
            onRemoteSelectValuesAdd(value);
        }, 0);

        return { value };
    };

    const onChange = (value: RemoteSelectValue) => {
        onChangeCb?.(value);
        updateOptions();
    };

    const onBlur = () => {
        onBlurCb?.();
        updateOptions();
    };

    const onClear = () => {
        onClearCb?.();
        onSelectClear();
    };
    const currentRules = required ? requiredRules : [];

    return (
        <Form.Item
            style={{ marginBottom: 0 }}
            name={name}
            label={label}
            rules={currentRules}
            getValueProps={onValueChange}
            className={styles.form_item}
            {...formItemProps}
        >
            <Select<RemoteSelectValue>
                onChange={onChange}
                onBlur={onBlur}
                onSearch={onSearch}
                onClear={onClear}
                showSearch
                allowClear
                options={options}
                filterOption={false}
                onPopupScroll={onPopupScroll}
                style={{ ...initialStyle, ...style }}
                className={className}
                dropdownClassName={styles.drop_down}
                {...props}
            />
        </Form.Item>
    );
};

export default memo(RemoteSelect);
