import { FC, memo } from 'react';

import { DeliveryCellProps } from '@typings';
import { getFormattedConfirmedTimepoint, isMergedRequest } from '@utils';

import { TextCell } from '.';

interface Props extends DeliveryCellProps {
    columnDataIndex: string;
}

const getMinValue = (firstTimepoint: string, secondTimepoint: string) => {
    if (firstTimepoint?.toString().localeCompare(secondTimepoint!?.toString()) === 1) {
        return secondTimepoint;
    }

    return firstTimepoint;
};

export const ConfirmedTimepoint: FC<Props> = memo(
    ({ onCommit, isEditing, rowData, columnDataIndex }) => {
        const isRequestItemValue = columnDataIndex === 'items';
        const formattedCellValue = getFormattedConfirmedTimepoint(
            rowData.items[0].confirmed_timepoint
        );

        if (!isMergedRequest(rowData) && isRequestItemValue) {
            const formattedTimepointList = rowData.items.reduce((acc, { confirmed_timepoint }) => {
                if (!acc) {
                    return confirmed_timepoint;
                }

                return getMinValue(acc, confirmed_timepoint);
            }, '');

            return <>{getFormattedConfirmedTimepoint(formattedTimepointList)}</>;
        }

        return (
            <TextCell isEditing={isEditing} onCommit={onCommit} cellValue={formattedCellValue} />
        );
    }
);
