import { FC, memo, SyntheticEvent, useState } from 'react';
import { Col, Image, Modal, Row } from 'antd';

import { CommentEditor, CommentList } from '@components/Comment';
import { WorkersReportMiniTable } from '@components/CustomTables';
import { BASE_URL_SERVER } from '@constants';
import { closeModal } from '@store/modal';
import {
    WorkersReportGetNextWorkerId,
    WorkersReportUpdateWorker,
    WorkersReportWorkerId,
} from '@typings';

import { FooterCard } from './FooterCard';
import { useCall, useWorker } from './hooks';

interface Props {
    isOpen: boolean;
    initialWorkerId: WorkersReportWorkerId;
    updateWorker: WorkersReportUpdateWorker;
    getNextWorkerId: WorkersReportGetNextWorkerId;
}

const staticImage = 'https://www.eyurtlar.com/images/gorsel-yok.jpg';

const getSelfieUrl = (url: string | undefined) => {
    if (url) {
        return `${BASE_URL_SERVER}media/${url}`;
    }

    return staticImage;
};

export const WorkersReportWorkerCard: FC<Props> = memo(
    ({ isOpen, initialWorkerId, updateWorker, getNextWorkerId }) => {
        const [currentWorkerId, setCurrentWorkerId] = useState(initialWorkerId);
        useCall({ updateWorker, workerId: currentWorkerId });

        const {
            workerDataList,
            workerSelfie,
            workerComments,
            updateWorkerData,
            addWorkerComments,
            workerRegistrationOk,
            isLoading,
        } = useWorker({
            onError: closeModal,
            workerId: currentWorkerId,
            updateWorker,
        });

        const onImageError = ({ currentTarget }: SyntheticEvent<HTMLImageElement>) => {
            currentTarget.src = staticImage;
        };

        return (
            <Modal
                title='Карточка исполнителя'
                width={900}
                visible={isOpen}
                closable={false}
                destroyOnClose
                footer={
                    <FooterCard
                        isLoading={isLoading}
                        currentWorkerId={currentWorkerId}
                        getNextWorkerId={getNextWorkerId}
                        setCurrentWorkerId={setCurrentWorkerId}
                    />
                }
            >
                <Row gutter={20}>
                    <Col span={12}>
                        <WorkersReportMiniTable
                            registrationOk={!!workerRegistrationOk}
                            updateWorkerData={updateWorkerData}
                            dataSource={workerDataList}
                        />
                    </Col>

                    <Col span={12}>
                        <Image
                            src={getSelfieUrl(workerSelfie)}
                            onError={onImageError}
                            alt='селфи'
                            preview={false}
                        />

                        <CommentList dataSource={workerComments} />
                        <CommentEditor
                            onCommentAdd={addWorkerComments}
                            workerId={currentWorkerId}
                        />
                    </Col>
                </Row>
            </Modal>
        );
    }
);
