import { ExoticComponent, FC, memo } from 'react';
import { Button as UIButton, ButtonProps } from 'antd';
import { AxiosError } from 'axios';

import { addItemWorker, confirmWorker } from '@api';
import { useRequestConfirm } from '@hooks';
import {
    DeliveryRequestDetail,
    DeliveryRequestMutationDefaultResponse,
    Response,
    WorkerItemStatus,
} from '@typings';
import { mapperRequestResponseDataToDetailFx } from '@utils/deliveryWorkersModal';
import { showError } from '@utils/show_error';

import { $itemRequest, $requestId } from '../model';
import { UploadFinishPhoto } from '../UploadFinishPhoto';
import { UploadStartPhoto } from '../UploadStartPhoto';

export interface IRenderAction {
    worker_id: number;
    onUpdate: (data: DeliveryRequestDetail) => void;
    statusData: {
        status: number;
        start: string | null;
    };
}

const onFetchError = (error: Error | AxiosError) => {
    showError((error as AxiosError)?.response?.data?.detail || error.message);
};

const Button: FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <UIButton style={{ fontSize: '12px' }} size='small' {...props}>
            {children}
        </UIButton>
    );
};

export const RenderAction: ExoticComponent<IRenderAction> = memo(
    ({ statusData, worker_id, onUpdate }) => {
        const { sendRequestWithConfirm } = useRequestConfirm();
        const item = $itemRequest.getState();
        const request = $requestId.getState();
        const { status } = statusData;

        if (!item || !request) return null;

        const onFetchSuccess = ({ data }: Response<DeliveryRequestMutationDefaultResponse>) => {
            if (data) {
                onUpdate(mapperRequestResponseDataToDetailFx(data.data));
            }
        };

        switch (status) {
            case WorkerItemStatus.Failed:
            case WorkerItemStatus.Defect:
            case WorkerItemStatus.Cancelled: {
                const onClick = () => {
                    sendRequestWithConfirm({
                        requestCallback: addItemWorker,
                        onSuccess: onFetchSuccess,
                        onError: onFetchError,
                        initialParams: {
                            request,
                            item,
                            workers: [worker_id],
                        },
                    });
                };

                return <Button onClick={onClick}>Вернуть на адрес</Button>;
            }

            case WorkerItemStatus.New: {
                const onClick = () => {
                    confirmWorker({
                        params: { request, worker: worker_id },
                        onSuccess: onFetchSuccess,
                        onError: onFetchError,
                    });
                };

                return <Button onClick={onClick}>Подтвердить заявку</Button>;
            }

            case WorkerItemStatus.StartPhotoRejected:
            case WorkerItemStatus.RequestConfirmedByWorker: {
                return (
                    <UploadStartPhoto
                        item={item}
                        onError={onFetchError}
                        onSuccess={onFetchSuccess}
                        request={request}
                        worker_id={worker_id}
                    />
                );
            }

            case WorkerItemStatus.SuspiciousAddress: {
                return (
                    <>
                        <UploadStartPhoto
                            item={item}
                            onError={onFetchError}
                            onSuccess={onFetchSuccess}
                            request={request}
                            worker_id={worker_id}
                        />

                        <UploadFinishPhoto
                            item={item}
                            onError={onFetchError}
                            onSuccess={onFetchSuccess}
                            request={request}
                            worker_id={worker_id}
                        />
                    </>
                );
            }
            case WorkerItemStatus.FinishPhotoRejected:
            case WorkerItemStatus.StartConfirmed: {
                return (
                    <UploadFinishPhoto
                        item={item}
                        onError={onFetchError}
                        onSuccess={onFetchSuccess}
                        request={request}
                        worker_id={worker_id}
                    />
                );
            }

            default: {
                return null;
            }
        }
    }
);
