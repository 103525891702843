import { useEffect, useState } from 'react';
import { Col, Form, FormInstance, FormProps, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';

import RemoteSelect from '@components/RemoteSelect';
import {
    ExpensesExpenseDetail,
    ExpensesExpenseItem,
    ExpensesFormCostTypes,
    ExpensesFormFieldNames,
    ExpensesFormOnFinishValue,
    ExpensesOnFinishData,
} from '@typings';
import { getDate } from '@utils/date';
import { getInitialExpensesFormData, mapperExpensesFormData } from '@utils/expensesForm';

import { Amount, Comment, DaysInterval, SelectCost, Submit } from './components';
import { expensesFieldNamesConfig, expensesRemoteSelectItemsConfig } from './config';

export interface Props extends FormProps {
    showAdministrationExpenses: boolean;
    showLabels: boolean;
    showSubmitButton?: boolean;
    initial?: ExpensesExpenseDetail;
    rowExpense?: ExpensesExpenseItem;
    onSubmit: (values: ExpensesOnFinishData) => void;
}

export const ExpensesForm: React.FC<Props> = ({
    showAdministrationExpenses,
    rowExpense,
    onSubmit,
    showLabels,
    showSubmitButton,
    id,
    layout,
    initial,
}) => {
    const [form] = useForm();

    const { cost_type_group = null } = initial || {};
    const [costTypeGroup, setCostTypeGroup] = useState<ExpensesFormCostTypes | null>(
        cost_type_group
    );

    useEffect(() => {
        const isAvailableAdministrationExpenses = showAdministrationExpenses && !initial;

        if (isAvailableAdministrationExpenses) {
            const date = getDate(rowExpense?.first_day, rowExpense?.last_day);
            const formattedFieldNames: Array<ExpensesFormFieldNames | 'days_interval'> = [
                ...expensesFieldNamesConfig,
                'days_interval',
            ];

            const constTypePkData = rowExpense?.cost_type_pk;
            const daysData = rowExpense ? [moment(date?.a), moment(date?.b)] : [];
            const customerData = rowExpense?.customer_pk && rowExpense?.customer_pk;
            const constTypeData = rowExpense?.cost_type_group;

            const fieldsValues: Partial<
                Record<
                    ExpensesFormFieldNames | 'days_interval',
                    string | number | null | moment.Moment[]
                >
            > = {
                administration_cost_type: constTypePkData?.toString(),
                industrial_cost_type: constTypePkData?.toString(),
                material_type: constTypePkData?.toString(),
                customer: customerData?.toString(),
                days_interval: daysData,
                cost_type_group: constTypeData?.toString(),
                provider: rowExpense?.provider_pk?.toString(),
            };

            const generateFormData = () => {
                const result: Record<string, string | number | null | moment.Moment[] | undefined> =
                    {};

                for (const name of formattedFieldNames) {
                    result[name] = fieldsValues[name];
                }

                return result;
            };

            form.setFieldsValue(generateFormData());
            setCostTypeGroup((constTypeData as ExpensesFormCostTypes) || '');
        } else {
            form.setFieldsValue(getInitialExpensesFormData(expensesFieldNamesConfig, initial));
        }
    }, [rowExpense, initial, showAdministrationExpenses]);

    const onFinish = (values: ExpensesFormOnFinishValue) => {
        const formattedValues = initial ? { ...values, pk: initial.pk } : values;

        onSubmit(mapperExpensesFormData(formattedValues));
    };

    return (
        <Form
            id={id}
            form={form}
            labelCol={showLabels ? { span: 10, offset: 0 } : undefined}
            wrapperCol={showLabels ? { span: 10, offset: 0 } : undefined}
            layout={layout || 'inline'}
            onFinish={onFinish}
        >
            <Row gutter={[12, 2]}>
                <Col key='col1'>
                    <Space direction='vertical'>
                        <SelectCost
                            initial={initial}
                            showLabels={showLabels}
                            setCostType={setCostTypeGroup}
                            showAdminExpenses={showAdministrationExpenses}
                        />
                        {renderRemoteSelectItems(costTypeGroup, showLabels, form)}
                    </Space>
                </Col>
                <Col key='col2'>
                    <Space direction='vertical'>
                        {renderAdditionalFormItems(costTypeGroup, showLabels, !!showSubmitButton)}
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

const renderRemoteSelectItems = (
    costTypeGroup: ExpensesFormCostTypes | null,
    showLabels: boolean,
    formInstance: FormInstance
) => {
    if (!costTypeGroup) return null;

    return expensesRemoteSelectItemsConfig[costTypeGroup].map(({ url, key, label }) => {
        return (
            <RemoteSelect.Item
                form={formInstance}
                key={key}
                name={key}
                placeholder={label}
                label={showLabels ? label : undefined}
                url={url}
            />
        );
    });
};

const renderAdditionalFormItems = (
    costTypeGroup: ExpensesFormCostTypes | null,
    showLabels: boolean,
    showSubmitButton: boolean
) => {
    const isDisplayDaysInterval = costTypeGroup && costTypeGroup !== 'material';

    return (
        <>
            <Amount showLabels={showLabels} />
            {isDisplayDaysInterval && <DaysInterval showLabels={showLabels} />}

            <Comment showLabels={showLabels} />
            {showSubmitButton && <Submit />}
        </>
    );
};
