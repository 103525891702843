import { DeliveryTableStatus } from './components';

export interface WorkerStatus {
    [key: string]: {
        name: string;
        bgColor: string;
    };
}

/**
 *  @see https://wiki.yandex.ru/photos/request-status/#statusyrabotnikanaadrese
 */
export enum WorkerItemStatus {
    Removed = -4,
    Failed = -3,
    Defect = -2,
    Cancelled = -1,
    New = 0,
    StartPhotoRejected = 1,
    RequestConfirmedByWorker = 2,
    SuspiciousAddress = 3,
    StartChecking = 4,
    StartConfirmed = 5,
    FinishPhotoRejected = 6,
    FinishChecking = 7,
    Completed = 8,
}

export type DeliveryTableStatuses = Record<DeliveryTableCellStatuses, DeliveryTableStatus>;

export type DeliveryTableCellStatuses =
    | 'new'
    | 'declined'
    | 'cancelled'
    | 'failed'
    | 'driver_callback'
    | 'no_response'
    | 'cancelled_with_payment'
    | 'timepoint_confirmed'
    | 'partly_confirmed'
    | 'partly_arrival_submitted'
    | 'partly_arrived'
    | 'partly_photo_attached'
    | 'photo_attached'
    | 'finished';

export type ManualStatuses = {
    label: keyof Pick<
        DeliveryTableStatuses,
        | 'declined'
        | 'cancelled'
        | 'failed'
        | 'driver_callback'
        | 'no_response'
        | 'cancelled_with_payment'
    >;
    text: string;
}[];
