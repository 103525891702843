import {
    DeliveryRequestDetail,
    DeliveryRequestMutationDefaultResponse,
    DeliveryRequestWorkersItems,
} from '@typings';

import { normalizeDeliveryRequest } from '.';

interface GetWorkersOnAddressResult {
    [key: string]: string[];
}

export const getWorkersOnAddress = (
    workersList: DeliveryRequestWorkersItems
): GetWorkersOnAddressResult => {
    const result: GetWorkersOnAddressResult = {};

    for (const worker of Object.keys(workersList)) {
        result[worker] = [];

        for (const workerItem in workersList[worker]) {
            const status = workersList?.[worker]?.[workerItem]?.status;
            const isVirtual = Number(workerItem) < 0;

            if (Number(status) >= 0 || isVirtual) {
                result[worker].push(workerItem);
            }
        }
    }

    return result;
};

export const mapperRequestResponseDataToDetailFx = (
    data: DeliveryRequestMutationDefaultResponse['data']
): DeliveryRequestDetail => {
    const formattedRequest = normalizeDeliveryRequest(data.request);

    return {
        request: formattedRequest,
        item_workers: data.item_workers,
        workers: data.workers,
    };
};
