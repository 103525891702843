import { FC, useState } from 'react';
import { Button, Input, Popover, Tooltip } from 'antd';

import { confirmItemWorkerStart, startWorkerDiscrepancy } from '@api';
import {
    DashboardButtonIconProps,
    DashboardPhotoActionsView,
    DashborardPhotoActionButtonProps,
} from '@typings';

interface Props extends DashborardPhotoActionButtonProps {
    type: 'confirm' | 'cancel';
    viewType: DashboardPhotoActionsView;
    background: string;
    workerId: number;
    itemId: number;
    icon: FC<DashboardButtonIconProps>;
    buttonText: string;
}

export const SuspicionsButton: FC<Props> = ({
    requestId,
    itemId,
    onSuccess,
    workerId,
    background,
    type,
    onError,
    viewType,
    buttonText,
    icon: Icon,
}) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');

    const buttonDisable = comment === '';
    const buttonStyle = { background, borderColor: 'unset' };

    const onConfirm = () => {
        if (buttonDisable) {
            return;
        }

        setVisible(false);

        const onSuccessDiscrepancy = () => {
            if (type === 'cancel') {
                onSuccess();
                return;
            }

            confirmItemWorkerStart({
                params: {
                    request: requestId,
                    item: itemId,
                    worker: workerId,
                },
                onSuccess,
                onError,
            });
        };

        startWorkerDiscrepancy({
            params: {
                is_ok: type !== 'cancel',
                item: itemId,
                request: requestId,
                worker: workerId,
                comment,
            },
            onSuccess: onSuccessDiscrepancy,
            onError,
        });
    };

    const content = (
        <div>
            <Input onPressEnter={onConfirm} onChange={(e) => setComment(e.target.value)} />
        </div>
    );

    const renderContent = () => {
        if (viewType === 'icon') {
            return (
                <Tooltip placement='bottom' title={buttonText}>
                    <Icon style={{ margin: '0 5px 0 5px' }} onClick={onConfirm} />
                </Tooltip>
            );
        }

        return (
            <Button style={buttonStyle} type='primary' onClick={onConfirm} disabled={buttonDisable}>
                {buttonText}
            </Button>
        );
    };

    return (
        <Popover
            content={content}
            title='Комментарий'
            visible={visible}
            trigger='click'
            onVisibleChange={(visible) => setVisible(visible)}
            overlayStyle={{ zIndex: 9999 }}
            destroyTooltipOnHide
        >
            {renderContent()}
        </Popover>
    );
};
