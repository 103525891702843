import { FC } from 'react';
import { Form, FormItemProps } from 'antd';
import { NamePath } from 'antd/lib/form/interface';

type Value = string | undefined | number | boolean;

type IsFieldDataValid = (currentField: Value, name: string) => boolean;
interface Props extends FormItemProps {
    dependency?: NamePath[];
    externalDependency?: Array<Value>;
    isFieldDataValid?: IsFieldDataValid;
}

const isFieldDataValidDefault = (currentField: Value) => {
    return !!currentField;
};

export const FieldItemWithObservableValue: FC<Props> = ({
    children,
    isFieldDataValid,
    dependency = [],
    externalDependency = [],
    ...props
}) => {
    const getIsNotValidValue = (value: Value, name?: string) => {
        if (isFieldDataValid) {
            return !isFieldDataValid?.(value as string, name!!);
        }

        return !isFieldDataValidDefault(value as string);
    };

    return (
        <Form.Item dependencies={dependency} noStyle {...props}>
            {({ getFieldsValue }) => {
                const formValues = getFieldsValue(dependency) || {};

                for (const [name, value] of Object.entries(formValues)) {
                    if (getIsNotValidValue(value as Value, name)) {
                        return null;
                    }
                }

                for (const value of externalDependency) {
                    if (getIsNotValidValue(value as Value)) {
                        return null;
                    }
                }

                return <>{children}</>;
            }}
        </Form.Item>
    );
};
