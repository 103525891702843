import { AxiosError } from 'axios';

import { updateRequest, updateRequestItem } from '@api';
import {
    DeliveryRequestItem,
    DeliveryRequestMutationDefaultResponse,
    DeliveryRequestOrExpandedValues,
    Response,
} from '@typings';
import { showError } from '@utils/show_error';

import { useRequestConfirm } from '.';

interface DoUpdateRequest<T extends DeliveryRequestOrExpandedValues> {
    pk: number;
    items: DeliveryRequestItem[];
    field: string;
    value: T;
    onError?(error: Error): void;
    onSuccess?(data: Response<DeliveryRequestMutationDefaultResponse>): void;
}

const requestKeys = [
    'date',
    'driver_name',
    'driver_phones',
    'route',
    'status',
    'status_description',
    'timestamp',
    'comment',
    'operator',
    'worker_count',
    'worker_confirmed_count',
    'customer_resolution',
    'is_overdue',
    'customer_confirmation',
    'has_self_assigned_worker',
    'is_private',
    'extra_photos_exist',
    'confirmation_time',
    'arrival_time',
    'author',
    'customer',
    'service',
    'location',
    'lateness',
    'new_photo_count',
];

export function useUpdateRequest() {
    const { sendRequestWithConfirm } = useRequestConfirm();

    const doUpdateRequest = <T extends DeliveryRequestOrExpandedValues>({
        pk,
        items,
        field,
        value,
        onSuccess,
        onError: onErrorCallback,
    }: DoUpdateRequest<T>) => {
        const initialParams = {
            request: pk,
            [field]: value || value === false ? value.toString() : null,
        };

        const onError = (error: Error | AxiosError) => {
            showError((error as AxiosError)?.response?.data?.detail || error?.message);
            onErrorCallback?.(error);
        };

        if (requestKeys.includes(field)) {
            sendRequestWithConfirm({
                initialParams,
                onError,
                onSuccess,
                requestCallback: updateRequest,
            });
        } else {
            sendRequestWithConfirm({
                initialParams: {
                    ...initialParams,
                    item: items?.[0].pk || 0,
                },
                onError,
                onSuccess,
                requestCallback: updateRequestItem,
            });
        }
    };

    return doUpdateRequest;
}
