import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { RouteProps } from 'react-router';
import { Col, Divider, Row, Space, Spin, Table } from 'antd';
import moment from 'moment';

import { fetchImportHistory } from '@api';
import CustomDatePickerRange from '@components/CustomDatePickerRange';
import RemoteSelect from '@components/RemoteSelect';
import { API_URI } from '@constants';
import { CustomDatePickerRangeValue, ImportReportState } from '@typings';
import { showError } from '@utils/show_error';

type ReportsCountState = {
    customer: string | null;
    first_day: string;
    last_day: string;
};

type ReportsCountProps = RouteProps;

interface Columns {
    timePoint: string;
    file: ReactNode;
    report: ReactNode;
    status: string;
}

const ImportsReport: FC<ReportsCountProps> = ({ location }) => {
    const params = new URLSearchParams(location?.search);

    const first_day_of_month = new Date();
    first_day_of_month.setDate(1);
    const today = new Date();
    const initialState: ReportsCountState = {
        customer: params.get('customer'),
        first_day: params.get('first_day') || moment(first_day_of_month).format('DD.MM.YYYY'),
        last_day: params.get('last_day') || moment(today).format('DD.MM.YYYY'),
    };

    const [data, setData] = useState<ImportReportState[]>([]);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState<ReportsCountState>(initialState);

    const onChangeData = useCallback((data: CustomDatePickerRangeValue) => {
        setState((state) => ({ ...state, ...data }));
    }, []);

    const onChangeCustomer = useCallback((value: string | string[]) => {
        setState((state) => ({ ...state, customer: value.toString() }));
    }, []);

    const getTable = () => {
        const tableData: Columns[] = data?.map((item) => {
            const imported_file_label =
                item.imported_file.name + ' (' + item.imported_file.size + ')';
            let report_cell: ReactNode = '-';
            if (item.report) {
                const label = item.report.name + ' (' + item.report.size + ')';
                report_cell = <a href={item.report.url}>{label}</a>;
            }
            return {
                timePoint: item.timepoint,
                authorName: item.author.name,
                file: <a href={item.imported_file.url}>{imported_file_label}</a>,
                report: report_cell,
                status: item.status.text,
            };
        });

        const columns = [
            {
                title: 'Дата',
                dataIndex: 'timePoint',
                key: 'timePoint',
            },
            {
                title: 'Автор',
                dataIndex: 'authorName',
                key: 'authorName',
            },
            {
                title: 'Файл с заявками (оригинал)',
                dataIndex: 'file',
                key: 'file',
            },
            {
                title: 'Отчет',
                dataIndex: 'report',
                key: 'report',
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
            },
        ];

        return <Table columns={columns} dataSource={tableData} tableLayout='fixed' />;
    };

    useEffect(() => {
        setLoading(true);
        fetchImportHistory({
            params: state,
            onSuccess({ data }) {
                setData(data);
                setLoading(false);
            },
            onError(error) {
                showError(error.message);
            },
        });
    }, [state]);

    return (
        <>
            <Row>
                <Col>
                    <Space>
                        <RemoteSelect.Form
                            url={API_URI.AUTOCOMPLETE_COMMON_CUSTOMER}
                            name='customer'
                            placeholder='Клиент'
                            onChange={onChangeCustomer}
                        />

                        <CustomDatePickerRange.Form
                            name='datepicker'
                            first_day={state.first_day}
                            last_day={state.last_day}
                            placeholder={['С', 'По']}
                            onChange={onChangeData}
                        />
                    </Space>
                </Col>
            </Row>

            <Divider />

            <Spin spinning={loading}>
                <Row>
                    <Col>{getTable()}</Col>
                </Row>
            </Spin>
        </>
    );
};

export default ImportsReport;
