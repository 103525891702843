import { useCallback, useEffect } from 'react';

import { callToWorker } from '@api';
import { WorkersReportUpdateWorker, WorkersReportWorkerId } from '@typings';
import { showError } from '@utils/show_error';

interface Params {
    updateWorker: WorkersReportUpdateWorker;
    workerId: WorkersReportWorkerId;
}

export const useCall = ({ updateWorker, workerId }: Params) => {
    const callWorker = useCallback(() => {
        updateWorker(workerId, { callIcon: 0 });

        callToWorker({
            params: {
                worker: workerId,
            },
            onError(error) {
                showError(error.message);
                updateWorker(workerId, { callIcon: 1 });
            },
            onSuccess() {
                updateWorker(workerId, { callIcon: 1 });
            },
        });
    }, [workerId, updateWorker]);

    useEffect(() => {
        callWorker();
    }, [callWorker]);
};
