import { FC, useContext } from 'react';
import { Spin } from 'antd';

import { PhoneOutlined } from '@ant-design/icons';
import { WorkersReportWorkerCardModal } from '@components';
import { WorkersReportTableContext } from '@providers';
import { openModal } from '@store/modal';
import {
    RenderModal,
    WorkersReportGetNextWorkerId,
    WorkersReportUpdateWorker,
    WorkersReportWorkerId,
} from '@typings';

interface Props {
    callIcon: number;
    workerId: WorkersReportWorkerId;
}

export const Call: FC<Props> = ({ callIcon, workerId }) => {
    const { updateWorker, getNextWorkerId } = useContext(WorkersReportTableContext);

    const onCardOpen = () => {
        openModal(renderModal(workerId, updateWorker, getNextWorkerId!!));
    };

    if (callIcon) {
        return <PhoneOutlined onClick={onCardOpen} />;
    }

    return <Spin size='small' />;
};

const renderModal = (
    workerId: WorkersReportWorkerId,
    updateWorker: WorkersReportUpdateWorker,
    getNextWorkerId: WorkersReportGetNextWorkerId
): RenderModal => {
    return (isOpen) => (
        <WorkersReportWorkerCardModal
            getNextWorkerId={getNextWorkerId}
            isOpen={isOpen}
            updateWorker={updateWorker}
            initialWorkerId={workerId}
        />
    );
};
