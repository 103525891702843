import { FC, memo } from 'react';

import {
    CodeCell,
    CommentCell,
    CustomerCell,
    DateCell,
    DefaultWrappedCell,
    HasSelfAssignedWorkerIndicatorCell,
    LocationCell,
    OperatorCell,
    PhoneCallCell,
    PhotoCell,
    RequestPrivateCheckboxCell,
    ServiceCell,
    StatusCell,
    TimeCell,
    TimeIntervalCell,
} from '@components';
import { CurrentCellProps, DeliveryRequest } from '@typings';

interface Props extends Omit<CurrentCellProps, 'rowData' | 'setRequest'> {
    rowData: DeliveryRequest;
}

export const WrappedCell: FC<Props> = memo(
    ({
        cellValue,
        isEditing,
        onCommit,
        setRowWithFormat,
        isFetching,
        rowData,
        columnDataIndex,
        cellKey,
    }) => {
        switch (cellKey) {
            case 'confirmation_time':
            case 'arrival_time':
            case 'timestamp':
                return <TimeCell cellValue={cellValue} isEditing={isEditing} onCommit={onCommit} />;
            case 'time_interval':
                return (
                    <TimeIntervalCell isEditing={isEditing} onCommit={onCommit} rowData={rowData} />
                );
            case 'has_self_assigned_worker':
                return (
                    <HasSelfAssignedWorkerIndicatorCell
                        rowData={rowData}
                        isEditing={isEditing}
                        onCommit={onCommit}
                    />
                );

            case 'is_private':
                return (
                    <RequestPrivateCheckboxCell
                        is_private={rowData.is_private}
                        isFetching={isFetching}
                        onCommit={onCommit}
                    />
                );

            case 'status':
                return <StatusCell rowData={rowData} isEditing={isEditing} onCommit={onCommit} />;

            case 'operator':
                return <OperatorCell setRowWithFormat={setRowWithFormat} rowData={rowData} />;

            case 'timesheet_photo':
                return <PhotoCell rowData={rowData} setRowWithFormat={setRowWithFormat} />;

            case 'location':
                return <LocationCell rowData={rowData} isEditing={isEditing} onCommit={onCommit} />;

            case 'customer':
                return <CustomerCell rowData={rowData} isEditing={isEditing} onCommit={onCommit} />;

            case 'service':
                return <ServiceCell rowData={rowData} isEditing={isEditing} onCommit={onCommit} />;

            case 'call_to_driver':
                return <PhoneCallCell rowData={rowData} />;
            case 'code':
                return <CodeCell isEditing={isEditing} onCommit={onCommit} rowData={rowData} />;

            case 'date': {
                return <DateCell isEditing={isEditing} onCommit={onCommit} rowData={rowData} />;
            }

            case 'comment':
                return <CommentCell isEditing={isEditing} onCommit={onCommit} rowData={rowData} />;
            default:
                return (
                    <DefaultWrappedCell
                        columnDataIndex={columnDataIndex}
                        isEditing={isEditing}
                        onCommit={onCommit}
                        cellValue={cellValue}
                        rowData={rowData}
                    />
                );
        }
    }
);
