export const formatToMoney = (value: number) => {
    return new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB' })
        .format(value)
        .replace(/,(\d\d).₽/, (_, firstGroup) => {
            const defaultValue = '00';

            if (firstGroup === defaultValue) {
                return '';
            }

            return `.${firstGroup}`;
        })
        .replace(/\s/g, '\u202F');
};
