import { FC, useContext } from 'react';
import { PieChart } from 'react-minimal-pie-chart';

import { DeliveryContext } from '@providers/delivery';
import { DeliveryRequestsDashboard, DeliveryRequestsDashboardItemsNames } from '@typings';
import { formatToMoney } from '@utils/formatToMoney';

import styles from './RequestsDashboard.module.scss';

interface Props {
    data: DeliveryRequestsDashboard;
}

const getItemTotal = (
    data: DeliveryRequestsDashboard,
    item: DeliveryRequestsDashboardItemsNames
) => {
    return data.items.get(item)?.total || 1;
};

const getPieChartLabel = (totalNumber: number) => {
    if (!totalNumber) {
        return (
            <div>
                Всего <br />
                заявок
            </div>
        );
    }

    return (
        <div>
            Всего <br />
            заявок на <br />
            {formatToMoney(totalNumber)}&nbsp;&#8381;
        </div>
    );
};

export const DashboardPieChart: FC<Props> = ({ data }) => {
    const { setFilterStatus } = useContext(DeliveryContext);
    const dataPieChart = [
        { title: 'preprocessing', value: getItemTotal(data, 'preprocessing'), color: '#7DA2FF' },
        { title: 'in_work', value: getItemTotal(data, 'in_work'), color: '#67E1B5' },
        { title: 'no_contact', value: getItemTotal(data, 'no_contact'), color: '#FFCF24' },
        { title: 'cancelled', value: getItemTotal(data, 'cancelled'), color: '#FF4F4F' },
    ];

    const onClearClick = () => {
        setFilterStatus(null);
    };

    return (
        <div className={`${styles.block} ${styles.chart}`} onClick={onClearClick}>
            <div className={styles.pie}>
                <PieChart
                    key={data.total}
                    data={dataPieChart}
                    lineWidth={16}
                    animate={true}
                    animationDuration={500}
                />
                <div className={styles.count}>{data.total}</div>
            </div>

            {getPieChartLabel(data.totalMoney)}
        </div>
    );
};
