import { useMemo } from 'react';
import { SortOrder } from 'antd/lib/table/interface';
import _ from 'lodash';

import { CustomColumn, OnColumnSortChange, SetState } from '@typings';

interface GetColumnWithPriorityParams<T> {
    column: CustomColumn<T>;
    sortOrder: SortOrder | undefined;
    currentPriority: number;
}

const getColumnWithPriority = <T>({
    column,
    currentPriority,
    sortOrder,
}: GetColumnWithPriorityParams<T>): CustomColumn<T> => {
    if (_.isObject(column?.sorter) && 'compare' in column?.sorter) {
        return {
            ...column,
            sortOrder,
            sorter: {
                ...column.sorter,
                multiple: currentPriority,
            },
        };
    }

    return { ...column, sortOrder };
};

interface Params<T> {
    setColumns: SetState<CustomColumn<T>[]>;
}

export const useSort = <T>({ setColumns }: Params<T>) => {
    const onColumnSortChange = useMemo((): OnColumnSortChange => {
        let priorityList: string[] = [];

        const removePriority = (currentKey: string) => {
            priorityList = priorityList.filter((key) => {
                return key !== currentKey;
            });
        };

        const getCurrentPriority = (sortOrder: SortOrder | undefined, currentKey: string) => {
            if (!sortOrder) {
                removePriority(currentKey);

                return 0;
            }

            const currentPriority = priorityList.findIndex((key) => {
                return key === currentKey;
            });

            if (currentPriority !== -1) {
                return currentPriority;
            }

            const nextPriority = priorityList.length + 1;

            priorityList.push(currentKey);

            return nextPriority;
        };

        return (sortDate, withReset = false) => {
            if (withReset) {
                priorityList = [];
            }

            setColumns((prev) => {
                return prev?.map((column) => {
                    const key = column.key?.toString();

                    if (sortDate === null || !key) {
                        priorityList = [];

                        return { ...column, sortOrder: undefined };
                    }

                    const isExistSort = key!! in sortDate;

                    if (isExistSort) {
                        const sortOrder = sortDate[key!!];
                        const currentPriority = getCurrentPriority(sortOrder, key);

                        return getColumnWithPriority({ column, currentPriority, sortOrder });
                    }

                    removePriority(key);

                    return getColumnWithPriority({
                        column,
                        currentPriority: 0,
                        sortOrder: undefined,
                    });
                });
            });
        };
    }, []);

    return onColumnSortChange;
};
