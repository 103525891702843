import { FC, useContext, useState } from 'react';
import { Input } from 'antd';
import moment from 'moment';

import { updateRequestItem } from '@api';
import { useRequestConfirm } from '@hooks';
import { DeliveryWorkersOnAddressContext } from '@providers';
import { DeliveryRequestMutationDefaultResponse, Response } from '@typings';
import { mapperRequestResponseDataToDetailFx } from '@utils/deliveryWorkersModal';
import { showError } from '@utils/show_error';

interface Props {
    confirmedTimepoint: string;
    addressId: number;
    requestPk: number;
}

const getCurrentText = (timepoint: string) => {
    if (timepoint !== '-' || !timepoint) {
        return moment(timepoint, 'HH:mm:ss').format('HH:mm');
    }

    return '';
};

export const WorkerTimepoint: FC<Props> = ({ confirmedTimepoint, addressId, requestPk }) => {
    const { sendRequestWithConfirm } = useRequestConfirm();
    const { updateTable } = useContext(DeliveryWorkersOnAddressContext);
    const [isEditing, setIsEditing] = useState(false);
    const [currentText, setCurrentText] = useState(getCurrentText(confirmedTimepoint));

    const onError = (error: Error) => {
        showError(error.message);
        setIsEditing(false);
        setCurrentText(getCurrentText(confirmedTimepoint));
    };

    const onEditStart = () => {
        setIsEditing(true);
    };

    const onSuccess = ({ data }: Response<DeliveryRequestMutationDefaultResponse>) => {
        updateTable(mapperRequestResponseDataToDetailFx(data.data));
        setIsEditing(false);
    };

    const onFieldUpdate = () => {
        sendRequestWithConfirm({
            initialParams: {
                request: requestPk,
                confirmed_timepoint: currentText || null,
                item: addressId,
            },
            onError,
            onSuccess,
            requestCallback: updateRequestItem,
        });
    };

    const onChangeText = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentText(target.value);
    };

    const renderContent = () => {
        if (isEditing) {
            return (
                <Input
                    size='small'
                    autoFocus={true}
                    defaultValue={currentText}
                    onChange={onChangeText}
                    onPressEnter={onFieldUpdate}
                    onBlur={onFieldUpdate}
                />
            );
        }

        return <div onClick={onEditStart}>{currentText || '-'}</div>;
    };

    return <div>{renderContent()}</div>;
};
