import { FC } from 'react';
import { Form, Input, Modal } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { closeModal } from '@store/modal';

interface Props {
    isOpen: boolean;
    title: string;
    onOk(comment: string): void;
    okText: string;
    cancelText: string;
}

interface FormValues {
    comment: string;
}

export const ExpensesComment: FC<Props> = ({ cancelText, isOpen, okText, onOk, title }) => {
    const [form] = useForm<FormValues>();

    const onModalClose = () => {
        closeModal();
    };

    const onFinish = () => {
        form.validateFields().then(({ comment }) => {
            form.resetFields();

            closeModal();
            onOk(comment);
        });
    };

    return (
        <Modal
            onCancel={onModalClose}
            title={title}
            onOk={onFinish}
            okText={okText}
            visible={isOpen}
            cancelText={cancelText}
        >
            <Form form={form}>
                <Form.Item
                    name='comment'
                    label='Комментарий'
                    rules={[{ required: true, message: 'Комментарий необходим!' }]}
                >
                    <Input placeholder='Текст комментария'></Input>
                </Form.Item>
            </Form>
        </Modal>
    );
};
