import moment from 'moment';

import { DATE_FORMAT, WORKER_STATUS } from '@constants';
import {
    ArrayElement,
    FetchWorkersReportParams,
    MapperWorkersReportWorkerUpdateRatingToDetail,
    Nullable,
    TalkBankBindStatus,
    WorkerFetchWorkerResponse,
    WorkersDataItemResponse,
    WorkersDataResponse,
    WorkersReportFilterFormValues,
    WorkersReportWorker,
    WorkersReportWorkerComment,
    WorkersReportWorkerDefault,
    WorkersReportWorkerDetail,
    WorkersReportWorkerItem,
    WorkerTalkBankBindStatusResponse,
} from '@typings';

export const mapperWorkersReportWorkerItem = (
    workerItem: WorkersDataItemResponse
): WorkersReportWorkerItem => {
    return {
        id: workerItem?.id,
        value: workerItem?.name || '-',
    };
};

const formatDate = (date: Nullable<string | Date | moment.Moment>) => {
    return date ? moment(date).format(DATE_FORMAT) : null;
};

const formatToMoment = (date: Nullable<string>) => {
    return date ? moment(date, 'YYYY-MM-DD') : null;
};

export const formatToTalkBankBindStatus = (
    status: Nullable<WorkerTalkBankBindStatusResponse>
): Nullable<TalkBankBindStatus> => {
    if (status === null) {
        return null;
    }

    return {
        timestamp: moment(status.timestamp),
        isBound: status.is_bound,
        operationResult: status.operation_result,
        operationResultDescription: status.operation_result_description,
    };
};

const mapperWorkerReport = (
    worker: WorkerFetchWorkerResponse | ArrayElement<WorkersDataResponse['results']>
): WorkersReportWorkerDefault => {
    const {
        last_name,
        is_online_tomorrow,
        tel_number,
        last_turnout_date,
        input_date,
        status_code,
        planned_contact_day,
        contract_status,
        unconfirmed_count,
        zone,
        label,
        name,
        patronymic,
        citizenship,
        id,
        is_benevolent,
        last_call_date,
        reliability,
        registration_ok,
        talk_bank_bind_status,
        ...workerData
    } = worker;

    const fullName = `${last_name} ${name} ${patronymic}`;

    return {
        ...workerData,
        isBenevolent: is_benevolent,
        registrationOk: registration_ok,
        workerId: id,
        contractStatus: contract_status,
        isOnlineTomorrow: is_online_tomorrow,
        telNumber: tel_number,
        unconfirmedCount: unconfirmed_count,
        action: status_code,
        status: WORKER_STATUS[status_code],
        reliability: reliability?.toString() || null,
        lastCallDate: formatDate(formatToMoment(last_call_date)),
        plannedContactDay: formatToMoment(planned_contact_day),
        inputDate: formatDate(input_date),
        lastTurnoutDate: formatDate(last_turnout_date),
        citizenship: mapperWorkersReportWorkerItem(citizenship),
        zone: mapperWorkersReportWorkerItem(zone),
        label: mapperWorkersReportWorkerItem(label),
        fullName,
        callIcon: 1,
        talkBankBindStatus: formatToTalkBankBindStatus(talk_bank_bind_status),
    };
};

export const mapperWorkerDetail = (
    workerDetail: WorkerFetchWorkerResponse
): WorkersReportWorkerDetail => {
    const { comments, selfie } = workerDetail;

    const formattedComments =
        comments &&
        comments
            .map(({ author, ...comment }) => ({
                ...comment,
                author: mapperWorkersReportWorkerItem(author),
            }))
            .reverse();

    return {
        ...mapperWorkerReport(workerDetail),
        comments: formattedComments,
        selfie,
    };
};

export const mapperWorkersListReport = ({
    results,
}: WorkersDataResponse): WorkersReportWorker[] => {
    return (
        results?.map((workerData) => {
            const { last_comment } = workerData;

            const lastComment = last_comment && {
                ...last_comment,
                author: mapperWorkersReportWorkerItem(last_comment.author),
            };

            return {
                ...mapperWorkerReport(workerData),
                lastComment,
            };
        }) || []
    );
};

const formatMultiplyItem = (data?: string[]) => {
    if (!data?.length) {
        return undefined;
    }

    return data.join(',');
};

export const mapperWorkersReportFiltersForm = (
    formValues: Partial<WorkersReportFilterFormValues>
): FetchWorkersReportParams => {
    const planned_contact_day = formValues.planned_contact_day?.format(DATE_FORMAT);
    const last_call_date = formValues.last_call_date?.format(DATE_FORMAT);
    const status_code = formatMultiplyItem(formValues.status_code);
    const availability = formatMultiplyItem(formValues.availability);
    const readiness = formatMultiplyItem(formValues.readiness);

    return {
        planned_contact_day,
        last_call_date,
        status_code,
        availability,
        readiness,
        search_text: formValues?.search_text,
        zone: formValues.zone,
    };
};

export const mapperWorkersReportWorkerComment = ({
    author,
    text,
    timestamp,
}: WorkersReportWorkerComment) => {
    const datetime = moment(timestamp);

    return {
        datetime,
        author: author.value,
        key: datetime.format('YYYYMMDDHHmmss'),
        content: text,
    };
};

export const mapperWorkersReportWorkerUpdateRatingToDetail: MapperWorkersReportWorkerUpdateRatingToDetail =
    (data) => {
        const { is_benevolent, last_call_date, readiness, reliability, availability } = data;

        return {
            readiness,
            reliability,
            availability,
            isBenevolent: is_benevolent,
            lastCallDate: last_call_date,
        };
    };
