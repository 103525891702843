import { FC, memo } from 'react';
import { Modal } from 'antd';

import { DeliveryWorkersOnAddressTable } from '@components/CustomTables';
import { DeliveryWorkersOnAddressProvider } from '@providers';
import { closeModal } from '@store/modal';
import { SetRowWithFormat } from '@typings';

import { useDetailRequest } from './hooks';
import { WorkersController } from './WorkersController';

interface Props {
    isOpen: boolean;
    setRowWithFormat: SetRowWithFormat;
    requestPk: number;
    onCancel(): void;
}

export const DeliveryWorkersOnAddressModal: FC<Props> = memo(
    ({ isOpen, onCancel, requestPk, setRowWithFormat }) => {
        const { request, setRequest, onWorkerAdd } = useDetailRequest(requestPk);

        const onCancelClick = () => {
            closeModal();
            onCancel();
        };

        return (
            <Modal
                visible={isOpen}
                title='Грузчики на адресах'
                width='850px'
                destroyOnClose
                onCancel={onCancelClick}
                footer={null}
            >
                <DeliveryWorkersOnAddressProvider
                    setRowWithFormat={setRowWithFormat}
                    onUpdate={setRequest}
                >
                    <DeliveryWorkersOnAddressTable requestDetail={request} />
                </DeliveryWorkersOnAddressProvider>

                <WorkersController requestPk={requestPk} onWorkerAdd={onWorkerAdd} />
            </Modal>
        );
    }
);
