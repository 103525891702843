import { FC, useState } from 'react';
import { Button, Form, message, Modal, Row, Upload } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';

import { UploadOutlined } from '@ant-design/icons';
import { FieldItemWithObservableValue } from '@components/FieldItemWithObservableValue';
import RemoteSelect from '@components/RemoteSelect';
import { API_URI, API_URLS, BASE_URL_SERVER, BASE_URL_SERVER_API } from '@constants';
import { closeModal } from '@store/modal';
import { authFetch } from '@utils/auth/auth';
import { generateFormData } from '@utils/generateFormData';

interface Props {
    isOpen: boolean;
}

export const RequestsImport: FC<Props> = ({ isOpen }) => {
    const [form] = useForm();

    const [client, setClient] = useState<{ customer: string | undefined }>({ customer: undefined });
    const [location, setLocation] = useState<{ location: string | undefined }>({
        location: undefined,
    });

    const handleFormSubmit = (file: UploadChangeParam<UploadFile<unknown>>) => {
        form.validateFields().then((_values) => {
            const form = generateFormData({
                requests: file.file,
                customer: client.customer,
                location: location.location,
            });
            authFetch(`${BASE_URL_SERVER_API}${API_URLS.import_requests_url}`, {
                data: form,
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((_posts) => {
                    closeModal();
                    message.success({
                        duration: 1.5,
                        content:
                            'Файл импортируется. ' +
                            'Можно посмотреть отчет на странице Доставка/История импорта',
                    });
                })
                .catch((error) => {
                    message.error({ content: error.message });
                });
        });
    };

    return (
        <Modal
            key='requests_import'
            visible={isOpen}
            title='Импорт заявок'
            onCancel={() => {
                closeModal();
                setClient({ customer: undefined });
                setLocation({ location: undefined });

                form.setFieldsValue({ customer: undefined, location: undefined });
            }}
            footer={null}
        >
            <Form form={form}>
                <Row style={{ margin: '0 0 20px' }}>
                    <a href={`${BASE_URL_SERVER}${API_URLS.import_template_url}?v=2`}>
                        шаблон.xlsx
                    </a>
                </Row>
                <Row style={{ margin: '0 0 20px' }}>
                    <RemoteSelect.Item
                        form={form}
                        url={API_URI.AUTOCOMPLETE_COMMON_CUSTOMER}
                        name='customer'
                        placeholder='Клиент'
                        onChange={(value: string | string[]) => {
                            setClient({ customer: value.toString() });
                            setLocation({ location: undefined });
                        }}
                        onClear={() => {
                            setLocation({ location: undefined });
                        }}
                        required={false}
                    />
                </Row>
                <FieldItemWithObservableValue dependency={['customer']}>
                    <Row style={{ margin: '0 0 20px' }}>
                        <RemoteSelect.WithForward
                            form={form}
                            url={API_URI.AUTOCOMPLETE_LOCATION}
                            name='location'
                            placeholder='Филиал'
                            onChange={(value: string | string[]) => {
                                setLocation({ location: value.toString() });
                            }}
                            required={false}
                            formForward={['customer']}
                        />
                    </Row>
                </FieldItemWithObservableValue>

                {location.location && (
                    <Row style={{ margin: '0 0 20px' }}>
                        <Form.Item name='file' style={{ marginBottom: 0 }}>
                            <Upload
                                showUploadList={false}
                                onChange={handleFormSubmit}
                                beforeUpload={(_file) => {
                                    return false;
                                }}
                            >
                                <Button icon={<UploadOutlined />}>Импорт заявок</Button>
                            </Upload>
                        </Form.Item>
                    </Row>
                )}
            </Form>
        </Modal>
    );
};
