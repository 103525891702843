import { MouseEvent, useCallback } from 'react';

import { DownCircleTwoTone, UpCircleTwoTone } from '@ant-design/icons';
import { DeliveryRouteColumnProps } from '@typings';

import styles from './DeliveryRouteColumn.module.scss';

export const DeliveryRouteColumn = ({ isActive, onClick }: DeliveryRouteColumnProps) => {
    const Icon = isActive ? UpCircleTwoTone : DownCircleTwoTone;

    const handleClick = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation();
            onClick?.();
        },
        [onClick]
    );

    return (
        <div>
            <Icon style={{ paddingRight: 4 }} onClick={handleClick} className={styles.expandAll} />
            <span className={styles.title}>Мар-т</span>
        </div>
    );
};
