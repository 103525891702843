import { useCallback, useEffect, useState } from 'react';
import { Spin } from 'antd';

import { fetchMapRequest } from '@api';
import { Map, MapRequestForm } from '@components';
import {
    DeliveryMapRequest,
    DeliveryMapRequestSelectionState,
    MapRequestFormOnFinishValue,
    Nullable,
} from '@typings';
import { mapperRequestOnMap, showError } from '@utils';

const initialMapRequestZoneParams = {
    zone: '1',
};

interface MapState {
    isLoading: boolean;
    data: Nullable<DeliveryMapRequest>;
}

const initialSelectionState: DeliveryMapRequestSelectionState = {
    request: null,
    worker: null,
};

const initialMapState: MapState = {
    isLoading: false,
    data: null,
};

const RequestsOnMap = () => {
    const [selection, setSelection] = useState(initialSelectionState);
    const [{ data, isLoading }, setData] = useState(initialMapState);

    const updateMapData = useCallback(
        (params: MapRequestFormOnFinishValue = initialMapRequestZoneParams) => {
            setData((prev) => ({ ...prev, isLoading: true }));

            fetchMapRequest({
                params,
                onError(error) {
                    showError(error.message);
                    setData((prev) => ({ ...prev, isLoading: false }));
                },

                onSuccess({ data }) {
                    setData({ data: mapperRequestOnMap(data), isLoading: false });
                },
            });
        },
        []
    );

    useEffect(() => {
        updateMapData();
    }, [updateMapData]);

    return (
        <div>
            <MapRequestForm
                setSelection={setSelection}
                selection={selection}
                onSubmit={updateMapData}
                deliveryRequests={data?.deliveryRequests}
                workers={data?.workers}
                timestamp={data?.timestamp}
            />

            <Spin spinning={isLoading}>
                <Map
                    currentRequest={selection.request}
                    currentWorker={selection.worker}
                    setSelection={setSelection}
                    mapData={data}
                />
            </Spin>
        </div>
    );
};

export default RequestsOnMap;
