import { message } from 'antd';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import moment from 'moment';

import { DATE_FORMAT, DELIVERY_TABLE_FILTER_BY_STATUS } from '@constants';
import {
    AdditionalDeliveryRequestItem,
    DeliveryExpandedRequest,
    DeliveryFilterState,
    DeliveryFilterStateNames,
    DeliveryRequest,
    DeliveryRequestItem,
    DeliveryRequestItemKeys,
    DeliveryRequestItemValues,
    DeliveryRequestKeys,
    DeliveryRequestOrExpanded,
    DeliveryRequestOrExpandedKeys,
    DeliveryRequestOrExpandedRequestOrItemsKeys,
    DeliveryRequestOrExpandedRequestOrItemsValues,
    DeliveryRequestOrExpandedValues,
    DeliveryRequestsDashboardItemsNames,
    DeliverySearchFormData,
    DeliveryTableSortState,
    Nullable,
    ParsedRequestText,
    REQUEST_FIELDS_VALUES_LIST,
    SortOnValidValues,
} from '@typings';

const getFormattedTime = (time: string) => moment(time, 'HH:mm:ss').format('HH:mm');
const getValuesLocaleCompare = (
    a: DeliveryRequestOrExpandedRequestOrItemsValues,
    b: DeliveryRequestOrExpandedRequestOrItemsValues
) => a?.toString().localeCompare(b!?.toString()) || 0;

export const checkValidValues = (
    valueA: DeliveryRequestOrExpandedRequestOrItemsValues,
    valueB: DeliveryRequestOrExpandedRequestOrItemsValues,
    onValidValue: SortOnValidValues
) => {
    if (valueA === null || valueA === undefined) {
        return -1;
    }

    if (valueB === null || valueB === undefined) {
        return 1;
    }

    if (valueA === valueB) {
        return 0;
    }

    return onValidValue(valueA, valueB);
};

export function itemTextContent(
    item: DeliveryRequestItem,
    itemKey: DeliveryRequestItemKeys,
    defaultValue: string
): DeliveryRequestItemValues {
    if (!item) {
        return '-';
    }

    switch (itemKey) {
        case 'pk':
            return defaultValue;

        case 'time_interval':
            const { interval_begin, interval_end } = item;

            return `${getFormattedTime(interval_begin)}-${getFormattedTime(interval_end)}`;

        default:
            return item[itemKey] || defaultValue;
    }
}

export const textContent = (
    request: DeliveryRequestOrExpanded,
    itemOrRequestKey: DeliveryRequestOrExpandedRequestOrItemsKeys
): DeliveryRequestOrExpandedRequestOrItemsValues => {
    switch (itemOrRequestKey) {
        case 'date':
            return moment((request as DeliveryRequest).date).format(DATE_FORMAT);

        case 'status':
            return (request as DeliveryRequest).status_description || '';

        case 'customer':
            return (request as DeliveryRequest).customer.label || '';

        case 'assigned_workers':
            const workers = (request as DeliveryRequest).items.map((item) => {
                return item?.assigned_workers?.map((item) => item.name) || [];
            });

            const workersFlat = new Set(_.flatten(workers));

            return Array.from(workersFlat).join(', ');

        case 'location':
            return (request as DeliveryRequest).location.label || '';

        case 'service':
            return (request as DeliveryRequest).service.label || '';

        default:
            return (
                (request as DeliveryRequest)[itemOrRequestKey as keyof DeliveryRequest] ||
                itemTextContent(request.items[0], itemOrRequestKey as DeliveryRequestItemKeys, '')
            );
    }
};

export const copyToClipboard = (row_content: string): void => {
    message.success({ duration: 1.0, content: 'Скопировано!' });
    copy(row_content);
};

export const textContentInner = (
    request: DeliveryRequestOrExpanded,
    itemKey: DeliveryRequestItemKeys
): DeliveryRequestOrExpandedRequestOrItemsValues => {
    if (itemKey === 'time_interval' || itemKey === 'address') {
        return request.items.reduce((acc: string, item) => {
            const isShowAddress = item.workers_required;
            if (isShowAddress) {
                return (acc += `${itemTextContent(item, itemKey, '-')};`);
            }

            return acc;
        }, '');
    }

    return textContent(request, itemKey);
};

const _REQUEST_FIELDS: Record<string, REQUEST_FIELDS_VALUES_LIST> = {
    'Планируемая дата': 'date',
    'Индекс груза': 'code',
    'Общий вес, кг': 'mass',
    'Объем, м3': 'volume',
    'Кол-во мест': 'place_count',
    'Характер груза': 'shipment_type',
    'Адрес забора/доставки': 'address',
    'Временной интервал': 'time_interval',
    Водитель: 'driver_name',
    'Телефон водителя': 'driver_phones',
    'Наличие лифта': 'has_elevator',
    'Расстояние проноса': 'carrying_distance',
    Этаж: 'floor',
};

const generateRequestRegexList = (): RegExp[] => {
    const rxs: RegExp[] = [];

    for (const [key] of Object.entries(_REQUEST_FIELDS)) {
        const rx = new RegExp('^(' + key + '):\\s+(\\S.*)$');
        rxs.push(rx);
    }
    return rxs;
};

export const parseRequestText = (text: string): ParsedRequestText => {
    const result: Partial<ParsedRequestText> = {};

    const lines = text.split('\n').filter((el) => el);
    lines.forEach((line) => {
        generateRequestRegexList().forEach((rx) => {
            const [_, label, value] = line.trim().match(rx) || [];

            if (label && value) {
                const resultField = _REQUEST_FIELDS[label];

                switch (resultField) {
                    case 'date':
                        const date = moment(value, DATE_FORMAT);

                        if (date.isValid()) {
                            result[resultField] = date;
                        }

                        break;
                    case 'time_interval':
                        const [intervalBegin, intervalEnd] = value.split('-').map((date) => {
                            return moment(date, 'HH:mm');
                        });

                        if (intervalBegin.isValid() && intervalEnd.isValid()) {
                            result[resultField] = [intervalBegin, intervalEnd];
                        }

                        break;

                    case 'has_elevator':
                        const hasElevatorValue = /да/gi.test(value);

                        result[resultField] = hasElevatorValue;
                        break;

                    default:
                        result[resultField] = value.replace(',', '.');
                        break;
                }

                if (
                    resultField !== 'shipment_type' &&
                    (result[resultField] === '-' || result[resultField] === 'Нет')
                ) {
                    result[resultField] = '';
                }
            }
        });
    });

    return result as ParsedRequestText;
};

export const getValue = (
    request: DeliveryRequestOrExpanded,
    requestKey: DeliveryRequestOrExpandedKeys
): DeliveryRequestOrExpandedValues => {
    const currentValue = (request as DeliveryRequest)?.[requestKey as keyof DeliveryRequest];

    if (_.isObject(currentValue) && 'label' in currentValue) {
        return (currentValue as AdditionalDeliveryRequestItem).label;
    }

    return currentValue;
};

const getAllItemsValue = (
    { items }: DeliveryRequest,
    itemKey: DeliveryRequestItemKeys
): DeliveryRequestItemValues[] => {
    return items.reduce((acc: DeliveryRequestItemValues[], item) => {
        return [...acc, item[itemKey]];
    }, []);
};

export const getItemValue = (
    request: DeliveryRequest,
    itemKey: DeliveryRequestItemKeys
): DeliveryRequestItemValues => {
    return request?.items?.[0]?.[itemKey];
};

export const defaultSort =
    (requestKey: DeliveryRequestKeys) =>
    (requestA: DeliveryRequest, requestB: DeliveryRequest): number => {
        const sortOnValidValues: SortOnValidValues = (valueA, valueB) => {
            return Number(valueA!) < Number(valueB!) ? -1 : 1;
        };

        return checkValidValues(
            getValue(requestA, requestKey),
            getValue(requestB, requestKey),
            sortOnValidValues
        );
    };

export const stringSort =
    (requestKey: DeliveryRequestKeys) =>
    (requestA: DeliveryRequest, requestB: DeliveryRequest): number => {
        const sortOnValidValues: SortOnValidValues = (valueA, valueB) => {
            return getValuesLocaleCompare(valueA, valueB);
        };

        return checkValidValues(
            getValue(requestA, requestKey),
            getValue(requestB, requestKey),
            sortOnValidValues
        );
    };

export const numberSort =
    (requestKey: DeliveryRequestKeys) =>
    (requestA: DeliveryRequest, requestB: DeliveryRequest): number => {
        const sortOnValidValues: SortOnValidValues = (valueA, valueB) => {
            return parseInt(valueA?.toString() || '') - parseInt(valueB?.toString() || '');
        };

        return checkValidValues(
            getValue(requestA, requestKey),
            getValue(requestB, requestKey),
            sortOnValidValues
        );
    };

export const dateSort =
    (requestKey: DeliveryRequestKeys) =>
    (requestA: DeliveryRequest, requestB: DeliveryRequest): number => {
        const sortOnValidValues: SortOnValidValues = (valueA, valueB) => {
            return (
                new Date(valueA?.toString() || '').getTime() -
                new Date(valueB?.toString() || '').getTime()
            );
        };

        return checkValidValues(
            getValue(requestA, requestKey),
            getValue(requestB, requestKey),
            sortOnValidValues
        );
    };

export const labelSort =
    (requestKey: DeliveryRequestKeys) =>
    (requestA: DeliveryRequest, requestB: DeliveryRequest): number => {
        const sortOnValidValues: SortOnValidValues = (valueA, valueB) => {
            return getValuesLocaleCompare(valueA, valueB);
        };

        return checkValidValues(
            getValue(requestA, requestKey),
            getValue(requestB, requestKey),
            sortOnValidValues
        );
    };

export const allItemsFloatFieldSort =
    (itemKey: DeliveryRequestItemKeys) =>
    (requestA: DeliveryRequest, requestB: DeliveryRequest): number => {
        const getSumItemsValues = (itemsValues: DeliveryRequestItemValues[]) => {
            return itemsValues.reduce((acc: number, itemValue) => {
                return (acc += Number(itemValue));
            }, 0);
        };

        const sortOnValidValues: SortOnValidValues = (valueA, valueB) => {
            return Number(valueA) - Number(valueB);
        };

        return checkValidValues(
            getSumItemsValues(getAllItemsValue(requestA, itemKey)),
            getSumItemsValues(getAllItemsValue(requestB, itemKey)),
            sortOnValidValues
        );
    };

export const itemFloatFieldSort =
    (itemKey: DeliveryRequestItemKeys) =>
    (requestA: DeliveryRequest, requestB: DeliveryRequest): number => {
        const sortOnValidValues: SortOnValidValues = (valueA, valueB) => {
            return Number(valueA) - Number(valueB);
        };

        return checkValidValues(
            getItemValue(requestA, itemKey),
            getItemValue(requestB, itemKey),
            sortOnValidValues
        );
    };

export const itemStringFieldSort =
    (itemKey: DeliveryRequestItemKeys) =>
    (requestA: DeliveryRequest, requestB: DeliveryRequest): number => {
        const sortOnValidValues: SortOnValidValues = (valueA, valueB) => {
            return getValuesLocaleCompare(valueA, valueB);
        };

        return checkValidValues(
            getItemValue(requestA, itemKey),
            getItemValue(requestB, itemKey),
            sortOnValidValues
        );
    };

export const mapperDeliveryStateToFormData = (state: Partial<DeliveryFilterState>) => {
    const formatMultiplyItem = (value?: string) => {
        if (!value) {
            return undefined;
        }
        return value.split(',');
    };

    const formattedState = {
        ...state,
        zone: formatMultiplyItem(state.zone),
        operator: formatMultiplyItem(state.operator),
    };

    return Object.entries(formattedState).map(([name, value]) => ({
        value,
        name: name as DeliveryFilterStateNames,
    }));
};

export const mapperSearchFinishFormDataToFetchData = ({
    datepicker,
    ...data
}: DeliverySearchFormData): DeliveryFilterState => {
    const formatDates = (dates: moment.Moment[]) => {
        return dates.map((date) => date.format(DATE_FORMAT));
    };

    const getFlag = (data: boolean) => data || undefined;
    const [firstDay, lastDay] = formatDates(datepicker);

    return {
        ...data,
        operator: data.operator?.join(','),
        zone: data.zone?.join(','),
        in_progress: getFlag(data.in_progress),
        can_merge: getFlag(data.can_merge),
        unprofitable: getFlag(data.unprofitable),
        first_day: firstDay,
        last_day: lastDay,
    };
};

export const filterDeliveryRequestListByStatus = (
    rows: DeliveryRequest[],
    filterStatus: Nullable<DeliveryRequestsDashboardItemsNames>
) => {
    if (!filterStatus) {
        return rows;
    }

    return rows.filter(({ status }) => {
        return DELIVERY_TABLE_FILTER_BY_STATUS.get(status) === filterStatus;
    });
};

export const sortDeliveryRequestList = (
    rows: DeliveryRequest[],
    sortState?: DeliveryTableSortState
): DeliveryRequest[] => {
    if (!sortState) {
        return rows;
    }

    const copyRow = [...rows];
    const sorter = sortState.column.sorter;

    if (sorter) {
        if (sortState.direction === 'asc') {
            copyRow.sort((a, b) => sorter(a, b));
        } else {
            copyRow.sort((a, b) => sorter(b, a));
        }
    }

    return copyRow;
};

export const isUnwrappedRequest = (
    request: DeliveryRequestOrExpanded
): request is DeliveryExpandedRequest => {
    return 'parent_pk' in request;
};

export const getRequestPk = (request: DeliveryRequestOrExpanded) => {
    if ('pk' in request) {
        return request.pk;
    }

    return request.parent_pk;
};

export const isMergedRequest = (request: DeliveryRequestOrExpanded) => {
    return request.items.length === 1;
};
