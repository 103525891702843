import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Button, DatePicker, Form, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';

import { DATE_FORMAT } from '@constants';

type CustomDatePickerRangeOnSendProps = {
    placeholder?: [string, string];
    onChange: (values: FormValues) => void;
    first_day: string;
    last_day: string;
    showYearSelector?: boolean;
    showMonthSelector?: boolean;
};

type DatePickerState = [moment.Moment, moment.Moment];

interface FormValues {
    datePicker?: DatePickerState;
    full_date?: DatePickerState;
    year?: number;
    month?: number;
    status: string;
}

const START_YEAR = 2015;
const LAST_YEAR = new Date().getFullYear() + 2;
const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

const CustomDatePickerRangeOnSend: FC<CustomDatePickerRangeOnSendProps> = ({
    placeholder,
    onChange,
    first_day,
    last_day,
    showYearSelector,
    showMonthSelector,
}) => {
    showYearSelector = _.defaults(showYearSelector, true).valueOf();
    showMonthSelector = _.defaults(showMonthSelector, true).valueOf();

    const [initialState] = useState({ firstDay: first_day, lastDay: last_day });
    const initialDate: DatePickerState = [
        moment(initialState.firstDay, DATE_FORMAT),
        moment(initialState.firstDay, DATE_FORMAT),
    ];

    const initialValue: Partial<FormValues> = useMemo(
        () => ({
            full_date: initialDate,
            year: moment(initialState.firstDay, DATE_FORMAT).year(),
        }),
        [initialState, initialDate]
    );

    const [value, setValue] = useState(initialValue);

    const isInitialMount = useRef(true);
    const isInitialMountValue = useRef(true);
    const [form] = Form.useForm();

    const options = new Array(LAST_YEAR - START_YEAR)
        .fill(0)
        .map((_item, i) => START_YEAR + i)
        .reverse();

    const updateForm = (newValue: Partial<FormValues>) => {
        setValue((value) => ({ ...value, ...newValue }));
    };

    const onChangeDate = (newValue: RangeValue<moment.Moment>) => {
        updateForm({ full_date: newValue as DatePickerState });
    };

    const onChangeMonth = (month: number) => {
        const first_day = moment().year(value.year!!).month(month).startOf('month');
        const last_day = moment().year(value.year!!).month(month).endOf('month');

        updateForm({ full_date: [first_day, last_day] });
    };

    const onChangeYear = (year: number) => {
        const newValue = value?.full_date?.map((item) => {
            const date = item.toDate();
            date.setFullYear(year);

            return moment(date, DATE_FORMAT);
        }) as DatePickerState;

        updateForm({ full_date: newValue, year: +year });
    };

    const stateDate = value.full_date;

    useEffect(() => {
        if (stateDate === null) {
            setValue(initialValue);
            return;
        }
        if (isInitialMount.current) {
            isInitialMount.current = false;
        }
    }, [stateDate, initialValue]);

    useEffect(() => {
        if (isInitialMountValue.current) {
            isInitialMountValue.current = false;
        } else {
            isInitialMount.current = true;
            setValue((prevValue) => ({
                ...prevValue,
                full_date: [moment(first_day, DATE_FORMAT), moment(last_day, DATE_FORMAT)],
            }));
        }
    }, [first_day, last_day]);

    useEffect(() => {
        form.setFieldsValue(value);
    }, [form, value]);

    const statuses = [
        { label: 'новый', value: 'new' },
        { label: 'одобрен', value: 'confirmed' },
        { label: 'одобрен, ждем поступления', value: 'confirmed_waiting_supply' },
        { label: 'одобрен, поступил', value: 'confirmed_supplied' },
        { label: 'отклонен', value: 'rejected' },
        { label: 'оплачен', value: 'paid' },
    ];

    const onFinish = (formData: FormValues) => {
        onChange(formData);
    };

    return (
        <Form layout='inline' form={form} onFinish={onFinish}>
            {showYearSelector && (
                <Form.Item name='year'>
                    <Select
                        style={{ width: 75 }}
                        onChange={onChangeYear}
                        options={options.map((item) => ({ value: item, label: item }))}
                    />
                </Form.Item>
            )}

            {showMonthSelector && (
                <Form.Item name='month'>
                    <Select
                        style={{ width: 120 }}
                        placeholder='Месяц'
                        onChange={onChangeMonth}
                        options={months.map((item, i) => ({ value: i, label: item }))}
                    />
                </Form.Item>
            )}
            <Form.Item name='full_date'>
                <DatePicker.RangePicker
                    placeholder={placeholder}
                    format={DATE_FORMAT}
                    onChange={onChangeDate}
                />
            </Form.Item>
            <Form.Item name='status'>
                <Select allowClear style={{ width: 210 }} placeholder='Статус' options={statuses} />
            </Form.Item>
            <Form.Item>
                <Button type='primary' htmlType='submit'>
                    Показать
                </Button>
            </Form.Item>
        </Form>
    );
};

const isEqual = (
    prevProps: CustomDatePickerRangeOnSendProps,
    nextProps: CustomDatePickerRangeOnSendProps
) => {
    return (
        Object.keys(prevProps).length === Object.keys(nextProps).length &&
        (Object.keys(prevProps) as Array<keyof CustomDatePickerRangeOnSendProps>)
            .filter((param) => param !== 'placeholder')
            .every((param) => prevProps[param] === nextProps[param])
    );
};

export default memo(CustomDatePickerRangeOnSend, isEqual);
