import { FC, memo, useCallback, useState } from 'react';

import { useUpdateRequest } from '@hooks';
import { DeliveryRequestMutationDefaultResponse, Response, TableCellProps } from '@typings';
import { getCellValue, getRequestPk, isNotReadonlyCell, normalizeDeliveryRequest } from '@utils';

import { ActionsCell } from './ActionsCell';
import { CurrentCell } from './CurrentCell';
import { TooltipCell } from './TooltipCell';

export const TableCell: FC<TableCellProps> = memo(
    ({ column, rowData, setRowWithFormat, setRequest }) => {
        const doUpdateRequest = useUpdateRequest();
        const [cellState, setCellState] = useState({
            isEditing: false,
            isFetching: false,
        });
        const { key } = column;

        const onCommit = useCallback(
            async (value: NonNullable<string | boolean>) => {
                const onFinnal = () => {
                    setCellState({ isEditing: false, isFetching: false });
                };

                const onSuccess = ({ data }: Response<DeliveryRequestMutationDefaultResponse>) => {
                    setRowWithFormat(normalizeDeliveryRequest(data.data.request), rowData);
                    onFinnal();
                };

                setCellState({ isEditing: true, isFetching: true });

                doUpdateRequest({
                    pk: getRequestPk(rowData),
                    items: rowData.items,
                    onSuccess,
                    field: key,
                    onError: onFinnal,
                    value,
                });
            },
            [key, setRowWithFormat]
        );

        const onClick = () => {
            if (isNotReadonlyCell(key, rowData)) {
                setCellState((prev) => ({ ...prev, isEditing: true }));
            }
        };

        const style = key === 'address' ? { maxWidth: '360px' } : {};
        const className = `delivery_table_cell delivery_table_cell_${key}`;

        const CurrentCellEl = (
            <CurrentCell
                isEditing={cellState.isEditing}
                isFetching={cellState.isFetching}
                onCommit={onCommit}
                cellKey={key}
                setRowWithFormat={setRowWithFormat}
                setRequest={setRequest}
                columnDataIndex={column.dataIndex}
                cellValue={getCellValue(column, rowData)}
                rowData={rowData}
            />
        );

        if (cellState.isEditing) {
            return (
                <td className='editable' style={{ maxWidth: 'none' }}>
                    {CurrentCellEl}
                </td>
            );
        }

        return (
            <td className={className} onClick={onClick} style={style}>
                <TooltipCell cellKey={key} isFetching={cellState.isFetching}>
                    {CurrentCellEl}
                </TooltipCell>
                <ActionsCell column={column} isFetching={cellState.isFetching} />
                {CurrentCellEl}
            </td>
        );
    }
);
