import { FC } from 'react';
import Viewer from 'react-viewer';
import { Image } from 'antd';
import clsx from 'clsx';

import { BASE_URL_SERVER } from '@constants';
import { SetState } from '@typings';

import styles from './coverImage.module.scss';
interface Props {
    status?: string;
    initialUrl: string;
    showAction: boolean;
    setShowAction: SetState<boolean>;
}

const staticImage = 'https://www.eyurtlar.com/images/gorsel-yok.jpg';

const getUrl = (url: string) => {
    if (url) {
        return `${BASE_URL_SERVER}media/${url}`;
    }

    return staticImage;
};
export const CoverImage: FC<Props> = ({ setShowAction, showAction, initialUrl, status }) => {
    const currentImg = getUrl(initialUrl);

    const onViewClose = () => {
        setShowAction(false);
    };

    const onViewOpen = () => {
        setShowAction(true);
    };

    const imgStyles = clsx(styles.img, {
        [styles.img_new]: status === 'new',
    });

    return (
        <>
            <Image
                onClick={onViewOpen}
                src={currentImg}
                fallback={staticImage}
                className={imgStyles}
                preview={{
                    visible: false,
                }}
            />

            <Viewer
                noNavbar
                visible={showAction}
                className={styles.viewer}
                images={[{ src: currentImg }]}
                onClose={onViewClose}
                changeable={false}
            />
        </>
    );
};
