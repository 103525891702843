import { FC, memo, useState } from 'react';

import RemoteSelect from '@components/RemoteSelect';
import { API_URI } from '@constants';
import { AdditionalDeliveryRequestItem, DeliveryRequestCellProps } from '@typings';
import { textContent } from '@utils/delivery';

export const Service: FC<DeliveryRequestCellProps> = memo(({ rowData, isEditing, onCommit }) => {
    const [service, setService] = useState(rowData.service);

    const commit = (service: Pick<AdditionalDeliveryRequestItem, 'key'>) => {
        const value = service?.key?.toString();

        onCommit?.(value);
    };

    const onChange = (value: string | string[]) => {
        setService({ key: Number(value.toString()), label: '' });
        commit({ key: Number(value.toString()) });
    };

    const onBlur = () => {
        commit(service);
    };

    if (isEditing) {
        return (
            <RemoteSelect.Form
                url={API_URI.AUTOCOMPLETE_SERVICE}
                name='request'
                placeholder='Тариф'
                onChange={onChange}
                style={{ width: 160 }}
                defaultOpen
                autoFocus
                onBlur={onBlur}
                externalForward={{
                    customer: rowData.customer.key,
                    delivery_request_pk: rowData.pk,
                }}
            />
        );
    }

    return <>{textContent(rowData, 'service')}</>;
});
