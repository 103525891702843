import { createContext, FC, useCallback, useMemo } from 'react';

import { DeliveryRequestDetail, Nullable, SetRowWithFormat, SetState } from '@typings';

interface IDeliveryWorkersOnAddressContext {
    updateTable: (requestDetail: Nullable<DeliveryRequestDetail>) => void;
}

export const DeliveryWorkersOnAddressContext = createContext<IDeliveryWorkersOnAddressContext>(
    {} as IDeliveryWorkersOnAddressContext
);

interface Props {
    onUpdate: SetState<Nullable<DeliveryRequestDetail>>;
    setRowWithFormat: SetRowWithFormat;
}

export const DeliveryWorkersOnAddressProvider: FC<Props> = ({
    onUpdate,
    setRowWithFormat,
    children,
}) => {
    const updateTable = useCallback(
        (requestDetail: Nullable<DeliveryRequestDetail>) => {
            onUpdate(requestDetail);

            if (requestDetail?.request) {
                setRowWithFormat(requestDetail.request);
            }
        },
        [onUpdate, setRowWithFormat]
    );

    const value = useMemo(
        () => ({
            updateTable,
        }),
        [updateTable]
    );

    return (
        <DeliveryWorkersOnAddressContext.Provider value={value}>
            {children}
        </DeliveryWorkersOnAddressContext.Provider>
    );
};
