import { FC } from 'react';
import { Button, Table as UITable } from 'antd';
import { useStore } from 'effector-react';

import { CameraTwoTone } from '@ant-design/icons';
import { API_URLS, BASE_URL_SERVER } from '@constants';
import { DeliveryRequestDetail, DeliveryRequestWorkerItem, Nullable } from '@typings';
import { getWorkersOnAddress } from '@utils/deliveryWorkersModal';
import { formatToMoney } from '@utils/formatToMoney';

import {
    RemoveButton,
    RemoveFromItemActions,
    RenderAction,
    RenderHours,
    RenderPhone,
    RenderStatus,
} from './cells';
import { $requestDetail, reloadTableFx, setRequestDetailFx } from './model';

import styles from './DeliveryWorkerStatus.module.scss';

interface Props {
    request: number;
    item: Nullable<number>;
}

interface Worker {
    key: string;
    name: {
        name: string;
        id: number;
    };
    photoAction: number;

    phone: {
        phone: string;
        worker_id: number;
    };
    status: number;
    amount: string;
    workerdHours: {
        workerdHours: string;
        worker_id: number;
        onUpdate(data: DeliveryRequestDetail): void;
    };
    totalHours: string;
    id: number;
    action: {
        worker_id: number;
        onUpdate(data: DeliveryRequestDetail): void;
        statusData: DeliveryRequestWorkerItem;
    };
}

export const Table: FC<Props> = ({ request, item }) => {
    const requestDetail = useStore($requestDetail);
    const workersOnItem = getWorkersOnAddress(requestDetail.item_workers);

    const updateTable = (data: DeliveryRequestDetail) => {
        setRequestDetailFx(data);
        reloadTableFx();
    };

    const columns = [
        {
            title: 'ФИО',
            dataIndex: 'name',
            key: 'name',
            render: ({ id, name }: Worker['name']) => {
                const worker_url = API_URLS.worker_url_template.replace('12345', id.toString());

                return (
                    <a href={`${BASE_URL_SERVER}${worker_url}`} target='_blank' rel='noreferrer'>
                        {name}
                    </a>
                );
            },
        },
        {
            title: 'Телефон',
            dataIndex: 'phone',
            key: 'phone',
            render: ({ phone, worker_id }: Worker['phone']) => (
                <RenderPhone phone={phone} worker_id={worker_id} />
            ),
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
            render: (code: number) => <RenderStatus code={code} />,
        },
        {
            title: 'Часы на работу',
            dataIndex: 'workerdHours',
            key: 'workerdHours',
            render: ({ workerdHours, worker_id, onUpdate }: Worker['workerdHours']) => (
                <RenderHours
                    item={item}
                    onUpdate={onUpdate}
                    request={request}
                    worker={worker_id}
                    hours={workerdHours}
                />
            ),
        },
        {
            title: 'Часы всего',
            dataIndex: 'totalHours',
            key: 'totalHours',
            render: (totalHours: string) => <div>{totalHours}</div>,
        },
        {
            title: 'Сумма',
            dataIndex: 'amount',
            key: 'amount',
            render: formatToMoney,
        },
        {
            title: 'Действие',
            dataIndex: 'action',
            key: 'action',
            render: ({ onUpdate, statusData, worker_id }: Worker['action']) => (
                <RenderAction onUpdate={onUpdate} statusData={statusData} worker_id={worker_id} />
            ),
        },
        {
            title: '',
            dataIndex: 'photoAction',
            key: 'photoAction',
            render: (workerId: number) => (
                <a
                    href={`/rf/delivery/photos_dashboard/?request=${request}&worker=${workerId}`}
                    target='_blank'
                    rel='noreferrer'
                >
                    <Button>
                        <CameraTwoTone />
                    </Button>
                </a>
            ),
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id',
            render: (workerId: number) => {
                const workerOnAdress =
                    !!item && !!workersOnItem?.[item]?.includes(workerId.toString());

                return <RemoveFromItemActions workerOnAdress={workerOnAdress} worker={workerId} />;
            },
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'id_button',
            render: (id: number) => <RemoveButton worker={id} />,
        },
    ];

    const dataSource =
        (item &&
            requestDetail?.workers
                ?.filter(({ id }) => {
                    return requestDetail.item_workers?.[item]?.[id] || false;
                })
                .map(({ id, name, phone, total_hours, work_hours, amount }) => {
                    return {
                        key: `${item}-${id}`,
                        name: {
                            name,
                            id,
                        },
                        photoAction: id,

                        phone: {
                            phone,
                            worker_id: id,
                        },
                        status: requestDetail.item_workers[item][id].status,
                        amount,
                        workerdHours: {
                            workerdHours: work_hours || '0',
                            worker_id: id,
                            onUpdate: updateTable,
                        },
                        totalHours: total_hours || '0',
                        id,
                        action: {
                            statusData: requestDetail.item_workers[item][id],
                            worker_id: id,
                            onUpdate: updateTable,
                        },
                    };
                })) ||
        [];

    return (
        <UITable<Worker>
            className={styles.modalTableDefault}
            size='small'
            dataSource={dataSource}
            pagination={false}
            columns={columns}
        />
    );
};
