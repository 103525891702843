import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

import {
    DeliveryMapRequestBoundingBox,
    DeliveryMapRequestItem,
    DeliveryMapRequestLocation,
    DeliveryMapRequestMarkerItem,
    DeliveryMapRequestMarkerType,
    DeliveryMapRequestSelectionState,
    DeliveryMapRequestWorkerItem,
    Nullable,
} from '@typings';

import { useMarker } from './useMarker';

export interface Params {
    boundingBox: DeliveryMapRequestBoundingBox | undefined;
    requestsList: DeliveryMapRequestItem[];
    workersList: DeliveryMapRequestWorkerItem[];
    currentRequest: DeliveryMapRequestSelectionState['request'];
    currentWorker: DeliveryMapRequestSelectionState['worker'];
}
type AvgLocation = {
    latitude: number;
    longitude: number;
};

const initialAvgLocation: AvgLocation = {
    latitude: 0,
    longitude: 0,
};

const minLL = [55.754191, 37.620523];
const maxLL = [55.754191, 37.620523];

export const useChangeView = ({
    boundingBox,
    requestsList,
    workersList,
    currentRequest,
    currentWorker,
}: Params) => {
    const map = useMap();
    const { isMarkerActive } = useMarker({ currentRequest, currentWorker });

    useEffect(() => {
        const isValidMarker = (
            markerItem: DeliveryMapRequestMarkerItem,
            markerType: DeliveryMapRequestMarkerType
        ) => {
            const isActive = isMarkerActive(markerItem, markerType);

            const isDefaultStatus = isActive === null;

            return isActive || isDefaultStatus;
        };

        let minLat: Nullable<number> = null;
        let minLon: Nullable<number> = null;
        let maxLat: Nullable<number> = null;
        let maxLon: Nullable<number> = null;

        const locations: DeliveryMapRequestLocation[] = [];

        for (const worker of workersList) {
            if (isValidMarker(worker, 'worker')) {
                locations.push(worker.location);
            }
        }

        for (const request of requestsList) {
            if (isValidMarker(request, 'request')) {
                for (const requestItem of request.items) {
                    locations.push(requestItem.location);
                }
            }
        }

        if (!locations.length && boundingBox) {
            minLat = boundingBox.min_latitude;
            minLon = boundingBox.min_longitude;
            maxLat = boundingBox.max_latitude;
            maxLon = boundingBox.max_longitude;
        } else {
            let avgLocation = locations.reduce((acc: AvgLocation, location) => {
                const { latitude, longitude } = location || initialAvgLocation;

                return {
                    latitude: acc.latitude + latitude,
                    longitude: acc.longitude + longitude,
                };
            }, initialAvgLocation);

            avgLocation = {
                latitude: avgLocation.latitude / locations.length,
                longitude: avgLocation.longitude / locations.length,
            };

            locations.forEach((location) => {
                const lat = Number(location?.latitude || null);
                const lon = Number(location?.longitude || null);

                if (
                    currentWorker === null &&
                    currentRequest === null &&
                    (Math.abs(lat - avgLocation.latitude) > 1.5 ||
                        Math.abs(lon - avgLocation.longitude) > 1.5)
                ) {
                    return;
                }

                if (minLat === null || lat < minLat) {
                    minLat = lat;
                }
                if (minLon === null || lon < minLon) {
                    minLon = lon;
                }
                if (maxLat === null || lat > maxLat) {
                    maxLat = lat;
                }
                if (maxLon === null || lon > maxLon) {
                    maxLon = lon;
                }
            });
        }

        if (maxLat!! - minLat!! < 0.03) {
            maxLat = maxLat!! + 0.01;
            minLat = minLat!! - 0.01;
        }
        if (maxLon!! - minLon!! < 0.03) {
            maxLon = maxLon!! + 0.01;
            minLat = minLat!! - 0.01;
        }

        if (minLat !== null) {
            map.fitBounds([
                [minLat, minLon!!],
                [maxLat!!, maxLon!!],
            ]);
        }
    }, [
        map,
        boundingBox,
        workersList,
        requestsList,
        currentRequest,
        currentWorker,
        isMarkerActive,
    ]);

    useEffect(() => {
        map.fitBounds([
            [minLL[0], minLL[1]],
            [maxLL[0], maxLL[1]],
        ]);
    }, [map, minLL, maxLL]);
};
