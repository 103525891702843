import { FC, useRef, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';

interface IUploadPhoto {
    request: number | undefined;
    label: string;
    onLoad: (file: File) => void;
    onRemove?: () => void;
    viewFilename?: boolean;
    multiple?: boolean;
}

export const UploadPhoto: FC<IUploadPhoto> = ({
    request,
    label,
    onLoad,
    onRemove,
    viewFilename,
}) => {
    const [reload, setReload] = useState(0);
    const photoRef = useRef<HTMLInputElement>(null);

    const [photo, setPhoto] = useState<File | null>(null);

    const labelText = label || 'Загрузить фото';
    const photoName = photo
        ? photo.name.length < 15
            ? `${photo.name.slice(0, 15)}(...).${photo.name.split('.').reverse()[0]}`
            : photo.name
        : '';

    const uploadAction = () => {
        if (photoRef) {
            (photoRef.current as HTMLInputElement).click();
        }
    };

    const removeFile = () => {
        if (request) {
            setPhoto(null);
            setReload(reload + 1);

            onRemove?.();
        }
    };

    const containerStyle = {
        display: 'flex',
        FlexDirection: 'row',
        alignItems: 'center',
    };

    const renderFilename = viewFilename ? (
        request ? (
            photo ? (
                <>
                    {` (${photoName}) `}
                    <CloseOutlined onClick={removeFile} style={{ color: '#f44336' }} />
                </>
            ) : null
        ) : null
    ) : null;

    return (
        <div style={containerStyle} onClick={() => uploadAction()}>
            {labelText}
            {renderFilename}
            <input
                ref={photoRef}
                style={{ display: 'none' }}
                type='file'
                onChange={(event) => {
                    if (request) {
                        const file = (event?.target as HTMLInputElement)?.files?.[0] || null;

                        setPhoto(file);

                        if (file) {
                            onLoad(file);
                        }
                    }
                }}
            />
        </div>
    );
};
