import { sendRequest } from '@api';
import { API_URI, jsonTypeRequestHeaders } from '@constants';
import {
    DashboardGetPhotoListResponse,
    DeliveryAddAdditinalPhotosParams,
    DeliveryAddAdditinalPhotosResponse,
    DeliveryAdditionalPhotoListParams,
    DeliveryCreateRequestItemParams,
    DeliveryCreateRequestParams,
    DeliveryFetchImportHistoryParams,
    DeliveryFetchImportHistoryResponse,
    DeliveryFetchReconciliationsCalendarParams,
    DeliveryFetchReconciliationsCalendarResponse,
    DeliveryFetchReconciliationsParams,
    DeliveryFetchReconciliationsResponse,
    DeliveryFetchRequestCountParams,
    DeliveryFetchRequestCountResponse,
    DeliveryGetAdditionalPhotoListResponse,
    DeliveryItemWorkerConfirmFinish,
    DeliveryItemWorkerStart,
    DeliveryListResponse,
    DeliveryMapRequestParams,
    DeliveryMapRequestResponse,
    DeliveryMoveItemParams,
    DeliveryMoveRequestItemResponse,
    DeliveryPhotoListParams,
    DeliveryRequestDetailParams,
    DeliveryRequestDetailResponse,
    DeliveryRequestFinishHours,
    DeliveryRequestMutationDefaultResponse,
    DeliveryUpdateItemRequest,
    DeliveryUpdateRequest,
    DeliveryWorkerConfirmPhoto,
    DeliveryWorkerMapDataParams,
    DeliveryWorkerMapDataResponse,
    DeliveryWorkerRejectPhoto,
    DeliveryWorkerStartDiscrepancyParams,
    PhotoDashboardActionResponse,
    ServiceFetchType,
    WorkerAddItemParams,
    WorkerAddParams,
    WorkerConfirmParams,
    WorkerDeleteItemParams,
    WorkerFinishItemParams,
    WorkerRemoveItemParams,
    WorkerStartItemParams,
} from '@typings';
import { generateFormData } from '@utils/generateFormData';

/**
 * @see https://wiki.yandex.ru/photos/backoffice/request-actions/
 * @see https://wiki.yandex.ru/photos/backoffice/request-list-retrieve/
 */

export const fetchRequestData: ServiceFetchType<DeliveryListResponse> = ({
    params,
    onSuccess,
    onError,
}) => {
    sendRequest<DeliveryListResponse>(API_URI.DELIVERY_LIST, { method: 'GET', params })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const moveRequestItem: ServiceFetchType<
    DeliveryMoveRequestItemResponse,
    DeliveryMoveItemParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<DeliveryMoveRequestItemResponse>(API_URI.DELIVERY_MOVE_ITEM, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const createRequest: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    DeliveryCreateRequestParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_CREATE_REQUEST, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const createRequestItem: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    DeliveryCreateRequestItemParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_CREATE_ITEM_REQUEST, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchRequestDetail: ServiceFetchType<
    DeliveryRequestDetailResponse,
    DeliveryRequestDetailParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<DeliveryRequestDetailResponse>(API_URI.DELIVERY_REQUEST_DETAIL, {
        method: 'GET',
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchDeliveryPhotos: ServiceFetchType<
    DashboardGetPhotoListResponse,
    DeliveryPhotoListParams
> = ({ params, onError, onSuccess }) => {
    sendRequest<DashboardGetPhotoListResponse>(API_URI.DELIVERY_PHOTOS, {
        method: 'GET',
        params,
    })
        .then((data) => {
            onSuccess?.(data);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchDeliveryAdditionalPhotos: ServiceFetchType<
    DeliveryGetAdditionalPhotoListResponse,
    DeliveryAdditionalPhotoListParams
> = ({ params, onError, onSuccess }) => {
    sendRequest<DeliveryGetAdditionalPhotoListResponse>(API_URI.DELIVERY_ADDITIONAL_PHOTOS, {
        method: 'GET',
        params,
    })
        .then((data) => {
            onSuccess?.(data);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const updateDeliveryFinishHours: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    DeliveryRequestFinishHours
> = ({ params, onError, onSuccess }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_FINISH_HOURS, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const updateRequest: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    DeliveryUpdateRequest
> = ({ params, onError, onSuccess }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_UPDATE_REQUEST, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        data: params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const updateRequestItem: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    DeliveryUpdateItemRequest
> = ({ params, onError, onSuccess }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_UPDATE_ITEM_REQUEST, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        data: params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const confirmItemWorkerStartPhoto: ServiceFetchType<
    PhotoDashboardActionResponse,
    DeliveryWorkerConfirmPhoto
> = ({ params, onError, onSuccess }) => {
    sendRequest<PhotoDashboardActionResponse>(API_URI.DELIVERY_ITEM_WORKER_START_PHOTO_CONFIRM, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const confirmItemWorkerFinishPhoto: ServiceFetchType<
    PhotoDashboardActionResponse,
    DeliveryWorkerConfirmPhoto
> = ({ params, onError, onSuccess }) => {
    sendRequest<PhotoDashboardActionResponse>(API_URI.DELIVERY_ITEM_WORKER_FINISH_PHOTO_CONFIRM, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const rejectItemWorkerStartPhoto: ServiceFetchType<
    PhotoDashboardActionResponse,
    DeliveryWorkerRejectPhoto
> = ({ params, onError, onSuccess }) => {
    sendRequest<PhotoDashboardActionResponse>(API_URI.DELIVERY_ITEM_WORKER_START_PHOTO_REJECT, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const rejectItemWorkerFinishPhoto: ServiceFetchType<
    PhotoDashboardActionResponse,
    DeliveryWorkerRejectPhoto
> = ({ params, onError, onSuccess }) => {
    sendRequest<PhotoDashboardActionResponse>(API_URI.DELIVERY_ITEM_WORKER_FINISH_PHOTO_REJECT, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};
export const confirmItemWorkerStart: ServiceFetchType<
    PhotoDashboardActionResponse,
    DeliveryItemWorkerStart
> = ({ params, onError, onSuccess }) => {
    sendRequest<PhotoDashboardActionResponse>(API_URI.DELIVERY_ITEM_WORKER_START_CONFIRM, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const confirmItemWorkerFinish: ServiceFetchType<
    PhotoDashboardActionResponse,
    DeliveryItemWorkerConfirmFinish
> = ({ params, onError, onSuccess }) => {
    sendRequest<PhotoDashboardActionResponse>(API_URI.DELIVERY_ITEM_WORKER_FINISH_CONFIRM, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const deleteItemWorker: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    WorkerDeleteItemParams
> = ({ params, onError, onSuccess }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_ITEM_WORKER_DELETE, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        data: params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const removeItemWorker: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    WorkerRemoveItemParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_ITEM_WORKER_REMOVE, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const addWorker: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    WorkerAddParams
> = ({ params, onError, onSuccess }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_WORKER_ADD, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const addItemWorker: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    WorkerAddItemParams
> = ({ params, onSuccess, onError }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_ITEM_WORKER_ITEM_ADD, {
        method: 'POST',
        data: params,
        headers: jsonTypeRequestHeaders,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const startItemWorker: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    WorkerStartItemParams
> = ({ params, onError, onSuccess }) => {
    // * be sure to use the formData
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_ITEM_WORKER_START, {
        method: 'POST',
        data: generateFormData(params),
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const finishItemWorker: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    WorkerFinishItemParams
> = ({ params, onError, onSuccess }) => {
    // * be sure to use the formData
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_ITEM_WORKER_FINISH, {
        method: 'POST',
        data: generateFormData(params),
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const confirmWorker: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    WorkerConfirmParams
> = ({ params, onError, onSuccess }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(API_URI.DELIVERY_WORKER_CONFIRM, {
        method: 'POST',
        headers: jsonTypeRequestHeaders,
        data: params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const startWorkerDiscrepancy: ServiceFetchType<
    DeliveryRequestMutationDefaultResponse,
    DeliveryWorkerStartDiscrepancyParams
> = ({ params, onError, onSuccess }) => {
    sendRequest<DeliveryRequestMutationDefaultResponse>(
        API_URI.DELIVERY_ITEM_WORKER_START_DISCREPANCY,
        {
            method: 'POST',
            headers: jsonTypeRequestHeaders,
            data: params,
        }
    )
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchWorkerMapData: ServiceFetchType<
    DeliveryWorkerMapDataResponse,
    DeliveryWorkerMapDataParams
> = ({ params, onError, onSuccess }) => {
    sendRequest<DeliveryWorkerMapDataResponse>(API_URI.DELIVERY_WORKER_MAP_DATA, {
        method: 'GET',
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const addDeliveryAdditinalPhotos: ServiceFetchType<
    DeliveryAddAdditinalPhotosResponse,
    DeliveryAddAdditinalPhotosParams
> = ({ params, onError, onSuccess }) => {
    sendRequest<DeliveryAddAdditinalPhotosResponse>(API_URI.DELIVERY_ADDITIONAL_PHOTOS_ADD, {
        method: 'POST',
        data: generateFormData(params),
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchMapRequest: ServiceFetchType<
    DeliveryMapRequestResponse,
    DeliveryMapRequestParams
> = ({ onError, onSuccess, params }) => {
    sendRequest<DeliveryMapRequestResponse>(API_URI.MAP_REQUEST, {
        method: 'GET',
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchReconciliationsCalendar: ServiceFetchType<
    DeliveryFetchReconciliationsCalendarResponse,
    DeliveryFetchReconciliationsCalendarParams
> = ({ onError, onSuccess, params }) => {
    sendRequest<DeliveryFetchReconciliationsCalendarResponse>(
        API_URI.DELIVERY_RECONCILIATIONS_CALENDAR,
        {
            method: 'GET',
            params,
        }
    )
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchReconciliations: ServiceFetchType<
    DeliveryFetchReconciliationsResponse,
    DeliveryFetchReconciliationsParams
> = ({ onError, onSuccess, params }) => {
    const url = `${API_URI.DELIVERY_RECONCILIATIONS}${params?.id}/` as API_URI;

    sendRequest<DeliveryFetchReconciliationsResponse>(url, {
        method: 'GET',
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchImportHistory: ServiceFetchType<
    DeliveryFetchImportHistoryResponse[],
    DeliveryFetchImportHistoryParams
> = ({ onError, onSuccess, params }) => {
    sendRequest<DeliveryFetchImportHistoryResponse[]>(API_URI.DELIVERY_IMPORT_HISTORY, {
        method: 'GET',
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const fetchRequestCount: ServiceFetchType<
    DeliveryFetchRequestCountResponse,
    DeliveryFetchRequestCountParams
> = ({ onError, onSuccess, params }) => {
    sendRequest<DeliveryFetchRequestCountResponse>(API_URI.DELIVERY_REQUEST_COUNT, {
        method: 'GET',
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};
