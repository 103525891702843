import { FC, memo, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { TableCell } from '@components';
import {
    DeliveryRequest,
    DeliveryRequestOrExpanded,
    DeliveryTableCellStatuses,
    SetRowWithFormat,
    TableRowProps,
} from '@typings';
import { getClassNameByStatus, isUnwrappedRequest } from '@utils';

import styles from './TableRow.module.scss';

const cancelledStatusList = [
    'removed',
    'cancelled',
    'declined',
    'failed',
    'cancelled_with_payment',
    'no_response',
    'driver_callback',
    'new',
];

const getRowClassName = (rowData: DeliveryRequestOrExpanded) => {
    if (!isUnwrappedRequest(rowData)) {
        const isOverduded = rowData.is_overdued;
        const isTimepointOk = rowData.timepoint_ok;
        const isActiveRequest = !cancelledStatusList.includes(rowData.status);

        return clsx({
            [styles.overdued]: isOverduded,
            [styles.row]: !isOverduded,
            [styles[getClassNameByStatus(rowData.status as DeliveryTableCellStatuses)]]:
                !isOverduded,
            [styles.timepoint_ok]: isActiveRequest && !isTimepointOk,
        });
    }

    return '';
};

const TableRow: FC<TableRowProps> = memo(({ columns, onChangeData, ...props }) => {
    const [rowData, setRowData] = useState<DeliveryRequestOrExpanded>(props.rowData);

    useEffect(() => {
        setRowData(props.rowData);
    }, [props.rowData]);

    const setRowWithFormat: SetRowWithFormat = useCallback(
        (
            deliveryRequest: DeliveryRequest,
            prevDeliveryRequest: DeliveryRequestOrExpanded | undefined
        ) => {
            if (prevDeliveryRequest && isUnwrappedRequest(prevDeliveryRequest)) {
                const item = deliveryRequest.items.find(
                    (item) => item.pk === prevDeliveryRequest.tableId
                );

                if (!item) {
                    return;
                }

                // * we have two format of request: DeliveryRequest and DeliveryExpandedRequest
                const newRequestData = { ...prevDeliveryRequest, items: [item] };
                setRowData(newRequestData);
            } else {
                setRowData(deliveryRequest);
            }

            onChangeData(deliveryRequest);
        },
        []
    );

    return (
        <tr className={getRowClassName(rowData)}>
            {columns.map((column) => (
                <TableCell
                    key={column.key}
                    column={column}
                    setRequest={setRowData}
                    rowData={rowData}
                    setRowWithFormat={setRowWithFormat}
                />
            ))}
        </tr>
    );
}, _.isEqual);

export default TableRow;
