import { sendRequest } from '@api';
import { AutocompleteResponse, FetchAutocompleteParams, ServiceFetchType } from '@typings';

export const fetchAutocompleteData: ServiceFetchType<
    AutocompleteResponse,
    FetchAutocompleteParams
> = ({ params, onError, onSuccess }) => {
    const { url, ...currentParams } = params || {};

    sendRequest<AutocompleteResponse>(url!!, {
        method: 'GET',
        params: currentParams,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};
