import { ColumnsType, ColumnType } from 'antd/lib/table';
import clsx from 'clsx';

import {
    DeliveryRequestWorker,
    DeliveryRequestWorkersItems,
    DeliveryWorkersOnAddressTableData,
} from '@typings';
import { getWorkersOnAddress } from '@utils/deliveryWorkersModal';

import { WorkerAction, WorkerFullName, WorkerTimepoint } from './cells';
import { isWorkerOnAddresses, sortWorkers } from './utils';

import styles from './DeliveryWorkersOnAddressTable.module.scss';

interface Params {
    workers: DeliveryRequestWorker[];
    item_workers: DeliveryRequestWorkersItems;
}

export const generateColumns = ({
    item_workers,
    workers,
}: Params): ColumnsType<DeliveryWorkersOnAddressTableData> => {
    const workersOnItem = getWorkersOnAddress(item_workers);

    const sortedWorkers = sortWorkers(workers, workersOnItem);
    return [
        {
            title: '',
            dataIndex: 'address',
            key: 'address',
            render: ({
                addressId,
                name,
                metroLine,
                metroName,
            }: DeliveryWorkersOnAddressTableData['address']) =>
                `${addressId} - ${name} ${metroLine ? `(${metroLine}, ${metroName})` : ''}`,
        },
        {
            title: 'Подача',
            dataIndex: 'confirmedTimepoint',
            key: 'confirmedTimepoint',
            width: 70,
            onCell: ({ confirmedTimepoint }) => {
                const cellStyles = clsx({
                    [styles.dublicate]: confirmedTimepoint.isDuplicateFirst,
                });

                return {
                    className: cellStyles,
                };
            },
            render: ({
                addressId,
                confirmedTimepoint,
                requestPk,
            }: DeliveryWorkersOnAddressTableData['confirmedTimepoint']) => {
                return (
                    <WorkerTimepoint
                        requestPk={requestPk}
                        addressId={addressId}
                        confirmedTimepoint={confirmedTimepoint}
                    />
                );
            },
        },

        ...sortedWorkers.map(
            ({
                name,
                id: workerId,
                confirmed_timepoint: workerConfirmedTimepoint,
            }): ColumnType<DeliveryWorkersOnAddressTableData> => {
                const cellStyles = clsx({
                    [styles.worker_action_unknown_timepoint]:
                        !workerConfirmedTimepoint && isWorkerOnAddresses(workerId, workersOnItem),
                });

                return {
                    width: 200,
                    dataIndex: 'action',
                    key: `action-${workerId}`,
                    className: cellStyles,

                    title: () => {
                        const isWorkerNotOnAdresses = !isWorkerOnAddresses(workerId, workersOnItem);
                        return (
                            <WorkerFullName
                                isWorkerNotOnAdresses={isWorkerNotOnAdresses}
                                confirmedTimepoint={workerConfirmedTimepoint}
                                name={name}
                                workerId={workerId}
                            />
                        );
                    },

                    render: ({
                        addressId,
                        confirmedTimepoint,
                        requestPk,
                        duplicateConfirmedTimepointList,
                    }: DeliveryWorkersOnAddressTableData['action']) => {
                        const isDuplicateFirst =
                            duplicateConfirmedTimepointList.includes(workerConfirmedTimepoint);

                        const isOnAddress =
                            workersOnItem?.[addressId]?.includes(workerId.toString()) || false;

                        return (
                            <WorkerAction
                                requestPk={requestPk}
                                isFirst={confirmedTimepoint === workerConfirmedTimepoint}
                                workerId={workerId}
                                addressId={addressId}
                                key={`${workerId}-${workerId}`}
                                isNotVirtual={workerId > 0}
                                isDuplicateFirst={isDuplicateFirst}
                                isOnAddress={isOnAddress}
                            />
                        );
                    },
                };
            }
        ),
    ];
};
