import { FC } from 'react';

import { BASE_URL_SERVER } from '@constants';
import {
    Nullable,
    WorkersReportMiniTableData,
    WorkersReportWorkerAvailability,
    WorkersReportWorkerDetail,
    WorkersReportWorkerReadiness,
    WorkersReportWorkerStatus,
} from '@typings';

import {
    AvailabilityStatusCell,
    BenevolentStatusCell,
    DatePickerPlanContactDayCell,
    LinkCell,
    OnlineTomorrowStatusCell,
    ReadinessStatusCell,
    StatusCell,
    TagCell,
    TelNumberCell,
    ZoneSelectCell,
} from '.';

interface Props {
    value: WorkersReportMiniTableData['value'];
    title: string;
    cellKey: keyof WorkersReportWorkerDetail;
    workerId: number;
}

const pinkColor = '#fcc';

export const TableMini: FC<Props> = ({ cellKey, workerId, value }) => {
    switch (cellKey) {
        case 'fullName': {
            const url = `${BASE_URL_SERVER}worker/${workerId}`;

            return <LinkCell url={url} text={value as string} />;
        }

        case 'contractStatus': {
            const url = `${BASE_URL_SERVER}staff/worker/${workerId}/documents`;

            return <LinkCell url={url} text={value as string} />;
        }

        case 'zone':
            return <ZoneSelectCell workerId={workerId} value={value as string} />;

        case 'plannedContactDay':
            return (
                <DatePickerPlanContactDayCell
                    style={{ paddingLeft: 0 }}
                    workerId={workerId}
                    value={value as moment.Moment}
                />
            );
        case 'tag':
            return <TagCell tag={(value as string) || '-'} workerId={workerId} />;

        case 'isBenevolent':
            return <BenevolentStatusCell workerId={workerId} isBenevolent={value as boolean} />;

        case 'availability':
            return (
                <AvailabilityStatusCell
                    workerId={workerId}
                    bgColor={pinkColor}
                    availability={value as WorkersReportWorkerAvailability}
                />
            );

        case 'readiness':
            return (
                <ReadinessStatusCell
                    bgColor={pinkColor}
                    workerId={workerId}
                    readiness={value as WorkersReportWorkerReadiness}
                />
            );
        case 'isOnlineTomorrow':
            return (
                <OnlineTomorrowStatusCell
                    workerId={workerId}
                    isOnlineTomorrow={value as Nullable<boolean>}
                />
            );

        case 'telNumber':
            return <TelNumberCell value={value as string} workerId={workerId} />;

        case 'status':
            return <StatusCell status={value as WorkersReportWorkerStatus} />;
        default:
            return <div>{value || '-'}</div>;
    }
};
