import { FC } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { CustomDatePickerRangeProps } from '@typings';

import CustomDatePickerRange from '.';

const CustomDatePickerRangeForm: FC<Omit<CustomDatePickerRangeProps, 'formInstance'>> = ({
    name,
    ...props
}) => {
    const [form] = useForm();
    return (
        <Form name={`${name}Form`} form={form} layout='inline'>
            <CustomDatePickerRange.Item {...props} name={name} formInstance={form} />
        </Form>
    );
};

export default CustomDatePickerRangeForm;
