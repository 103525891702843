import { Moment } from 'moment';

import { Nullable } from '@typings/nullable';

export type WorkersReportWorkerId = number;
export type WorkersReportWorkerKeys = keyof WorkersReportWorker;
export type WorkersReportWorkerValues = WorkersReportWorker[WorkersReportWorkerKeys];

export interface TalkBankBindStatus {
    timestamp: Moment;
    isBound: boolean;
    operationResult: string;
    operationResultDescription: string;
}

export interface WorkersReportWorkerDefault {
    workerId: WorkersReportWorkerId;
    inputDate: Nullable<string>;
    telNumber: string;
    lastTurnoutDate: Nullable<string>;
    action: WorkersReportWorkerStatusCode;
    contractStatus: WorkersReportWorkerStatusCode;
    unconfirmedCount: number;
    registrationOk: boolean;
    isOnlineTomorrow: Nullable<boolean>;
    plannedContactDay: Nullable<Moment>;
    fullName: string;
    zone: WorkersReportWorkerItem;
    label: WorkersReportWorkerItem;
    citizenship: WorkersReportWorkerItem;
    status: WorkersReportWorkerStatus;
    callIcon: number;
    balance: string;
    tag: string;
    lastCallDate: Nullable<string>;
    reliability: Nullable<string>;
    isBenevolent: boolean;
    availability: WorkersReportWorkerAvailability;
    readiness: WorkersReportWorkerReadiness;
    talkBankBindStatus: Nullable<TalkBankBindStatus>;
}

export type WorkersReportWorkerReadiness = 'tomorrow' | 'another_day' | 'not_ready' | 'unknown';
export type WorkersReportWorkerAvailability =
    | 'available'
    | 'not_available'
    | 'no_answer'
    | 'unknown';

export interface WorkersReportWorker extends WorkersReportWorkerDefault {
    lastComment: Nullable<WorkersReportWorkerComment>;
}
export interface WorkersReportWorkerDetail extends WorkersReportWorkerDefault {
    selfie: string;
    comments: Nullable<WorkersReportWorkerComment[]>;
}

export interface WorkersReportWorkerStatus {
    name: string;
    bgColor: string;
}

export interface WorkersReportWorkerItem {
    id: number;
    value: string;
}

export enum WorkersReportWorkerStatusCode {
    ComeOutRecently = 1,
    ComeOutLongAgo = 2,
    NotComeOut = 0,
    Banned = -1,
}

export interface WorkersReportNextDateContact {
    status: string;
    comment?: string;
    date: Moment;
}

export type FetchWorkersReportParams = Partial<{
    availability: string;
    readiness: string;
    first_day: string;
    status_code: string;
    planned_contact_day: string;
    last_call_date: string;
    search_text: string; // TODO not sure
    zone: string;
}>;

export interface WorkersReportFilterFormValues {
    last_call_date: Moment;
    planned_contact_day: Moment;
    availability: string[];
    readiness: string[];
    status_code: string[];
    zone: string;
    search_text: string;
}

export interface WorkersReportWorkerComment {
    text: string;
    timestamp: Date;
    author: WorkersReportWorkerItem;
}

export interface WorkersReportMiniTableData {
    value: Nullable<string | moment.Moment | boolean | WorkersReportWorkerStatus>;
    title: string;
    key: keyof WorkersReportWorkerDetail;
    workerId: WorkersReportWorkerId;
}
