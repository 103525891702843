import { FC, memo } from 'react';
import { Button } from 'antd';

import { updateRequest } from '@api';
import { useRequestConfirm } from '@hooks';
import { DeliveryRequest, SetRowWithFormat } from '@typings';
import { showError } from '@utils/show_error';
import { normalizeDeliveryRequest } from '@utils/standartDataToNewBackend';

interface Props {
    rowData: DeliveryRequest;
    setRowWithFormat: SetRowWithFormat;
}

export const Operator: FC<Props> = memo(({ rowData, setRowWithFormat }) => {
    const { sendRequestWithConfirm } = useRequestConfirm();
    const handleChangeOperator = () => {
        sendRequestWithConfirm({
            requestCallback: updateRequest,
            initialParams: {
                request: rowData.pk.toString(),
                operator: 0,
            },
            onSuccess({ data }) {
                setRowWithFormat(normalizeDeliveryRequest(data.data.request));
            },
            onError(error) {
                showError(error.message);
            },
        });
    };

    if (rowData.operator === null || rowData.operator === '-') {
        return (
            <Button style={{ maxHeight: 20 }} onClick={handleChangeOperator}>
                забрать
            </Button>
        );
    }

    return <>{rowData.operator}</>;
});
