import { FC, useMemo } from 'react';
import { Button } from 'antd';

import { closeModal } from '@store/modal';
import { SetState, WorkersReportGetNextWorkerId, WorkersReportWorkerId } from '@typings';

interface Props {
    isLoading: boolean;
    currentWorkerId: WorkersReportWorkerId;
    setCurrentWorkerId: SetState<WorkersReportWorkerId>;
    getNextWorkerId: WorkersReportGetNextWorkerId;
}

export const FooterCard: FC<Props> = ({
    isLoading,
    setCurrentWorkerId,
    getNextWorkerId,
    currentWorkerId,
}) => {
    const nextWorkerId = useMemo(() => {
        return getNextWorkerId(currentWorkerId);
    }, [currentWorkerId]);

    const onWorkerNext = () => {
        setCurrentWorkerId(nextWorkerId!!);
    };

    const onModalClose = () => {
        closeModal();
    };

    return (
        <>
            {!!nextWorkerId && (
                <Button disabled={isLoading} onClick={onWorkerNext}>
                    Позвонить следующему исполнителю
                </Button>
            )}

            <Button type='primary' onClick={onModalClose}>
                Ок
            </Button>
        </>
    );
};
