import { API_URI, BASE_URL_SERVER_API } from '@constants';
import { RequestConfig, Response } from '@typings';
import { authFetch } from '@utils/auth/auth';

export const sendRequest = <T>(
    uri: API_URI,
    { method = 'GET', ...config }: RequestConfig
): Promise<Response<T>> => {
    const path = `${BASE_URL_SERVER_API}${uri}`;

    return authFetch<T>(path, {
        method,
        ...config,
    });
};
