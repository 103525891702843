const _ADDRESS_RE = /^(Россия, )?(Москва город, )?(Москва,? )?(.*)/;

const _removeMoscow = (address: string): string => {
    const m = address.match(_ADDRESS_RE);

    if (m) {
        return m[4];
    }
    return address;
};

export const shortenAddress = (address: string): string => {
    return _removeMoscow(address).replace('Московская область', 'МО');
};
