import { FC, memo } from 'react';

import {
    AddressCell,
    ElevatorIndicatorCell,
    MetroLineCell,
    MetroNameCell,
    RowControllerCell,
    WorkerCell,
    WorkerCountCell,
} from '@components';
import { CurrentCellProps } from '@typings';

import { ConfirmedTimepointCell, LatenessCell } from '../cells';

export const DefaultCell: FC<Omit<CurrentCellProps, 'isFetching' | 'cellValue'>> = memo(
    ({
        isEditing,
        onCommit,
        rowData,
        cellKey,
        setRequest,
        setRowWithFormat,
        columnDataIndex,
        children,
    }) => {
        switch (cellKey) {
            case 'pk':
                return <RowControllerCell rowData={rowData} />;
            case 'has_elevator': {
                return <ElevatorIndicatorCell rowData={rowData} />;
            }
            case 'assigned_workers':
                return (
                    <WorkerCell
                        setRowWithFormat={setRowWithFormat}
                        rowData={rowData}
                        setRequest={setRequest}
                    />
                );

            case 'confirmed_timepoint':
                return (
                    <ConfirmedTimepointCell
                        columnDataIndex={columnDataIndex}
                        isEditing={isEditing}
                        onCommit={onCommit}
                        rowData={rowData}
                    />
                );
            case 'lateness':
                return <LatenessCell rowData={rowData} />;
            case 'metro_name':
                return (
                    <MetroNameCell isEditing={isEditing} onCommit={onCommit} rowData={rowData} />
                );

            case 'metro_line':
                return <MetroLineCell rowData={rowData} />;
            case 'address':
                return <AddressCell isEditing={isEditing} onCommit={onCommit} rowData={rowData} />;

            case 'worker_count':
                return (
                    <WorkerCountCell isEditing={isEditing} onCommit={onCommit} rowData={rowData} />
                );
            default:
                return <>{children}</>;
        }
    }
);
