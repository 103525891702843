import { FC, useCallback, useEffect, useState } from 'react';
import { RouteProps } from 'react-router';
import { Button, Col, Divider, message, Row, Space, Tabs } from 'antd';
import Title from 'antd/es/typography/Title';
import moment from 'moment';

import {
    createClaims,
    createExpensesExpense,
    fetchActualExpenses,
    fetchClaimsList,
    fetchExpensesExpenseDetail,
    fetchExpensesProviderDetail,
    updateExpensesExpense,
    updateExpensesProvider,
} from '@api';
import {
    ClaimsForm,
    ClaimsTable,
    ExpensesChangeExpenseModal,
    ExpensesForm,
    ExpensesTable,
    GetProviderModal,
} from '@components';
import CustomDatePickerRangeOnSend from '@components/CustomDatePickerRange/CustomDatePickerRangeOnSend';
import { DATE_FORMAT } from '@constants';
import { closeModal, openModal } from '@store/modal';
import {
    ClaimsClaimItem,
    ClaimsFetchClaimsListResponse,
    ClaimsFormFinishValues,
    ExpensesExpenseDetail,
    ExpensesExpenseDetailResponse,
    ExpensesExpenseItem,
    ExpensesFetchActualExpensesReponse,
    ExpensesOnFinishData,
    ExpensesProviderDetail,
    Nullable,
    RenderModal,
    Response,
} from '@typings';
import { GetProviderFormFinishValues } from '@typings/components/getProviderForm';
import { showError } from '@utils/show_error';

import styles from './expenses.module.scss';

const { TabPane } = Tabs;

type ExpensesProps = RouteProps;

interface ExpensesState {
    first_day: string;
    last_day: string;
}

interface ProviderExpensesValues {
    name: string;
    tax_code?: string;
}
type DatePickerState = [moment.Moment, moment.Moment];
interface SearchFormValues {
    datePicker?: DatePickerState;
    full_date?: DatePickerState;
    year?: number;
    month?: number;
    status: string;
}
interface SearchFormProps {
    state: ExpensesState;
    changeFormDataHandler: (arg0: SearchFormValues) => void;
}

const onFetchError = (error: Error) => {
    showError(error.message);
};
interface InitialLoadingState {
    expenses: boolean;
    claims: boolean;
}

const initialLoading: InitialLoadingState = { expenses: false, claims: false };

const SearchForm: FC<SearchFormProps> = ({ state, changeFormDataHandler }) => {
    return (
        <>
            <Row>
                <Col>
                    <Space>
                        <CustomDatePickerRangeOnSend
                            first_day={state.first_day}
                            last_day={state.last_day}
                            placeholder={['С', 'По']}
                            onChange={changeFormDataHandler}
                        />
                    </Space>
                </Col>
            </Row>

            <Divider />
        </>
    );
};

const Expenses: FC<ExpensesProps> = ({ location }) => {
    const params = new URLSearchParams(location?.search);
    const today = new Date();
    const first_day = new Date();
    first_day.setDate(1);
    const initialState: ExpensesState = {
        first_day: params.get('first_day') || moment(first_day).format('DD.MM.YYYY'),
        last_day: params.get('last_day') || moment(today).format('DD.MM.YYYY'),
    };

    const [tab, setTab] = useState('expenses');
    const [dataExpenses, setDataExpenses] = useState<ExpensesExpenseItem[]>([]);
    const [dataClaims, setDataClaims] = useState<ClaimsClaimItem[]>([]);
    const [loading, setLoading] = useState(initialLoading);
    const [state, setState] = useState<ExpensesState>(initialState);

    const [expensesLoading, setExpensesLoading] = useState<boolean>(false);

    const [providerLoading, setProviderLoading] = useState<boolean>(false);
    const [providerInitial, setProviderInitial] = useState<Nullable<ExpensesProviderDetail>>(null);

    const [claimsLoading, setClaimsLoading] = useState<boolean>(false);

    const [_hotReload, setHotReload] = useState<boolean>(false);

    const [rowExpense, setRowExpense] = useState<ExpensesExpenseItem | undefined>(undefined);

    const hotReload = () => {
        setHotReload(!_hotReload);
    };

    const onProviderClick = (pk: number) => {
        fetchExpensesProviderDetail({
            params: { pk },
            onError: onFetchError,
            onSuccess: ({ data }) => {
                setProviderInitial(data.data);
                openProviderMiniModal();
            },
        });
    };

    const onSubmitProvider = (values: ProviderExpensesValues) => {
        setProviderLoading(true);

        const onSuccess = () => {
            hotReload();
            setProviderLoading(false);
            closeModal();
            setProviderInitial(null);
            message.success({ duration: 1.5, content: 'Провайдер внесен.' });
        };

        const onError = (error: Error) => {
            setProviderLoading(false);
            onFetchError(error);
        };

        updateExpensesProvider({ params: values, onSuccess, onError });
    };

    const onSubmitClaims = ({ data, files }: ClaimsFormFinishValues) => {
        setClaimsLoading(true);

        const onSuccess = () => {
            hotReload();
            setClaimsLoading(false);
            message.success({ duration: 1.5, content: 'Претензия внесена.' });
        };
        const onError = (error: Error) => {
            onFetchError(error);
            setProviderLoading(false);
        };

        const photoFormData = new FormData();
        files.forEach((item) => photoFormData.append('files', item));

        createClaims({
            params: { requestParams: data, data: photoFormData },
            onSuccess,
            onError,
        });
    };

    const changeFormDataHandler = useCallback(({ full_date: [first_day, last_day], status }) => {
        setState((state) => ({
            ...state,
            ...{
                first_day: moment(first_day).format(DATE_FORMAT),
                last_day: moment(last_day).format(DATE_FORMAT),
                status,
            },
        }));
    }, []);

    const setLoadingFunction = (loadingNext: Partial<InitialLoadingState>) => {
        setLoading((loadingPrev) => ({ ...loadingPrev, ...loadingNext }));
    };

    const onSubmit = (values: ExpensesOnFinishData) => {
        setExpensesLoading(true);

        const onSuccess = () => {
            hotReload();
            setExpensesLoading(false);
            closeModal();
            message.success({ duration: 1.5, content: 'Расход заявлен.' });
        };

        const onError = (error: Error) => {
            onFetchError(error);
            setExpensesLoading(false);
        };

        createExpensesExpense({ params: values, onError, onSuccess });
    };

    const editExpense = (pk: number) => {
        const onSuccess = ({ data }: Response<ExpensesExpenseDetailResponse>) => {
            openModal(
                renderExpensesFormModal({
                    isLoading: expensesLoading,
                    initial: data.data,
                    onSubmit,
                })
            );
        };

        fetchExpensesExpenseDetail({ params: { pk }, onSuccess, onError: onFetchError });
    };

    const updateExpense = async (expenseId: number, action: string, comment = '') => {
        const onSuccess = () => {
            hotReload();
        };

        const params = {
            pk: expenseId,
            action: action,
            comment: comment,
        };

        updateExpensesExpense({ params, onSuccess, onError: onFetchError });
    };

    const onCloseProvider = () => {
        hotReload();
        closeModal();
        setProviderInitial(null);
    };

    const onExpenseAction = (
        expenseId: number,
        action: string,
        comment = '',
        row?: ExpensesExpenseItem
    ) => {
        if (action === 'fill_from') {
            setRowExpense(row);
        } else if (action === 'edit') {
            editExpense(expenseId);
        } else {
            updateExpense(expenseId, action, comment);
        }
    };

    const openProviderMiniModal = () => {
        openModal(
            renderGetProviderModal({
                initial: providerInitial,
                onSubmit: onSubmitProvider,
                isLoading: providerLoading,
                onClose: onCloseProvider,
            })
        );
    };

    useEffect(() => {
        const updateData = () => {
            if (tab === 'expenses') {
                setLoadingFunction({ expenses: true });

                const onSuccess = ({ data }: Response<ExpensesFetchActualExpensesReponse>) => {
                    setDataExpenses(data.expenses);
                    setLoadingFunction({ expenses: false });
                };
                const onError = () => {
                    setLoadingFunction({ expenses: false });
                };

                fetchActualExpenses({ params: state, onSuccess, onError });
            }
            if (tab === 'claims') {
                setLoadingFunction({ claims: true });

                const onSuccess = ({ data }: Response<ClaimsFetchClaimsListResponse>) => {
                    setDataClaims(data.data);
                    setLoadingFunction({ claims: false });
                };

                const onError = () => {
                    setLoadingFunction({ claims: false });
                };

                fetchClaimsList({ params: state, onSuccess, onError });
            }
        };

        updateData();
    }, [state, tab, _hotReload]);

    return (
        <>
            <Tabs
                defaultActiveKey='1'
                onChange={(tab) => {
                    setTab(tab);
                }}
            >
                <TabPane tab='Расходы' key='expenses'>
                    <Row key='provider_row' justify='space-between' align='top'>
                        <Col>
                            {!expensesLoading && (
                                <ExpensesForm
                                    rowExpense={rowExpense}
                                    showAdministrationExpenses={true}
                                    showLabels={false}
                                    showSubmitButton={true}
                                    onSubmit={onSubmit}
                                />
                            )}
                        </Col>
                        <Col key='expense_form_provider'>
                            <Button
                                key='provider_button'
                                onClick={openProviderMiniModal}
                                style={{ float: 'right' }}
                            >
                                Завести поставщика
                            </Button>
                        </Col>
                    </Row>
                    <Title style={{ marginTop: 50 }} level={4}>
                        Расходы за интервал
                    </Title>
                    <SearchForm state={state} changeFormDataHandler={changeFormDataHandler} />
                    <Row>
                        <Col className={styles.expenses_table_column}>
                            <ExpensesTable
                                data={dataExpenses}
                                loading={loading.expenses}
                                onExpenseAction={onExpenseAction}
                                onProviderClick={onProviderClick}
                                initialPageSize={100}
                                showTotal={(total) => `Всего ${total} записей`}
                            />
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tab='Претензии' key='claims'>
                    <Row>
                        <Col>{!claimsLoading && <ClaimsForm onSubmit={onSubmitClaims} />}</Col>
                    </Row>
                    <Title style={{ marginTop: 50 }} level={4}>
                        Претензии за интервал
                    </Title>
                    <SearchForm state={state} changeFormDataHandler={changeFormDataHandler} />
                    <Row>
                        <Col>
                            <ClaimsTable data={dataClaims} loading={loading.claims} />
                        </Col>
                    </Row>
                </TabPane>
            </Tabs>
        </>
    );
};

export default Expenses;

interface RenderExpensesFormModalParams {
    initial: ExpensesExpenseDetail;
    onSubmit(values: ExpensesOnFinishData): void;
    isLoading: boolean;
}

const renderExpensesFormModal = ({
    initial,
    isLoading,
    onSubmit,
}: RenderExpensesFormModalParams): RenderModal => {
    return (isOpen) => {
        return (
            <ExpensesChangeExpenseModal
                initial={initial}
                isLoading={isLoading}
                isOpen={isOpen}
                onSubmit={onSubmit}
            />
        );
    };
};

interface RenderProviderMiniModalParams {
    onClose(): void;
    isLoading: boolean;
    initial: Nullable<ExpensesProviderDetail>;
    onSubmit(values: GetProviderFormFinishValues): void;
}

const renderGetProviderModal = ({
    onClose,
    initial,
    isLoading,
    onSubmit,
}: RenderProviderMiniModalParams): RenderModal => {
    return (isOpen) => {
        return (
            <GetProviderModal
                isOpen={isOpen}
                initial={initial}
                onSubmit={onSubmit}
                isLoading={isLoading}
                onCancel={onClose}
            />
        );
    };
};
