import { memo, useState } from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/es/table';
import { ColumnsType } from 'antd/lib/table';

import styles from './ScrollableTable.module.css';

interface ScrollableTableProps<T> extends TableProps<T> {
    data: T[];
    loading: boolean;
    columns: ColumnsType<T>;
    dataToTableData?: (item: T) => T;
    initialPageSize?: number;
    showTotal?: (total: number) => string;
}

const typedMemo: <T>(_props: T) => T = memo;

export const ScrollableTable = typedMemo(
    <T extends object>({
        dataToTableData,
        loading,
        data,
        columns,
        initialPageSize,
        showTotal,
        ...otherProps
    }: ScrollableTableProps<T>) => {
        const [pageSize, setPageSize] = useState(initialPageSize || 20);

        const tableData = data.map((item, i) => {
            const currentData = dataToTableData?.(item) || {};

            return {
                key: i,
                ...item,
                ...currentData,
            };
        });

        return (
            <Table
                size='small'
                className={styles.TableNoWrap}
                columns={columns}
                dataSource={tableData}
                scroll={{ x: true }}
                tableLayout='fixed'
                loading={loading}
                pagination={{
                    showTotal,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    onChange: (_value, pageSize) => setPageSize(pageSize || initialPageSize || 20),
                }}
                {...otherProps}
            />
        );
    }
);
