import { FC } from 'react';
import { Tag } from 'antd';

interface Props {
    code: number;
}

const getStatusByCode = (code: number) => {
    switch (code) {
        case -1:
            return { text: 'Отмена', color: 'lime' };

        case -2:
            return { text: 'Брак', color: 'lime' };

        case -3:
            return { text: 'Срыв', color: 'lime' };

        case -4:
            return { text: 'Удален', color: 'lime' };

        case 0:
            return { text: 'Новая', color: '' };

        case 2:
            return { text: 'Подтвержден на заявке', color: 'cyan' };

        case 1:
            return { text: 'Селфи отклонено', color: 'orange' };

        case 3:
            return { text: 'Подозрительный адрес', color: 'blue' };

        case 4:
            return { text: 'Проверка адреса', color: 'blue' };

        case 5:
            return { text: 'Подтверждён на адресе', color: 'orange' };

        case 6:
            return { text: 'Накладная отклонена', color: 'blue' };

        case 7:
            return { text: 'Накладная', color: 'blue' };

        case 8:
            return { text: 'Работа выполнена', color: 'green' };
        default:
            return {
                text: 'Неизвестно',
                color: '',
            };
    }
};

export const RenderStatus: FC<Props> = ({ code }) => {
    const { color, text } = getStatusByCode(code);

    return <Tag color={color}>{text}</Tag>;
};
