import React, { FC, useState } from 'react';
import { Button, Input, Popover, Tooltip } from 'antd';

import { CloseCircleTwoTone, CloseOutlined } from '@ant-design/icons';
import { rejectItemWorkerFinishPhoto, rejectItemWorkerStartPhoto } from '@api';
import { useRequestConfirm } from '@hooks';
import {
    DashboardPhotoActionsView,
    DashboardPhotoItemType,
    DashborardPhotoActionButtonProps,
} from '@typings';

interface Props extends DashborardPhotoActionButtonProps {
    type: DashboardPhotoItemType;
    photoId: number;
    viewType: DashboardPhotoActionsView;
}

export const CancelButton: FC<Props> = ({
    onSuccess,
    requestId,
    type,
    photoId,
    onError,
    viewType,
}) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const { sendRequestWithConfirm } = useRequestConfirm();

    const buttonDisable = comment === '';
    const buttonStyle = { background: '#f44336', borderColor: 'unset' };

    const onCancelSubmit = async () => {
        if (buttonDisable) {
            return;
        }

        setVisible(false);

        const initialRequestParams = {
            onSuccess,
            onError,
            initialParams: {
                request: requestId,
                photo: photoId,
                comment,
            },
        };

        if (type === 'finish') {
            sendRequestWithConfirm({
                ...initialRequestParams,
                requestCallback: rejectItemWorkerFinishPhoto,
            });
        } else {
            sendRequestWithConfirm({
                ...initialRequestParams,
                requestCallback: rejectItemWorkerStartPhoto,
            });
        }
    };

    const content = (
        <div>
            <Input onPressEnter={onCancelSubmit} onChange={(e) => setComment(e.target.value)} />
        </div>
    );

    const renderContent = () => {
        if (viewType === 'icon') {
            return (
                <Tooltip placement='bottom' title='Отклонить фото'>
                    <CloseCircleTwoTone
                        style={{ margin: '0 5px 0 5px' }}
                        onClick={onCancelSubmit}
                    />
                </Tooltip>
            );
        }

        return (
            <Button
                style={buttonStyle}
                type='primary'
                onClick={onCancelSubmit}
                disabled={buttonDisable}
            >
                <CloseOutlined />
                Отклонить фото
            </Button>
        );
    };

    return (
        <Popover
            content={content}
            title='Комментарий'
            visible={visible}
            trigger='click'
            onVisibleChange={(visible) => setVisible(visible)}
            overlayStyle={{ zIndex: 9999 }}
            destroyTooltipOnHide
        >
            {renderContent()}
        </Popover>
    );
};
