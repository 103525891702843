export const readinessOptions = [
    {
        value: 'tomorrow',
        label: 'Готов завтра',
    },
    {
        value: 'another_day',
        label: 'Готов в др. день',
    },
    {
        value: 'not_ready',
        label: 'Не готов',
    },
    {
        value: 'unknown',
        label: 'Неизвестно',
    },
];

export const availabilityOptions = [
    {
        value: 'available',
        label: 'На связи',
    },
    {
        value: 'not_available',
        label: 'Нет связи',
    },
    {
        value: 'no_answer',
        label: 'Не отвечает',
    },
    {
        value: 'unknown',
        label: 'Не звонили',
    },
];

export const statusCodeOptions = [
    {
        value: '1',
        label: 'Актив',
    },
    {
        value: '2',
        label: 'Выходил давно',
    },
    {
        value: '0',
        label: 'Не выходил',
    },
    {
        value: '-1',
        label: 'Забанен',
    },
];
