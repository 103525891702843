import { FC } from 'react';

import { SetState } from '@typings';

import styles from './tooltipPhotoElementInfo.module.scss';
interface ToolTipData {
    reason: string;
    title: string;
    isVisible: boolean;
}
interface Props {
    tooltipData: ToolTipData[];
    setShowAction: SetState<boolean>;
}

export const TooltipPhotoElementInfo: FC<Props> = ({ tooltipData, setShowAction }) => {
    const isAllToolTipNotVisible = tooltipData.every(({ isVisible }) => !isVisible);

    if (isAllToolTipNotVisible) {
        return null;
    }

    const onClick = () => {
        setShowAction(true);
    };

    const tooltipEl = tooltipData.map(renderTooltipElements);
    return (
        <div onClick={onClick} className={styles.main}>
            {tooltipEl}
        </div>
    );
};

interface TooltipElementProps {
    title: string;
    reason: string;
    isVisible: boolean;
}

const renderTooltipElements = ({ title, isVisible, reason }: ToolTipData, index: number) => {
    return <TooltipElement key={index} title={title} isVisible={isVisible} reason={reason} />;
};

const TooltipElement: FC<TooltipElementProps> = ({ reason, title, isVisible }) => {
    if (!isVisible) {
        return null;
    }

    return (
        <>
            <p className={styles.title}>{title}</p>
            <p className={styles.subtitle}>{reason}</p>
            <br />
        </>
    );
};
