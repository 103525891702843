import { FC, useState } from 'react';
import { Button, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';

import RemoteSelect from '@components/RemoteSelect';
import { API_URI } from '@constants';

import styles from './DeliveryWorkersOnAddress.module.scss';

export type TSelectWorkers = Array<number>;

interface FormValues {
    worker: number;
}

interface Props {
    requestPk: number;
    onWorkerAdd: (workerId: number) => void;
}

export const WorkersController: FC<Props> = ({ requestPk, onWorkerAdd }) => {
    const [form] = useForm<FormValues>();
    const [isDisabled, setIsDisabled] = useState(true);

    const addWorkers = ({ worker }: FormValues) => {
        onWorkerAdd(worker);

        form.resetFields();
    };

    const handleFormChange = () => {
        const hasErrors = !form.getFieldValue('worker')?.length;
        setIsDisabled(hasErrors);
    };

    return (
        <Form
            className={styles.worker_controller}
            form={form}
            layout='inline'
            onFinish={addWorkers}
            onFieldsChange={handleFormChange}
        >
            <RemoteSelect.WithForward
                name='worker'
                required={false}
                form={form}
                className={styles.worker_controller_item}
                url={API_URI.AUTOCOMPLETE_WORKER}
                externalForward={{
                    delivery_request_pk: requestPk,
                }}
            />

            <Form.Item>
                <Button htmlType='submit' type='primary' disabled={isDisabled}>
                    Добавить грузчика
                </Button>
            </Form.Item>
        </Form>
    );
};
