import { sendRequest } from '@api';
import { API_URI } from '@constants';
import {
    ClaimsCreateClaimParams,
    ClaimsCreateClaimResponse,
    ClaimsFetchClaimsListParams,
    ClaimsFetchClaimsListResponse,
    ServiceFetchType,
} from '@typings';

export const fetchClaimsList: ServiceFetchType<
    ClaimsFetchClaimsListResponse,
    ClaimsFetchClaimsListParams
> = ({ params, onError, onSuccess }) => {
    sendRequest<ClaimsFetchClaimsListResponse>(API_URI.CLAIMS_LIST, {
        method: 'GET',
        params,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};

export const createClaims: ServiceFetchType<ClaimsCreateClaimResponse, ClaimsCreateClaimParams> = ({
    params,
    onSuccess,
    onError,
}) => {
    const { requestParams, data } = params || {};

    sendRequest<ClaimsCreateClaimResponse>(API_URI.CLAIMS_CREATE, {
        method: 'POST',
        params: requestParams,
        data,
    })
        .then((response) => {
            onSuccess?.(response);
        })
        .catch((error: Error) => {
            onError?.(error);
        });
};
