import { useCallback, useEffect, useMemo } from 'react';

import {
    CustomColumn,
    FilterColumnsState,
    FilterListColumns,
    Nullable,
    OnColumnViewChange,
    SetState,
} from '@typings';

import { useLocalStorage } from '../useLocalStorage';

interface Params<T> {
    notifyFilterChange(columnIndex: number): void;
    setColumns: SetState<CustomColumn<T>[]>;
    columns: CustomColumn<T>[];
    name: string;
}

const mapperColumnsToFilters = <T>(columns: CustomColumn<T>[]) => {
    return columns?.reduce((acc: FilterColumnsState, column) => {
        const { isVisible, key } = column || {};

        if (key) {
            return { ...acc, [key.toString()]: !!isVisible };
        }

        return acc;
    }, {});
};

export const useFilters = <T>({ name, notifyFilterChange, setColumns, columns }: Params<T>) => {
    const [columnsFilters, setColumnsFilters] = useLocalStorage<Nullable<FilterColumnsState>>(
        `${name}_columns_filter`,
        null
    );

    const initializeFilters = useCallback(
        (columns: CustomColumn<T>[]) => {
            if (!columnsFilters) {
                setColumns(columns);

                return setColumnsFilters(mapperColumnsToFilters(columns));
            }

            setColumns(() => {
                return columns.map((column) => {
                    if (!column?.key) {
                        return column;
                    }

                    const isExistFilter = column.key.toString()!! in columnsFilters!!;

                    if (isExistFilter) {
                        return { ...column, isVisible: columnsFilters[column.key.toString()] };
                    }

                    return column;
                });
            });
        },
        [columnsFilters, setColumnsFilters]
    );

    useEffect(() => {
        setColumnsFilters(mapperColumnsToFilters(columns));
    }, [columns]);

    const onColumnViewChange: OnColumnViewChange = useCallback(
        (key, isVisible) => {
            setColumns((prev) => {
                return prev?.map((column, columnIndex) => {
                    notifyFilterChange(columnIndex);

                    if (column.key === key) {
                        return { ...column, isVisible };
                    }

                    return column;
                });
            });
        },
        [setColumnsFilters]
    );

    const filterListColumns: FilterListColumns = useMemo(() => {
        return columns?.reduce((acc: FilterListColumns, column) => {
            const { isVisible, title, fullTitle, key, isNotEdit } = column || {};

            if (isNotEdit) {
                return acc;
            }

            const currentTitle = fullTitle || title?.toString() || '';

            const filterData = {
                isVisible: !!isVisible,
                title: currentTitle,
                key: key?.toString() || '',
            };

            return [...acc, filterData];
        }, []);
    }, [columns]);

    return {
        onColumnViewChange,
        filterListColumns,
        initializeFilters,
    };
};
