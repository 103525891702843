import { FC } from 'react';
import { DatePicker, Form } from 'antd';

import { DATE_FORMAT } from '@constants';

interface Props {
    showLabels: boolean;
}

export const DaysInterval: FC<Props> = ({ showLabels }) => (
    <Form.Item
        key='days_interval'
        name='days_interval'
        style={{ marginBottom: 0 }}
        label={showLabels ? 'Интервал' : undefined}
        rules={[{ required: true, message: 'Это поле необходимо!' }]}
    >
        <DatePicker.RangePicker
            format={DATE_FORMAT}
            placeholder={['С', 'По']}
            style={{ width: '240px' }}
        />
    </Form.Item>
);
