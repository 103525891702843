import { memo, useMemo } from 'react';
import { Button, Descriptions, List, Popover, Typography } from 'antd';
import moment from 'moment';

import { MoreOutlined } from '@ant-design/icons';
import { DATE_FORMAT } from '@constants';

import { statusToText, TooltipRender } from './tooltip';

export function statusToColor(status?: string): string {
    switch (status) {
        case 'new':
            return 'white';
        case 'ready_for_notification':
            return '#F4FDAF';
        case 'notification_sent':
            return '#93B8DE';
        case 'confirmed':
            return '#8AEA92';
        default:
            return 'red';
    }
}

export interface RequestData {
    date?: string;
    id?: number;
    location?: number;
    requests_paid?: number;
    requests_total?: number;
    status?: string;
    worker_count?: number;
    worked_hours?: string;
    actions?: {
        confirm_unconfirm: boolean;
    };
    columnDate?: string;
}

export const CellRender = memo((props: RequestData) => {
    const {
        id,
        location,
        date,
        worked_hours,
        worker_count,
        requests_paid,
        requests_total,
        actions,
        status,
    } = props ?? {};

    const customStyle = useMemo(() => {
        const statusColor = statusToColor(status);
        return {
            cell: { backgroundColor: statusColor, mixBlendMode: 'multiply' as const },
        };
    }, [status]);

    const existsData = useMemo(() => {
        return !(!props || !(Object.keys(props ?? {}).length > 2));
    }, [props]);

    let title = undefined;
    if (existsData) {
        title = statusToText(status);
        title += `\nзаявок: ${requests_paid ?? '0'} в оплату/${
            requests_total ?? '0'
        } подано (включая отмены)`;
        title += `\n${worker_count ?? '0'} грузчиков/${worked_hours ?? '0'} часов`;
    }

    const formattedDate = moment(date).format(DATE_FORMAT);
    const ref = `/rf/delivery/?first_day=${formattedDate}&last_day=${formattedDate}&location=${location}`;

    return (
        <a href={ref} target='_blank' rel='noreferrer' title={title}>
            <Descriptions column={6} size='small'>
                <Descriptions.Item span={4} style={existsData ? customStyle.cell : undefined}>
                    {existsData && (
                        <List size={'small'}>
                            <List.Item style={{ padding: '0px' }}>
                                <Typography.Text>
                                    {`${requests_paid ?? '0'}/${requests_total ?? '0'}`}
                                </Typography.Text>
                            </List.Item>
                            <List.Item style={{ padding: '0px' }}>
                                <Typography.Text>
                                    {`${worker_count ?? '0'}/${worked_hours ?? '0'}`}
                                </Typography.Text>
                            </List.Item>
                        </List>
                    )}
                </Descriptions.Item>
                <Descriptions.Item style={existsData ? customStyle.cell : undefined}>
                    {existsData && (
                        <Popover
                            placement='leftBottom'
                            content={<TooltipRender id={`${id ?? ''}`} actions={actions} />}
                            destroyTooltipOnHide={true}
                            trigger={'click'}
                        >
                            <Button
                                type='default'
                                shape='circle'
                                size={'small'}
                                icon={<MoreOutlined />}
                                style={customStyle.cell}
                            />
                        </Popover>
                    )}
                </Descriptions.Item>
            </Descriptions>
        </a>
    );
});
