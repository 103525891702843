import { FC, useContext, useState } from 'react';
import { Checkbox } from 'antd';
import { AxiosError } from 'axios';

import { addItemWorker, removeItemWorker } from '@api';
import { useRequestConfirm } from '@hooks';
import { DeliveryWorkersOnAddressContext } from '@providers';
import { DeliveryRequestMutationDefaultResponse, Response } from '@typings';
import { mapperRequestResponseDataToDetailFx } from '@utils/deliveryWorkersModal';
import { showError } from '@utils/show_error';

interface Props {
    onAddress: boolean;
    requestPk: number;
    addressId: number;
    workerId: number;
    isNotVirtual: boolean;
}

export const AddressCheckbox: FC<Props> = ({
    onAddress,
    addressId,
    requestPk,
    workerId,
    isNotVirtual,
}) => {
    const { sendRequestWithConfirm } = useRequestConfirm();
    const { updateTable } = useContext(DeliveryWorkersOnAddressContext);
    const [itemState, setItemState] = useState(onAddress);

    const changeStateInItem = () => {
        if (addressId && isNotVirtual) {
            const onSuccess = ({ data }: Response<DeliveryRequestMutationDefaultResponse>) => {
                if (data) {
                    setItemState(!itemState);
                    updateTable(mapperRequestResponseDataToDetailFx(data.data));
                }
            };

            const onError = (error: Error | AxiosError) => {
                showError((error as AxiosError)?.response?.data?.detail || error?.message);
            };

            if (itemState) {
                sendRequestWithConfirm({
                    requestCallback: removeItemWorker,
                    initialParams: {
                        request: requestPk,
                        item: addressId,
                        worker: workerId,
                        reason: 'cancelled',
                    },
                    onSuccess,
                    onError,
                });
            } else {
                sendRequestWithConfirm({
                    requestCallback: addItemWorker,
                    initialParams: {
                        request: requestPk,
                        item: addressId,
                        workers: [workerId],
                    },
                    onSuccess,
                    onError,
                });
            }
        }
    };

    return <Checkbox checked={itemState} onClick={changeStateInItem} />;
};
