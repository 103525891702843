import { createContext, FC, useMemo } from 'react';

import { SetState } from '@typings';

interface IDeliveryTableContext {
    setExpandedRequests: SetState<number[]>;
}

export const DeliveryTableContext = createContext<IDeliveryTableContext>(
    {} as IDeliveryTableContext
);

export const DeliveryTableProvider: FC<IDeliveryTableContext> = ({
    setExpandedRequests,
    children,
}) => {
    const value = useMemo(
        () => ({
            setExpandedRequests,
        }),
        []
    );

    return <DeliveryTableContext.Provider value={value}>{children}</DeliveryTableContext.Provider>;
};
