import { getEnvValue } from '@utils/getEnvValue';

export const BASE_URL_SERVER = getEnvValue(
    'REACT_APP_GT_BO_BASE_URL_SERVER',
    'http://dodo.ozbs.ru:52080/'
);
export const BASE_URL_SERVER_API = `${BASE_URL_SERVER}bo/`;

export const DADATA_API_KEY = '864c4422fa0e7f050a37d04c1dc17ef57bb4f879';
export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_FORMAT_WITH_TIME = 'DD.MM.YYYY HH:mm';
export const MEDIA_PREFIX = '/media/';
export const PATH_ROUTES = [
    {
        key: 'requests_page',
        path: '/rf/delivery/',
    },
    {
        key: 'delivery_requests_on_map',
        path: '/rf/delivery/requests_on_map/',
    },
    {
        key: 'expenses_page',
        path: '/rf/expenses/',
    },
    {
        key: 'imports_report',
        path: '/rf/delivery/imports_report/',
    },
    {
        key: 'photo_dashboard',
        path: '/rf/delivery/photos_dashboard/',
    },
    {
        key: 'requests_count',
        path: '/rf/delivery/requests_count/',
    },
    {
        key: 'requests_on_map',
        path: '/rf/delivery/requests_on_map/',
    },
    {
        key: 'workers_report',
        path: '/rf/delivery/workers_report/',
    },
    {
        key: 'daily_reconciliations',
        path: '/rf/delivery/calendar/',
    },
];

export const jsonTypeRequestHeaders = {
    'Content-Type': 'application/json',
};

export const API_URLS = {
    customer_ac_url: 'common/customer_autocomplete/',
    import_requests_url: 'delivery/request/import/',
    import_template_url: 'static/files/delivery/delivery_template.xlsx',
    interval_report_url: 'delivery/v2/reports/interval/',
    day_report_url: 'delivery/v2/reports/day/',
    item_ac_url: 'delivery/item_autocomplete/',
    move_item_url: 'delivery/move_item/',
    copy_item_url: 'delivery/copy_item/',
    photo_checking_url: 'delivery/photo_checking_page/',
    remove_item_url: 'delivery/remove_item/',
    requests_list_url: 'delivery/requests_list/',
    route_ac_url: 'delivery/route_autocomplete/',
    location_ac_url: 'delivery/location_autocomplete/',
    service_ac_url: 'delivery/service_autocomplete/',
    update_assigned_worker_url: 'delivery/update_assigned_worker/',
    update_request_url: 'delivery/update_request/',
    worker_ac_url: 'delivery/worker_autocomplete/',
    operator_ac_url: 'delivery/operator_autocomplete/',
    worker_url_template: 'worker/12345/',
};

export enum API_URI {
    DELIVERY_LIST = 'delivery/request/',
    DELIVERY_ADDITIONAL_PHOTOS = 'delivery/request/photos/',
    DELIVERY_ADDITIONAL_PHOTOS_ADD = 'delivery/request/photos/add/',
    DELIVERY_REQUEST_DETAIL = 'delivery/request/detail/',
    DELIVERY_PHOTOS = 'delivery/photos/',
    DELIVERY_WORKERS_CALL = 'delivery/workers/call/',
    DELIVERY_MOVE_ITEM = 'delivery/request/item/move/ ',
    DELIVERY_CREATE_REQUEST = 'delivery/request/create/',
    DELIVERY_CREATE_ITEM_REQUEST = 'delivery/request/item/create/',
    DELIVERY_FINISH_HOURS = 'delivery/request/worker/hours/',
    DELIVERY_UPDATE_REQUEST = 'delivery/request/update/',
    DELIVERY_UPDATE_ITEM_REQUEST = 'delivery/request/item/update/',

    DELIVERY_WORKER_ADD = 'delivery/request/worker/add/',
    DELIVERY_ITEM_WORKER_ITEM_ADD = 'delivery/request/item/worker/add/',
    DELIVERY_ITEM_WORKER_REMOVE = 'delivery/request/item/worker/remove/',
    DELIVERY_ITEM_WORKER_DELETE = 'delivery/request/item/worker/delete/',
    DELIVERY_ITEM_WORKER_START = 'delivery/request/item/worker/start/',
    DELIVERY_WORKER_CONFIRM = 'delivery/request/worker/confirm/',
    DELIVERY_ITEM_WORKER_START_PHOTO_CONFIRM = 'delivery/request/item/worker/start/photo/confirm/',
    DELIVERY_ITEM_WORKER_FINISH_PHOTO_CONFIRM = 'delivery/request/item/worker/finish/photo/confirm/',
    DELIVERY_ITEM_WORKER_START_PHOTO_REJECT = 'delivery/request/item/worker/start/photo/reject/',
    DELIVERY_ITEM_WORKER_FINISH_PHOTO_REJECT = 'delivery/request/item/worker/finish/photo/reject/',
    DELIVERY_ITEM_WORKER_START_CONFIRM = 'delivery/request/item/worker/start/confirm/',
    DELIVERY_ITEM_WORKER_FINISH_CONFIRM = 'delivery/request/item/worker/finish/confirm/',
    DELIVERY_ITEM_WORKER_FINISH = 'delivery/request/item/worker/finish/',
    DELIVERY_ITEM_WORKER_START_DISCREPANCY = 'delivery/request/item/worker/start/discrepancy/',

    DELIVERY_WORKERS = 'delivery/workers/',
    DELIVERY_BAN_WORKER = 'delivery/ban_worker/',
    DELIVERY_UNBAN_WORKER = 'delivery/unban_worker/',
    DELIVERY_WORKER_MAP_DATA = 'delivery/worker_map_data/',
    DELIVERY_WORKERS_SET_PLANNED_CONTACT_DAY = 'delivery/workers/set_planned_contact_day/',
    DELIVERY_WORKERS_LABEL_SET = 'delivery/workers/label/set/',
    DELIVERY_WORKER_ZONE_SET = 'delivery/set_worker_zone/',
    DELIVERY_WORKER_RATING_UPDATE = 'delivery/workers/rating/update/',
    DELIVERY_WORKER_UPDATE_ONLINE_STATUS = 'delivery/workers/update_online_status/',
    DELIVERY_WORKER_BIND_TO_TALK_BANK = 'delivery/workers/bind_to_talk_bank/',

    DELIVERY_RECONCILIATIONS_CALENDAR = 'delivery/reconciliations/calendar/',
    DELIVERY_RECONCILIATIONS = 'delivery/reconciliations/',
    DELIVERY_IMPORT_HISTORY = 'delivery/import_history/',
    DELIVERY_REQUEST_COUNT = 'delivery/request_count/',

    MAP_PAGE = 'map/',
    MAP_REQUEST = 'delivery/map/request/',

    EXPENSES_PROVIDER_UPDATE = 'expenses/provider/update/',
    EXPENSES_PROVIDER_DETAIL = 'expenses/provider/detail/',
    EXPENSES_EXPENSE_CREATE = 'expenses/expense/create/',
    EXPENSES_EXPENSE_DETAIL = 'expenses/expense/detail/',
    EXPENSES_EXPENSE_UPDATE = 'expenses/expense/update/',
    EXPENSES_ACTUAL_EXPENSES = 'expenses/actual_expenses/',

    CLAIMS_LIST = 'claims/list/',
    CLAIMS_CREATE = 'claims/create/',

    AUTOCOMPLETE_ZONE = 'delivery/zone_autocomplete/',
    AUTOCOMPLETE_ITEM = 'delivery/item_autocomplete/',
    AUTOCOMPLETE_COMMON_CUSTOMER = 'common/customer_autocomplete/',
    AUTOCOMPLETE_LOCATION = 'delivery/location_autocomplete/',
    AUTOCOMPLETE_ROUTE = 'delivery/route_autocomplete/',
    AUTOCOMPLETE_WORKER_BY_CUSTOMER = 'common/worker_by_customer_autocomplete/',
    AUTOCOMPLETE_PROVIDER = 'expenses/provider_autocomplete/',
    AUTOCOMPLETE_INDUSTRIAL_CUST_TYPE = 'common/industrial_cost_type_autocomplete/',
    AUTOCOMPLETE_EXPENSE = 'expenses/expense_autocomplete/',
    AUTOCOMPLETE_TURNOUT = 'claims/turnout_autocomplete/',
    AUTOCOMPLETE_ADMINISTRATION_COST_TYPE = 'common/administration_cost_type_autocomplete',
    AUTOCOMPLETE_MATERIAL = 'common/material_autocomplete',
    AUTOCOMPLETE_WORKER_LABEL = 'delivery/worker_label_autocomplete/',
    AUTOCOMPLETE_WORKER = 'delivery/worker_autocomplete/',
    AUTOCOMPLETE_DELIVERY_CUSTOMER = 'delivery/customer_autocomplete/',
    AUTOCOMPLETE_SERVICE = 'delivery/service_autocomplete/',
    AUTOCOMPLETE_OPERATOR = 'delivery/operator_autocomplete/',
    AUTOCOMPLETE_MAP_WORKER = 'delivery/map/autocomplete/worker/',
    AUTOCOMPLETE_MAP_REQUEST = 'delivery/map/autocomplete/request/',

    AUTH_LOGIN = 'auth/login/',
}
