import { FC, useState } from 'react';
import { Button as BSButton } from 'react-bootstrap';
import { ButtonProps, Checkbox } from 'antd';
import { AxiosError } from 'axios';

import { StopOutlined } from '@ant-design/icons';
import { addItemWorker, deleteItemWorker, removeItemWorker } from '@api';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRequestConfirm } from '@hooks';
import { DeliveryRequestMutationDefaultResponse, Response } from '@typings';
import { mapperRequestResponseDataToDetailFx } from '@utils/deliveryWorkersModal';
import { showError } from '@utils/show_error';

import { $itemRequest, $requestId, reloadTableFx, setRequestDetailFx } from '../model';
interface IRemoveFromItem {
    worker: number;
    workerOnAdress: boolean;
}

const ButtonAction: FC<ButtonProps> = (props) => (
    <BSButton
        {...props}
        style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            fontSize: '12px',
        }}
        variant='outline-warning'
        size='sm'
    >
        {props.children}
    </BSButton>
);

const onFetchError = (error: Error) => {
    showError(error.message);
};

export const RemoveFromItemActions: FC<IRemoveFromItem> = ({ worker, workerOnAdress }) => {
    const [onAddress, setOnAddress] = useState(workerOnAdress);
    const { sendRequestWithConfirm } = useRequestConfirm();
    const request = $requestId.getState();
    const item = $itemRequest.getState();

    const setReason = (reason: 'cancelled' | 'failure') => {
        if (request && item) {
            sendRequestWithConfirm({
                requestCallback: removeItemWorker,
                onError: onFetchError,
                initialParams: {
                    request,
                    item,
                    worker,
                    reason,
                },
                onSuccess: ({ data }) => {
                    if (data) {
                        setRequestDetailFx(mapperRequestResponseDataToDetailFx(data.data));
                        reloadTableFx();
                    }
                },
            });
        }
    };

    const changeOnAdress = () => {
        if (request && item) {
            const onSuccess = ({ data }: Response<DeliveryRequestMutationDefaultResponse>) => {
                if (data) {
                    setRequestDetailFx(mapperRequestResponseDataToDetailFx(data.data));
                    setOnAddress(!onAddress);
                }
            };

            const onError = (error: Error | AxiosError) => {
                showError((error as AxiosError)?.response?.data?.detail || error?.message);
            };

            if (onAddress) {
                sendRequestWithConfirm({
                    requestCallback: removeItemWorker,
                    onSuccess,
                    onError,
                    initialParams: {
                        reason: 'cancelled',
                        request,
                        item,
                        worker,
                    },
                });
            } else {
                sendRequestWithConfirm({
                    requestCallback: addItemWorker,
                    onSuccess,
                    onError,
                    initialParams: {
                        request,
                        item,
                        workers: [worker],
                    },
                });
            }
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
            }}
        >
            <ButtonAction onClick={() => setReason('failure')}>
                <StopOutlined />
                Срыв
            </ButtonAction>

            <Checkbox checked={onAddress} onChange={changeOnAdress}>
                <span style={{ fontSize: 12 }}>На адресе</span>
            </Checkbox>
        </div>
    );
};

interface RemoveButtonProps {
    worker: number;
}

export const RemoveButton: FC<RemoveButtonProps> = ({ worker }) => {
    const { sendRequestWithConfirm } = useRequestConfirm();
    const request = $requestId.getState();
    const item = $itemRequest.getState();

    const removeAction = () => {
        if (request && item) {
            sendRequestWithConfirm({
                requestCallback: deleteItemWorker,
                onError: onFetchError,
                initialParams: {
                    request,
                    item,
                    worker,
                },
                onSuccess: ({ data }) => {
                    if (data) {
                        setRequestDetailFx(mapperRequestResponseDataToDetailFx(data.data));
                        reloadTableFx();
                    }
                },
            });
        }
    };

    return (
        <BSButton variant='outline-danger' size='sm' onClick={removeAction}>
            <FontAwesomeIcon icon={faTrash} />
        </BSButton>
    );
};
