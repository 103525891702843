import { FC } from 'react';

import {
    DashboardPhotoActionsView,
    DashboardPhotoItemType,
    DashborardPhotoActionButtonProps,
} from '@typings';

import { CancelButton } from './CancelButton';
import { ConfirmFinishButton } from './ConfirmFinishButton';
import { ConfirmStartButton } from './ConfirmStartButton';

interface ButtonsNewProps extends DashborardPhotoActionButtonProps {
    type: DashboardPhotoItemType;
    photoId: number;
    itemId: number;
    workerId: number;
    isVisible: boolean;
    viewType: DashboardPhotoActionsView;
}

export const ButtonsNew: FC<ButtonsNewProps> = ({
    itemId,
    photoId,
    requestId,
    workerId,
    type,
    onSuccess,
    onError,
    viewType,
    isVisible,
}) => {
    const renderConfirmButton = () => {
        if (type === 'start') {
            if (isVisible) {
                return (
                    <ConfirmStartButton
                        viewType={viewType}
                        itemId={itemId}
                        type={type}
                        onSuccess={onSuccess}
                        onError={onError}
                        requestId={requestId}
                        workerId={workerId}
                    />
                );
            }
        } else {
            return (
                <ConfirmFinishButton
                    viewType={viewType}
                    itemId={itemId}
                    type={type}
                    onSuccess={onSuccess}
                    onError={onError}
                    requestId={requestId}
                    workerId={workerId}
                />
            );
        }
    };

    return (
        <>
            <CancelButton
                viewType={viewType}
                photoId={photoId}
                type={type}
                onSuccess={onSuccess}
                onError={onError}
                requestId={requestId}
            />
            {renderConfirmButton()}
        </>
    );
};
