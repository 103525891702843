import { memo, useCallback, useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment from 'moment';

import { SearchOutlined } from '@ant-design/icons';
import { FieldItemWithObservableValue } from '@components';
import CustomDatePickerRange from '@components/CustomDatePickerRange';
import { DeliveryAddRequestModal } from '@components/modals';
import RemoteSelect from '@components/RemoteSelect';
import { API_URI, DATE_FORMAT } from '@constants';
import { openModal } from '@store/modal';
import {
    DeliveryColumn,
    DeliveryFilterState,
    DeliverySearchFormData,
    FieldData,
    OnColumnViewChange,
    RenderModal,
    SetState,
} from '@typings';
import {
    mapperDeliveryStateToFormData,
    mapperSearchFinishFormDataToFetchData,
} from '@utils/delivery';

import { ColumnsController } from './ColumnsController';
import { ReportsExport } from './ReportsExport';
import { RequestsImport } from './RequestsImport';

import styles from './searchForm.module.scss';

interface SearchFormProps {
    columns: DeliveryColumn[];
    filterState: Partial<DeliveryFilterState>;
    setColumns: SetState<DeliveryColumn[]>;
    fetchData: () => void;
    changeFormFilterHandler: (filters: DeliveryFilterState) => void;
    toggleLoading: (isLoading: boolean) => void;
}

type FiledValue = string | boolean | string[] | undefined;

interface DefaultForward {
    datepicker: [moment.Moment, moment.Moment];
}

export const SearchForm = memo(
    ({
        columns,
        setColumns,
        changeFormFilterHandler,
        filterState,
        fetchData,
        toggleLoading,
    }: SearchFormProps) => {
        const [form] = useForm();
        const [{ lastDay, firstDay }, setDays] = useState({
            lastDay: filterState.last_day,
            firstDay: filterState.first_day,
        });
        const [fields] = useState<FieldData<FiledValue>[]>(
            mapperDeliveryStateToFormData(filterState)
        );

        useEffect(() => {
            form.setFields(mapperDeliveryStateToFormData(filterState));
            setDays({ lastDay: filterState.last_day, firstDay: filterState.first_day });
        }, [filterState]);

        const onColumnViewChange: OnColumnViewChange = useCallback(
            (key, isVisible) => {
                setColumns((prev) => {
                    return prev?.map((column) => {
                        if (column.key === key) {
                            return { ...column, checked: isVisible };
                        }

                        return column;
                    });
                });
            },
            [setColumns, columns]
        );

        const onFinish = (values: DeliverySearchFormData) => {
            changeFormFilterHandler(mapperSearchFinishFormDataToFetchData(values));
        };

        const onModalOpen = () => {
            openModal(renderModal({ fetchData, toggleLoading }));
        };

        const transformDefaultForward = ({ datepicker }: DefaultForward) => {
            const [first_day, last_day] =
                datepicker?.map((date) => {
                    return moment(date).format(DATE_FORMAT);
                }) || [];

            return {
                first_day,
                last_day,
            };
        };

        return (
            <div className={styles.search_form}>
                <Row gutter={[0, 10]}>
                    <Form layout='inline' fields={fields} form={form} onFinish={onFinish}>
                        <Col span={10}>
                            <Form.Item name='search_text'>
                                <Input
                                    className={styles.search}
                                    prefix={<SearchOutlined />}
                                    placeholder='Найти заявки'
                                />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <RemoteSelect.WithForward
                                url={API_URI.AUTOCOMPLETE_ZONE}
                                className={styles.zone}
                                form={form}
                                name='zone'
                                placeholder='Город'
                                required={false}
                                formForward={['datepicker']}
                                isClearOnForward={false}
                                transformForward={transformDefaultForward}
                                mode='multiple'
                            />
                        </Col>

                        <Col span={2.5}>
                            <Form.Item name='can_merge' valuePropName='checked'>
                                <Checkbox>Только объединяемые</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={2.5}>
                            <Form.Item name='in_progress' valuePropName='checked'>
                                <Checkbox>Только незакрытые заявки</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={2.5}>
                            <Form.Item name='unprofitable' valuePropName='checked'>
                                <Checkbox>Только убыточные</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={24} className={styles.second_row}>
                            <div className={styles.row_container}>
                                <CustomDatePickerRange.Item
                                    name='datepicker'
                                    first_day={firstDay!!}
                                    last_day={lastDay!!}
                                    placeholder={['С', 'По']}
                                    showYearSelector={false}
                                    showMonthSelector={false}
                                    formInstance={form}
                                />

                                <RemoteSelect.WithForward
                                    form={form}
                                    url={API_URI.AUTOCOMPLETE_OPERATOR}
                                    name='operator'
                                    placeholder='Оператор'
                                    required={false}
                                    formForward={['datepicker']}
                                    isClearOnForward={false}
                                    transformForward={transformDefaultForward}
                                    mode='multiple'
                                />

                                <Form.Item name='customer_resolution'>
                                    <Select
                                        allowClear={true}
                                        className='search-form__client-resolution-select'
                                        placeholder='Резолюция клиента'
                                        options={[
                                            { label: 'Согласованы', value: 'confirmed' },
                                            { label: 'На рассмотрении', value: 'normal' },
                                            { label: 'Подозрительные', value: 'suspicious' },
                                        ]}
                                    />
                                </Form.Item>

                                <RemoteSelect.WithForward
                                    form={form}
                                    url={API_URI.AUTOCOMPLETE_DELIVERY_CUSTOMER}
                                    name='customer'
                                    placeholder='Клиент'
                                    required={false}
                                    formForward={['datepicker']}
                                    isClearOnForward={false}
                                    transformForward={transformDefaultForward}
                                />
                                <FieldItemWithObservableValue dependency={['customer']}>
                                    <RemoteSelect.WithForward
                                        form={form}
                                        url={API_URI.AUTOCOMPLETE_LOCATION}
                                        name='location'
                                        placeholder='Филиал'
                                        required={false}
                                        formForward={['customer']}
                                    />
                                </FieldItemWithObservableValue>

                                <Button htmlType='submit' type='primary'>
                                    Обновить
                                </Button>
                            </div>

                            <button
                                type='button'
                                className={styles.create_request_button}
                                onClick={onModalOpen}
                                title='Создать заявку'
                            >
                                <img alt='icon' src='/icons/add_icon.svg' />
                            </button>

                            <RequestsImport />
                            <ReportsExport state={filterState} />
                            <ColumnsController
                                columns={columns}
                                onColumnViewChange={onColumnViewChange}
                            />
                        </Col>
                    </Form>
                </Row>
            </div>
        );
    }
);

interface RenderModalParams {
    fetchData: () => void;
    toggleLoading: (isLoading: boolean) => void;
}

const renderModal =
    ({ fetchData, toggleLoading }: RenderModalParams): RenderModal =>
    (isOpen) => {
        return (
            <DeliveryAddRequestModal
                onSuccess={fetchData}
                toggleLoading={toggleLoading}
                isOpen={isOpen}
            />
        );
    };
