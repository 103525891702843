import { useEffect, useRef } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';

export const useScroll = () => {
    const gridRef = useRef<Grid>();

    useEffect(() => {
        gridRef?.current?.resetAfterIndices({
            columnIndex: 0,
            rowIndex: 0,
            shouldForceUpdate: true,
        });
    }, [gridRef]);

    return { gridRef };
};
