import { ChangeEvent, FC, useState } from 'react';
import { Input } from 'antd';
import { AxiosError } from 'axios';

import { CheckOutlined } from '@ant-design/icons';
import { updateDeliveryFinishHours } from '@api';
import { useRequestConfirm } from '@hooks';
import {
    DeliveryRequestDetail,
    DeliveryRequestMutationDefaultResponse,
    Nullable,
    Response,
} from '@typings';
import { mapperRequestResponseDataToDetailFx } from '@utils/deliveryWorkersModal';
import { showError } from '@utils/show_error';

interface IRenderHours {
    request: number;
    item: Nullable<number>;
    worker: number;
    hours: string;
    onUpdate: (data: DeliveryRequestDetail) => void;
}

export const RenderHours: FC<IRenderHours> = ({ request, worker, hours, onUpdate }) => {
    const { sendRequestWithConfirm } = useRequestConfirm();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [{ currentHours, editHours }, setHours] = useState({
        currentHours: hours,
        editHours: hours,
    });

    const showEdit = () => {
        setEditMode(true);
    };

    const onUpdateHours = () => {
        setEditMode(false);

        const clearField = () => {
            setHours({
                editHours: hours,
                currentHours: hours,
            });
        };
        const onError = (error: Error) => {
            showError((error as AxiosError)?.response?.data?.detail || error.message);
            clearField();
        };

        const onSuccess = (data: Response<DeliveryRequestMutationDefaultResponse>) => {
            onUpdate(mapperRequestResponseDataToDetailFx(data.data.data));
            setHours((prev) => ({
                ...prev,
                currentHours: prev.editHours,
            }));
        };

        sendRequestWithConfirm({
            requestCallback: updateDeliveryFinishHours,
            onCancel: clearField,
            onError,
            onSuccess,
            initialParams: {
                hours: parseFloat(editHours),
                request,
                worker,
            },
        });
    };
    const onHoursChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
        setHours((prev) => ({ ...prev, editHours: target.value }));
    };

    const inputStyles = { width: '50px', border: '1px solid #d9d9d9', outline: 'none' };
    const editContainerStyles = { display: 'flex', gap: '5px', alignItems: 'center' };
    const iconStyles = { color: 'green' };

    if (!editMode) {
        return <div onClick={showEdit}>{currentHours}</div>;
    }

    return (
        <div style={editContainerStyles}>
            <Input
                onPressEnter={onUpdateHours}
                onChange={onHoursChange}
                style={inputStyles}
                value={editHours}
                autoFocus
            />
            <CheckOutlined style={iconStyles} onClick={onUpdateHours} />
        </div>
    );
};
