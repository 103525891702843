import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { fetchRequestData } from '@api';
import { DeliveryFilterState, DeliveryParamsType, DeliveryState } from '@typings';
import { concatParamsToQuery, mapperDeliveryParamsToDeliveryState } from '@utils/queryString';
import { showError } from '@utils/show_error';
import { normalizeDeliveryRequestList } from '@utils/standartDataToNewBackend';

import { useDeliverySearchParams } from '.';

const generateInitialData = (params: DeliveryParamsType): DeliveryState => ({
    requestsFilters: mapperDeliveryParamsToDeliveryState(params),
    requestsData: [],
    isLoading: false,
});

export const useRequests = () => {
    const history = useHistory();
    const params = useDeliverySearchParams();

    const [{ requestsData, isLoading, requestsFilters }, setRequestsData] = useState(
        generateInitialData(params)
    );

    const toggleLoading = useCallback((isLoading: boolean) => {
        setRequestsData((prevState) => ({
            ...prevState,
            isLoading,
        }));
    }, []);

    const onFiltersChange = useCallback((filters: DeliveryFilterState) => {
        setRequestsData((state) => ({
            ...state,
            requestsFilters: {
                ...state.requestsFilters,
                ...filters,
            },
        }));
    }, []);

    const getRequests = useCallback(
        (withLoading = true) => {
            if (!requestsFilters) {
                return;
            }

            if (withLoading) {
                toggleLoading(true);
            }

            fetchRequestData({
                params: requestsFilters,
                onError: (error: Error) => {
                    toggleLoading(false);
                    showError(error.message);
                },

                onSuccess: ({ data }) => {
                    const query = concatParamsToQuery(requestsFilters);
                    const href = history.location.pathname + query; // TODO refactor to react router dom

                    history.push(href); // update URL after correct response, no reason to update if error appears

                    setRequestsData((prevState) => ({
                        ...prevState,
                        isLoading: false,
                        requestsData: normalizeDeliveryRequestList(data.data),
                    }));
                },
            });
        },
        [requestsFilters]
    );
    useEffect(() => {
        getRequests();
    }, [getRequests]);

    return {
        getRequests,
        toggleLoading,
        onFiltersChange,
        requestsData,
        requestsFilters,
        setRequestsData,
        isLoading,
    };
};
